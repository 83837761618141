<div class="border-gray-400 border rounded-2xl">
  <table class="cust-table-home w-full table-auto rounded-t-2xl overflow-hidden">
    <thead>
      <tr class="text-[12px] text-left">
        <th class="bg-gray-300 dark:bg-slate-900 dark:text-gray-300">Equipment</th>
        <th class="bg-gray-300 dark:bg-slate-900 dark:text-gray-300">Hours</th>
        <th class="bg-gray-300 dark:bg-slate-900 dark:text-gray-300 text-right" *ngIf="!readOnly">
          Action
        </th>
      </tr>
    </thead>
    <!-- <span class="block h-3"></span> -->
    <tbody class="font-avertareguler">
      <ng-container *ngIf="equipment.selectedEquipment.length > 0; else noEquip">
        <ng-container *ngFor="let equip of equipment.selectedEquipment">
          <tr *ngIf="!equip.deleted">
            <td class="dark:text-gray-300">
              <span class="text-xs"
                >{{ equip?.name }} ({{ equip?.equipmentNumber }})</span
              >
            </td>
            <td class="dark:text-gray-300">
              <span class="text-xs">
                {{ formatHours(equip?.hours) }}
              </span>
            </td>
            <td class="dark:text-gray-300">
              <span class="flex justify-end space-x-2">
                <button
                  *ngIf="!readOnly"
                  (click)="loadEdit(equip)"
                  class="text-yellow-500 text-right text-xs"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                </button>
                <button
                  *ngIf="!readOnly"
                  (click)="deleteEquipment(equip)"
                  class="text-red-600 text-right text-xs"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </button>
              </span>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-template #noEquip>
        <tr>
          <td colspan="4">
            <span
              class="bg-[#ECD60E]/30 text-[#102030] dark:text-white duration-200 py-1 px-2 text-xs rounded-full block"
              >No Equipment Selected</span
            >
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
  <div class="flex justify-end mt-2">
    <button
      *ngIf="!readOnly"
      class="py-2 px-4 space-x-1 rounded-full text-[16px] border-2 bg-gradient-to-t from-[#000733] to-[#000D59] text-white flex items-center mr-2 mb-2"
      (click)="openEquipmentModal()"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
      </span>
      <span>Add</span>
    </button>
  </div>
</div>