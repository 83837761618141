import { Component, OnDestroy, OnInit, inject, ViewChild } from '@angular/core';
import { ProfileService } from '../shared/profile/profile.service';
import { AuthService } from '../core/services/auth.service';
import { Subscription } from 'rxjs';
import { ConstantsService } from '../core/services/constants.service';
import { IdleService } from '../shared/idle.service';
import { MatDialog } from '@angular/material/dialog';
import { IdleDialogComponent } from '../shared/idle-dialog/idle-dialog.component';
import { Router } from '@angular/router';
import { RcService } from '../core/services/rc.service';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReviewEntriesComponent } from '../pages/review-entries/review-entries.component';
import { SignalrService } from '../core/services/signalr.service';

declare var $: any;
@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.css']
})

export class LayoutsComponent implements OnInit, OnDestroy {

  idleService = inject(IdleService);
  private idleSubscription?: Subscription
  isModalOpen: boolean = false;
  isIdeallogOut: boolean = false;

  userData: any;
  employeeNumber!: number;
  theme!: string;

  settingsSubscription!: Subscription;

  isSupervisor: boolean = false;
  isReviewer: boolean = false;
  isModalOpenCloseConcurrentLogin: boolean = false;
  progressPercentage: number = 100;
  timer: number = 10;



  constructor(
    private readonly signalrService: SignalrService,
    private readonly profileService: ProfileService,
    private readonly constantsService: ConstantsService,
    private readonly authService: AuthService,
    private router: Router,
    public rcService: RcService,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    this.authService.getCurrentUser().subscribe((user) => {
      this.userData = user;
      this.employeeNumber = user?.credentials?.EmployeeNumber;
      this.isSupervisor = user?.isSupervisor;
      this.isReviewer = user?.canReviewFirstLevel;

    });

    this.signalrService.initializeSignalRConnection(this.userData);



    if (this.isSupervisor || this.isReviewer) {
      this.settingsSubscription = this.profileService.settings$.subscribe(newSettings => {
        this.theme = newSettings?.find((itm: any) => itm.ProfileTypeID == 3)?.SettingValue;
        if (this.theme === undefined) {
          this.getProfileSettings();
        } else if (this.theme === 'seasonal') {
          this.theme = this.constantsService.getCurrentSeason();
        }
      });
    } else {
      this.theme = this.constantsService.getCurrentSeason();
    }
    this.initListener();
  }
  openModalOpenCloseConcurrentLogin() {
    this.isModalOpenCloseConcurrentLogin = true;
  }


  ngAfterViewInit() {
    if (this.userData.ConcurrentLogin) {

      this.authService.checkActiveSessions(this.userData.credentials.EmployeeNumber).subscribe((d) => {
        if (!d) {
          this.isModalOpenCloseConcurrentLogin = true;
        }
      });
    }
  }
  ngOnInit(): void {
    this.idleSubscription = this.idleService.idleState.subscribe((isIdle) => {
      // console.log('User is isIdle');
      // console.log(isIdle);
      if (isIdle) {
        this.isModalOpen = true;
        this.startProgressCountdown();
        this.isIdeallogOut = true;
        this.progressPercentage = 100;
        this.timer = 10;
      }
      // else {
      //   console.log('User is active');
      // }
    })
    // this.getProfileSettings();    
  }

  startProgressCountdown(): void {
    const interval = setInterval(() => {

      if (this.isModalOpen) {
        if (this.progressPercentage > 0) {
          this.progressPercentage -= (1 * +this.timer);
        } else {
          if (this.isIdeallogOut) {
            clearInterval(interval);
            // Handle what should happen when the progress reaches 0, e.g., auto logout
            this.logout(); // Automatically log out when progress reaches 0
          }
        }
      }
    }, 1000); // Decrease by 1% every second

  }

  stay(): void {
    this.idleService.resetTimer();
    this.isModalOpen = false;
    this.isIdeallogOut = false;
    this.progressPercentage = 100;
    this.timer = 10;
  }

  CloseConcurrentLogin(): void {
    this.authService.clearUserData();
    this.router.navigate([`/${this.rcService.getTenant()}/auth/login`]);
  }
  logout(): void {
    this.authService.clearUserData();
    this.router.navigate([`/${this.rcService.getTenant()}/auth/login`]);
  }

  private initListener(): void {
    document.addEventListener('mousemove', () => {
      this.idleService.resetTimer();
    });
    document.addEventListener('keypress', () => {
      this.idleService.resetTimer();
    });
  }

  ngOnDestroy(): void {
    if (this.idleSubscription) {
      this.idleSubscription?.unsubscribe();
    }
  }

  getProfileSettings(): void {
    // Assuming there is a method in your service to retrieve saved settings
    this.profileService.getProfileSettings(this.employeeNumber).subscribe((savedSettings) => {
      // debugger;
      if (savedSettings) {
        let currentTheme = savedSettings.find((setting: { ProfileTypeID: number; }) => setting.ProfileTypeID === 3)?.SettingValue;
        this.theme = currentTheme;
        if (this.theme === 'seasonal') {
          this.theme = this.constantsService.getCurrentSeason();
        }
      }
    });
  }

}
