import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DropdownService } from './dropdown.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { operatorMetadata } from '../operator-metadata';

interface OperatorOption {
    operator: 'Contains' | 'DoesNotContains' | 'Equals' | 'DoesNotEqual' | 'LessThan' | 'LessThanOrEqual' | 'GreaterThan' | 'GreaterThanOrEqual' | 'StartsWith' | 'EndsWith' | 'IsLike' | 'IsNotLike';
    label: string;
    icon: string;
    paths?: string[];
}

@Component({
    selector: 'app-operator-dropdown',
    templateUrl: './operator-dropdown.component.html',
    styleUrls: ['./operator-dropdown.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OperatorDropdownComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() operators: OperatorOption[] = [];
    @Input() dropdownId: string = '';
    @Input() defaultOperator: any;

    @Output() operatorSelected = new EventEmitter<any>();

    dropdownVisible = false;
    selectedOperator: string = '';
    iconCache: { [key: string]: SafeHtml } = {};

    private subscription!: Subscription;

    constructor(
        private dropdownService: DropdownService,
        public sanitizer: DomSanitizer,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.subscription = this.dropdownService.openDropdownId$.subscribe(id => {
            if (id !== null) {
                this.dropdownVisible = (id === this.dropdownId);
                this.cdr.detectChanges(); // Trigger change detection when the open state changes
            }
        });

        // Pre-cache icons
        this.operators.forEach(operator => {
            this.iconCache[operator.icon] = this.getIconSvg(operator.icon);
        });
        this.iconCache[this.selectedOperator] = this.getIconSvg(this.selectedOperator);
    }

    ngAfterViewInit() {
        document.addEventListener('click', this.handleClickOutside);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['defaultOperator']?.currentValue) {
            this.selectedOperator = operatorMetadata[this.defaultOperator]?.icon;
        }
    }

    /* toggleDropdown(event: MouseEvent) {
        event.stopPropagation();
        this.dropdownVisible = !this.dropdownVisible;
        this.dropdownService.setOpenDropdown(this.dropdownVisible ? this.dropdownId : null);
        this.cdr.detectChanges(); // Force change detection
    } */
    toggleDropdown(event: MouseEvent) {
        event.stopPropagation();
        if (this.dropdownVisible) {
            // If this dropdown is open, close it
            this.dropdownService.setOpenDropdown(null);
        } else {
            // If this dropdown is closed, open it and close any other open dropdowns
            this.dropdownService.setOpenDropdown(this.dropdownId);
        }
        this.cdr.detectChanges(); // Force change detection
    }

    selectOperator(operator: OperatorOption) {
        this.selectedOperator = operator.icon;
        this.operatorSelected.emit(operator.operator);
        this.dropdownVisible = false;
        this.dropdownService.setOpenDropdown(null);
        this.cdr.detectChanges(); // Update the view
    }

    private handleClickOutside = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const clickedInside = target.closest('.dropdown-container');
        if (!clickedInside) {
            this.dropdownVisible = false;
            this.dropdownService.setOpenDropdown(null);
            this.cdr.detectChanges(); // Ensure view is updated
        }
    }

    getIconSvg(icon: string): SafeHtml {
        if (!this.iconCache[icon]) {
            this.iconCache[icon] = this.sanitizer.bypassSecurityTrustHtml(icon);
        }
        return this.iconCache[icon];
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        document.removeEventListener('click', this.handleClickOutside);
    }
}