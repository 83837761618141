import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { SharedService } from '../shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RcService } from 'src/app/core/services/rc.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  userData: any;
  updatePasswordForm!: FormGroup;

  forcePassword: boolean = false;

  hideCurrentPassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;
  // hide = true;

  submitted = false;

  passwordErrors: any = {
    lowercase: 'at least one lowercase character',
    uppercase: 'at least one uppercase character',
    digit: 'at least one number',
    specialCharacter: ' at least one special character',
    minLength: 'at least 8 characters',
    // noSpace: 'Password must not contain spaces.'
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private rcService: RcService,
    private toastr: ToastrService,
    private readonly authService: AuthService,
    private readonly sharedService: SharedService,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.forcePassword = data.forcePassword;
    this.authService.getCurrentUser().subscribe((user) => {
      this.userData = user;
    });
  }

  ngOnInit(): void {
    this.updatePasswordForm = this.fb.group({
      currentPassword: ['', {
        validators: [Validators.required, this.validateCurrentPassword.bind(this)]
      }],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        this.passwordStrengthValidator,
        // this.noSpaceValidator
      ]],
      confirmPassword: ['', Validators.required],
    }, { validators: this.passwordMatchValidator });
  }

  passwordStrengthValidator(control: any) {
    const password = control.value;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    const errors: any = {};
    
    if (!passwordRegex.test(password)) {
      if (!/(?=.*[a-z])/.test(password)) {
        errors.lowercase = true;
      }
      if (!/(?=.*[A-Z])/.test(password)) {
        errors.uppercase = true;
      }
      if (!/(?=.*\d)/.test(password)) {
        errors.digit = true;
      }
      if (!/(?=.*[!@#$%^&*(),.?":{}|<>])/.test(password)) {
        errors.specialCharacter = true;
      }
      const passwordWithoutSpace = password.trim();
      console.log("passwordWithoutSpace : ",passwordWithoutSpace)
      if (passwordWithoutSpace.length < 8) {
        errors.minLength = true;
      }
    }
    
    return Object.keys(errors).length !== 0 ? errors : null;
  }

  // noSpaceValidator(control: any) {
  //   const password = control.value;
  //   if (password && password.trim().length === password.length) {
  //     return null;
  //   } else {
  //     return { hasSpace: true };
  //   }
  // }

  validateCurrentPassword(control: any) {
    let currentPassword = this.userData?.credentials?.Password;
    const enteredPassword = control.value;
    return enteredPassword === currentPassword ? null : { invalidCurrentPassword: true };
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const newPasswordControl = formGroup.get('newPassword');
    const confirmPasswordControl = formGroup.get('confirmPassword');

    if (newPasswordControl && confirmPasswordControl) {
      const newPassword = newPasswordControl.value;
      const confirmPassword = confirmPasswordControl.value;

      if (newPassword !== confirmPassword) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }
  }

  updatePassword() {
    this.submitted = true;
    if (this.updatePasswordForm.valid) {
      let fieldData = this.updatePasswordForm.value;
      let employeeNumber = this.userData?.credentials?.EmployeeNumber;
      // Perform password update logic here
      console.log('Form submitted successfully!', fieldData);
      this.sharedService.updatePassword(employeeNumber, fieldData.currentPassword, fieldData.newPassword, false).subscribe({
        next: (response) => {
          console.log('response', response);
          console.log('password updated for Employee: ', employeeNumber);
          this.toastr.clear();
          this.toastr.success('Password updated successfully!', 'Success', {
            positionClass: 'toast-bottom-left',
            toastClass: "toast-icon custom-toast-success"
          });
          this.dialogRef.close();
          this.authService.clearUserData();
          this.router.navigate([`/${this.rcService.getTenant()}/auth/login`]);
        },
        error: (error) => {
          console.log('Error with password update: ', error);
          let errorMsg;
          if (error.status == 0) {
            errorMsg = 'Please check your internet connection!';
          } else {
            errorMsg = 'Error with password update!';
          }
          this.toastr.clear();
          this.toastr.error(errorMsg, 'Error', {
            positionClass: 'toast-bottom-left',
            toastClass: "toast-icon custom-toast-error",
          });            
        }
      });
    }
  }

  toggleVisibility(field: string): void {
    if (field === 'current') {
      this.hideCurrentPassword = !this.hideCurrentPassword;
    } else if (field === 'new') {
      this.hideNewPassword = !this.hideNewPassword;
    } else if (field === 'confirm') {
      this.hideConfirmPassword = !this.hideConfirmPassword;
    }
  }

  closePasswordDialog(): void {
    this.dialogRef.close();
  }
  
  closePasswordLogout(logout: string): void {
    this.dialogRef.close('logout');
  }

  // @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent) {
  //   event.preventDefault();
  // }

  @HostListener('copy', ['$event']) blockCopy(event: ClipboardEvent) {
    event.preventDefault();
  }

}
