import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConstantsService } from 'src/app/core/services/constants.service';

@Injectable({
  providedIn: 'root'
})
export class ReviewEntriesService {
  private getgroupsUrl = '/supervisor/getgroups';
  private getreviewgroupsUrl = '/supervisor/getreviewgroups';
  private getgroupemployeesUrl = '/supervisor/getgroupemployees';
  private hastimeclockUrl = '/employeehours/hastimeclock';
  private getcalendarcountsUrl = '/supervisor/getcalendarcounts';
  private createemployeemessageUrl = '/supervisor/createemployeemessage';
  private savegroupUrl = '/supervisor/savegroup';
  private savegroupemployeesUrl = '/supervisor/savegroupemployees';
  private deletegroupUrl = '/supervisor/deletegroup';
  private movetimeUrl = '/employeehours/movetime';

  private getSupervisorReviewEmployeesUrl = '/employeehours/getSupervisorReviewEmployees';
  private getSupervisorDepartmentDetailsUrl = '/employeehours/getSupervisorDepartmentDetails';
  private getsupervisorreviewUrl = '/employeehours/getsupervisorreview';
  private getsupervisoremployeesUrl = '/employeehours/getsupervisoremployees';
  private getallemployeesUrl = '/employeehours/getallemployees';
  private getPageSupervisorHeadDetailsUrl = '/employeehours/getPageSupervisorHeadDetails';
  private getPageSupervisorHeadEmpDetailsUrl = '/employeehours/getPageSupervisorHeadEmpDetails';
  private getPageSupervisorDetailsPendingUrl = '/employeehours/getPageSupervisorDetailsPending';
  private getPageSupervisorDetailsSummitedUrl = '/employeehours/getPageSupervisorDetailsSummited';
  private getPageSupervisorDetailsRejectedUrl = '/employeehours/getPageSupervisorDetailsRejected';
  private getPageSupervisorDetailsAprrovedUrl = '/employeehours/getPageSupervisorDetailsAprroved';
  private getsupervisorrejectedUrl = '/employeehours/getsupervisorrejected';
  private getPageSupervisorHeadSummaryDetailsUrl = '/employeehours/getPageSupervisorHeadSummaryDetails';
  private getPageEmployeeSummaryDetailsUrl = '/employeehours/getPageEmployeeSummaryDetails';

  private copytransactionUrl = '/details/copytransaction';
  private validatepayhourscopytransactionsEmployeesUrl = '/details/validatepayhourscopytransactionsEmployees';

  private readonly clientToken: string = '';
  private readonly authToken: any = '';

  constructor(private http: HttpClient, private authService: AuthService,
    private constantService: ConstantsService) {
    this.clientToken = this.constantService.CLIENT_TOKEN;
    this.authToken = this.authService.getToken();
  }

  getGroups(employeeNumber: number): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeNumber = employeeNumber;
    return this.http.post(`${this.getgroupsUrl}`, payload);
  }

  getSupervisorReviewEmployees(employeeNumber: number): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeNumber = employeeNumber;
    return this.http.post(`${this.getSupervisorReviewEmployeesUrl}`, payload);
  }

  async getSupervisorReviewEmployeesAsync(employeeNumber: number): Promise<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeNumber = employeeNumber;
    return firstValueFrom(this.http.post(`${this.getSupervisorReviewEmployeesUrl}`, payload));
  }

  getSupervisorDepartmentDetails(employeeNumber: number, isDirectReport: boolean): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('SupervisorNumber', employeeNumber);
    httpParams = httpParams.append('IsDirectReport', isDirectReport);
    return this.http.get(`${this.getSupervisorDepartmentDetailsUrl}`, { params: httpParams });
  }

  getSupervisorReview(employeeNumber: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('SupervisorNumber', employeeNumber);
    return this.http.get(`${this.getsupervisorreviewUrl}`, { params: httpParams });
  }

  getAllEmployees(): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    return this.http.post(`${this.getallemployeesUrl}`, payload);
  }

  getSupervisorEmployees(employeeNumber: number): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeNumber = employeeNumber;
    return this.http.post(`${this.getsupervisoremployeesUrl}`, payload);
  }

  getReviewGroups(employeeNumber: number): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeNumber = employeeNumber;
    return this.http.post(`${this.getreviewgroupsUrl}`, payload);
  }

  getGroupEmployees(groupId: number): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.GroupID = groupId;
    return this.http.post(`${this.getgroupemployeesUrl}`, payload);
  }

  employeeHasTimeclockHours(ee: any, date: string): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeNumber = ee;
    payload.HoursDate = date;
    return this.http.post(`${this.hastimeclockUrl}`, payload);
  }

  getPageSupervisorHeadDetails(employeeNumber: number, date: string, ee: any, searchcolumn: string, search: string, isDirectReport: any, noOfDay: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('SupervisorNumber', employeeNumber);
    httpParams = httpParams.append('TimeCardDate', date);
    httpParams = httpParams.append('EmployeeNumber', (ee ? ee : "null"));
    httpParams = httpParams.append('Searchcolumn', searchcolumn);
    httpParams = httpParams.append('Search', search);
    httpParams = httpParams.append('IsDirectReport', isDirectReport);
    httpParams = httpParams.append('NoOfDay', noOfDay);
    return this.http.get(`${this.getPageSupervisorHeadDetailsUrl}`, { params: httpParams });
  }

  getPageSupervisorHeadEmpDetails(employeeNumber: number, date: string, ee: any, searchcolumn: string, search: string, isDirectReport: any, noOfDay: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('SupervisorNumber', employeeNumber);
    httpParams = httpParams.append('TimeCardDate', date);
    httpParams = httpParams.append('EmployeeNumber', (ee ? ee : "null"));
    httpParams = httpParams.append('Searchcolumn', searchcolumn);
    httpParams = httpParams.append('Search', search);
    httpParams = httpParams.append('IsDirectReport', isDirectReport);
    httpParams = httpParams.append('NoOfDay', noOfDay);
    return this.http.get(`${this.getPageSupervisorHeadEmpDetailsUrl}`, { params: httpParams });
  }

  getPageSupervisorDetailsPending(employeeNumber: number, date: string, ee: any, pageNo: number, pageSize: number, sortColumn: any, sortOrder: any, searchcolumn: string, search: string, isDirectReport: any, noOfDay: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('SupervisorNumber', employeeNumber);
    httpParams = httpParams.append('TimeCardDate', date);
    httpParams = httpParams.append('EmployeeNumber', (ee ? ee : "null"));
    httpParams = httpParams.append('PageNo', pageNo);
    httpParams = httpParams.append('PageSize', pageSize);
    httpParams = httpParams.append('sortColumn', sortColumn);
    httpParams = httpParams.append('SortOrder', sortOrder);
    httpParams = httpParams.append('Searchcolumn', searchcolumn);
    httpParams = httpParams.append('Search', search);
    httpParams = httpParams.append('IsDirectReport', isDirectReport);
    httpParams = httpParams.append('NoOfDay', noOfDay);
    return this.http.get(`${this.getPageSupervisorDetailsPendingUrl}`, { params: httpParams });
  }

  getPageSupervisorDetailsSummited(employeeNumber: number, date: string, ee: any, pageNo: number, pageSize: number, sortColumn: any, sortOrder: any, searchcolumn: string, search: string, isDirectReport: any, noOfDay: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('SupervisorNumber', employeeNumber);
    httpParams = httpParams.append('TimeCardDate', date);
    httpParams = httpParams.append('EmployeeNumber', (ee ? ee : "null"));
    httpParams = httpParams.append('PageNo', pageNo);
    httpParams = httpParams.append('PageSize', pageSize);
    httpParams = httpParams.append('sortColumn', sortColumn);
    httpParams = httpParams.append('SortOrder', sortOrder);
    httpParams = httpParams.append('Searchcolumn', searchcolumn);
    httpParams = httpParams.append('Search', search);
    httpParams = httpParams.append('IsDirectReport', isDirectReport);
    httpParams = httpParams.append('NoOfDay', noOfDay);
    return this.http.get(`${this.getPageSupervisorDetailsSummitedUrl}`, { params: httpParams });
  }

  getPageSupervisorDetailsRejected(employeeNumber: number, date: string, ee: any, pageNo: number, pageSize: number, sortColumn: any, sortOrder: any, searchcolumn: string, search: string, isDirectReport: any, noOfDay: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('SupervisorNumber', employeeNumber);
    httpParams = httpParams.append('TimeCardDate', date);
    httpParams = httpParams.append('EmployeeNumber', (ee ? ee : "null"));
    httpParams = httpParams.append('PageNo', pageNo);
    httpParams = httpParams.append('PageSize', pageSize);
    httpParams = httpParams.append('sortColumn', sortColumn);
    httpParams = httpParams.append('SortOrder', sortOrder);
    httpParams = httpParams.append('Searchcolumn', searchcolumn);
    httpParams = httpParams.append('Search', search);
    httpParams = httpParams.append('IsDirectReport', isDirectReport);
    httpParams = httpParams.append('NoOfDay', noOfDay);
    return this.http.get(`${this.getPageSupervisorDetailsRejectedUrl}`, { params: httpParams });
  }

  getPageSupervisorDetailsAprroved(employeeNumber: number, date: string, ee: any, pageNo: number, pageSize: number, sortColumn: any, sortOrder: any, searchcolumn: string, search: string, isDirectReport: any, noOfDay: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('SupervisorNumber', employeeNumber);
    httpParams = httpParams.append('TimeCardDate', date);
    httpParams = httpParams.append('EmployeeNumber', (ee ? ee : "null"));
    httpParams = httpParams.append('PageNo', pageNo);
    httpParams = httpParams.append('PageSize', pageSize);
    httpParams = httpParams.append('sortColumn', sortColumn);
    httpParams = httpParams.append('SortOrder', sortOrder);
    httpParams = httpParams.append('Searchcolumn', searchcolumn);
    httpParams = httpParams.append('Search', search);
    httpParams = httpParams.append('IsDirectReport', isDirectReport);
    httpParams = httpParams.append('NoOfDay', noOfDay);
    return this.http.get(`${this.getPageSupervisorDetailsAprrovedUrl}`, { params: httpParams });
  }

  getSupervisorRejectedRecords(employeeNumber: number, date: string, ee: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('SupervisorNumber', employeeNumber);
    httpParams = httpParams.append('TimeCardDate', date);
    httpParams = httpParams.append('EmployeeNumber', (ee ? ee : "null"));
    return this.http.get(`${this.getsupervisorrejectedUrl}`, { params: httpParams });
  }

  getCalendarCounts(supervisorNumber: number, showAll: any, groupId: number, department: any): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.SupervisorNumber = supervisorNumber;
    payload.showAll = showAll;
    payload.GroupID = groupId;
    payload.Department = department;
    return this.http.post(`${this.getcalendarcountsUrl}`, payload);
  }

  getPageSupervisorHeadSummaryDetails(employeeNumber: number, date: string, ee: any, searchcolumn: string, search: string, isDirectReport: any, noOfDay: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('SupervisorNumber', employeeNumber);
    httpParams = httpParams.append('TimeCardDate', date);
    httpParams = httpParams.append('EmployeeNumber', (ee ? ee : "null"));
    httpParams = httpParams.append('Searchcolumn', searchcolumn);
    httpParams = httpParams.append('Search', search);
    httpParams = httpParams.append('IsDirectReport', isDirectReport);
    httpParams = httpParams.append('NoOfDay', noOfDay);
    return this.http.get(`${this.getPageSupervisorHeadSummaryDetailsUrl}`, { params: httpParams });
  }

  getPageEmployeeSummaryDetails(employeeNumber: number, date: string, ee: any, pageNo: number, pageSize: number, sortColumn: any, sortOrder: any, searchcolumn: string, search: string, isDirectReport: any, noOfDay: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('SupervisorNumber', employeeNumber);
    httpParams = httpParams.append('TimeCardDate', date);
    httpParams = httpParams.append('EmployeeNumber', (ee ? ee : "null"));
    httpParams = httpParams.append('PageNo', pageNo);
    httpParams = httpParams.append('PageSize', pageSize);
    httpParams = httpParams.append('sortColumn', sortColumn);
    httpParams = httpParams.append('SortOrder', sortOrder);
    httpParams = httpParams.append('Searchcolumn', searchcolumn);
    httpParams = httpParams.append('Search', search);
    httpParams = httpParams.append('IsDirectReport', isDirectReport);
    httpParams = httpParams.append('NoOfDay', noOfDay);
    return this.http.get(`${this.getPageEmployeeSummaryDetailsUrl}`, { params: httpParams });
  }

  copyTransaction(employeeNumber: any, transactionNumber: number, startDate: string, endDate: string, copyEquipment: boolean, copyInventory: boolean): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeNumber = employeeNumber;
    payload.TransactionNumber = transactionNumber;
    payload.StartDate = startDate;
    payload.EndDate = endDate;
    payload.CopyEquipment = copyEquipment;
    payload.CopyInventory = copyInventory;
    return this.http.post(`${this.copytransactionUrl}`, payload);
  }

  validatepayhourscopytransactionsEmployees(TransactionNumbers: any, copyStartDate: string, copyEndDate: string, EmployeeNumbers: any, CopyEquipment: boolean, CopyInventory: boolean): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('TransactionNumbers', TransactionNumbers);
    httpParams = httpParams.append('CopyStartDate', copyStartDate);
    httpParams = httpParams.append('CopyEndDate', copyEndDate);
    httpParams = httpParams.append('EmployeeNumbers', EmployeeNumbers);
    httpParams = httpParams.append('CopyEquipment', CopyEquipment);
    httpParams = httpParams.append('CopyInventory', CopyInventory);
    return this.http.get(`${this.validatepayhourscopytransactionsEmployeesUrl}`, { params: httpParams });
  }

  sendMessages(employeeList: any, message: string, startDate: string, endDate: string): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeList = employeeList;
    payload.Message = message;
    payload.ValidFrom = startDate;
    payload.ValidTo = endDate;
    return this.http.post(`${this.createemployeemessageUrl}`, payload);
  }

  saveGroup(group: any, groupEmployees?: any): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.GroupID = group.GroupID;
    payload.GroupName = group.GroupName;
    payload.CreatedBy = group.CreatedBy;
    return this.http.post(`${this.savegroupUrl}`, payload);
  }

  saveGroupEmployees(groupID: any, groupEmployees?: any): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.GroupID = groupID;
    payload.GroupEmployees = groupEmployees
    return this.http.post(`${this.savegroupemployeesUrl}`, payload);
  }

  deleteGroup(groupID: any): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.GroupID = groupID;
    payload.GroupName = '';
    payload.CreatedBy = undefined
    return this.http.post(`${this.deletegroupUrl}`, payload);
  }

  applySupervisorReview(
    submissions: any,
    NoOfDay: string,
    IsDirectReport: boolean,
    EmployeeNumber: string,
    TimeCardDate: string,
    ApproveAll: boolean
  ): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.Details = submissions;
    payload.NoOfDay = NoOfDay;
    payload.IsDirectReport = IsDirectReport;
    payload.EmployeeNumber = EmployeeNumber;
    payload.TimeCardDate = TimeCardDate;
    payload.ApproveAll = ApproveAll;
    return this.http.post(`${this.movetimeUrl}`, payload);
  }


}
