// remove-nan.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeNaN'
})
export class RemoveNaNPipe implements PipeTransform {
  transform(number: any): any {
    // Return the number if it's not NaN, otherwise return 0 (or any default value you prefer)
    return isNaN(number) ? '' : number;
  }
}
