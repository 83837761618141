import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDateFormat]',
  //standalone: true
})
export class DateFormatDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent): void {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = input.value.replace(/\D/g, ''); // Remove any non-digit characters
  
    // Format as mm/dd/yyyy
    if (value.length > 2) {
      value = value.slice(0, 2) + '/' + value.slice(2);
    }
    if (value.length > 5) {
      value = value.slice(0, 5) + '/' + value.slice(5, 9); // Limit the year to 4 digits
    }
  
    // Ensure the total input doesn't exceed mm/dd/yyyy (10 characters including slashes)
    if (value.length > 10) {
      value = value.slice(0, 10); // Enforce mm/dd/yyyy format with no extra characters
    }
  
    input.value = value;  // Set the formatted value back to the input field
  }
  
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];
    const isAllowedKey = allowedKeys.indexOf(event.key) !== -1;
  
    // Allow only digits, backspace, and arrow keys
    if (!/^\d+$/.test(event.key) && !isAllowedKey) {
      event.preventDefault(); // Stop the event for invalid input
    }
  }
  
  @HostListener('blur')
  onBlur(): void {
    const input = this.el.nativeElement as HTMLInputElement;
    let value = input.value;
  
    // Revalidate the format when the input loses focus (on blur)
    if (value.length === 10) {
      const dayPart = value.slice(0, 2);
      const monthPart = value.slice(3, 5);
      const yearPart = value.slice(6, 10);
  
      // Ensure it's in the correct mm/dd/yyyy format
      value = `${dayPart}/${monthPart}/${yearPart}`;
    } else {
      value = value.slice(0, 10); // In case there's any excess, slice it
    }
  
    input.value = value; // Enforce the correct format on blur
  }
  
}
