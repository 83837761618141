<div
  class="min-h-screen relative z-20 bg-fixed bg-top bg-cover"
  [ngClass]="{
    'bg-summer': theme === 'summer',
    'bg-winter': !theme || theme === 'winter',
    'bg-fall': !theme || theme === 'fall',
    'bg-spring': !theme || theme === 'spring',
 }"
>
  <div
    class="before:block before:absolute before:left-0 before:top-0 before:h-full before:w-full before:bg-black/10 before:inset-1 before:-z-[10] dark:before:bg-black/60 duration-200"
  >
    <router-outlet class="z-10"></router-outlet>
  </div>
</div>
