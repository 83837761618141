<div
  class="relative text-left custom-dropdown"
  [ngClass]="{ 'bg-gray-200 rounded-2xl opacity-90': readonly }"
  #dropdownContainer
>
  <div
    class="relative cust-input"
    [ngClass]="{ 'cust-input': readonly }"
    (click)="toggleDropdown()"
  >
    <input
      type="text"
      [attr.id]="label"
      class="block rounded-xl !h-[58px] px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-700 border-0 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
      placeholder=" "
      [formControl]="filterControl"
      #filterInput
      [readonly]="readonly"
      (focusout)="onFocusOut($event)"
    />
    <label
      [attr.for]="label"
      class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-5 z-10 origin-[0] start-2.5 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
    >
      {{ label }}<span *ngIf="required" class="text-red-600">*</span>
    </label>
    <button
      *ngIf="filterControl.value && !readonly"
      type="button"
      (click)="clearInput($event)"
      class="absolute end-1 bottom-2 text-red-400 hover:text-red-500 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-2 py-2 dark:hover:text-red-700"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18 18 6M6 6l12 12"
        />
      </svg>
    </button>
  </div>
  <div
    *ngIf="isOpen"
    class="custom-select mt-1 bg-white border border-gray-300 rounded-md shadow-lg z-20 w-full sm:w-auto absolute"
  >
    <div class="m-table-container table-container">
      <table
        #table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="mat-elevation-z8 resizable-table border border-slate-500"
      >
        <ng-container
          *ngFor="let column of columns"
          [matColumnDef]="column.columnDef"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            class="px-4 py-2 text-left text-xs font-semibold text-gray-600 border"
          >
            <div class="grid grid-cols-1">
              <div
                class="header-content flex items-center"
                mat-sort-header
                [disableClear]="false"
                [arrowPosition]="'after'"
              >
                <span>{{ column.header }}</span>
                <!-- Sort icon will be managed by mat-sort-header -->
              </div>
              <div class="flex">
                <span
                  class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 rounded-l-md border-gray-300 border-e-0 dark:bg-gray-400 dark:text-gray-400 dark:border-gray-600"
                >
                  @if (column?.filterOptions?.length) {
                  <span class="text-gray-500 dark:text-gray-400">
                    <app-operator-dropdown
                      [operators]="column.filterOptions || []"
                      (operatorSelected)="
                        onOperatorSelected($event, column.columnDef)
                      "
                      [dropdownId]="column.columnDef"
                    ></app-operator-dropdown>
                  </span>
                  }
                </span>
                <input
                  type="text"
                  class="filter-input-box block min-w-[80px] w-1/2 p-1 text-gray-900 border-gray-300 rounded-r-md bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  (input)="applySearch($event, column.columnDef)"
                  [attr.id]="column.columnDef"
                />
              </div>
            </div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="px-4 py-2 text-xs text-gray-700 border"
          >
            <ng-container [ngSwitch]="column.dataType">
              <span *ngSwitchCase="'string'">{{
                element[column.columnDef]
              }}</span>
              <span *ngSwitchCase="'number'">{{
                element[column.columnDef] | number : column.format
              }}</span>
              <span *ngSwitchCase="'date'">{{
                element[column.columnDef] | date : column.format
              }}</span>
              <mat-checkbox
                *ngSwitchCase="'boolean'"
                [checked]="element[column.columnDef]"
                disabled
              ></mat-checkbox>
            </ng-container>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
          class="thead"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <!-- (click)="toggleRow(row)"
          [class.selected]="isRowSelected(row)" -->
      </table>
    </div>
    <mat-paginator
      #paginator
      [length]="totalItems"
      [pageSize]="pageSize"
      [pageSizeOptions]="[10, 25, 50, 100]"
      (page)="onPageChange($event)"
    ></mat-paginator>
  </div>
</div>
