import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, delay, of } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConstantsService } from 'src/app/core/services/constants.service';

@Injectable({
  providedIn: 'root'
})
export class DetailsService {
  private buttonCategoriesUrl = '/Button/categories';
  private getAccountsUrl = '/Button/getaccounts';
  private getActivitiesUrl = '/Button/getactivities';
  private programDirectionsUrl = '/Button/programDirections';
  private getPayTypeAbsenceUrl = '/Button/getpaytypeabsence';
  private getDepartmentsUrl = '/Button/getdepartments';
  private getEquipmentFunctionsUrl = '/Button/getequipmentfunctions';
  private getEquipmentDepartmentUrl = '/Button/getequipmentdepartment';
  private getEquipmentDistrictUrl = '/Button/getequipmentdistrict';
  private getFunctionsUrl = '/Button/getfunctions';
  private getFundsUrl = '/Button/getfunds';
  private getRepairOrdersUrl = '/Button/getrepairorders';
  private getRepairOrderCategoriesUrl = '/Button/getrepairordercategories';
  private getItemNumbersUrl = '/Button/getitemnumbers';
  private getJobClassificationsUrl = '/Button/getjobclassifications';
  private getPayTypeNoPayUrl = '/Button/getpaytypenopay';
  private getPayTypeRegularUrl = '/Button/getpaytyperegular';
  private getProjectsUrl = '/Button/getprojects';
  private getStateFunctionsUrl = '/Button/getstatefunctions';
  private getStateProjectsUrl = '/Button/getstateprojects';
  private getSupervisorUrl = '/Button/getsupervisor';
  private getWorkOrderUrl = '/Button/getworkorder';
  private getWorkOrderWorkTypesUrl = '/Button/getworkorderworktypes';
  private getWorkTypesUrl = '/Button/getworktypes';
  // employe hours
  private getEmployeeTotalEquipmentTimeByDateUrl = '/employeehours/getEmployeeTotalEquipmentTimeByDate';
  private getEmployeeTotalTimeByDateUrl = '/employeehours/getEmployeeTotalTimeByDate';
  private getTransactionEquipmentUrl = '/employeehours/gettransactionequipment';
  private addTransactionEquipmentUrl = '/employeehours/addtransactionequipment';
  private editTransactionEquipmentUrl = '/employeehours/edittransactionequipment';
  private deleteequipmentUrl = '/employeehours/deleteequipment';
  private getDailySummaryUrl = '/employeehours/getdailysummary';
  // inventory
  private gettransactioninventoryUrl = '/employeehours/gettransactioninventory';
  private getinventorydepartmentUrl = '/Button/getinventorydepartment';
  private getinventoryUrl = '/Button/getinventory';
  private addtransactioninventoryUrl = '/employeehours/addtransactioninventory';
  private deleteinventoryUrl = '/employeehours/deleteinventory';
  // save detail
  private saveDetailUrl = 'Details';
  private completeRoUrl = '/details/completero';
  private addEntriesUrl = '/details/addentries';
  private gettransactionjsonUrl = '/details/gettransactionjson';
  // transaction detail
  private gettransactiondetailsUrl = '/details/gettransactiondetails';
  private getAllowNegativeBankPayTypeUrl = '/details/getAllowNegativeBankPayType';

  private getEquipmentUrl = '/Button/getequipment';


  private clientToken: any = '';
  private authToken: any = '';

  constructor(private http: HttpClient, private authService: AuthService,
    private constantService: ConstantsService) {
    this.clientToken = this.constantService.CLIENT_TOKEN;
    this.authToken = this.authService.getToken();
  }

  /**
     * Get button categories
     * @param employeeNumber 
     * @returns 
     */
  getButtonCategories(employeeNumber: number): Observable<any> {
    return this.http.get(`${this.buttonCategoriesUrl}/${employeeNumber}`);
  }

  getAccounts(
    employeeNumber: number,
    category: string,
    accountType: string,
    timeCardDate: string,
    // accountVaule?: string
  ): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    httpParams = httpParams.append('category', category);
    httpParams = httpParams.append('accountType', accountType);
    httpParams = httpParams.append('TimeCardDate', timeCardDate);
    // httpParams = httpParams.append('AccountVaule', accountVaule);
    return this.http.get(`${this.getAccountsUrl}`, { params: httpParams });
  }

  getActivities(employeeNumber: number, category: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    httpParams = httpParams.append('category', category);
    return this.http.get(`${this.getActivitiesUrl}`, { params: httpParams });
  }


  getProgramDirection(category: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', category);
    return this.http.get(`${this.programDirectionsUrl}`, { params: httpParams });
  }

  getPayTypeAbsence(employeeNumber: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    return this.http.get(`${this.getPayTypeAbsenceUrl}`, { params: httpParams });
  }

  getDepartments(employeeNumber: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', employeeNumber);
    return this.http.get(`${this.getDepartmentsUrl}`, { params: httpParams });
  }

  getEquipmentFunctions(employeeNumber: number, department: string, district: string, activity?: string): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeNumber = employeeNumber;
    payload.EquipmentDepartment = department;
    payload.EquipmentDistrict = district;
    payload.Activity = activity;
    return this.http.post(`${this.getEquipmentFunctionsUrl}`, payload);
  }

  getEquipmentDepartments(employeeNumber: number, district: string): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeNumber = employeeNumber;
    payload.EquipmentDistrict = district;
    return this.http.post(`${this.getEquipmentDepartmentUrl}`, payload);
  }

  getEquipmentDistricts(employeeNumber: number, department: string): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeNumber = employeeNumber;
    payload.EquipmentDepartment = department;
    return this.http.post(`${this.getEquipmentDistrictUrl}`, payload);
  }

  getFunctions(employeeNumber: number, area: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    httpParams = httpParams.append('area', area);
    return this.http.get(`${this.getFunctionsUrl}`, { params: httpParams });
  }

  getFunds(employeeNumber: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('id', employeeNumber);
    return this.http.get(`${this.getFundsUrl}`, { params: httpParams });
  }

  getRepairOrders(employeeNumber: number, ROType: string, RODate: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    httpParams = httpParams.append('ROType', ROType);
    httpParams = httpParams.append('RODate', RODate);
    return this.http.get(`${this.getRepairOrdersUrl}`, { params: httpParams });
  }

  getRepairOrderCategories(employeeNumber: number, ROType: string, RONumber: string, Category: string, SubCategory: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    httpParams = httpParams.append('ROType', ROType);
    httpParams = httpParams.append('RONumber', RONumber);
    httpParams = httpParams.append('Category', Category);
    httpParams = httpParams.append('SubCategory', SubCategory);
    return this.http.get(`${this.getRepairOrderCategoriesUrl}`, { params: httpParams });
  }

  getItemNumbers(employeeNumber: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    return this.http.get(`${this.getItemNumbersUrl}`, { params: httpParams });
  }

  getJobClassifications(employeeNumber: number, timeCardDate: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    httpParams = httpParams.append('timeCardDate', timeCardDate);
    return this.http.get(`${this.getJobClassificationsUrl}`, { params: httpParams });
  }

  getPayTypeNoPay(employeeNumber: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    return this.http.get(`${this.getPayTypeNoPayUrl}`, { params: httpParams });
  }

  getPayTypeRegular(employeeNumber: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    return this.http.get(`${this.getPayTypeRegularUrl}`, { params: httpParams });
  }

  getProjects(employeeNumber: number, category: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    httpParams = httpParams.append('category', category);
    return this.http.get(`${this.getProjectsUrl}`, { params: httpParams });
  }

  getStateFunctions(employeeNumber: number, project: string, area: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    httpParams = httpParams.append('project', project);
    httpParams = httpParams.append('area', area);
    return this.http.get(`${this.getStateFunctionsUrl}`, { params: httpParams });
  }

  getStateProjects(employeeNumber: number, category: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    httpParams = httpParams.append('category', category);
    return this.http.get(`${this.getStateProjectsUrl}`, { params: httpParams });
  }

  getSupervisor(employeeNumber: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    return this.http.get(`${this.getSupervisorUrl}`, { params: httpParams });
  }

  getWorkOrder(employeeNumber: number, category: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    httpParams = httpParams.append('category', category);
    return this.http.get(`${this.getWorkOrderUrl}`, { params: httpParams });
  }

  getWorkOrderWorkType(employeeNumber: number, sFunction: string, area: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    httpParams = httpParams.append('sFunction', sFunction);
    httpParams = httpParams.append('area', area);
    return this.http.get(`${this.getWorkOrderWorkTypesUrl}`, { params: httpParams });
  }

  getWorkTypes(employeeNumber: number, category: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    httpParams = httpParams.append('category', category);
    return this.http.get(`${this.getWorkTypesUrl}`, { params: httpParams });
  }

  GetEmployeeTotalEquipmentTimeByDate(employeeNumber: number, CalendarDate: string, TransactionNumber: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeNumber', employeeNumber);
    httpParams = httpParams.append('TransactionNumber', TransactionNumber);
    httpParams = httpParams.append('CalendarDate', CalendarDate);
    return this.http.get(`${this.getEmployeeTotalEquipmentTimeByDateUrl}`, { params: httpParams });
  }

  GetEmployeeTotalTimeByDate(employeeNumber: number, CalendarDate: string, TransactionNumber: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeNumber', employeeNumber);
    httpParams = httpParams.append('TransactionNumber', TransactionNumber);
    httpParams = httpParams.append('CalendarDate', CalendarDate);
    return this.http.get(`${this.getEmployeeTotalTimeByDateUrl}`, { params: httpParams });
  }


  saveDetails(
    transactionNumber: any,
    sequenceNumber: any,
    department: any,
    timeCardDate: any,
    additionalHourlyRate: any,
    additionalAmount: any,
    workShift: any,
    shiftPremiumAmount: any,
    timeCardComment: any,
    userID: any,
    fund: any,
    glDepartment: any,
    activity: any,
    project: any,
    glFunction: any,
    account: any,
    subaccount: any,
    workType: any,
    repairOrderNumber: any,
    payType: any,
    payHours: any,
    personalHours: any,
    approvalUserID: any,
    approvalDate: any,
    subCategory: any,
    repairOrderComment: any,
    roCategory: any,
    tckCategory: any,
    supervisorNumber: any,
    additionalHourlyRateFlag: any,
    jobClassification: any,
    additionalHourlyRateJobClass: any,
    itemNumber: any,
    transactionJSON: any,
    empNum: any
  ): Observable<any> {
    const params = this.formatDetails(
      transactionNumber,
      sequenceNumber,
      department,
      timeCardDate,
      additionalHourlyRate,
      additionalAmount,
      workShift,
      shiftPremiumAmount,
      timeCardComment,
      userID,
      fund,
      glDepartment,
      activity,
      project,
      glFunction,
      account,
      subaccount,
      workType,
      repairOrderNumber,
      payType,
      payHours,
      personalHours,
      approvalUserID,
      approvalDate,
      subCategory,
      repairOrderComment,
      roCategory,
      tckCategory,
      supervisorNumber,
      additionalHourlyRateFlag,
      jobClassification,
      additionalHourlyRateJobClass,
      itemNumber,
      transactionJSON,
      empNum
    );
    console.log('params data: ', params);
    // return of(true);
    return this.http.put(`${this.saveDetailUrl}`, params);
  }

  formatDetails(
    transactionNumber: any,
    sequenceNumber: any,
    department: any,
    timeCardDate: any,
    additionalHourlyRate: any,
    additionalAmount: any,
    workShift: any,
    shiftPremiumAmount: any,
    timeCardComment: any,
    userID: any,
    fund: any,
    glDepartment: any,
    activity: any,
    project: any,
    glFunction: any,
    account: any,
    subaccount: any,
    workType: any,
    repairOrderNumber: any,
    payType: any,
    payHours: any,
    personalHours: any,
    approvalUserID: any,
    approvalDate: any,
    subCategory: any,
    repairOrderComment: any,
    roCategory: any,
    tckCategory: any,
    supervisorNumber: any,
    additionalHourlyRateFlag: any,
    jobClassification: any,
    additionalHourlyRateJobClass: any,
    itemNumber: any,
    transactionJSON: any,
    empNum: any
  ): any {
    const params: any = {};
    // const employeeNumber = JSON.parse(kioskSession.getData("credentials")).EmployeeNumber;
    let employeeNumber = 0;
    if (empNum) {
      employeeNumber = empNum;
    }

    params.EmployeeNumber = employeeNumber;
    params.UserToken = this.authToken;
    params.ClientToken = this.clientToken;
    params.TransactionNumber = transactionNumber;
    params.SequenceNumber = sequenceNumber;
    params.Department = department;
    params.TimeCardDate = timeCardDate;
    params.AdditionalHourlyRate = additionalHourlyRate;
    params.AdditionalAmount = additionalAmount;
    params.WorkShift = workShift;
    params.ShiftPremiumAmount = shiftPremiumAmount;
    params.TimeCardComment = timeCardComment;
    params.UserID = userID;
    params.Fund = fund;
    params.GLDepartment = glDepartment;
    params.Activity = activity;
    params.Project = project;
    params.GLFunction = glFunction;
    params.Account = account;
    params.SubAccount = subaccount;
    params.WorkType = workType;
    params.RepairOrderNumber = repairOrderNumber;
    params.PayType = payType;
    params.PayHours = payHours;
    params.PersonalHours_SCRC = personalHours;
    params.ApprovalUserID = approvalUserID;
    params.ApprovalDate = approvalDate;
    if (subCategory) {
      params.SubCategory = subCategory;
    }
    params.RepairOrderComment = repairOrderComment;
    params.ROCategory = roCategory;
    params.TCKCategory = tckCategory;
    params.SupervisorNumber = supervisorNumber;
    params.AdditionalHourlyRateFlag = additionalHourlyRateFlag;
    params.JobClassification = jobClassification;
    params.AdditionalHourlyRateJobClass = additionalHourlyRateJobClass;
    params.ItemNumber = itemNumber;
    params.TransactionJSON = JSON.stringify(transactionJSON);

    return params;
  }

  completeRO(
    roNumber: any,
    category: any,
    subCategory: any,
    sequenceNumber: any,
    timeCardDate: any,
    supervisorNumber: any,
    transactionNumber: any,
    tckCategory: any,
    roType: any
  ): any {
    const params: any = {};
    params.ClientToken = this.clientToken;
    params.UserToken = this.authToken;
    params.RepairOrderNumber = roNumber;
    params.Category = category;
    params.SubCategory = subCategory;
    params.SequenceNumber = sequenceNumber;
    params.TimeCardDate = timeCardDate;
    params.SupervisorNumber = supervisorNumber;
    params.TransactionNumber = transactionNumber;
    params.TCKCategory = tckCategory;
    params.ROType = roType;

    return this.http.post(`${this.completeRoUrl}`, params);
  }

  addRecords(arr: any[]): Observable<any> {
    return this.http.post(`${this.addEntriesUrl}`, { details: arr });
  }

  getTransactionEquipment(employeeNumber: number, TransactionNumber: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeNumber', employeeNumber);
    httpParams = httpParams.append('TransactionNumber', TransactionNumber);
    return this.http.get(`${this.getTransactionEquipmentUrl}`, { params: httpParams });
  }

  getEquipment(employeeNumber: number, district: string, department: string): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeNumber = employeeNumber;
    payload.EquipmentDepartment = department;
    payload.EquipmentDistrict = district;
    return this.http.post(`${this.getEquipmentUrl}`, payload);
  }

  addTransactionEquipment(transactionNumber: number, equipmentNumber: string, equipmentHours: number, empNum: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeNumber', empNum);
    httpParams = httpParams.append('TransactionNumber', transactionNumber);
    httpParams = httpParams.append('EquipmentNumber', equipmentNumber);
    httpParams = httpParams.append('EquipmentHours', equipmentHours);
    return this.http.get(`${this.addTransactionEquipmentUrl}`, { params: httpParams });
  }

  editTransactionEquipment(identity: string, equipmentHours: string): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.Identity = identity;
    payload.EquipmentHours = equipmentHours;
    return this.http.post(`${this.editTransactionEquipmentUrl}`, payload);
  }

  deleteEquipment(item: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('Identity', item);
    return this.http.get(`${this.deleteequipmentUrl}`, { params: httpParams });
  }

  getDailySummary(employeeNumber: number, timeCardDate: Date): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeNumber = employeeNumber;
    payload.HoursDate = timeCardDate;
    return this.http.post(`${this.getDailySummaryUrl}`, payload);
  }

  getTransactionInventory(employeeNumber: number, transactionNumber: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeNumber', employeeNumber);
    httpParams = httpParams.append('TransactionNumber', transactionNumber);
    return this.http.get(`${this.gettransactioninventoryUrl}`, { params: httpParams });
  }

  getInventoryDepartment(employeeNumber: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    return this.http.get(`${this.getinventorydepartmentUrl}`, { params: httpParams });
  }

  getInventory(employeeNumber: number, category: string, department: string, trxNum: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('employeeNumber', employeeNumber);
    httpParams = httpParams.append('category', category);
    httpParams = httpParams.append('department', department);
    httpParams = httpParams.append('transactionNumber', trxNum);
    return this.http.get(`${this.getinventoryUrl}`, { params: httpParams });
  }

  addTransactionInventory(
    employeeNumber: number,
    transactionNumber: string,
    department: string,
    requisitionNumber: number,
    sequenceNumber: number,
    itemNumber: number,
    itemQuantity: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeNumber', employeeNumber);
    httpParams = httpParams.append('TransactionNumber', transactionNumber);
    httpParams = httpParams.append('Department', department);
    httpParams = httpParams.append('RequisitionNumber', requisitionNumber);
    httpParams = httpParams.append('SequenceNumber', sequenceNumber);
    httpParams = httpParams.append('ItemNumber', itemNumber);
    httpParams = httpParams.append('ItemQuantity', itemQuantity);
    return this.http.get(`${this.addtransactioninventoryUrl}`, { params: httpParams });
  }

  deleteInventory(item: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('RequisitionNumber', item.RequisitionNumber);
    httpParams = httpParams.append('TransactionNumber', item.TransactionNumber);
    httpParams = httpParams.append('SequenceNumber', item.SequenceNumber);
    return this.http.get(`${this.deleteinventoryUrl}`, { params: httpParams });
  }

  getTransactionDetails(transactionNumber: number, sequenceNumber: number): Observable<any> {
    let payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.TransactionNumber = transactionNumber;
    payload.SequenceNumber = sequenceNumber;
    return this.http.post(`${this.gettransactiondetailsUrl}`, payload);
  }

  getTransactionJSON(transactionNumber: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('TransactionNumber', transactionNumber);
    return this.http.get(`${this.gettransactionjsonUrl}`, { params: httpParams });
  }

  GetAllowNegativeBankPayType(): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    return this.http.get(`${this.getAllowNegativeBankPayTypeUrl}`, { params: httpParams });
  }


}
