<div class="font-avertareguler w-full bg-white h-screen p-14 pb-28 dark:bg-gray-900 space-y-6 relative">
    <div class="flex">
      <h2 class="text-[#102030] text-[24px] font-avertaBold dark:text-white">Training Configuration</h2>
    </div>
    <div class="space-y-4">
        <div class="grid grid-cols-12 items-start">
            <div class="col-span-12 md:col-span-4">
                <span class="font-avertaBold block dark:text-white duration-200">Email Configuration:</span>
            </div>
            <div class="col-span-12 md:col-span-8">
                <p class="text-sm text-[#B29600] bg-[#ECD60E]/30 p-4 rounded-2xl"><b class="font-avertaBold">Note!</b> Training mail configuration are used for setting notifications emails to employee based on manual trigger Employee training details page.</p>
            </div>
        </div>
        <div class="grid grid-cols-12 items-center">
            <div class="col-span-12 md:col-span-4">
                <span class="font-avertaBold block dark:text-white duration-200 text-sm">FROM <span class="text-red-600">*</span></span>
                <span class="text-xs text-gray-500 hidden md:block">Email Id From</span>
            </div>
            <div class="col-span-12 md:col-span-8">
                <mat-form-field class="w-full">
                    <mat-label class="text-black">Email</mat-label>
                    <input matInput>
                </mat-form-field>
            </div>
        </div>
        <div class="grid grid-cols-12 items-center">
            <div class="col-span-12 md:col-span-4">
                <span class="font-avertaBold block dark:text-white duration-200 text-sm">SUBJECT <span class="text-red-600">*</span></span>
                <span class="text-xs text-gray-500 hidden md:block">Subject</span>
            </div>
            <div class="col-span-12 md:col-span-8">
                <mat-form-field class="w-full">
                    <mat-label class="text-black">Training mail from Kiosk</mat-label>
                    <input matInput>
                </mat-form-field>
            </div>
        </div>
        <div class="grid grid-cols-12 items-center">
            <div class="col-span-12 md:col-span-4">
                <span class="font-avertaBold block dark:text-white duration-200 text-sm">HOST <span class="text-red-600">*</span></span>
                <span class="text-xs text-gray-500 hidden md:block">Host Info</span>
            </div>
            <div class="col-span-12 md:col-span-8">
                <mat-form-field class="w-full">
                    <mat-label class="text-black">email-smtp.us-east-1.amazonaws.com</mat-label>
                    <input matInput>
                </mat-form-field>
            </div>
        </div>
        <div class="grid grid-cols-12 items-center">
            <div class="col-span-12 md:col-span-4">
                <span class="font-avertaBold block dark:text-white duration-200 text-sm">SMTP PORT <span class="text-red-600">*</span></span>
                <span class="text-xs text-gray-500 hidden md:block">SMTP</span>
            </div>
            <div class="col-span-12 md:col-span-8">
                <mat-form-field class="w-full">
                    <mat-label class="text-black">587</mat-label>
                    <input matInput>
                </mat-form-field>
            </div>
        </div>
        <div class="grid grid-cols-12 items-center">
            <div class="col-span-12 md:col-span-4">
                <span class="font-avertaBold block dark:text-white duration-200 text-sm">USER NAME <span class="text-red-600">*</span></span>
                <span class="text-xs text-gray-500 hidden md:block">User Name</span>
            </div>
            <div class="col-span-12 md:col-span-8">
                <mat-form-field class="w-full">
                    <mat-label class="text-black">AKIAIMEFQKOW3GBZR44A</mat-label>
                    <input matInput>
                </mat-form-field>
            </div>
        </div>
        <div class="grid grid-cols-12 items-center">
            <div class="col-span-12 md:col-span-4">
                <span class="font-avertaBold block dark:text-white duration-200 text-sm">PASSWORD <span class="text-red-600">*</span></span>
                <span class="text-xs text-gray-500 hidden md:block">Password</span>
            </div>
            <div class="col-span-12 md:col-span-8">
                <mat-form-field class="w-full">
                    <mat-label class="text-black">AvtioqbW+aG10mx3hA15oN+2Tmf7dQ+BR5GoOjrp32WM</mat-label>
                    <input matInput>
                </mat-form-field>
            </div>
        </div>
        <div class="grid grid-cols-12 items-center">
            <div class="col-span-12 md:col-span-4">
                <span class="font-avertaBold block dark:text-white duration-200 text-sm">Enable SSL <span class="text-red-600">*</span></span>
                <span class="text-xs text-gray-500 hidden md:block">SSL check/uncheck</span>
            </div>
            <div class="col-span-12 md:col-span-8">
                <mat-checkbox class="example-margin text-xs text-gray-200">By clicking on check box you can Enable SSL Or Disable SSL </mat-checkbox>
            </div>
        </div>
        <div class="font-avertaBold block dark:text-white duration-200 py-6">Employee Training Details Configuration:</div>
        <div class="grid grid-cols-12 items-center">
            <div class="col-span-12 md:col-span-4">
                <span class="font-avertaBold block dark:text-white duration-200 text-sm">Department Restriction Pre-Filter <span class="text-red-600">*</span></span>
                <span class="text-xs text-gray-500 hidden md:block">Filter Option</span>
            </div>
            <div class="col-span-12 md:col-span-8">
                <mat-form-field class="w-full">
                    <mat-label>Department Restriction Pre-Filter</mat-label>
                    <select matNativeControl required>
                      <option value="volvo">Volvo</option>
                      <option value="saab">Saab</option>
                      <option value="mercedes">Mercedes</option>
                      <option value="audi">Audi</option>
                    </select>
                </mat-form-field>
            </div>
        </div>
        <div class="grid grid-cols-12 items-center">
            <div class="col-span-12 md:col-span-4">
                <span class="font-avertaBold block dark:text-white duration-200 text-sm">Default Department Filter <span class="text-red-600">*</span></span>
                <span class="text-xs text-gray-500 hidden md:block">Filter Option</span>
            </div>
            <div class="col-span-12 md:col-span-8">
                <mat-form-field class="w-full">
                    <mat-label>Default Department Filter</mat-label>
                    <select matNativeControl required>
                      <option value="volvo">Volvo</option>
                      <option value="saab">Saab</option>
                      <option value="mercedes">Mercedes</option>
                      <option value="audi">Audi</option>
                    </select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="flex justify-between items-center w-full popFooter py-7">
      <button class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>        
        </span>
        <span>Close</span>
      </button>
      <button class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#010D58] text-[#ffffff] flex items-center">
        <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
            </svg>                           
        </span>
        <span>SAVE</span>
      </button>
    </div>
  </div>
  
  
  