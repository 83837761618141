<div class="border-gray-400 border rounded-2xl">
  <table class="cust-table-home w-full table-auto rounded-t-2xl overflow-hidden">
    <thead>
      <tr class="text-[12px] text-left rounded-lg">
        <th class="bg-gray-300 dark:bg-slate-900 dark:text-gray-300">Department</th>
        <th class="bg-gray-300 dark:bg-slate-900 dark:text-gray-300">Inventory</th>
        <th class="bg-gray-300 dark:bg-slate-900 dark:text-gray-300">Quantity</th>
        <th class="bg-gray-300 dark:bg-slate-900 dark:text-gray-300 text-right" *ngIf="!readOnly">Action</th>
      </tr>
    </thead>
    <!-- <span class="block h-3"></span> -->
    <tbody class="font-avertareguler">
      <ng-container *ngIf="inventory.selectedInventory.length > 0; else noInv;">
        <tr *ngFor="let inv of inventory.selectedInventory">
          <td class="dark:text-gray-300">
            <span class="text-xs">{{ inv.department }}</span>
          </td>
          <td class="dark:text-gray-300">
            <span class="text-xs">{{ inv.name }}</span>
          </td>
          <td class="dark:text-gray-300">
            <span class="text-xs">{{ inv.quantity }}</span>
          </td>
          <td class="dark:text-gray-300">
            <span class="flex justify-end">
              <button
                class="text-red-600 text-right text-xs"
                (click)="deleteInventory(inv)"
                *ngIf="!readOnly"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                  />
                </svg>
              </button>
            </span>
          </td>
        </tr>
      </ng-container>
      <ng-template #noInv>
        <tr>
          <td colspan="4">
            <span class="bg-[#ECD60E]/30 text-[#102030] dark:text-white duration-200 py-1 px-2 text-xs rounded-full block">No Inventory Selected</span>
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
  <div class="flex justify-end mt-2">
    <button
      *ngIf="!readOnly"
      class="py-2 px-4 space-x-1 rounded-full text-[16px] border-2 bg-gradient-to-t from-[#000733] to-[#000D59] text-white flex items-center mr-2 mb-2"
      (click)="loadInventoryModal()"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.5v15m7.5-7.5h-15"
          />
        </svg>
      </span>
      <span>Add</span>
    </button>
  </div>
</div>
