<div
  class="fixed md:right-0 top-0 h-full bg-[#000733] text-white duration-300 font-avertareguler z-50 dark:bg-gray-950"
  [ngClass]="sidebarOpen ? 'w-60 -right-0' : ' w-20 -right-20'"
>
  <div
    class="flex justify-between items-center px-6 border-b py-2 md:py-[18px] border-white border-opacity-10 duration-300 menu-head md:left-0 relative"
    [ngClass]="sidebarOpen ? '-left-0' : ' -left-20'"
  >
    <div
      class="bg-white rounded-lg"
      [ngClass]="sidebarOpen ? '' : 'translate-x-0 duration-300'"
    >
      <img
        class="w-12"
        [src]="getImageUrl(tenant)"
        (error)="handleImageError(tenant)"
        loading="lazy"
      />
    </div>
    <div class="">
      <a
        href="javascript:void(0)"
        (click)="togglesidebar()"
        class="duration-300 w-8 text-center inline-block md:text-white mt-2"
        [ngClass]="
          sidebarOpen
            ? 'text-white py-2 px-2 rounded-lg'
            : ' bg-[#000733] py-2 px-2 rounded-lg'
        "
      >
        <svg
          [ngClass]="sidebarOpen ? 'rotate-180' : ''"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="duration-300 w-4 h-4 m-auto"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
          />
        </svg>
      </a>
    </div>
  </div>
  <div
    class="py-4 px-2 min-menu-ht scrollbar-thin scrollbar-thumb-white/20 scrollbar-track-[#000733] overflow-y-scroll"
    (click)="handleSidebarClick($event)"
  >
    <ul class="space-y-1">
      <li
        class="block text-center"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
        [ngClass]="{ active: isActive() }"
      >
        <a
          [routerLink]="'/' + rcService.getTenant()"
          class="flex space-x-3 py-3 px-3 items-center"
          matTooltip="Home"
          matTooltipPosition="left"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              viewBox="0 0 200 200"
              fill="none"
            >
              <path
                d="M23.1976 176.525V105.49L100.244 37.7325L177.291 106.582V176.525C177.291 183.957 170.733 186.908 167.455 187.454H132.483C124.615 187.454 121.919 180.168 121.555 176.525V141.554C121.555 134.56 115.726 132.811 112.812 132.811H87.6762C80.682 132.811 78.9334 138.64 78.9334 141.554V176.525C78.9334 183.52 73.1048 186.725 70.1905 187.454H34.1262C26.6948 187.454 23.7441 180.168 23.1976 176.525Z"
                fill="#748398"
              />
              <path
                d="M93.1975 14.9285L1.97137 95.6959C0.885345 98.2426 -0.20068 104.427 4.14342 108.793C8.48752 113.159 15.3657 112.068 18.2618 110.976L100.257 38.3947L182.252 110.976L182.343 111.003C184.944 111.75 191.541 113.647 196.37 108.793C200.304 104.84 198.904 98.6064 197.999 95.6959C170.124 70.2287 112.963 18.4211 107.316 14.9285C101.668 11.4358 95.5506 13.4732 93.1975 14.9285Z"
                fill="white"
              />
            </svg>
          </span>
          <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'">Home</span>
        </a>
      </li>
      <li
        *ngIf="IsTraining"
        class="block"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <a
          (click)="toggleSubmenu('training')"
          class="flex space-x-3 py-3 px-2 items-center relative cursor-pointer submenu-toggle overflow-hidden"
          matTooltip="Training"
          matTooltipPosition="left"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              viewBox="0 0 200 200"
              fill="none"
            >
              <path
                d="M178.554 129.25H88.8543C98.7343 134.97 104.238 144.633 105.754 148.75H181.154C194.674 148.75 198.488 136.183 198.704 129.9V39.55C198.704 26.55 187.004 22.4333 181.154 22H69.3543C56.3543 22 51.8043 33.7 51.1543 39.55V51.25C59.4743 50.73 68.4876 54.0667 71.9543 55.8V41.5H178.554V129.25Z"
                fill="#748398"
              />
              <path
                d="M120.054 117.55V129.9H159.704V117.55C159.704 112.35 154.504 110.183 151.904 109.75H127.854C122.654 109.75 120.488 114.95 120.054 117.55Z"
                fill="white"
              />
              <path
                d="M52.4543 119.5C68.9676 119.5 82.3543 106.113 82.3543 89.6C82.3543 73.0867 68.9676 59.7 52.4543 59.7C35.941 59.7 22.5543 73.0867 22.5543 89.6C22.5543 106.113 35.941 119.5 52.4543 119.5Z"
                fill="white"
              />
              <path
                d="M94.7043 178H8.25429C1.49429 178 1.53763 168.467 2.40429 163.7C6.04429 138.74 27.1043 129.25 38.1543 129.25H66.1043C90.1543 131.85 99.9043 152.65 101.204 163.7C101.816 168.9 103.024 178 94.7043 178Z"
                fill="white"
              />
            </svg>
          </span>
          <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
            >Training</span
          >
          <span
            class="absolute right-0 top-1/1 h-full w-4 bg-blue-500 rounded-r-lg flex justify-center items-center duration-300"
          >
            <!-- [ngClass]="submenuOpen ? 'rotate-180' : ''" -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-3 h-3"
              [ngClass]="submenuStates['training'] ? 'rotate-180' : ''"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
              />
            </svg>
          </span>
        </a>
        <div
          class="grid overflow-hidden transition-all duration-300 ease-in-out submenu-container"
          [ngClass]="
            submenuStates['training']
              ? 'grid-rows-[1fr] opacity-100'
              : 'grid-rows-[0fr] opacity-0'
          "
        >
          <ul
            class="py-0 px-1 bg-white/10 duration-300 rounded-md overflow-hidden"
          >
            <div class="py-1">
              <li class="block" *ngIf="MailSettings || IsTrainingAdmin">
                <a
                  (click)="openTrainingSettingDailog()"
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center cursor-pointer"
                  matTooltip="Settings"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                    >
                      <path
                        d="M72.0056 30.8836C65.5309 33.3739 58.1015 38.977 55.1961 41.4673C38.7601 31.506 28.8405 32.7513 25.9351 34.6192C22.1997 39.5998 13.2346 52.1758 7.25789 62.635C1.28116 73.0943 18.0492 85.6703 27.1803 90.6509V109.951C7.25789 119.414 5.59769 131.741 7.25789 136.721C9.95571 142.117 16.9701 155.025 23.4448 163.492C29.9196 171.959 47.7252 163.7 55.8187 158.511C61.7954 164.986 69.1003 167.85 72.0056 168.473C72.5037 193.874 85.0797 199.394 91.3054 198.979H110.605C126.543 198.979 129.282 178.641 128.66 168.473C134.637 167.477 142.772 161.417 146.092 158.511C162.528 168.473 172.448 167.227 175.353 165.36C178.881 161.417 187.306 150.543 192.785 138.589C198.264 126.636 182.616 114.516 174.108 109.951V90.6509C192.536 80.1916 195.068 69.2758 194.03 65.1253C191.955 60.1447 186.061 47.8177 179.088 38.3546C172.116 28.8915 154.6 36.4868 146.715 41.4673C142.73 37.4827 133.018 32.7512 128.66 30.8836C128.66 1.00024 115.586 1.00008 108.738 1H91.3054C73.8733 1 71.1755 20.9224 72.0056 30.8836Z"
                        fill="white"
                      />
                      <path
                        d="M100.631 137.967C121.261 137.967 137.985 121.242 137.985 100.612C137.985 79.9817 121.261 63.2575 100.631 63.2575C80.0007 63.2575 63.2765 79.9817 63.2765 100.612C63.2765 121.242 80.0007 137.967 100.631 137.967Z"
                        fill="#183153"
                      />
                      <path
                        d="M100.009 125.515C114.106 125.515 125.535 114.087 125.535 99.9895C125.535 85.8921 114.106 74.464 100.009 74.464C85.9117 74.464 74.4835 85.8921 74.4835 99.9895C74.4835 114.087 85.9117 125.515 100.009 125.515Z"
                        fill="#748398"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Settings</span
                  >
                </a>
              </li>
              <li
                *ngIf="TrainingCourse || IsTrainingAdmin"
                class="block"
                routerLinkActive="active"
              >
                <a
                  [routerLink]="
                    '/' + rcService.getTenant() + '/training/courses'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Training Courses"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                    >
                      <path
                        d="M37.6709 161.677C37.6709 154.341 43.6185 148.393 50.9553 148.393H174.31V174.962H50.9553C43.6185 174.962 37.6709 169.014 37.6709 161.677Z"
                        fill="#748398"
                      />
                      <path
                        d="M186.329 139.537V11.1214C186.329 4.03642 180.003 1.42173 176.84 1H45.8946C21.0971 1 13.6326 24.6166 13 36.4249V165.473C13 190.776 33.6645 198.367 43.9968 199H176.84C180.003 198.367 186.329 194.951 186.329 186.348C186.329 177.745 180.003 174.329 176.84 173.696H48.4249C44.6294 173.275 37.0383 170.281 37.0383 161.677C37.0383 153.074 44.6294 149.658 48.4249 149.026H175.575C182.154 149.026 185.486 142.7 186.329 139.537Z"
                        fill="white"
                      />
                      <path
                        d="M143.946 49.7092H68.0352C64.5415 49.7092 61.7094 52.5414 61.7094 56.0351V56.0351C61.7094 59.5288 64.5415 62.361 68.0352 62.361H143.946C147.439 62.361 150.272 59.5288 150.272 56.0351C150.272 52.5414 147.439 49.7092 143.946 49.7092Z"
                        fill="#183153"
                      />
                      <path
                        d="M143.946 75.0128H68.0352C64.5415 75.0128 61.7094 77.845 61.7094 81.3387V81.3387C61.7094 84.8324 64.5415 87.6646 68.0352 87.6646H143.946C147.439 87.6646 150.272 84.8324 150.272 81.3387C150.272 77.845 147.439 75.0128 143.946 75.0128Z"
                        fill="#183153"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Training Courses</span
                  >
                </a>
              </li>
              <li
                *ngIf="CourseNotifications || IsTrainingAdmin"
                class="block"
                routerLinkActive="active"
              >
                <a
                  [routerLink]="
                    '/' +
                    rcService.getTenant() +
                    '/training/course-notification'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Course Notifications"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                    >
                      <path
                        d="M176.821 161.875H23.0179C16.5357 161.875 13 155.393 13 149.5C13 144.786 19.6786 137.714 23.0179 134.768C38.575 117.325 39.7143 88.2143 38.3393 75.8393C42.5821 38.125 73.1071 23.9821 87.8393 21.625V10.4286C88.4286 7.28571 91.8464 1 100.804 1C109.761 1 113.179 7.28571 113.768 10.4286V21.625C134.393 25.1607 162.089 46.375 162.679 75.8393C163.359 109.857 166.804 120.625 178.589 134.768C182.125 139.011 187.429 142.993 187.429 150.089C187.429 161.875 180.161 160.893 176.821 161.875Z"
                        fill="#748398"
                      />
                      <path
                        d="M126.143 174.25H75.4644C75.8573 182.5 81.3573 199 100.214 199C119.071 199 125.357 182.5 126.143 174.25Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Course Notifications</span
                  >
                </a>
              </li>
              <li
                *ngIf="TrainingGroup || IsTrainingAdmin"
                class="block"
                routerLinkActive="active"
              >
                <a
                  [routerLink]="
                    '/' + rcService.getTenant() + '/training/admin-groups'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Training Admin Groups"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                    >
                      <path
                        d="M98.2244 100C125.562 100 147.724 77.8381 147.724 50.5C147.724 23.1619 125.562 1 98.2244 1C70.8863 1 48.7244 23.1619 48.7244 50.5C48.7244 77.8381 70.8863 100 98.2244 100Z"
                        fill="#748398"
                      />
                      <path
                        d="M177.939 199H21.7245C14.5244 197.457 12.2959 191.071 12.0816 188.071C10.153 152.071 42.9387 132.143 54.5102 127.643C63.7673 124.043 67.7959 130 68.653 133.429L80.8673 188.071L93.0816 140.5C92.2244 139.643 89.4816 136 85.3673 128.286C81.253 120.571 87.5102 118.643 91.153 118.643H108.51C117.253 118.643 115.582 125.071 113.653 128.286L105.296 140.5L118.153 188.071C121.582 173.071 128.824 141.143 130.367 133.429C131.91 125.714 139.582 126.357 143.224 127.643C179.224 138.571 185.653 173.286 186.939 184.214C187.967 192.957 181.367 197.714 177.939 199Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Training Admin Groups</span
                  >
                </a>
              </li>
              <li
                *ngIf="EmployeeTraining || IsTrainingAdmin"
                class="block"
                routerLinkActive="active"
              >
                <a
                  [routerLink]="
                    '/' + rcService.getTenant() + '/training/employee-training'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                    >
                      <path
                        d="M128.448 198.483H26.0345C9.64828 198.483 2.51724 182.805 1 174.966V26.2759C1 7.46207 18.1954 2.25287 26.7931 2H173.207C189.593 2 197.23 18.1839 199 26.2759V127.931H148.931C131.938 127.931 128.195 140.575 128.448 146.897V198.483Z"
                        fill="white"
                      />
                      <path
                        d="M40.4484 58.8966C46.733 58.8966 51.8277 53.8019 51.8277 47.5173C51.8277 41.2327 46.733 36.138 40.4484 36.138C34.1638 36.138 29.0691 41.2327 29.0691 47.5173C29.0691 53.8019 34.1638 58.8966 40.4484 58.8966Z"
                        fill="#183153"
                      />
                      <path
                        d="M40.4484 110.483C46.733 110.483 51.8277 105.388 51.8277 99.1034C51.8277 92.8188 46.733 87.7241 40.4484 87.7241C34.1638 87.7241 29.0691 92.8188 29.0691 99.1034C29.0691 105.388 34.1638 110.483 40.4484 110.483Z"
                        fill="#183153"
                      />
                      <path
                        d="M40.4484 162.069C46.733 162.069 51.8277 156.975 51.8277 150.69C51.8277 144.405 46.733 139.311 40.4484 139.311C34.1638 139.311 29.0691 144.405 29.0691 150.69C29.0691 156.975 34.1638 162.069 40.4484 162.069Z"
                        fill="#183153"
                      />
                      <path
                        d="M141.345 148.414V198.483L199 141.586H147.414C142.559 141.586 141.345 146.138 141.345 148.414Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Employee Training Details</span
                  >
                </a>
              </li>
              <li
                *ngIf="EmployeeNotifications || IsTrainingAdmin"
                class="block"
                routerLinkActive="active"
              >
                <a
                  [routerLink]="
                    '/' +
                    rcService.getTenant() +
                    '/training/employee-notification'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                    >
                      <path
                        d="M176.821 161.875H23.0179C16.5357 161.875 13 155.393 13 149.5C13 144.786 19.6786 137.714 23.0179 134.768C38.575 117.325 39.7143 88.2143 38.3393 75.8393C42.5821 38.125 73.1071 23.9821 87.8393 21.625V10.4286C88.4286 7.28571 91.8464 1 100.804 1C109.761 1 113.179 7.28571 113.768 10.4286V21.625C134.393 25.1607 162.089 46.375 162.679 75.8393C163.359 109.857 166.804 120.625 178.589 134.768C182.125 139.011 187.429 142.993 187.429 150.089C187.429 161.875 180.161 160.893 176.821 161.875Z"
                        fill="white"
                      />
                      <path
                        d="M126.143 174.25H75.4644C75.8573 182.5 81.3573 199 100.214 199C119.071 199 125.357 182.5 126.143 174.25Z"
                        fill="white"
                      />
                      <path
                        d="M100.884 143C107.999 143 113.768 137.231 113.768 130.116C113.768 123.001 107.999 117.232 100.884 117.232C93.7686 117.232 88.0004 123.001 88.0004 130.116C88.0004 137.231 93.7686 143 100.884 143Z"
                        fill="#183153"
                      />
                      <path
                        d="M100.884 44H100.883C95.2661 44 90.7123 48.5538 90.7123 54.1712V96.2124C90.7123 101.83 95.2661 106.384 100.883 106.384H100.884C106.501 106.384 111.055 101.83 111.055 96.2124V54.1712C111.055 48.5538 106.501 44 100.884 44Z"
                        fill="#183153"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Employee Notifications</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' + rcService.getTenant() + '/training/my-training'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                    >
                      <path
                        d="M37.6709 162.677C37.6709 155.341 43.6185 149.393 50.9553 149.393H174.31V175.962H50.9553C43.6185 175.962 37.6709 170.014 37.6709 162.677Z"
                        fill="#748398"
                      />
                      <path
                        d="M186.329 140.537V12.1214C186.329 5.03642 180.003 2.42173 176.84 2H45.8946C21.0971 2 13.6326 25.6166 13 37.4249V166.473C13 191.776 33.6645 199.367 43.9968 200H176.84C180.003 199.367 186.329 195.951 186.329 187.348C186.329 178.745 180.003 175.329 176.84 174.696H48.4249C44.6294 174.275 37.0383 171.281 37.0383 162.677C37.0383 154.074 44.6294 150.658 48.4249 150.026H175.575C182.154 150.026 185.486 143.7 186.329 140.537Z"
                        fill="white"
                      />
                      <path
                        d="M106.6 72.5882C119.465 72.5882 129.894 62.1591 129.894 49.2941C129.894 36.4291 119.465 26 106.6 26C93.735 26 83.3059 36.4291 83.3059 49.2941C83.3059 62.1591 93.735 72.5882 106.6 72.5882Z"
                        fill="#183153"
                      />
                      <path
                        d="M139.601 118.012H71.898C66.604 118.012 66.6379 110.495 67.3166 106.737C70.1673 87.0585 86.6603 79.5764 95.314 79.5764H117.203C136.037 81.6263 143.673 98.0254 144.691 106.737C145.17 110.837 146.117 118.012 139.601 118.012Z"
                        fill="#183153"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >My Training</span
                  >
                </a>
              </li>
            </div>
          </ul>
        </div>
      </li>
      <li
        *ngIf="isInventoryAccess"
        class="block"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <a
          class="flex space-x-3 py-3 px-2 items-center relative cursor-pointer submenu-toggle overflow-hidden"
          matTooltip="Item Master"
          matTooltipPosition="left"
          href="javascript:void(0);"
        >
          <span (click)="goToItemMaster($event)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              viewBox="0 0 200 200"
              fill="none"
            >
              <rect
                x="1"
                y="2"
                width="90.5581"
                height="90.5581"
                rx="12"
                fill="#748398"
              />
              <rect
                x="16.3489"
                y="17.3488"
                width="59.8605"
                height="59.8605"
                rx="9"
                fill="#183153"
              />
              <path
                d="M60.8605 38.8372L43.9768 55.7209L36.3024 48.0465"
                stroke="white"
                stroke-width="7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <rect
                x="106.907"
                y="2"
                width="90.5581"
                height="90.5581"
                rx="12"
                fill="white"
              />
              <rect
                x="122.256"
                y="17.3488"
                width="59.8605"
                height="59.8605"
                rx="9"
                fill="#183153"
              />
              <rect
                x="1"
                y="107.907"
                width="90.5581"
                height="90.5581"
                rx="12"
                fill="white"
              />
              <rect
                x="16.3489"
                y="123.256"
                width="59.8605"
                height="59.8605"
                rx="9"
                fill="#183153"
              />
              <rect
                x="108.442"
                y="107.907"
                width="90.5581"
                height="90.5581"
                rx="12"
                fill="white"
              />
              <rect
                x="123.791"
                y="123.256"
                width="59.8605"
                height="59.8605"
                rx="9"
                fill="#183153"
              />
            </svg>
          </span>
          <span
            (click)="goToItemMaster($event)"
            [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
            >Item Master</span
          >
          <span
            class="absolute right-0 top-1/1 h-full w-4 bg-blue-500 rounded-r-lg flex justify-center items-center duration-300"
            (click)="toggleSubmenu('item-master')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-3 h-3"
              [ngClass]="submenuStates['item-master'] ? 'rotate-180' : ''"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
              />
            </svg>
          </span>
        </a>
        <div
          class="grid overflow-hidden transition-all duration-300 ease-in-out submenu-container"
          [ngClass]="
            submenuStates['item-master']
              ? 'grid-rows-[1fr] opacity-100'
              : 'grid-rows-[0fr] opacity-0'
          "
        >
          <ul
            class="py-0 px-1 bg-white/10 duration-300 rounded-md overflow-hidden"
          >
            <div class="py-1">
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' +
                    rcService.getTenant() +
                    '/inventory/maintenance/view/item'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Items"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      viewBox="0 0 200 200"
                      fill="none"
                    >
                      <rect
                        x="1"
                        y="13"
                        width="198"
                        height="173.854"
                        rx="21"
                        fill="white"
                      />
                      <rect
                        x="22.7317"
                        y="32.3171"
                        width="154.537"
                        height="135.22"
                        rx="19"
                        fill="#183153"
                      />
                      <rect
                        x="61.3658"
                        y="13"
                        width="77.2683"
                        height="77.2683"
                        rx="16"
                        fill="white"
                      />
                      <path
                        d="M80.683 32.3171H119.317V62.9512C119.317 67.3695 115.735 70.9512 111.317 70.9512H88.683C84.2647 70.9512 80.683 67.3695 80.683 62.9512V32.3171Z"
                        fill="#183153"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Items</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' +
                    rcService.getTenant() +
                    '/inventory/maintenance/detail'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Overview"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                    >
                      <path
                        d="M128.448 198.483H26.0345C9.64828 198.483 2.51724 182.805 1 174.966V26.2759C1 7.46207 18.1954 2.25287 26.7931 2H173.207C189.593 2 197.23 18.1839 199 26.2759V127.931H148.931C131.938 127.931 128.195 140.575 128.448 146.897V198.483Z"
                        fill="white"
                      />
                      <path
                        d="M40.4484 58.8966C46.733 58.8966 51.8277 53.8019 51.8277 47.5173C51.8277 41.2327 46.733 36.138 40.4484 36.138C34.1638 36.138 29.0691 41.2327 29.0691 47.5173C29.0691 53.8019 34.1638 58.8966 40.4484 58.8966Z"
                        fill="#183153"
                      />
                      <path
                        d="M40.4484 110.483C46.733 110.483 51.8277 105.388 51.8277 99.1034C51.8277 92.8188 46.733 87.7241 40.4484 87.7241C34.1638 87.7241 29.0691 92.8188 29.0691 99.1034C29.0691 105.388 34.1638 110.483 40.4484 110.483Z"
                        fill="#183153"
                      />
                      <path
                        d="M40.4484 162.069C46.733 162.069 51.8277 156.975 51.8277 150.69C51.8277 144.405 46.733 139.311 40.4484 139.311C34.1638 139.311 29.0691 144.405 29.0691 150.69C29.0691 156.975 34.1638 162.069 40.4484 162.069Z"
                        fill="#183153"
                      />
                      <path
                        d="M141.345 148.414V198.483L199 141.586H147.414C142.559 141.586 141.345 146.138 141.345 148.414Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Overview</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' +
                    rcService.getTenant() +
                    '/inventory/maintenance/vendors'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Vendors"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                    >
                      <path
                        d="M98.2244 100C125.562 100 147.724 77.8381 147.724 50.5C147.724 23.1619 125.562 1 98.2244 1C70.8863 1 48.7244 23.1619 48.7244 50.5C48.7244 77.8381 70.8863 100 98.2244 100Z"
                        fill="#748398"
                      />
                      <path
                        d="M177.939 199H21.7245C14.5244 197.457 12.2959 191.071 12.0816 188.071C10.153 152.071 42.9387 132.143 54.5102 127.643C63.7673 124.043 67.7959 130 68.653 133.429L80.8673 188.071L93.0816 140.5C92.2244 139.643 89.4816 136 85.3673 128.286C81.253 120.571 87.5102 118.643 91.153 118.643H108.51C117.253 118.643 115.582 125.071 113.653 128.286L105.296 140.5L118.153 188.071C121.582 173.071 128.824 141.143 130.367 133.429C131.91 125.714 139.582 126.357 143.224 127.643C179.224 138.571 185.653 173.286 186.939 184.214C187.967 192.957 181.367 197.714 177.939 199Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Vendors</span
                  >
                </a>
              </li>
            </div>
          </ul>
        </div>
      </li>
      <li
        *ngIf="isInventoryAccess"
        class="block"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <a
          class="flex space-x-3 py-3 px-2 items-center relative cursor-pointer text-center submenu-toggle overflow-hidden"
          matTooltip="Inventory"
          matTooltipPosition="left"
          href="javascript:void(0);"
        >
          <span (click)="goToInventoryMaster($event)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 200 200"
              fill="none"
            >
              <path
                d="M17.0144 171.403C17.0144 175.97 12.3908 177.826 9.16482 177.826C4.02693 177.826 1.79096 173.544 1.31521 171.403C1.07735 142.859 0.744332 83.0596 1.31521 72.2129C1.88609 61.3662 10.592 55.8001 14.8736 54.3729C39.3739 44.6203 89.6589 24.5444 94.7969 22.2609C99.9348 19.9773 104.549 21.3094 106.214 22.2609C128.812 31.0619 176.575 49.8058 186.851 54.3729C197.127 58.9399 199.22 68.1691 198.982 72.2129V171.403C198.982 174.971 196.413 177.826 191.846 177.826C187.279 177.826 183.997 174.971 183.997 171.403V75.7809C183.997 70.643 177.337 66.9798 174.007 65.7905L100.506 35.8193C79.0977 44.3825 34.2836 62.3652 26.2912 65.7905C18.2989 69.2158 16.7766 73.8779 17.0144 75.7809V171.403Z"
                fill="white"
              />
              <path
                d="M39.136 177.826C33.9981 177.826 31.7621 173.068 31.2864 170.69V90.7665C31.2864 82.7742 38.4224 79.8246 41.9904 79.3489H158.307C166.87 79.3489 169.487 86.9606 169.725 90.7665V170.69C169.487 173.068 167.584 177.826 161.875 177.826C156.166 177.826 154.264 173.068 154.026 170.69V94.3345H46.272V170.69C46.0341 173.068 44.2739 177.826 39.136 177.826Z"
                fill="white"
              />
              <rect
                x="54.8352"
                y="108.606"
                width="47.0976"
                height="29.9712"
                rx="6"
                fill="#748398"
              />
              <rect
                x="54.8352"
                y="148.568"
                width="47.0976"
                height="29.9712"
                rx="6"
                fill="white"
              />
              <rect
                x="109.069"
                y="148.568"
                width="35.68"
                height="29.9712"
                rx="6"
                fill="#748398"
              />
            </svg>
          </span>
          <span
            (click)="goToInventoryMaster($event)"
            [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
            >Inventory</span
          >
          <span
            (click)="toggleSubmenu('inventory-master')"
            class="absolute right-0 top-1/1 h-full w-4 bg-blue-500 rounded-r-lg flex justify-center items-center duration-300"
          >
            <!-- [ngClass]="submenuOpen ? 'rotate-180' : ''" -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-3 h-3"
              [ngClass]="submenuStates['inventory-master'] ? 'rotate-180' : ''"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
              />
            </svg>
          </span>
        </a>
        <div
          class="grid overflow-hidden transition-all duration-300 ease-in-out submenu-container"
          [ngClass]="
            submenuStates['inventory-master']
              ? 'grid-rows-[1fr] opacity-100'
              : 'grid-rows-[0fr] opacity-0'
          "
        >
          <ul
            class="py-0 px-1 bg-white/10 duration-300 rounded-md overflow-hidden"
          >
            <div class="py-1">
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' +
                    rcService.getTenant() +
                    '/inventory/maintenance-master'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Maintenance"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M14.5 10a4.5 4.5 0 0 0 4.284-5.882c-.105-.324-.51-.391-.752-.15L15.34 6.66a.454.454 0 0 1-.493.11 3.01 3.01 0 0 1-1.618-1.616.455.455 0 0 1 .11-.494l2.694-2.692c.24-.241.174-.647-.15-.752a4.5 4.5 0 0 0-5.873 4.575c.055.873-.128 1.808-.8 2.368l-7.23 6.024a2.724 2.724 0 1 0 3.837 3.837l6.024-7.23c.56-.672 1.495-.855 2.368-.8.096.007.193.01.291.01ZM5 16a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
                        clip-rule="evenodd"
                      />
                      <path
                        d="M14.5 11.5c.173 0 .345-.007.514-.022l3.754 3.754a2.5 2.5 0 0 1-3.536 3.536l-4.41-4.41 2.172-2.607c.052-.063.147-.138.342-.196.202-.06.469-.087.777-.067.128.008.257.012.387.012ZM6 4.586l2.33 2.33a.452.452 0 0 1-.08.09L6.8 8.214 4.586 6H3.309a.5.5 0 0 1-.447-.276l-1.7-3.402a.5.5 0 0 1 .093-.577l.49-.49a.5.5 0 0 1 .577-.094l3.402 1.7A.5.5 0 0 1 6 3.31v1.277Z"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Maintenance</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' + rcService.getTenant() + '/inventory/year-end'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Year End"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="37.037"
                        y="23.75"
                        width="162.963"
                        height="138.75"
                        fill="white"
                      />
                      <rect
                        x="37.037"
                        y="77.5"
                        width="162.963"
                        height="85"
                        fill="#748397"
                      />
                      <rect
                        x="74.0741"
                        width="27.1605"
                        height="23.75"
                        fill="white"
                      />
                      <rect
                        x="135.802"
                        width="27.1605"
                        height="23.75"
                        fill="white"
                      />
                      <rect
                        y="62.5"
                        width="20.9877"
                        height="137.5"
                        fill="white"
                      />
                      <rect
                        x="162.963"
                        y="178.75"
                        width="21.25"
                        height="154.321"
                        transform="rotate(90 162.963 178.75)"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Year End</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' + rcService.getTenant() + '/inventory/reports'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Reports"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2 3.5A1.5 1.5 0 0 1 3.5 2h9A1.5 1.5 0 0 1 14 3.5v11.75A2.75 2.75 0 0 0 16.75 18h-12A2.75 2.75 0 0 1 2 15.25V3.5Zm3.75 7a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-4.5Zm0 3a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-4.5ZM5 5.75A.75.75 0 0 1 5.75 5h4.5a.75.75 0 0 1 .75.75v2.5a.75.75 0 0 1-.75.75h-4.5A.75.75 0 0 1 5 8.25v-2.5Z"
                        clip-rule="evenodd"
                      />
                      <path
                        d="M16.5 6.5h-1v8.75a1.25 1.25 0 1 0 2.5 0V8a1.5 1.5 0 0 0-1.5-1.5Z"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Reports</span
                  >
                </a>
              </li>

              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' +
                    rcService.getTenant() +
                    '/inventory/receiving/stock-receipt'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Scan Receiving"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.6667 119.333L28 75.3333C18.6667 73.1111 0 62.4 0 37.3333C0 12.2667 23.5556 2 35.3333 0H108C117.6 0 121.333 9.33333 122 14C122.222 26.4444 122.533 53.8667 122 64C121.467 74.1333 112.444 77.5555 108 78H90.6667C79.7778 96.6667 56.4 136.667 50 147.333C43.6 158 32.6667 154.889 28 152C24.8889 150 16.6667 144.667 8.6667 139.333C0.666703 134 1.33337 123.778 2.6667 119.333Z"
                        fill="white"
                      />
                      <rect
                        x="147"
                        y="110"
                        width="56"
                        height="11"
                        rx="4"
                        fill="white"
                      />
                      <rect
                        x="147"
                        y="88"
                        width="56"
                        height="11"
                        rx="4"
                        fill="white"
                      />
                      <rect
                        x="147"
                        y="33"
                        width="56"
                        height="11"
                        rx="4"
                        fill="white"
                      />
                      <rect
                        x="147"
                        y="132"
                        width="56"
                        height="22"
                        rx="6"
                        fill="white"
                      />
                      <rect
                        x="147"
                        y="55"
                        width="56"
                        height="22"
                        rx="6"
                        fill="white"
                      />
                      <rect
                        x="147"
                        width="56"
                        height="22"
                        rx="6"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Scan Receiving</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' + rcService.getTenant() + '/inventory/stock-receipt'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Stock Receipt"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="36.9798"
                        cy="23.882"
                        rx="18.951"
                        ry="18.882"
                        fill="white"
                      />
                      <path
                        d="M5 130.683L31.0576 157.236V181.381C31.0576 188.577 25.2244 194.41 18.0288 194.41V194.41C10.8332 194.41 5 188.577 5 181.381V130.683Z"
                        fill="white"
                      />
                      <path
                        d="M65.9986 88.1989H82.5807L87.3185 112.391C79.2248 112.588 62.0899 112.863 58.2997 112.391C53.562 111.801 51.1931 108.261 48.8242 105.901C46.9291 104.013 44.0865 99.6067 42.902 97.6399V119.472L65.9986 143.075C68.17 155.269 72.3945 180.131 71.9208 182.019C71.3285 184.379 71.9208 192.64 60.6686 194.41C51.6669 195.826 47.0476 187.919 45.8631 183.789L41.7176 153.696C31.6499 143.665 10.9222 122.777 8.55333 119.472C6.18446 116.168 5.19741 111.408 5 109.441V72.8573C4.99998 70.497 6.18446 68.399 8.55331 63.4163C13.8833 52.2051 26.9121 52.2051 29.281 52.2051C31.6499 52.2051 39.3487 52.2051 45.8631 58.6958C51.0746 63.8883 61.4582 80.5281 65.9986 88.1989Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M112.784 195C125.867 195 136.473 184.433 136.473 171.397C136.473 158.362 125.867 147.795 112.784 147.795C99.7009 147.795 89.0951 158.362 89.0951 171.397C89.0951 184.433 99.7009 195 112.784 195ZM112.784 177.298C116.055 177.298 118.706 174.656 118.706 171.397C118.706 168.139 116.055 165.497 112.784 165.497C109.513 165.497 106.862 168.139 106.862 171.397C106.862 174.656 109.513 177.298 112.784 177.298Z"
                        fill="#748398"
                      />
                      <path
                        d="M125.813 159.021L180.98 144.325C186.02 142.983 191.201 145.978 192.553 151.015V151.015C193.904 156.052 190.915 161.224 185.875 162.566L130.707 177.262L125.813 159.021Z"
                        fill="#748398"
                      />
                      <path
                        d="M98.1664 155.338L78.9377 78.8885C77.6614 73.8145 80.7419 68.6737 85.8181 67.4062V67.4062C90.8943 66.1387 96.044 69.2245 97.3202 74.2985L116.549 150.748L98.1664 155.338Z"
                        fill="#748398"
                      />
                      <rect
                        x="115"
                        y="99.6748"
                        width="49.8687"
                        height="49.4296"
                        rx="10"
                        transform="rotate(-14.7239 115 99.6748)"
                        fill="#748398"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Stock Receipt</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' + rcService.getTenant() + '/inventory/receipt'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px]"
                  matTooltip="Inventory Receipt"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      viewBox="0 0 200 200"
                      fill="none"
                    >
                      <path
                        d="M27 190.497V9.31715C30.4581 -0.538962 38.527 0.862131 42.1292 2.79469L57.9788 16.5643C62.0612 12.9407 71.0907 5.11374 74.5488 2.79469C78.0069 0.475643 81.7532 1.82842 83.1941 2.79469L101.205 16.5643C105.047 12.9407 113.596 5.11374 117.055 2.79469C120.513 0.475643 124.739 1.82842 126.42 2.79469L143.711 16.5643L159.56 2.79469C168.206 -2.42328 172.768 4.96885 173.969 9.31715V190.497C173.249 199.193 165.324 200.643 160.281 197.019L143.711 183.249C138.908 187.115 128.726 195.28 126.42 197.019C124.115 198.758 120.176 197.744 118.495 197.019L100.485 183.249C96.4021 186.631 87.3726 194.12 83.9145 197.019C80.4564 199.918 76.2298 198.227 74.5488 197.019L57.9788 183.249L42.8496 196.294C32.0431 203.541 27.7204 194.362 27 190.497Z"
                        fill="#748398"
                      />
                      <rect
                        x="56.9836"
                        y="57.5514"
                        width="88.4522"
                        height="13.0503"
                        rx="6.52516"
                        fill="white"
                      />
                      <rect
                        x="56.9836"
                        y="93.8022"
                        width="88.4522"
                        height="13.0503"
                        rx="6.52516"
                        fill="white"
                      />
                      <rect
                        x="56.9836"
                        y="130.053"
                        width="88.4522"
                        height="13.0503"
                        rx="6.52516"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Inventory Receipt</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' + rcService.getTenant() + '/inventory/usage'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Inventory Usage"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      class="h-4 w-4"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="100" cy="100" r="95" fill="#748398" />
                      <circle
                        cx="40.7716"
                        cy="99.4136"
                        r="12.3148"
                        fill="#183153"
                      />
                      <circle
                        cx="58.3643"
                        cy="57.1914"
                        r="12.3148"
                        fill="#183153"
                      />
                      <circle
                        cx="140.463"
                        cy="58.3643"
                        r="12.3148"
                        fill="#183153"
                      />
                      <circle
                        cx="158.056"
                        cy="99.4136"
                        r="12.3148"
                        fill="#183153"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M100 27.2839C94.1703 27.2839 89.4444 32.0098 89.4444 37.8395V111.728C89.4444 111.737 89.4445 111.745 89.4445 111.753C81.1233 115.706 75.3704 124.187 75.3704 134.012C75.3704 147.615 86.3974 158.642 100 158.642C113.603 158.642 124.63 147.615 124.63 134.012C124.63 124.187 118.877 115.706 110.556 111.753C110.556 111.745 110.556 111.737 110.556 111.728V37.8395C110.556 32.0098 105.83 27.2839 100 27.2839Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Inventory Usage</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' + rcService.getTenant() + '/inventory/blanket-po'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Blanket Order Request"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      class="h-4 w-4"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 44.6404V143.742L50.1418 104.785L143.121 100.684L195 122.554V43.957C194.775 38.7171 192.035 25.9137 182.872 16.6187C173.709 7.32375 158.842 5 152.553 5H42.0567C35.9929 5 24.539 9.10073 15.1064 18.6691C7.56028 26.3238 5.22459 39.1728 5 44.6404Z"
                        fill="#748398"
                      />
                      <path
                        d="M58.9007 153.993H114.823H168.05C177.033 152.398 195 143.057 195 118.453C195 93.8486 174.787 86.3306 164.681 85.6471H56.2057C38.6879 85.647 5.00008 100.66 5 138.957C4.99991 180.647 38.2388 194.317 54.8582 195H185.567C188.712 194.544 195 191.036 195 180.647C195 170.259 186.017 167.662 181.525 167.662C141.773 167.662 62.1348 167.525 61.5957 167.525C60.922 167.525 33.2979 165.475 33.2979 140.87C33.2979 118.453 54.8582 112.985 62.2695 112.985H157.943C159.291 112.985 166.702 112.985 166.702 119.82C166.702 125.288 161.312 126.654 158.617 126.654H59.5745C56.8794 126.654 45.4255 128.705 45.4255 141.007C45.4255 150.849 54.409 153.765 58.9007 153.993Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Blanket Order Request</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' +
                    rcService.getTenant() +
                    '/inventory/bulk-material/batch/add/header'
                  "
                  [queryParams]="{
                    type: 'batch',
                    status: 'Active'
                  }"
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Bulk Material Requisition"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      class="h-4 w-4"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M45.8148 80.9508H97.3007H155.759C157.01 80.9508 160.049 81.4871 162.194 83.6323C164.34 85.7776 164.876 89.1742 164.876 90.6044V164.079C164.876 166.761 164.952 168.906 166.485 171.587C168.201 174.591 170.775 174.805 172.384 174.805H188.474C189.189 174.805 191.247 174.713 192.764 173.196C194.373 171.587 194.909 168.012 194.909 166.224C195.088 136.012 195.124 74.1933 193.837 68.6156C192.55 63.038 184.362 58.0682 180.429 56.2805C156.474 46.6269 107.812 26.9978 104.809 25.7107C101.806 24.4236 97.4794 25.1744 95.6917 25.7107C71.5577 35.5431 21.359 56.0659 13.6361 59.4983C5.91325 62.9307 4.69761 74.1575 5.05515 79.3418V168.906C5.05515 169.978 5.8436 171.84 7.20037 173.196C8.27301 174.269 9.88193 175.342 10.9546 175.342H30.2618C31.3344 175.342 32.9433 173.733 34.016 172.124C34.8741 170.837 35.0886 167.654 35.0886 166.224V89.5317C35.0886 86.8502 35.6249 85.2413 37.7701 83.096C39.4863 81.3798 43.8483 80.9508 45.8148 80.9508Z"
                        fill="white"
                      />
                      <rect
                        x="42.5969"
                        y="90.0681"
                        width="58.9942"
                        height="38.6144"
                        rx="8"
                        fill="#748398"
                      />
                      <rect
                        x="42.5969"
                        y="137.263"
                        width="57.9216"
                        height="38.6144"
                        rx="8"
                        fill="#748398"
                      />
                      <rect
                        x="109.099"
                        y="136.191"
                        width="48.268"
                        height="38.6144"
                        rx="8"
                        fill="#748398"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Bulk Material Requisition</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' +
                    rcService.getTenant() +
                    '/inventory/requisition/batch/add'
                  "
                  [queryParams]="{ type: 'batch', status: 'Active' }"
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Material Requisition"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="36.9798"
                        cy="23.882"
                        rx="18.951"
                        ry="18.882"
                        fill="white"
                      />
                      <path
                        d="M5 130.683L31.0576 157.236V181.381C31.0576 188.577 25.2244 194.41 18.0288 194.41V194.41C10.8332 194.41 5 188.577 5 181.381V130.683Z"
                        fill="white"
                      />
                      <path
                        d="M65.9986 88.1987H82.5807L87.3185 112.391C79.2248 112.588 62.0899 112.863 58.2997 112.391C53.562 111.801 51.1931 108.261 48.8242 105.901C46.9291 104.012 44.0865 99.6066 42.902 97.6397V119.472L65.9986 143.075C68.17 155.269 72.3945 180.13 71.9208 182.019C71.3285 184.379 71.9208 192.64 60.6686 194.41C51.6669 195.826 47.0476 187.919 45.8631 183.789L41.7176 153.696C31.6499 143.665 10.9222 122.776 8.55333 119.472C6.18446 116.168 5.19741 111.408 5 109.441V72.8571C4.99998 70.4969 6.18446 68.3988 8.55331 63.4161C13.8833 52.205 26.9121 52.205 29.281 52.205C31.6499 52.205 39.3487 52.205 45.8631 58.6956C51.0746 63.8882 61.4582 80.5279 65.9986 88.1987Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M112.784 195C125.867 195 136.473 184.433 136.473 171.398C136.473 158.362 125.867 147.795 112.784 147.795C99.7009 147.795 89.0951 158.362 89.0951 171.398C89.0951 184.433 99.7009 195 112.784 195ZM112.784 177.298C116.055 177.298 118.706 174.656 118.706 171.398C118.706 168.139 116.055 165.497 112.784 165.497C109.513 165.497 106.862 168.139 106.862 171.398C106.862 174.656 109.513 177.298 112.784 177.298Z"
                        fill="#748398"
                      />
                      <path
                        d="M125.813 159.021L180.98 144.325C186.02 142.983 191.201 145.978 192.553 151.015V151.015C193.904 156.052 190.915 161.224 185.875 162.566L130.707 177.262L125.813 159.021Z"
                        fill="#748398"
                      />
                      <path
                        d="M98.1664 155.338L78.9377 78.8885C77.6614 73.8145 80.7419 68.6737 85.8181 67.4062V67.4062C90.8943 66.1387 96.044 69.2245 97.3202 74.2985L116.549 150.748L98.1664 155.338Z"
                        fill="#748398"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Material Requisition</span
                  >
                </a>
              </li>

              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' +
                    rcService.getTenant() +
                    '/inventory/transfer-maintenance/batch/add'
                  "
                  [queryParams]="{
                    type: 'batch',
                    status: 'Active'
                  }"
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Transfer Maintenance"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="9.7052"
                        y="74.3042"
                        width="66.7246"
                        height="66.1372"
                        rx="10"
                        transform="rotate(-14.7239 9.7052 74.3042)"
                        fill="#748398"
                      />
                      <path
                        d="M31.8718 68.694L51.6636 63.6244L56.8292 83.5226L36.9362 88.587L31.8718 68.694Z"
                        fill="white"
                      />
                      <circle
                        cx="164.843"
                        cy="145.847"
                        r="29.1534"
                        fill="#748398"
                      />
                      <path
                        d="M154.431 109.925C133.378 116.753 128.054 134.567 127.88 142.202L27.926 169.794C21.1583 171.876 10.1216 175 7.62272 166.67C5.12386 158.341 10.7463 154.176 13.8699 153.135L108.098 127.626V40.6862C108.098 26.526 120.592 23.68 126.839 24.0271H193.996V122.94C189.484 115.478 173.693 103.678 154.431 109.925Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Transfer Maintenance</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' +
                    rcService.getTenant() +
                    '/inventory/finish-good-maintenance/add/header'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Finish Good Maintenance"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 130.421C0 125.189 4.24151 120.947 9.47368 120.947H189.474C194.706 120.947 198.947 125.189 198.947 130.421C198.947 135.653 194.706 139.895 189.474 139.895H9.47368C4.24151 139.895 0 135.653 0 130.421Z"
                        fill="#748398"
                      />
                      <path
                        d="M1.05263 169.368C1.05263 164.136 5.29414 159.895 10.5263 159.895H190.526C195.758 159.895 200 164.136 200 169.368C200 174.601 195.758 178.842 190.526 178.842H10.5263C5.29414 178.842 1.05263 174.601 1.05263 169.368Z"
                        fill="#748398"
                      />
                      <path
                        d="M20 135.684H42.1053V164.105H20V135.684Z"
                        fill="#748398"
                      />
                      <path
                        d="M88.4211 136.737H110.526V165.158H88.4211V136.737Z"
                        fill="#748398"
                      />
                      <path
                        d="M156.842 136.737H178.947V165.158H156.842V136.737Z"
                        fill="#748398"
                      />
                      <rect
                        x="20"
                        y="22"
                        width="80"
                        height="78.9474"
                        rx="10"
                        fill="white"
                      />
                      <rect
                        x="117.895"
                        y="42"
                        width="60"
                        height="58.9474"
                        rx="10"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Finish Good Maintenance</span
                  >
                </a>
              </li>

              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' +
                    rcService.getTenant() +
                    '/inventory/physical-inventory/view'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Physical Inventory"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M45.8148 80.9508H97.3007H155.759C157.01 80.9508 160.049 81.4871 162.194 83.6323C164.34 85.7776 164.876 89.1742 164.876 90.6044V164.079C164.876 166.761 164.952 168.906 166.485 171.587C168.201 174.591 170.775 174.805 172.384 174.805H188.474C189.189 174.805 191.247 174.713 192.764 173.196C194.373 171.587 194.909 168.012 194.909 166.224C195.088 136.012 195.124 74.1933 193.837 68.6156C192.55 63.038 184.362 58.0682 180.429 56.2805C156.474 46.6269 107.812 26.9978 104.809 25.7107C101.806 24.4236 97.4794 25.1744 95.6917 25.7107C71.5577 35.5431 21.359 56.0659 13.6361 59.4983C5.91325 62.9307 4.69761 74.1575 5.05515 79.3418V168.906C5.05515 169.978 5.8436 171.84 7.20037 173.196C8.27301 174.269 9.88193 175.342 10.9546 175.342H30.2618C31.3344 175.342 32.9433 173.733 34.016 172.124C34.8741 170.837 35.0886 167.654 35.0886 166.224V89.5317C35.0886 86.8502 35.6249 85.2413 37.7701 83.096C39.4863 81.3798 43.8483 80.9508 45.8148 80.9508Z"
                        fill="white"
                      />
                      <rect
                        x="44"
                        y="94"
                        width="112"
                        height="22"
                        fill="#748398"
                      />
                      <rect
                        x="44"
                        y="125"
                        width="112"
                        height="19"
                        fill="#748398"
                      />
                      <path
                        d="M44 153H156V169C156 172.314 153.314 175 150 175H50C46.6863 175 44 172.314 44 169V153Z"
                        fill="#748398"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Physical Inventory</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="[
                    rcService.getTenant() +
                      '/inventory/stock-order/maintenance/add/header'
                  ]"
                  [queryParams]="{ tab: 0 }"
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Stock Order Maintenance"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_14_231)">
                        <path
                          d="M107.884 138.8L182.157 114.585C188.193 112.617 194.69 115.912 196.669 121.945V121.945C198.648 127.977 195.359 134.462 189.323 136.43L115.05 160.645L107.884 138.8Z"
                          fill="white"
                        />
                        <path
                          d="M60.7722 39.1546L89.4111 125.612L67.7969 133.177L35.9158 38.6143H9.97863C6.73648 38.2541 0.252197 35.5883 0.252197 27.8071C0.252197 20.026 7.09672 17.3602 10.519 17H40.779C42.5802 17 47.2633 17.9726 51.5861 21.8632C55.909 25.7538 59.5114 35.0119 60.7722 39.1546Z"
                          fill="white"
                        />
                        <circle
                          cx="88.8708"
                          cy="157.478"
                          r="33.5022"
                          fill="#748398"
                        />
                        <rect
                          x="79.6593"
                          y="51.3701"
                          width="77.636"
                          height="76.9525"
                          rx="10"
                          transform="rotate(-17.6681 79.6593 51.3701)"
                          fill="#748398"
                        />
                        <path
                          d="M105.082 43.5264L127.777 36.4527L134.968 59.2656L112.155 66.3393L105.082 43.5264Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_14_231">
                          <rect width="200" height="200" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Stock Order Maintenance</span
                  >
                </a>
              </li>
              <li class="block" routerLinkActive="active">
                <a
                  [routerLink]="
                    '/' +
                    rcService.getTenant() +
                    '/inventory/inventory-adjustment/add'
                  "
                  class="flex space-x-3 py-2 px-3 text-[12px] items-center"
                  matTooltip="Inventory Adjustment"
                  matTooltipPosition="left"
                >
                  <span>
                    <svg
                      class="w-4 h-4"
                      viewBox="0 0 200 200"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="101.027"
                        cy="46.29"
                        r="25.2753"
                        fill="#748398"
                      />
                      <rect
                        x="34.0627"
                        y="17.8552"
                        width="148.203"
                        height="21.0628"
                        rx="10.5314"
                        transform="rotate(18.707 34.0627 17.8552)"
                        fill="#748398"
                      />
                      <rect
                        x="111.558"
                        y="33.6523"
                        width="148.203"
                        height="21.0628"
                        rx="10.5314"
                        transform="rotate(90 111.558 33.6523)"
                        fill="#748398"
                      />
                      <rect
                        x="30.4668"
                        y="161.082"
                        width="81.0917"
                        height="21.0628"
                        rx="10.5314"
                        fill="#748398"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M33.7312 54.7151L2.137 108.425C1.61043 110.18 0.662603 114.323 1.08386 116.85C1.61043 120.01 9.5089 141.073 40.0499 141.599C64.4828 142.02 77.2609 126.68 80.5958 118.957C81.1224 117.377 81.5436 113.059 79.0161 108.425C77.4475 105.549 69.4432 91.9115 61.3718 78.1591C56.4374 69.7518 51.4779 61.3016 47.9485 55.2417C46.7218 53.1354 44.3678 51.5557 40.5765 51.5557C36.7852 51.5557 34.4332 53.6619 33.7312 54.7151ZM64.7114 112.111L40.9982 72.6184L17.285 112.111H64.7114Z"
                        fill="white"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M152.736 94.7344L121.142 148.444C120.615 150.2 119.667 154.342 120.089 156.87C120.615 160.029 128.514 181.092 159.055 181.618C183.488 182.04 196.266 166.699 199.601 158.976C200.127 157.396 200.548 153.078 198.021 148.444C196.452 145.569 188.448 131.931 180.377 118.178C175.442 109.771 170.483 101.321 166.953 95.2609C165.727 93.1547 163.373 91.575 159.581 91.575C155.79 91.575 153.438 93.6812 152.736 94.7344ZM183.716 152.13L160.003 112.638L136.29 152.13H183.716Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
                    >Inventory Adjustment</span
                  >
                </a>
              </li>
            </div>
          </ul>
        </div>
      </li>
      <li
        *ngIf="isSupervisor || isReviewer"
        class="block"
        routerLinkActive="active"
      >
        <a
          [routerLink]="'/' + rcService.getTenant() + '/review-entries'"
          class="flex space-x-3 py-3 px-3 items-center"
          matTooltip="Review Entries"
          matTooltipPosition="left"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              viewBox="0 0 200 200"
              fill="none"
            >
              <path
                d="M188.737 178.315L162.145 151.723L172.658 141.21L198.631 167.802C199.868 169.657 200.053 173.755 197.394 177.078C194.921 180.171 190.592 179.346 188.737 178.315Z"
                fill="white"
              />
              <path
                d="M139.881 152.026C158.15 152.026 172.96 137.216 172.96 118.947C172.96 100.678 158.15 85.8682 139.881 85.8682C121.612 85.8682 106.802 100.678 106.802 118.947C106.802 137.216 121.612 152.026 139.881 152.026Z"
                stroke="white"
                stroke-width="13"
              />
              <path
                d="M70.6185 99.1576C92.4772 99.1576 110.197 81.4376 110.197 59.5788C110.197 37.72 92.4772 20 70.6185 20C48.7597 20 31.0397 37.72 31.0397 59.5788C31.0397 81.4376 48.7597 99.1576 70.6185 99.1576Z"
                fill="#748398"
              />
              <path
                d="M133.697 178.315H7.53978C0.613489 175.347 0.531033 166.771 1.35559 162.855C6.30294 126.244 36.3993 115.03 50.8291 114H91.0264C92.0158 159.021 124.421 169.039 140.5 168.421C140.5 174.852 135.965 177.697 133.697 178.315Z"
                fill="#748398"
              />
            </svg>
          </span>
          <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
            >Review Entries</span
          >
        </a>
      </li>
      <li class="block" routerLinkActive="active">
        <a
          [routerLink]="'/' + rcService.getTenant() + '/reports'"
          class="flex space-x-3 py-3 px-3 items-center"
          matTooltip="Reports"
          matTooltipPosition="left"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              viewBox="0 0 200 200"
              fill="none"
            >
              <path
                d="M46.6976 1.00943H113.825L174.849 61.3559V177.302C174.849 192.491 160.836 198.096 153.829 199H46.6976C29.882 199 25.226 184.535 25 177.302V21.3509C25 4.53529 39.4651 0.783418 46.6976 1.00943Z"
                fill="#748398"
              />
              <path
                d="M110.434 98.6486C110.434 92.657 105.577 87.7998 99.5855 87.7998C93.5939 87.7998 88.7367 92.657 88.7367 98.6486V163.741C88.7367 169.733 93.5939 174.59 99.5855 174.59C105.577 174.59 110.434 169.733 110.434 163.741V98.6486Z"
                fill="white"
              />
              <path
                d="M144.337 123.059C144.337 117.067 139.479 112.21 133.488 112.21C127.496 112.21 122.639 117.067 122.639 123.059V163.742C122.639 169.733 127.496 174.591 133.488 174.591C139.479 174.591 144.337 169.733 144.337 163.742V123.059Z"
                fill="white"
              />
              <path
                d="M76.5318 147.468C76.5318 141.476 71.6747 136.619 65.683 136.619C59.6914 136.619 54.8342 141.476 54.8342 147.468V163.741C54.8342 169.733 59.6914 174.59 65.683 174.59C71.6747 174.59 76.5318 169.733 76.5318 163.741V147.468Z"
                fill="white"
              />
              <path
                d="M174.849 61.3559L113.825 1.00946C113.825 16.1526 113.146 48.0661 113.146 54.5754C113.146 61.0847 119.023 62.712 121.961 62.712L174.849 61.3559Z"
                fill="white"
              />
            </svg>
          </span>
          <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
            >Reports</span
          >
        </a>
      </li>
      <li
        *ngIf="notifications && notifications.length"
        class="block"
        routerLinkActive="active"
      >
        <a
          [routerLink]="'/' + rcService.getTenant() + '/notification'"
          class="flex space-x-3 py-3 px-3 items-center"
          matTooltip="Notifications"
          matTooltipPosition="left"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              viewBox="0 0 200 200"
              fill="none"
            >
              <path
                d="M176.821 161.875H23.0179C16.5357 161.875 13 155.393 13 149.5C13 144.786 19.6786 137.714 23.0179 134.768C38.575 117.325 39.7143 88.2143 38.3393 75.8393C42.5821 38.125 73.1071 23.9821 87.8393 21.625V10.4286C88.4286 7.28571 91.8464 1 100.804 1C109.761 1 113.179 7.28571 113.768 10.4286V21.625C134.393 25.1607 162.089 46.375 162.679 75.8393C163.359 109.857 166.804 120.625 178.589 134.768C182.125 139.011 187.429 142.993 187.429 150.089C187.429 161.875 180.161 160.893 176.821 161.875Z"
                fill="#748398"
              />
              <path
                d="M126.143 174.25H75.4644C75.8573 182.5 81.3573 199 100.214 199C119.071 199 125.357 182.5 126.143 174.25Z"
                fill="white"
              />
            </svg>
          </span>
          <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
            >Notifications</span
          >
          <span
            class="p-1 bg-red-600 text-white h-5 w-5 rounded-full text-[10px] flex justify-center items-center !-mt-6 !-m-1"
            >{{ notifications.length }}</span
          >
        </a>
      </li>
      <li class="block" routerLinkActive="active">
        <a
          [routerLink]="'/' + rcService.getTenant() + '/benefit-history'"
          class="flex space-x-3 py-3 px-3 items-center"
          matTooltip="Benefits History"
          matTooltipPosition="left"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              viewBox="0 0 200 200"
              fill="none"
            >
              <path
                d="M89.7011 62.5732C95.9791 85.7537 87.0852 121.329 81.8535 136.22L116.866 138.03C126.041 92.6354 116.665 58.3476 110.829 46.8781L99.9633 45.0671L89.7011 62.5732Z"
                fill="#748398"
              />
              <path
                d="M144.031 51.1036H102.378L58.311 96.378C53.4817 101.207 49.8598 98.3902 48.6524 96.378C34.1646 73.6805 52.2744 47.8841 63.1403 37.8232L52.2744 26.9573L39.5976 39.0305H23.9025C21.689 39.0305 18.1073 36.978 21.4878 28.7683C25.7134 18.5061 37.7866 1 69.7805 1C95.3756 1 109.421 15.0854 113.244 22.128C122.902 14.8841 137.793 13.4756 144.031 13.6768C175.421 12.4695 189.909 36.0122 192.323 42.6524C194.255 47.9646 191.518 50.5 189.909 51.1036H168.177L156.104 39.0305L144.031 51.1036Z"
                fill="white"
              />
              <path
                d="M148.528 199H16.5482C6.22796 199 6.29411 186.723 7.61722 180.584C13.1743 148.441 35.372 136.22 62.1953 136.22H104.865C141.616 136.22 156.467 166.354 158.451 180.584C159.385 187.281 161.23 199 148.528 199Z"
                fill="white"
              />
            </svg>
          </span>
          <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
            >Benefits History</span
          >
        </a>
      </li>
      <li class="block" *ngIf="hasEvents && !isDetailsPage()">
        <a
          href="javascript:void(0);"
          (click)="openCopyDetailDailog()"
          class="flex space-x-3 py-3 px-3 items-center"
          matTooltip="Copy Details"
          matTooltipPosition="left"
        >
          <span>
            <svg
              class="w-6 h-6"
              viewBox="0 0 200 200"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M141.344 1.06358H93.7326C79.8821 1.06358 75.5951 13.4301 75.1829 19.6133V149.462H169.168C183.514 149.462 187.512 135.859 187.718 129.057V51.7663C187.718 43.3571 185.657 37.957 184.627 36.3081C180.298 31.5677 169.416 19.8607 160.512 10.9568C151.608 2.0529 144.023 0.651364 141.344 1.06358Z"
                fill="white"
              />
              <path
                d="M64.0529 74.0261V50.5297H34.3732C14.5868 50.5297 11.289 66.1939 12.1135 74.0261V179.141C12.1135 196.455 26.9533 199.546 34.3732 198.928H117.229C134.047 198.928 137.84 185.737 137.634 179.141V160.592H113.519V174.813H38.0832V74.0261H64.0529Z"
                fill="#748398"
              />
            </svg>
          </span>
          <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
            >Copy Details</span
          >
        </a>
      </li>
      <li *ngIf="isUpdatePasswordAvailable" class="block">
        <a
          href="javascript:void(0);"
          (click)="openChangePasswordDailog()"
          class="flex space-x-3 py-3 px-3 items-center"
          matTooltip="Change Password"
          matTooltipPosition="left"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              viewBox="0 0 200 200"
              fill="none"
            >
              <path
                d="M169.784 75.072H30C20.6112 75.072 13 82.6832 13 92.072V182C13 191.389 20.6112 199 30 199H169.784C179.173 199 186.784 191.389 186.784 182V92.072C186.784 82.6832 179.173 75.072 169.784 75.072Z"
                fill="white"
              />
              <path
                d="M112.712 123.504H87.0719C79.9916 123.504 74.2518 129.244 74.2518 136.324V136.324C74.2518 143.405 79.9916 149.144 87.0719 149.144H112.712C119.793 149.144 125.532 143.405 125.532 136.324V136.324C125.532 129.244 119.793 123.504 112.712 123.504Z"
                fill="#183153"
              />
              <path
                d="M69.9784 75.072H43.6259V53.7051C43.6259 21.6547 72.1151 1 99.8921 1C127.669 1 142.626 20.2302 148.324 28.0647C153.087 34.614 152.597 41.5971 151.173 43.7338C147.059 49.9051 134.791 57.1238 126.245 42.3094C117.698 27.495 105.115 25.2159 99.8921 25.9281C77.6705 25.9281 70.6907 44.4461 69.9784 53.7051V75.072Z"
                fill="#748398"
              />
            </svg>
          </span>
          <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
            >Change Password</span
          >
        </a>
      </li>
      <li *ngIf="isSupervisor || isReviewer" class="block">
        <a
          href="javascript:void(0);"
          (click)="openProfileDailog()"
          class="flex space-x-3 py-3 px-3 items-center"
          matTooltip="Profile"
          matTooltipPosition="left"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              viewBox="0 0 200 200"
              fill="none"
            >
              <path
                d="M180 24H20C9.50659 24 1 32.5066 1 43V157.576C1 168.069 9.50659 176.576 20 176.576H180C190.493 176.576 199 168.069 199 157.576V43C199 32.5066 190.493 24 180 24Z"
                fill="#748398"
              />
              <path
                d="M61.5647 100.871C74.4297 100.871 84.8588 90.4415 84.8588 77.5765C84.8588 64.7115 74.4297 54.2824 61.5647 54.2824C48.6998 54.2824 38.2706 64.7115 38.2706 77.5765C38.2706 90.4415 48.6998 100.871 61.5647 100.871Z"
                fill="white"
              />
              <path
                d="M94.5655 146.294H26.8627C21.5687 146.294 21.6026 138.778 22.2813 135.02C25.132 115.341 41.625 107.859 50.2787 107.859H72.1676C91.0022 109.909 98.6379 126.308 99.656 135.02C100.135 139.12 101.081 146.294 94.5655 146.294Z"
                fill="white"
              />
              <path
                d="M171.047 67.0941H126.788C123.572 67.0941 120.965 69.7014 120.965 72.9176V72.9177C120.965 76.1339 123.572 78.7412 126.788 78.7412H171.047C174.264 78.7412 176.871 76.1339 176.871 72.9177V72.9176C176.871 69.7014 174.264 67.0941 171.047 67.0941Z"
                fill="#183153"
              />
              <path
                d="M171.047 89.2235H126.788C123.572 89.2235 120.965 91.8308 120.965 95.047V95.0471C120.965 98.2633 123.572 100.871 126.788 100.871H171.047C174.264 100.871 176.871 98.2633 176.871 95.0471V95.047C176.871 91.8308 174.264 89.2235 171.047 89.2235Z"
                fill="#183153"
              />
              <path
                d="M171.047 111.353H126.788C123.572 111.353 120.965 113.96 120.965 117.177V117.177C120.965 120.393 123.572 123 126.788 123H171.047C174.264 123 176.871 120.393 176.871 117.177V117.177C176.871 113.96 174.264 111.353 171.047 111.353Z"
                fill="#183153"
              />
            </svg>
          </span>
          <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
            >Profile</span
          >
        </a>
      </li>
      <li *ngIf="isIT" class="block">
        <a
          href="javascript:void(0);"
          (click)="openITPasswordTool()"
          class="flex space-x-3 py-3 px-3 items-center"
          matTooltip="IT Password Reset"
          matTooltipPosition="left"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              viewBox="0 0 200 200"
              fill="none"
            >
              <path
                d="M169.784 75.072H30C20.6112 75.072 13 82.6832 13 92.072V182C13 191.389 20.6112 199 30 199H169.784C179.173 199 186.784 191.389 186.784 182V92.072C186.784 82.6832 179.173 75.072 169.784 75.072Z"
                fill="white"
              />
              <path
                d="M112.712 123.504H87.0719C79.9916 123.504 74.2518 129.244 74.2518 136.324V136.324C74.2518 143.405 79.9916 149.144 87.0719 149.144H112.712C119.793 149.144 125.532 143.405 125.532 136.324V136.324C125.532 129.244 119.793 123.504 112.712 123.504Z"
                fill="#183153"
              />
              <path
                d="M69.9784 75.072H43.6259V53.7051C43.6259 21.6547 72.1151 1 99.8921 1C127.669 1 142.626 20.2302 148.324 28.0647C153.087 34.614 152.597 41.5971 151.173 43.7338C147.059 49.9051 134.791 57.1238 126.245 42.3094C117.698 27.495 105.115 25.2159 99.8921 25.9281C77.6705 25.9281 70.6907 44.4461 69.9784 53.7051V75.072Z"
                fill="#748398"
              />
            </svg>
          </span>
          <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
            >IT Password Reset</span
          >
        </a>
      </li>
      <li class="block">
        <button
          (click)="onLogout()"
          class="flex space-x-3 py-3 px-3 items-center"
          matTooltip="Logout"
          matTooltipPosition="left"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              viewBox="0 0 200 200"
              fill="none"
            >
              <path
                d="M20.9884 139.825V103.214C3.51794 104.242 0.434938 96.791 1.07725 92.9372C0.563411 89.8542 3.86053 86.086 5.57332 84.5873C24.6281 66.8172 63.8938 30.2491 68.5183 26.1384C73.1429 22.0277 79.4374 24.4257 82.0066 26.1384L117.975 59.5378C107.698 67.8877 90.3564 89.7258 97.4217 104.499C103.074 116.317 116.476 115.418 122.471 113.491L114.121 137.256L99.3485 152.028H33.8343C24.0714 152.028 21.2025 143.893 20.9884 139.825Z"
                fill="#748398"
              />
              <path
                d="M70.5836 78.8066H49.7534C46.992 78.8066 44.7534 81.0452 44.7534 83.8066V104.637C44.7534 107.398 46.992 109.637 49.7534 109.637H70.5836C73.345 109.637 75.5836 107.398 75.5836 104.637V83.8066C75.5836 81.0452 73.345 78.8066 70.5836 78.8066Z"
                fill="#183153"
              />
              <path
                d="M103.845 95.5064C103.331 102.186 110.482 104.713 114.121 105.141C117.333 105.355 124.013 103.856 125.04 96.1487C126.068 88.4411 134.033 84.3732 137.886 83.3027L133.39 100.645C129.793 107.325 133.604 114.989 135.959 117.987L159.724 144.963L164.22 165.517C164.22 170.013 166.533 179.005 175.782 179.005C185.031 179.005 186.487 170.013 186.059 165.517C184.346 159.736 180.792 147.019 180.278 142.394C179.764 137.769 177.066 133.616 175.782 132.117L159.724 114.775L165.505 95.5064C167.86 102.786 175.782 116.831 188.628 114.775C204.685 112.206 198.904 96.4241 192.481 95.5064C187.985 94.8641 185.416 93.5795 184.774 90.368C184.346 80.9477 176.938 61.7217 150.732 60.1802C117.975 58.2533 104.487 87.1566 103.845 95.5064Z"
                fill="white"
              />
              <path
                d="M143.667 141.109L128.252 123.767L121.829 142.394L103.845 160.378C100.633 162.733 95.6231 169.113 101.275 175.793C106.928 182.473 114.764 178.148 117.975 175.151L134.675 158.451C139.813 153.313 142.811 144.749 143.667 141.109Z"
                fill="white"
              />
              <path
                d="M164.22 51.8302C172.734 51.8302 179.635 44.9286 179.635 36.4151C179.635 27.9016 172.734 21 164.22 21C155.707 21 148.805 27.9016 148.805 36.4151C148.805 44.9286 155.707 51.8302 164.22 51.8302Z"
                fill="white"
              />
            </svg>
          </span>
          <span [ngClass]="sidebarOpen ? '' : 'hidden duration-300'"
            >Logout</span
          >
        </button>
      </li>
    </ul>
  </div>
</div>
