import { Injectable, ViewChild, viewChild } from '@angular/core';
import { ConstantsService } from './constants.service';
import { ReviewEntriesService } from 'src/app/pages/review-entries/review-entries.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { RcService } from './rc.service';
import { LayoutsComponent } from 'src/app/layouts/layouts.component';
import { ConcurrentLoginComponent } from 'src/app/shared/ConcurrentLogin-dialog/ConcurrentLogin.component';
import { MatDialog } from '@angular/material/dialog';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private connection: any;
  private proxy: any;
  _notesHubProxy: any = undefined;
  private connectionId: any = "not connected";
  private Pageemployees: any[] = [];
  private isModalOpenCloseConcurrentLogin: boolean = false;
  constructor(private dialog: MatDialog,
    private readonly constantsService: ConstantsService,
    private reviewService: ReviewEntriesService,
    private toastr: ToastrService,
    private readonly authService: AuthService,
    private router: Router,
    public rcService: RcService
  ) {

  }
  getPageSupervisorEmployees(EmployeeNumber: number): any {
    this.reviewService.getSupervisorReviewEmployees(EmployeeNumber).subscribe((data: any) => {
      //this.Pageemployees = data;
      return data;
    })
  }

  public addNewUser(EmployeeNumber: number): void {
    this.proxy.invoke('AddNewUser', EmployeeNumber, sessionStorage.getItem('EmpTenant'), this.connectionId);
  }
  public addUserUpdates(EmployeeNumber: number): void {
    this.proxy.invoke('AddUserUpdates', EmployeeNumber, '', sessionStorage.getItem('EmpTenant'), this.connectionId)

      .catch((error: any) => {
        console.log('sending message error -> ' + error);
      });
  }
  public initializeSignalRConnection(userData: any): void {

    if (this.connectionId == "not connected") {


      this.connection = $.hubConnection(`${this.constantsService.Signalr_URL}`, { useDefaultPath: false });
      // Please note that by default '/signalr' URL will be used to connect to your SignalR service. Providing 'useDefaultPath' allows you to customize it
      this.proxy = this.connection.createHubProxy('notesHub');
      if (userData.ConcurrentLogin) {

        this.proxy.on('broadcastNewUser', (user: any) => this.broadcastNewUser(user));
      }
      if ((userData.isSupervisor || (userData.canReviewFirstLevel && userData.tailoring.SecondApproval))) {

        this.proxy.on('broadcastSupervisorUser', (user: any) => this.broadcastSupervisorUser(user));
      }
      this.connection.start().done((data: any) => {
        console.log('Connected to Connection Hub');
        this.connectionId = this.connection.id
        this.addNewUser(userData?.credentials?.EmployeeNumber);
      }).catch((error: any) => {
        console.log('Connection Hub error:' + error);
      });
    }
  }

  public broadcastNewUser(user: any): void {
    let userData: any = localStorage.getItem('user');
    userData = JSON.parse(userData);
    console.log('broadcastSupervisorUser -> ' + user);
    if (this.connectionId != user.ConnectionId && (sessionStorage.getItem('EmpTenant') == user.Domain || user.Domain == null) && parseInt(userData?.credentials?.EmployeeNumber) == user.EmployeeNo) {
      console.log('Same User' + user);

      this.dialog.open(ConcurrentLoginComponent, {
        width: '400px',
        data: {}  // Optionally pass data here
      });
    
    }
  }
  public broadcastSupervisorUser(user: any): void {
    if (window.location.href.toLowerCase().indexOf("review") > -1) {
      let userData: any = localStorage.getItem('user');
      userData = JSON.parse(userData);
      console.log('broadcastSupervisorUser -> ' + user);

      if (this.connectionId != user.ConnectionId && sessionStorage.getItem('EmpTenant') == user.Domain && user.EmployeeNo == parseInt(userData?.credentials?.EmployeeNumber)) {
        console.log('BroadcastSupervisorUser' + user);

      }
      else {

        this.reviewService.getSupervisorReviewEmployees(userData?.credentials?.EmployeeNumber).subscribe((Pageemployees: any) => {
          if (Pageemployees.length) {
            let emp = Pageemployees.find((x: any) => x.EmployeeNumber == user.EmployeeNo)

            if (emp) {
              this.toastr.clear();
              this.toastr.info(
                '',
                '<h4>Some of the Time Card data you are viewing has been updated since you last loaded this screen</h4><p>Consider <span class="reloadBtn" type="btn" ng-click="ReviewPageReload()">reloading</span> to see the most current info </p>',
                {
                  enableHtml: true,
                  positionClass: 'toast-bottom-left',
                  toastClass: "toast-icon custom-toast-info",
                  tapToDismiss: false,
                  timeOut:150000,
                  // @ts-ignore
                  toastType: 'toast-review-update'
                });
             

            }

          }
        });


      }
    }
  }


}
