<div>
  <label for="input-field" class="sr-only">Label</label>
  <div class="flex items-center relative">
    <input
      #inputField
      type="{{ fieldType == 'password' && hidePassword ? 'password' : 'text' }}"
      [(ngModel)]="inputValue"
      [id]="fieldName"
      [placeholder]="placeholder || 'Please Enter ' + (fieldName | titleCase)"
      (focus)="handleInputFocus(fieldName)"
      (input)="onInputChange($event)"
      [class]="
        class ||
        'w-full text-sm py-3 px-4 text-white rounded-lg max-[640px]:rounded-r-lg sm:rounded-r-none rounded-l-lg placeholder-white bg-white/30 transition disabled:ring-gray-200 disabled:bg-gray-100 disabled:placeholder-gray-400 invalid:ring-red-400 focus:invalid:outline-none'
      "
      [ngClass]="{
        'rounded-lg max-[640px]:rounded-r-none sm:rounded-r-none':
          fieldType == 'password' && inputValue
      }"
      autocomplete="off"
      [maxlength]="maxlength"
    />
    <a
      *ngIf="fieldType == 'password' && inputValue"
      href="javascript:void(0)"
      (click)="togglePasswordVisibility($event)"
      [class]="
        passwordButtonClass || 'text-white bg-white/30 py-2.5 px-2 inline-block'
      "
      [ngClass]="{
        'max-[640px]:rounded-r-lg sm:rounded-r-none sm:rounded-l-none':
          fieldType == 'password' && inputValue
      }"
    >
      <ng-container *ngIf="hidePassword; else showPasswordIcon">
        <!-- Crossed Eye SVG -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </svg>
      </ng-container>
      <ng-template #showPasswordIcon>
        <!-- Eye SVG -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
          />
        </svg>
      </ng-template>
    </a>
    <button
      (click)="toggleKeyboard()"
      [class]="
        keyButtonClass ||
        'hidden sm:block keyboard-btn border-l border-gray-950 rounded-r-lg relative text-white bg-white/30 py-2.5 px-2'
      "
    >
      <!-- [ngClass]="{
        'bg-yellow-600 after:absolute after:w-3 after:h-3 after:border-white after:left-[12px]  after:z-10 after:border-l-transparent after:border-r-transparent after:border-b-8 after:border-l-8 after:border-r-8':
          fieldType == 'password'
      }" -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6 dark:text-white"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z"
        />
      </svg>
    </button>
  </div>
  <div
    *ngIf="isKeyboardOpen"
    id="keyboard-container"
    class="bg-slate-200 fixed transform z-[9999999] !top-auto left-0 right-0 bottom-0 rounded-t-2xl dark:bg-gray-950"
    [ngClass]="{ 'w-80': keyboardType === 'number' }"
  >
    <!-- left-1/2 -translate-x-1/2 -->
    <!-- [style.left.px]="keyboardLeft" -->
    <!-- [style.top]="keyboardTopPosition()" -->
    <div
      class="relative mx-auto text-base font-normal"
      [ngClass]="{ 'w-full max-w-screen-md ': keyboardType === 'number' }"
    >
      <button
        (click)="closeKeyboard()"
        class="absolute -top-6 right-[50%] -mr-[10px] p-2 bg-slate-200 dark:bg-gray-800 rounded-full shadow"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4 text-red-600"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <!-- <div class="bg-white rounded-lg shadow-md p-4 mt-2"> -->
      <div class="rounded-lg shadow-2xl p-2 mt-2">
        <div *ngIf="keyboardType === 'qwerty'">
          <!-- QWERTY Keyboard -->
          <ng-container *ngFor="let row of qwertyKeyboard">
            <div class="flex justify-center align-middle gap-0.5 mb-0.5">
              <ng-container *ngFor="let key of row">
                <button
                  *ngIf="
                    key !== '←' &&
                      key !== 'Tab' &&
                      key !== 'Caps' &&
                      key !== 'Shift' &&
                      key !== 'Ctrl' &&
                      key !== 'Win' &&
                      key !== 'Alt' &&
                      key !== 'Space' &&
                      key !== 'Enter';
                    else otherKeys
                  "
                  class="py-2 px-6 border border-gray-300 bg-white dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded-md"
                  (click)="pressKey($event, key)"
                  [ngClass]="{ uppercase: capsLockOn }"
                >
                  {{ capsLockOn ? key.toUpperCase() : key }}
                </button>
                <ng-template #otherKeys>
                  <button
                    (click)="pressKey($event, key)"
                    class="py-2 px-6 border border-gray-300 bg-white dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded-md"
                    [ngClass]="{
                      relative: key === 'Caps' && capsLockOn
                    }"
                  >
                    <ng-container *ngIf="key === '⟳'">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                        />
                      </svg>
                    </ng-container>
                    <span
                      class="absolute top-1 right-1"
                      *ngIf="key === 'Caps' && capsLockOn"
                    >
                      <span class="relative flex h-2 w-2">
                        <span
                          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75"
                        ></span>
                        <span
                          class="relative inline-flex rounded-full h-2 w-2 bg-amber-500"
                        ></span>
                      </span>
                    </span>
                    <ng-container *ngIf="key === 'Space'" class="w-60"
                      >SPACE
                    </ng-container>
                    <ng-container *ngIf="key !== 'Space' && key !== '⟳'">
                      {{ key }}
                    </ng-container>
                  </button>
                </ng-template>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <div *ngIf="keyboardType === 'number'">
          <!-- Number Keyboard -->
          <div class="grid grid-cols-3 gap-4">
            <ng-container *ngFor="let row of numberKeyboard">
              <ng-container *ngFor="let key of row">
                <button
                  class="py-2 px-4 bg-gray-100 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 rounded"
                  (click)="pressKey($event, key)"
                >
                  {{ key }}
                </button>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
