import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DetailsService } from '../details.service';
import { MatDialog } from '@angular/material/dialog';
import { AddInventoryDialogComponent } from '../add-inventory-dialog/add-inventory-dialog.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {
  userData: any;

  @Input() transactionNumber: string = '';
  @Input() readOnly: boolean = false;
  @Input() category: string = '';
  @Input() employeeNumber: number = 0;

  @Output() invChangeEvent = new EventEmitter<string>();

  loading: boolean = false;
  searchText: string = '';
  searchAvailable: string = '';
  inventory: any = {
    inventoryList: [],
    selectedInventory: [],
    department: '',
    quantity: '',
    number: '',
    name: '',
    showButtons: '',
    showQuantity: '',
    isValid: '',
    availableQuantity: '',

  };
  showScanButton: boolean = false;
  unitOfMeasure: string = '';
  InventoryLockedAfterDate: string = '';
  InventoryLockedMessage: string = '';

  constructor(
    private detailsService: DetailsService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private readonly authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe((user) => {
      this.userData = user;
      // if (!this.employeeNumber) {
      //   this.employeeNumber = user?.credentials?.EmployeeNumber;
      // }
      this.InventoryLockedAfterDate = user?.tailoring?.InventoryLockedAfterDate;
      this.InventoryLockedMessage = user?.tailoring?.InventoryLockedMessage;
    });
    this.getInventories();
  }

  getInventories(): void {
    // this.loading = true;

    this.detailsService.getTransactionInventory(this.employeeNumber, this.transactionNumber).subscribe(
      (data) => {
        this.inventory.selectedInventory = data.map((item: any) => ({
          name: item.ItemDescription,
          number: item.ItemNumber,
          department: item.Department,
          quantity: item.QuantityUsed,
          RequisitionNumber: item.RequisitionNumber,
          TransactionNumber: this.transactionNumber,
          SequenceNumber: item.SequenceNumber
        }));
        // this.loading = false;
      },
      (error) => {
        // Handle the error
        console.error('Error populating inventory:', error);
        // this.loading = false;
      }
    );
  }

  getInventoriesButtons(): void {
    this.detailsService.getInventoryDepartment(this.employeeNumber).subscribe(
      (data) => {
        this.inventory.inventoryList = data;
        // this.loadInventoryModal();
        this.showScanButton = false;

        // Angular's change detection should handle this automatically, but if needed, you can use ChangeDetectorRef
        // import { ChangeDetectorRef } from '@angular/core';
        // constructor(private detailsService: YourDetailsService, private cdr: ChangeDetectorRef) {}
        // this.cdr.detectChanges();

        if (data.length === 1) {
          this.inventorySelect(data[0]);
        }
      },
      (error) => {
        // Handle the error
        console.error('Error getting inventory department:', error);
      }
    );

  }

  inventorySelect(item: any): void {
    if (this.inventory.department === '') {
      this.inventory.department = item.Tag;

      this.detailsService.getInventory(this.employeeNumber, this.category, this.inventory.department, this.transactionNumber).subscribe(
        (data) => {
          this.inventory.inventoryList = data;
          // Uncomment the line below if you want to show the scan button
          // this.showScanButton = true;
        },
        (error) => {
          // Handle the error
          console.error('Error getting inventory:', error);
        }
      );
    } else {
      this.inventory.name = item.ButtonDescription;
      this.inventory.number = item.Tag;
      this.inventory.showButtons = false;
      this.inventory.showQuantity = true;
      this.inventory.availableQuantity = item.AvailableQuantity;
      this.inventory.isValid = false;
      this.unitOfMeasure = item.UnitOfMeasure;
    }
  }

  loadInventoryModal(): void {
    this.searchText = '';
    this.inventory.showButtons = false;

    if (this.isLocked()) {
      this.toastr.warning(this.InventoryLockedMessage, 'Inventory Locked', {
        enableHtml: true,
        positionClass: 'toast-bottom-left',
        toastClass: "toast-icon custom-toast-warning",
      });
      
    } else {
      // Fetch data from the API before opening the dialog
      this.detailsService.getInventoryDepartment(this.employeeNumber).subscribe((apiData) => {
        this.inventory.inventoryList = apiData;
        // this.loadInventoryModal();
        this.showScanButton = false;
        // Open the dialog after fetching the data
        let dialogRef = this.dialog.open(AddInventoryDialogComponent, {
          maxWidth: '600px',
          // hasBackdrop: false,
          data: {
            inventory: {
              ...this.inventory,
              quantity: '',
              number: '',
              name: '',
              department: '',
              showButtons: true,
              showQuantity: false,
            },
            category: this.category,
            searchText: this.searchText,
            unitOfMeasure: this.unitOfMeasure,
            employeeNumber: this.employeeNumber,
            transactionNumber: this.transactionNumber,
            apiData: apiData, // Pass the fetched data to the dialog
          },
        });

        // Subscribe to the afterClosed event to handle actions after the dialog is closed
        dialogRef.afterClosed().subscribe((result) => {
          console.log('add inventory dialog result', result);
          if (result === 'inventorydone') {
            console.log('Done Add Inventories');
            this.getInventories();
            this.invChangeEvent.emit();
            this.toastr.success('Detail entry inventory added successfully.', 'Success', {
              positionClass: 'toast-bottom-left',
              toastClass: "toast-icon custom-toast-success",
            });
            // Perform any additional actions after the dialog is closed
          }
        });
      });
    }
  }

  isLocked() {
    var returnVal = false;

    // Get the value of "InventoryLockedAfterDate" from the kioskSession
    var b = this.InventoryLockedAfterDate;

    // Check if the value exists and if it is a date in the past
    if (b) {
      var d = new Date(b);
      if (d < new Date()) {
        // If the date is in the past, set returnVal to true
        returnVal = true;
      }
    }

    // Return the final result
    return returnVal;
  };


  deleteInventory(i: any): void {
    this.detailsService.deleteInventory(i).subscribe(
      (data) => {
        if (data) {
          // Broadcast an event using Angular's EventEmitter or a service
          // Example using a service:
          // this.inventoryService.notifyInventoryModified();
          this.getInventories();
          this.invChangeEvent.emit();
          this.toastr.success('Detail entry inventory deleted successfully.', 'Success', {
            positionClass: 'toast-bottom-left',
            toastClass: "toast-icon custom-toast-success",
          });
        }
      },
      (error) => {
        // Handle the error
        console.error('Error deleting inventory:', error);
      }
    );
  }

}
