import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { ConstantsService } from '../core/services/constants.service';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  // get tenants
  private readonly getTenantListUrl = '/authenticate/gettenants';
  // get tailoring
  private readonly getTailoringUrl = '/authenticate/gettailoring';

  // update password
  private readonly updatePasswordUrl = '/authenticate/updatepassword';
  // supervisor employees
  private readonly getSupervisorEmployeesUrl = '/employeehours/getsupervisoremployees';

  private readonly clientToken: string = '';
  private readonly authToken: any = '';


  private selectedDateSource = new BehaviorSubject<Date>(new Date);
  selectedDate$ = this.selectedDateSource.asObservable();


  private headerCountSource = new BehaviorSubject<any>(null); // Initialize with null or any default value
  currentHeaderData = this.headerCountSource.asObservable();

  constructor(private http: HttpClient, private authService: AuthService,
    private constantService: ConstantsService) {
    this.clientToken = this.constantService.CLIENT_TOKEN;
    this.authToken = this.authService.getToken();
  }

  private eventsSource = new BehaviorSubject<Event[]>([]);
  events$ = this.eventsSource.asObservable();

  setSelectedDate(date: Date): void {
    this.selectedDateSource.next(date);
  }

  setEvents(events: Event[]): void {
    this.eventsSource.next(events);
  }

  changeHeaderCount(data: any) {
    this.headerCountSource.next(data); // Method to change the header count data
  }

  getTenantList(): Observable<any> {
    return this.http.get(`${this.getTenantListUrl}`);
  }

  getTailoring(): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken
    }
    return this.http.post(`${this.getTailoringUrl}`, payload);
  }

  updatePassword(empNumber: number, currentPassword: string, newPassword: string, itOverride: boolean = false): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeNumber', empNumber);
    httpParams = httpParams.append('CurrentPassword', currentPassword);
    httpParams = httpParams.append('NewPassword', newPassword);
    httpParams = httpParams.append('ITOverride', itOverride);
    return this.http.get(`${this.updatePasswordUrl}`, { params: httpParams });
  }

  updateEmployeePassword(empNumber: number, newPassword: string, itOverride: boolean = true): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeNumber', empNumber);
    httpParams = httpParams.append('CurrentPassword', '');
    httpParams = httpParams.append('NewPassword', newPassword);
    httpParams = httpParams.append('ITOverride', itOverride);
    return this.http.get(`${this.updatePasswordUrl}`, { params: httpParams });
  }


  getSupervisorEmployees(empNumber: number): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      EmployeeNumber: empNumber
    }
    return this.http.post(`${this.getSupervisorEmployeesUrl}`, payload);
  }

  // Helper function to parse date string in "MM/DD/YYYY" format
  parseDateString(dateValue: Date | string): Date | null {
    if (typeof dateValue === 'string') {
      const parts = dateValue.split('/');
      const month = parseInt(parts[0], 10) - 1;
      const day = parseInt(parts[1], 10);
      const year = parseInt(parts[2], 10);

      // Check if the parsed values are valid
      if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
        return new Date(year, month, day);
      }
    }

    return null;
  }

  transformString(input: string | number): string {
    /* const inputString = typeof input === 'number' ? input.toString() : input;
    const sanitizedString = inputString.replace(/[^\w\s]/gi, '');
    let seoUrl = sanitizedString.trim().replace(/\s+/g, '-');
    seoUrl = seoUrl.toLowerCase();
    return seoUrl; */
    const inputString = typeof input === 'number' ? input.toString() : input;
    return inputString?.split(' ').join('_').split('.').join('').split('/').join('').split('(').join('').split(')').join('');
  }

  toTitleCase(str: string): string {
    let title = str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
    return title;
  }

  isObject(value: any) {
    return value !== null && typeof value === 'object';
  }

  formatColumnsLabel(arr: any[]) {
    return arr.map(item => {
      return item.replace(/([A-Z])/g, ' $1').replace(/^ /, '');
    });
  }

  dbDateFormat(dateString: any): any {
    let date = moment(dateString).format("YYYY-MM-DD");
    // Combine specific date with current time
    const currentTime = moment();
    const combinedDateTime = moment(date).set({
      hour: currentTime.hour(),
      minute: currentTime.minute(),
      second: currentTime.second(),
      millisecond: currentTime.millisecond(),
    });
    let dbDate = combinedDateTime.toISOString();
    return dbDate;
  }



}
