import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { RcService } from '../services/rc.service';

@Injectable({
  providedIn: 'root',
})
export class InventoryAccessGuard implements CanActivate {
  constructor(
    private router: Router,
    private rcService: RcService,
    private authService: AuthService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const storedUser = localStorage.getItem('user');
    const userData = storedUser ? JSON.parse(storedUser) : null;
    const employeeNumber = userData?.credentials?.EmployeeNumber; // Replace with dynamic employee number as needed
    const requiredFeature = route.data['feature'] as string;
    return this.authService.checkInventoryAccess(employeeNumber).pipe(
      map((response: any) => {
        // if (accessRights.includes(requiredFeature)) {
        if (response.IsInventoryAccess) {
          // this.authService.setCurrentUser({ userData, ApplicationUserName: response.LienceUserName });
          return true;
        } else {
          // Redirect to an appropriate URL, e.g., /no-access
          // this.router.navigate(['/no-access']);
          this.router.navigate([`/${this.rcService.getTenant()}`]);
          return false;
        }
      }),
      catchError(() => {
        this.router.navigate([`/${this.rcService.getTenant()}`]);
        return of(false);
      })
    );
  }
}
