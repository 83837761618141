import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { CalendarService } from 'src/app/core/services/calendar.service';
import { DatePipe } from '@angular/common';
import { SharedService } from '../shared.service';

interface CalendarEvent {
  TimeCardDate: string;
  Type: string;
  TCHours: string;
  bgColor: string;
  timeClockStatus: string;
}

@Component({
  selector: 'app-custom-calendar',
  templateUrl: './custom-calendar.component.html',
  styleUrls: ['./custom-calendar.component.css'],
  providers: [DatePipe]
})
export class CustomCalendarComponent implements OnInit {
  @Input() showSelectedDate: boolean = false;
  @Input() showEvents: boolean = true;
  @Input() selectedDate: any = new Date();
  @Input() calendarEvents: CalendarEvent[] = [];
  @Output() dateSelected: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() monthChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() yearChanged: EventEmitter<number> = new EventEmitter<number>();

  years: number[] = [];
  days: any[] = [];
  currentDate: Date = new Date();
  currYear: number = new Date().getFullYear();
  currMonth: number = new Date().getMonth();
  months: string[] = ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"];

  constructor(
    private cdr: ChangeDetectorRef,
    private calendarService: CalendarService,
    private sharedService: SharedService,
    private datePipe: DatePipe
  ) {
    this.years = Array.from({ length: 6 }, (_, i) => this.currYear - i);
  }

  ngOnInit() {
    // this.selectedDate = document.querySelector(".current-date");
    // Check if this.selectedDate is a valid Date object
    if (!(this.selectedDate instanceof Date && !isNaN(this.selectedDate.getTime()))) {
      // If it's not a valid Date, assume it's a string and convert it
      this.selectedDate = this.sharedService.parseDateString(this.selectedDate) || new Date();
    }
    // this.currYear = this.selectedDate.getFullYear();
    // this.currMonth = this.selectedDate.getMonth();
    this.calendarService.data$.subscribe((data) => {
      if (data) {
        this.calendarEvents = data;
      }
      this.renderCalendar();
    });
  }

  /* ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedDate']?.previousValue === undefined && changes['selectedDate'].firstChange === true) {
      // this.currentDate = changes['selectedDate'].currentValue
      this.resetToCurrentMonth();
    }
  } */

  resetToCurrentMonth() {
    const currentDate = new Date();
    this.currYear = currentDate.getFullYear();
    this.currMonth = currentDate.getMonth();
    // this.selectedDate = currentDate;
    this.currentDate = new Date();
    this.renderCalendar();
    this.monthChanged.emit(this.currMonth);
    this.yearChanged.emit(this.currYear);
    // this.dateSelected.emit(this.selectedDate);
    this.dateSelected.emit(this.currentDate);
    // this.currentDate.innerText = `${this.months[this.currMonth]}`;
  }

  renderCalendar() {
    this.days = [];
    let firstDayofMonth = new Date(this.currYear, this.currMonth, 1).getDay(),
      lastDateofMonth = new Date(this.currYear, this.currMonth + 1, 0).getDate(),
      lastDayofMonth = new Date(this.currYear, this.currMonth, lastDateofMonth).getDay(),
      lastDateofLastMonth = new Date(this.currYear, this.currMonth, 0).getDate();

    for (let i = firstDayofMonth; i > 0; i--) {
      this.days.push({ value: lastDateofLastMonth - i + 1, active: false, selected: false, inactive: true, customText: '', bgColor: '', formattedDate: '' });
    }

    for (let i = 1; i <= lastDateofMonth; i++) {
      let isToday = i === this.currentDate.getDate() &&
        this.currMonth === this.currentDate.getMonth() &&
        this.currYear === this.currentDate.getFullYear();

      let selected = i === this.selectedDate.getDate() &&
        this.currMonth === this.selectedDate.getMonth() &&
        this.currYear === this.selectedDate.getFullYear();

      const formattedDate = this.datePipe.transform(new Date(this.currYear, this.currMonth, i), 'MM/dd/yyyy');
      const matchingEvent = this.calendarEvents.find(event => event.TimeCardDate === formattedDate);

      this.days.push({
        value: i,
        active: isToday,
        selected: selected,
        inactive: false,
        Type: matchingEvent ? matchingEvent.Type : '',
        timeClockStatus: matchingEvent ? matchingEvent.timeClockStatus : '',
        // bgColor: matchingEvent && matchingEvent.Type === 'Partial' ? 'lightskyblue' : matchingEvent && matchingEvent.Type === 'Supervisor' ? 'cornsilk' : matchingEvent && matchingEvent.Type === 'User' ? 'thistle' : matchingEvent && matchingEvent.Type === 'Payroll' ? 'lightgreen' : '',
        bgColor: matchingEvent && matchingEvent.Type === 'Partial' ? 'lightskyblue' :
          matchingEvent && matchingEvent.Type === 'Supervisor' ? 'cornsilk' :
            matchingEvent && matchingEvent.Type === 'User' ? 'thistle' :
              matchingEvent && matchingEvent.Type === 'Payroll' ? 'lightgreen' :
                matchingEvent && matchingEvent.Type === 'UserRejected' ? 'salmon' :
                  '',
        // formattedDate: formattedDate
        TimeCardDate: formattedDate,
        TCHours: matchingEvent ? matchingEvent.TCHours : ''
      });
    }

    for (let i = lastDayofMonth; i < 6; i++) {
      this.days.push({ value: i - lastDayofMonth + 1, active: false, inactive: true, TimeCardDate: '', TCHours: '', Type: '', timeClockStatus: '' });
    }

    if (this.selectedDate !== null) {
      // this.selectedDate.innerText = `${this.months[this.currMonth]}`;
      this.selectedDate.innerText = `${this.selectedDate}`;
    }
  }

  /* toggleDate(index: number) {
    const clickedDate = this.days[index];
    this.days.forEach((day) => {
      day.active = false;
    });
    clickedDate.active = true;
    this.selectedDate = new Date(this.currYear, this.currMonth, clickedDate.value);
    this.dateSelected.emit(this.selectedDate);
  } */
  toggleDate(index: number) {
    const clickedDate = this.days[index];

    // Check if the clicked date is active or inactive
    if (clickedDate.inactive) {
      // Extract month and year from the inactive date
      const clickedMonth = this.currMonth + (index < 7 ? -1 : 1);
      const clickedYear = index < 7 && this.currMonth === 0 ? this.currYear - 1 : (index >= this.days.length - 7 && this.currMonth === 11 ? this.currYear + 1 : this.currYear);

      // Check if the clicked date falls within the specified year range
      const isWithinYearRange = clickedYear >= Math.min(...this.years) && clickedYear <= Math.max(...this.years);

      // Update current month and year only if within the year range
      if (isWithinYearRange) {
        this.currMonth = clickedMonth;
        this.currYear = clickedYear;
        this.renderCalendar();
        this.monthChanged.emit(this.currMonth);
        this.yearChanged.emit(this.currYear);
      }
    } else {
      // If the clicked date is active, toggle the date
      this.days.forEach((day) => {
        day.selected = false;
        // day.active = false;
      });
      // clickedDate.active = true;
      clickedDate.selected = true;
      this.selectedDate = new Date(this.currYear, this.currMonth, clickedDate.value);
      this.dateSelected.emit(this.selectedDate);
    }
  }

  onYearChange(selectedYear: number) {
    this.currYear = selectedYear;

    const isNextDisabled = this.currMonth === 11 && this.currYear === this.years[this.years.length - 1];
    const isPrevDisabled = this.currMonth === 0 && this.currYear === this.years[0];

    if (!isNextDisabled && !isPrevDisabled) {
      this.renderCalendar();
      this.yearChanged.emit(this.currYear);
    }
  }

  onMonthChange(month: number) {
    let selectedMonth = +month;
    this.currMonth = selectedMonth;
    this.renderCalendar();
    this.monthChanged.emit(selectedMonth);
  }

  changeMonth(offset: number) {
    // debugger;
    let newMonth = this.currMonth + offset;
    let newYear = this.currYear;

    if (newMonth < 0) {
      newYear--;
      newMonth = 11;
    } else if (newMonth > 11) {
      newYear++;
      newMonth = 0;
    }

    // Check if the next arrow should be disabled
    const isNextDisabled = newYear > Math.max(...this.years) || (newYear === Math.max(...this.years) && newMonth > new Date().getMonth() && newYear !== this.currYear);

    // Check if the previous arrow should be disabled
    const isPrevDisabled = (newYear < Math.min(...this.years) || (newYear === Math.min(...this.years) && newMonth === 0)) && !(newYear === Math.min(...this.years) && newMonth === 0);

    if (!isNextDisabled && !isPrevDisabled) {
      this.currMonth = newMonth;
      this.currYear = newYear;
      this.renderCalendar();
      this.monthChanged.emit(this.currMonth);
      this.yearChanged.emit(this.currYear);
      // Update the selected month in the dropdown
      this.onMonthChange(this.currMonth);
    }
  }

  setToday() {
    const today = new Date();
    this.currYear = today.getFullYear();
    this.currMonth = today.getMonth();
    this.selectedDate = today;
    this.currentDate = today;
    this.renderCalendar();
    this.dateSelected.emit(this.selectedDate);
    this.monthChanged.emit(this.currMonth);
    this.yearChanged.emit(this.currYear);
  }

}
