import { createReducer, on } from '@ngrx/store';
import * as UserActions from './../actions/user.actions';
import { initialUserState } from '../state/user.state';


export const userReducer = createReducer(
    initialUserState,
    on(UserActions.login, (state, { user }) => {
        return {
            ...state,
            user,
            isLoggedIn: true,
        };
    }),
    on(UserActions.hydrateSuccess, (state, { state: hydratedState }) => ({
        ...state,
        ...hydratedState,
    })),
    on(UserActions.logout, (state) => ({
        ...state,
        user: null,
        isLoggedIn: false,
    }))
);
