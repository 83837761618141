import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DetailsService } from '../details.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PaginationService } from 'src/app/core/services/pagination.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/shared.service';
import { HomeService } from '../../home.service';

@Component({
  selector: 'app-add-equipment-dialog',
  templateUrl: './add-equipment-dialog.component.html',
  styleUrls: ['./add-equipment-dialog.component.css']
})
export class AddEquipmentDialogComponent implements OnInit {
  userData: any;
  loginemployeeNumber: number = 0;

  public equipment: any = {};
  public hasDistrict: boolean;
  public searchText: string = '';
  public employeeNumber: number = 0;
  public transactionNumber: number = 0;
  public currentSelection: any = {};

  editItem: any = {};

  IsUpateNumberEntry: boolean = true;
  btnEquipmentHoursflag: boolean = false;
  popoverflag: boolean = false;
  payHours: string = '';
  popovermsg: string = "";
  isEdit: boolean = false;
  saving: boolean = false;

  searchQuery: string = '';
  isSearching: boolean = false;

  dataSource: any[] = []

  userFavoriting: string[] = [];
  showmeloader: boolean = false;
  Lock_In_Out_User_Preferences: number = 0;
  Lock_In_Out_Total_User_Preferences: number = 0;

  userEquipmentFavoriting: any = [];

  constructor(
    private readonly authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<AddEquipmentDialogComponent>,
    private detailsService: DetailsService,
    private sanitizer: DomSanitizer,
    public paginationService: PaginationService,
    private toastr: ToastrService,
    public sharedService: SharedService,
    private readonly homeService: HomeService
  ) {

    this.editItem = data?.editItem;
    this.btnEquipmentHoursflag = data?.btnEquipmentHoursflag;
    this.equipment = data.equipment;
    this.isEdit = data?.isEdit ? data?.isEdit : false;
    this.hasDistrict = data.hasDistrict;
    this.currentSelection = data.currentSelection;
    this.employeeNumber = data.employeeNumber;
    this.transactionNumber = data.transactionNumber;
    this.payHours = data.payHours;
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe(async (user) => {
      this.userData = user;
      this.loginemployeeNumber = user?.credentials?.EmployeeNumber;
      if (this.equipment?.buttons) {
        this.updateDisplayedData();
      }
      await this.getTimeCardEmployeeSettingsByEmployee();
      this.updateButtons(this.dataSource);

      // this.userFavoriting = user?.ButtonPreferences ? user?.ButtonPreferences.split('$') : [];
      // console.log('user?.ButtonPreferences', user?.ButtonPreferences);
      // console.log('this.userFavoriting', this.userFavoriting);
      // this.Lock_In_Out_User_Preferences = user?.Lock_In_Out_User_Preferences;
      // this.Lock_In_Out_Total_User_Preferences = user?.Lock_In_Out_Total_User_Preferences;
    });
  }

  buttonSelect(item: any): void {
    switch (this.currentSelection) {
      case 'department':
        this.equipment.department = item.Tag;

        if (this.hasDistrict) {
          this.detailsService.getEquipmentDistricts(this.employeeNumber, this.equipment.department)
            .subscribe(d => {
              this.equipment.buttons = d;
              this.searchText = '';
              this.currentSelection = 'district';
            });
        } else {
          this.detailsService.getEquipment(this.employeeNumber, this.equipment.district, this.equipment.department)
            .subscribe(d => {
              this.equipment.buttons = d;
              this.searchText = '';
              this.currentSelection = 'equipment';
            });
        }
        break;

      case 'district':
        this.equipment.district = item.Tag;
        this.detailsService.getEquipment(this.employeeNumber, this.equipment.district, this.equipment.department)
          .subscribe(d => {
            this.equipment.buttons = d;
            this.searchText = '';
            this.currentSelection = 'equipment';
          });
        break;

      case 'equipment':
        this.equipment.number = item.Tag;
        this.equipment.name = item.ButtonDescription;
        this.equipment.showButtons = false;
        this.equipment.showHours = true;
        this.currentSelection = 'hours';
        break;
    }
  }

  addItem(): void {
    // $(".equipmentHoursWarning").popover('hide');
    // $(".equipmentandLaborHoursWarning").popover('hide');
    this.saving = true;
    // console.log('this.transactionNumber', this.transactionNumber);
    // console.log('this.equipment', this.equipment); return;
    if (this.isEdit) {
      this.detailsService.editTransactionEquipment(this.editItem.identity, this.equipment.hours)
        .subscribe(
          () => {
            this.isEdit = false;
            // this.loadEquipmentArray(true);
            // $('#equipmentModal_' + this.transactionNumber + this.id).modal('hide');
            this.AddEquimentDialog();
          },
          (error) => {
            this.saving = false;
            console.error("Error editing transaction equipment:", error);
          }
        );
    } else {
      this.detailsService.addTransactionEquipment(this.transactionNumber, this.equipment.number, this.equipment.hours, this.employeeNumber)
        .subscribe(
          (d) => {
            if (d) {
              this.equipment.selectedEquipment.push({ name: this.equipment.name, number: this.equipment.number, hours: this.equipment.hours });
              // this.loadEquipmentArray(true);
              // $('#equipmentModal_' + this.transactionNumber + this.id).modal('hide');
              this.AddEquimentDialog();
            } else {
              this.saving = false;
              // alert("There was an error while adding the equipment.");
              this.toastr.clear();
              this.toastr.error('There was an error while adding the equipment.', 'Error', {
                positionClass: 'toast-bottom-left',
                toastClass: "toast-icon custom-toast-error",
              });
            }
          },
          (error) => {
            this.saving = false;
            console.error("Error adding transaction equipment:", error);
          }
        );
    }
  }

  async getTimeCardEmployeeSettingsByEmployee() {
    let empNumber = this.employeeNumber;
    let userSetting = await this.homeService.getTimeCardEmployeeSettings(empNumber);

    this.userEquipmentFavoriting = userSetting?.ButtonPreferencesEqu?.toString()?.split('$')
    console.log(this.userEquipmentFavoriting)
    /* this.homeService.getTimeCardEmployeeSettingsByEmployee(empNumber).subscribe({
      next: (response) => {
        this.userEquipmentFavoriting = response.ButtonPreferencesEqu.toString().split('$');
        console.log('this.userEquipmentFavoriting', this.userEquipmentFavoriting);
      },
      error: (error) => {
        console.log('get TimeCard Employee Settings error : ', error);
      }
    }); */
  }

  updateButtons(buttons: any[]): any[] {
    buttons.forEach((item: any) => {
      var index = this.userEquipmentFavoriting.indexOf(item.Tag === null ? 'null' : item.Tag.toString().split(' ').join('_').split('.').join('').split('/').join('').split('(').join('').split(')').join(''));
      if (index > -1)
        item.Favoriting = true;
      else item.Favoriting = false
    })
    
    buttons.sort((a, b) => (a.Favoriting === b.Favoriting ? 0 : a.Favoriting ? -1 : 1));
    console.log('buttons', buttons);
    return buttons;
  }

  favouriteUnFavourite(tag: string, flag: boolean): void {
    // let buttonId = btnId === null ? 'null' : btnId.toString().split(' ').join('_').split('.').join('').split('/').join('').split('(').join('').split(')').join('');
    // let buttonId = btnId === null ? 'null' : this.sharedService.transformString(btnId);
    if (tag !== null) {
      if (flag) {
        this.removeUserFavoriting(tag);
      } else {
        this.addUserFavoriting(tag);
      }
    }
  }

  addUserFavoriting(buttonId: string) {
    this.showmeloader = true;
    // if (buttonId == '') {
    //   this.showmeloader = false;
    //   return false;
    // }
    let tag = buttonId === null ? 'null' : buttonId.toString().split(' ').join('_').split('.').join('').split('/').join('').split('(').join('').split(')').join('');
    this.userEquipmentFavoriting.push(tag);
    let timeCardEmployeeSettings = {
      EmployeeId: this.employeeNumber,
      ButtonPreferencesEqu: this.userEquipmentFavoriting.join("$")
    }
    this.homeService.saveTimeCardEmployeeSettings(timeCardEmployeeSettings).subscribe((d) => {
      if (d) {
        this.showmeloader = false;
      } else {
        this.showmeloader = false;
      }
      // this.authService.setCurrentUser({ ...this.userData, ButtonPreferences: this.userFavoriting.join(',') });
      this.authService.setCurrentUser({ ...this.userData, ButtonPreferences: this.userFavoriting.join('$') });
    });

  }

  removeUserFavoriting(buttonId: string) {
    this.showmeloader = true;
    // if (buttonId == '') {
    //   this.showmeloader = false;
    //   return false;
    // }
    let tag = buttonId === null ? 'null' : buttonId.toString().split(' ').join('_').split('.').join('').split('/').join('').split('(').join('').split(')').join('')
    var index = this.userEquipmentFavoriting.indexOf(tag.toString());
    this.userEquipmentFavoriting.splice(index, 1);
    let timeCardEmployeeSettings = {
      EmployeeId: this.employeeNumber,
      ButtonPreferencesEqu: this.userEquipmentFavoriting.join("$")
    }
    this.homeService.saveTimeCardEmployeeSettings(timeCardEmployeeSettings).subscribe((d) => {
      if (d) {
        this.showmeloader = false;
      } else {
        this.showmeloader = false;
      }
      this.authService.setCurrentUser({ ...this.userData, ButtonPreferences: this.userFavoriting.join('$') });
    });

  }


  onCalculatorValueChanged(value: any) {
    this.equipment.hours = value;
    this.btnEquipmentHoursflag = false;
    const equipmenthours: number = isNaN(parseFloat(this.equipment.hours)) ? 0.0 : parseFloat(this.equipment.hours);
    // $(".equipmentHoursWarning").popover('dispose');

    // this.popoverflag = false;
    this.popovermsg = "";

    let TotalEmployeeEquipmentTimeByDate = equipmenthours;

    if (this.userData.IsEquipmentMaxHoursWarning == true) {
      if (TotalEmployeeEquipmentTimeByDate > parseFloat(this.userData.EquipmentMaxHours)) {
        this.popoverflag = true;
        this.popovermsg = `<span class="flex"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-12 h-4 text-orange-500">
        <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
      </svg>
       You have entered more than ${parseFloat(this.userData.EquipmentMaxHours)} hours set by your organization for a single piece of equipment.</span>`;
      }
    }
    console.log(equipmenthours, parseFloat(this.payHours));
    if (this.userData.IsEquipmentandLaborHoursWarning == true) {
      if (equipmenthours > parseFloat(this.payHours)) {
        this.popoverflag = true;
        this.popovermsg += `<br/><span class="flex"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-12 h-4 text-orange-500">
        <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
      </svg>
       Currently, the labor hours you have entered is ${this.payHours} hours. The equipment hours you are about to add will exceed your labor hours.</span>`;
      }
    }

    if (this.userData.IsEquipmentNotLaborHoursWarning == true) {
      if (equipmenthours < parseFloat(this.payHours) && equipmenthours > 0) {
        this.popoverflag = true;
        this.popovermsg += `<br/><span class="flex"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-12 h-4 text-orange-500">
        <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
      </svg>
       Your labor hours and equipment hours for a single piece of equipment do not match each other.</span>`;
      }
    }

    if (this.popoverflag) {
      if (this.IsUpateNumberEntry) {
        this.btnEquipmentHoursflag = true;
      }
      this.popovermsg += "Please confirm your entry is correct.";
    }

    if (value == '') {
      this.popoverflag = false;
    }
    // this.getTrustedHtmlContent(this.popovermsg);
    // console.log('this.popoverflag', this.popoverflag);
    // console.log('this.popovermsg', this.popovermsg);
  }

  getTrustedHtmlContent(msgContent: any): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(msgContent);
  }

  AddEquimentDialog() {
    this.dialog.close('done');
  }

  closeDialog() {
    this.dialog.close();
  }


  updateDisplayedData() {
    const offset = this.paginationService.calculateOffset();
    // Filter data based on search query
    const filteredData = this.isSearching ? this.filterData(this.equipment?.buttons, this.searchQuery) : this.equipment?.buttons;
    // Use slice to get the desired subset of data based on offset and page size
    this.dataSource = filteredData.slice(offset, offset + this.paginationService.pageSize);
    // console.log('this.displayedButtons', this.displayedButtons);
  }

  generatePageLinks(): number[] {
    const totalRecords = this.isSearching ? this.filterData(this.equipment?.buttons, this.searchQuery).length : this.equipment?.buttons.length;
    const totalPages = Math.ceil(totalRecords / this.paginationService.pageSize);
    const currentPage = this.paginationService.currentPage;
    const displayLimit = 3;

    if (totalPages <= displayLimit) {
      // If total pages are less than or equal to the display limit, show all pages
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      // Show a range of pages centered around the current page with next and previous arrows
      const startPage = Math.max(1, currentPage - Math.floor(displayLimit / 2));
      const endPage = Math.min(totalPages, startPage + displayLimit - 1);

      return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    }
  }

  nextPage(event: Event) {
    event.preventDefault();
    if (!this.isLastPage()) {
      this.paginationService.nextPage();
      this.updateDisplayedData();
    }
  }

  prevPage(event: Event) {
    event.preventDefault();
    if (!this.isFirstPage()) {
      this.paginationService.prevPage();
      this.updateDisplayedData();
    }
  }

  protected isFirstPage(): boolean {
    return this.paginationService.currentPage === 1;
  }

  protected isLastPage(): boolean {
    const totalPages = Math.ceil(this.equipment?.buttons.length / this.paginationService.pageSize);
    return this.paginationService.currentPage === totalPages;
  }

  firstPage(event: Event) {
    event.preventDefault();
    if (!this.isFirstPage()) {
      this.paginationService.currentPage = 1;
      this.updateDisplayedData();
    }
  }

  lastPage(event: Event) {
    event.preventDefault();
    if (!this.isLastPage()) {
      const totalPages = Math.ceil(this.equipment?.buttons.length / this.paginationService.pageSize);
      this.paginationService.currentPage = totalPages;
      this.updateDisplayedData();
    }
  }

  onSearchQueryChange(value: any) {
    this.searchQuery = value;
    this.search();
  }
  search() {
    this.paginationService.currentPage = 1; // Reset page when performing a new search
    this.isSearching = true;
    this.updateDisplayedData();
  }

  clearSearch() {
    this.searchQuery = ''; // Clear the search query
    this.isSearching = false; // Reset the search flag
    this.updateDisplayedData();
  }

  private filterData(data: any[], query: string): any[] {
    // Implement your filtering logic here
    return data.filter(item => {
      // Check if ButtonDescription or Tag contains the query (case-insensitive)
      const buttonDescriptionMatch = item.ButtonDescription && item.ButtonDescription.toLowerCase().includes(query.toLowerCase());
      const tagMatch = item.Tag && item.Tag.toLowerCase().includes(query.toLowerCase());

      // Return true if either ButtonDescription or Tag matches the query
      return buttonDescriptionMatch || tagMatch;
    });
  }


}
