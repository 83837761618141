<div class="flex">
  <div class="w-full">
    <div class="block reviewDetailBlock p-4" style="font-size: 14px">
      <div class="flex">
        <div class="w-full">
          <div class="flex" *ngIf="!condensed">
            <ng-container *ngFor="let p of details?.programDirections">
              <span
                class=""
                *ngIf="
                  p.header != '' &&
                  p.header != 'Summary' &&
                  p.header != 'Hours/Shift'
                "
              >
                <span class="text-blue-700 font-avertaBold">{{ p.header }}:</span>
                <span
                  [ngClass]="{
                    'text-red-500 font-avertaBold': p.highlight
                  }"
                  >&nbsp; {{ p.displayValue }}
                  <i *ngIf="p.highlight" class="fa fa-exclamation-triangle"></i
                ></span>
              </span>
            </ng-container>
          </div>
          <div class="flex" *ngIf="condensed">
            <div class="w-full">
              <ng-container *ngFor="let p of details?.programDirections">
                <span
                  *ngIf="
                    p.header != '' &&
                    p.header != 'Summary' &&
                    p.header != 'Hours/Shift' &&
                    p.header != 'Fund' &&
                    p.header != 'Daily TimeCard Hours'
                  "
                >
                  <span class="text-blue-700 dark:text-blue-400 font-avertaBold"
                    >{{ p.header }}:</span
                  >
                  <span
                    [ngClass]="{
                      'text-red-500 font-avertaBold': p.highlight
                    }"
                    >&nbsp;{{ p.displayValue }}
                    <span *ngIf="p.highlight" class="inline-block mx-1 align-middle">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5">
                        <path fill-rule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                      </svg>                      
                    </span>
                  </span>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
        <div
          class="w-full"
          *ngIf="(showEquipment || showInventory) && !hideEquipmentInventory"
        >
          <!-- <div class="bg-transparent p-1">
            <div class="flex">
              <div class="w-full md:w-6/12">
                <kiosk-equipment
                  [payHours]="payHours"
                  *ngIf="showEquipment"
                  [transactionNumber]="transactionNumber"
                  [employeeNumber]="employeeNumber"
                  [readOnly]="readOnly"
                  [location]="'review'"
                  class="krd"
                  [totalHours]="totalEquipmentHours"
                  [elementId]="'rd'"
                ></kiosk-equipment>
              </div>
              <div class="w-full md:w-6/12">
                <kiosk-inventory
                  *ngIf="showInventory"
                  [transactionNumber]="transactionNumber"
                  [employeeNumber]="employeeNumber"
                  [readOnly]="readOnly"
                  [location]="'review'"
                  class="krd"
                  [category]="category"
                  [elementId]="'rd'"
                ></kiosk-inventory>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
