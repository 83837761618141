<ng-container>
  <!-- <div
    class="text-[20px] text-[#102030] flex justify-center font-avertaBold mb-2"
  >
    Enter Your Hours
  </div> -->
  <div
    *ngIf="isAvailable"
    class="text-[16px] text-[#102030] dark:text-white flex justify-end font-avertareguler mb-2"
  >
    Available: {{ kioskBenefitMax | number : "1.2" }}
  </div>
  <div
    class="w-full flex justify-between bg-[#010D58] dark:bg-black items-center p-6 text-white"
  >
    <div>
      <a href="javascript:void(0)" (click)="increaseCount()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4.5 15.75l7.5-7.5 7.5 7.5"
          />
        </svg>
      </a>
      <a href="javascript:void(0)" (click)="decreaseCount()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
          />
        </svg>
      </a>
    </div>
    <div
      class="font-avertaLight text-5xl flex justify-between items-center space-x-3"
    >
      <span>{{ input || "" | removeNaN }}</span>
      <span>
        <a href="javascript:void(0)" (click)="addToInput('backspace')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z"
            />
          </svg>
        </a>
      </span>
    </div>
  </div>
  <div class="grid grid-cols-3 px-6">
    <button
      (click)="addToInput('1')"
      class="col-span-1 h-20 border border-[#4E1BD9]/30 bg-[#DBEAFE] dark:text-white dark:bg-gray-800 text-3xl font-avertaBold hover:text-white hover:bg-blue-700 transition-all duration-200"
    >
      1
    </button>
    <button
      (click)="addToInput('2')"
      class="col-span-1 h-20 border border-[#4E1BD9]/30 bg-[#DBEAFE] dark:text-white dark:bg-gray-800 text-3xl font-avertaBold hover:text-white hover:bg-blue-700 transition-all duration-200"
    >
      2
    </button>
    <button
      (click)="addToInput('3')"
      class="col-span-1 h-20 border border-[#4E1BD9]/30 bg-[#DBEAFE] dark:text-white dark:bg-gray-800 text-3xl font-avertaBold hover:text-white hover:bg-blue-700 transition-all duration-200"
    >
      3
    </button>
    <button
      (click)="addToInput('4')"
      class="col-span-1 h-20 border border-[#4E1BD9]/30 bg-[#DBEAFE] dark:text-white dark:bg-gray-800 text-3xl font-avertaBold hover:text-white hover:bg-blue-700 transition-all duration-200"
    >
      4
    </button>
    <button
      (click)="addToInput('5')"
      class="col-span-1 h-20 border border-[#4E1BD9]/30 bg-[#DBEAFE] dark:text-white dark:bg-gray-800 text-3xl font-avertaBold hover:text-white hover:bg-blue-700 transition-all duration-200"
    >
      5
    </button>
    <button
      (click)="addToInput('6')"
      class="col-span-1 h-20 border border-[#4E1BD9]/30 bg-[#DBEAFE] dark:text-white dark:bg-gray-800 text-3xl font-avertaBold hover:text-white hover:bg-blue-700 transition-all duration-200"
    >
      6
    </button>
    <button
      (click)="addToInput('7')"
      class="col-span-1 h-20 border border-[#4E1BD9]/30 bg-[#DBEAFE] dark:text-white dark:bg-gray-800 text-3xl font-avertaBold hover:text-white hover:bg-blue-700 transition-all duration-200"
    >
      7
    </button>
    <button
      (click)="addToInput('8')"
      class="col-span-1 h-20 border border-[#4E1BD9]/30 bg-[#DBEAFE] dark:text-white dark:bg-gray-800 text-3xl font-avertaBold hover:text-white hover:bg-blue-700 transition-all duration-200"
    >
      8
    </button>
    <button
      (click)="addToInput('9')"
      class="col-span-1 h-20 border border-[#4E1BD9]/30 bg-[#DBEAFE] dark:text-white dark:bg-gray-800 text-3xl font-avertaBold hover:text-white hover:bg-blue-700 transition-all duration-200"
    >
      9
    </button>
    <button
      (click)="addToInput('clear')"
      class="col-span-1 h-20 border border-[#4E1BD9]/30 bg-[#DBEAFE] text-3xl font-avertaBold text-red-600 dark:text-white dark:bg-red-600 hover:text-white hover:bg-blue-700 transition-all duration-200"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6 m-auto"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
        />
      </svg>
    </button>
    <button
      (click)="addToInput('0')"
      class="col-span-1 h-20 border border-[#4E1BD9]/30 bg-[#DBEAFE] dark:text-white dark:bg-gray-800 text-3xl font-avertaBold hover:text-white hover:bg-blue-700 transition-all duration-200"
    >
      0
    </button>
    <button
      (click)="addToInput('.')"
      class="col-span-1 h-20 border border-[#4E1BD9]/30 bg-[#DBEAFE] dark:text-white dark:bg-gray-800 text-3xl font-avertaBold hover:text-white hover:bg-blue-700 transition-all duration-200"
    >
      .
    </button>
  </div>
</ng-container>
