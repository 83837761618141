import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomCalendarModule } from '../custom-calendar/custom-calendar.module';

@Component({
  selector: 'datepicker-dialog',
  templateUrl: './datepicker-dialog.component.html',
  styleUrl: './datepicker-dialog.component.css',
  standalone: true,
  imports: [CommonModule, CustomCalendarModule]
})
export class DatepickerDialogComponent implements OnInit {
  selectedDate: any = {};
  showEvents: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<DatepickerDialogComponent>) {
    this.selectedDate = data.selectedDate;
    this.showEvents = data.showEvents;
    // this.employeeNumber = data.employeeNumber;
  }

  ngOnInit(): void {
      // console.log("selection.timeCardDate", this.selection.timeCardDate);
  }

  onDateSelected(date: any) {
    const dateObject = new Date(date);
    const formattedDate = dateObject.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    // close the calendar dialog
    this.dialog.close({date: formattedDate});
  }

  onMonthChanged(month: any) {
    console.log('month', month)
  }
  onYearChanged(year: any) {
    console.log('year', year)
  }

  closeCalendarDialog() {
    this.dialog.close();
  }

}
