<!-- <div class="dropdown-container">
  <button class="dropbtn" (click)="toggleDropdown($event)">
    <span
      (click)="$event.stopPropagation(); toggleDropdown($event)"
      [innerHTML]="getIconSvg(selectedOperator)"
    ></span>
  </button>
  <div class="dropdown-content" [ngClass]="{ show: dropdownVisible }">
    <a *ngFor="let operator of operators" (click)="selectOperator(operator)">
      <div class="flex align-middle gap-4">
        <span [innerHTML]="getIconSvg(operator.icon)"></span>
        <span>{{ operator.label }}</span>
      </div>
    </a>
  </div>
</div> -->
<div class="dropdown-container">
  <button class="dropbtn" (click)="toggleDropdown($event)">
    <span [innerHTML]="iconCache[selectedOperator]"></span>
  </button>
  <div class="dropdown-content" [ngClass]="{ show: dropdownVisible }">
    <a *ngFor="let operator of operators" (click)="selectOperator(operator)">
      <div class="flex align-middle gap-2">
        <span [innerHTML]="iconCache[operator.icon]"></span>
        <span>{{ operator.label }}</span>
      </div>
    </a>
  </div>
</div>
