import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: '[floatingPoint]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => FloatingPointDirective),
        multi: true
    }]
})
export class FloatingPointDirective implements ControlValueAccessor {
    @Input() decimalPlaces: number = 2;  // Default to 2 decimal places
    private onChange!: (val: string) => void;
    private onTouched!: () => void;
    private value: string = '';

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['decimalPlaces'] && !changes['decimalPlaces'].firstChange) {
            const currentValue = this.elementRef.nativeElement.value;
            const formattedValue = this.formatToFloatingPoint(currentValue, false);
            this.updateTextInput(formattedValue, false);
        }
    }

    @HostListener('focus')
    onFocus(): void {
        const currentValue = this.elementRef.nativeElement.value;
        if (currentValue === this.formatEmptyValue()) {
            this.selectEntireValue();
        }
    }

    @HostListener('blur')
    onBlur(): void {
        const value = this.formatToFloatingPoint(this.elementRef.nativeElement.value, false);
        this.updateTextInput(value, this.value !== value);
        this.onTouched();
    }

    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        let cursorPosition = inputElement.selectionStart || 0;

        const rawValue = inputElement.value;
        const oldLength = rawValue.length;

        // Don't format during input; only clean non-numeric and non-decimal values
        const cleanedValue = rawValue.replace(/[^0-9.-]/g, '');

        // Only update the input value if there are invalid characters removed
        if (cleanedValue !== rawValue) {
            this.updateTextInput(cleanedValue, true);
        }

        // Keep the cursor at its natural position
        this.renderer.setProperty(inputElement, 'selectionStart', cursorPosition);
        this.renderer.setProperty(inputElement, 'selectionEnd', cursorPosition);
    }

    private formatToFloatingPoint(value: string, allowManualDecimal: boolean): string {
        // Format only on blur or if manual decimal is not allowed
        if (!allowManualDecimal) {
            let parsedValue = parseFloat(value);
            if (!isNaN(parsedValue)) {
                return parsedValue.toFixed(this.decimalPlaces);
            }
            return this.formatEmptyValue();
        }
        return value;
    }

    private formatEmptyValue(): string {
        return `0.${'0'.repeat(this.decimalPlaces)}`;
    }

    private updateTextInput(value: string, propagateChange: boolean): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
        if (propagateChange) {
            this.onChange(value);
        }
        this.value = value;
    }

    private selectEntireValue(): void {
        setTimeout(() => {
            this.elementRef.nativeElement.select();
        });
    }

    // ControlValueAccessor Interface
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    }

    writeValue(value: any): void {
        if (value === null || value === undefined || value === '') {
            value = this.formatEmptyValue();
        } else {
            value = this.formatToFloatingPoint(String(value), false);
        }
        this.updateTextInput(value, false);
    }
}
