import { Component, Input, OnInit, OnChanges, ViewChild, SimpleChanges, EventEmitter, Output, Renderer2, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, takeUntil } from 'rxjs/operators';
// import * as XLSX from 'xlsx';
import * as XLSX from 'xlsx-js-style';
import { ColumnDefinition, TableData, FilterCriteria, OperatorOption } from './table.model';
import { DropdownService } from './operator-dropdown/dropdown.service';
import { operatorMetadata } from './operator-metadata';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() columns!: ColumnDefinition[];
  @Input() data!: TableData[];
  @Input() length: number = 0;
  @Input() showCheckbox: boolean = false;
  @Input() isNested: boolean = false;
  @Input() moduleName: string = '';

  @Output() filterChanged = new EventEmitter<any>();
  @Output() sortChanged = new EventEmitter<any>();
  // selection models
  @Input() selectionMode: 'single' | 'multiple' | 'none' = 'multiple'; // Add input for selection mode
  @Output() selectionChange = new EventEmitter<TableData[]>();

  // decimalPlaces: number = this.columns.map((column) => (column.decimalPlaces ? column.decimalPlaces : 2)) ||2

  filterCriteria: { [key: string]: { value: string; operator?: string } } = {};
  searchParams: any = {
    filters: [],
    Sortings: [],
    Start: 0,
    Length: 10
  };

  private searchSubject = new Subject<void>();
  private destroy$ = new Subject<void>();

  dataSource!: MatTableDataSource<TableData>;
  displayedColumns!: string[];
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  selection!: SelectionModel<TableData>;

  private clickListener!: () => void;

  selectedRows: Set<TableData> = new Set<TableData>();

  // Store footer totals for each column
  footerTotals: { [key: string]: number | null } = {};
  @Input() isTotal: boolean = false;

  constructor(
    private dropdownService: DropdownService,
    private renderer: Renderer2,
    // private operatorService: OperatorService,
    private _liveAnnouncer: LiveAnnouncer,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort; // Connect MatSort to dataSource
    this.dataSource.paginator = this.paginator;
    this.getFilterOperators();

    this.clickListener = this.renderer.listen('document', 'click', (event) => {
      const openDropdownId = this.dropdownService.getOpenDropdownId();
      if (openDropdownId && !event.target.closest('.dropdown-container')) {
        this.dropdownService.setOpenDropdown(null);
      }
    });

    this.searchSubject.pipe(
      debounceTime(300),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.processSearch();
    });

    this.selection = new SelectionModel<TableData>(
      this.selectionMode === 'multiple',
      []
    );

    // this.initializeFooterTotals();

  }

  ngAfterViewInit(): void {
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((sortState: Sort) => {
      this.processSort(sortState);
    });
    this.initializeFooterTotals();
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['columns'] && !changes['columns'].firstChange) {
      this.columns = changes['columns'].currentValue;
      // this.getFilterOperators();  // Recalculate displayedColumns and filterCriteria
      this.initializeFooterTotals();  // Reinitialize footer totals
      this.cdr.markForCheck();
    }
    // if (changes['data']) {
    if (changes['data'] && changes['data']?.currentValue?.length > 0) {
      this.dataSource.data = this.data;
      console.log("dynamic table data : ", this.data);
      let id: string;
      // console.log("dynamic table primary key : ",this.primaryKey);
      // console.log("dynamic table data : ",this.dataSource.data);

      //id = this.route.snapshot.queryParams['id'];
      // console.log("dynamic table id : ",id);
      //if (id !== undefined) {
      this.selectRowAsPerModule();

    }
    else {
      // this.dataSource.data = [];
      if (this.dataSource) {
        this.dataSource.data = changes['data']?.currentValue || [];
      }
    }
  }

  trackByFn(index: number, item: ColumnDefinition): any {
    return item.columnDef; // or any unique identifier
  }

  getFilterOperators(): void {
    this.displayedColumns = this.columns.map(col => col.columnDef);
    this.columns.map(column => {
      if (column.filterOptions) {
        column.filterOptions = column.filterOptions.map((operator: any) => {
          const meta = operatorMetadata[operator];
          return meta ? { operator, label: meta.label, icon: meta.icon } : { operator: operator?.operator, label: operator?.label, icon: operator?.icon };
        });
      }
      // set initial filter
      this.filterCriteria[column.columnDef] = { value: '', operator: column.defaultFilter || 'Contains' };
    });
  }

  selectRowAsPerModule() {
    // console.log("dynamic table primary key : ",this.primaryKey);      
    if (this.moduleName) {
      let row: TableData | any;
      if (this.moduleName === 'itemMasterList') {
        let itemNumber = this.route.snapshot.queryParams['id'];
        row = this.data.find((row: any) => row['ItemNumber'] == itemNumber);
      }
      else if (this.moduleName === 'StockOrderHeader') {
        let stockOrderNumberParam = this.route.snapshot.queryParams['id'];
        row = this.data.find((row: any) => row['StockOrderNumber'] == stockOrderNumberParam);
      }
      else if (this.moduleName === 'BlanketPOHeader') {
        let blanketPOParam = this.route.snapshot.queryParams['id'];
        let orNumberParam = this.route.snapshot.queryParams['ORNumber'];
        row = this.data.find((row: any) => row['BlanketPO'] == blanketPOParam && row['ORNumber'] == orNumberParam);
      }
      else if (this.moduleName === 'BlanketPOHeaderDetail') {
        let blanketPOParam = this.route.snapshot.queryParams['id'];
        let orNumberParam = this.route.snapshot.queryParams['ORNumber'];
        let lineNumber = this.route.snapshot.queryParams['LineNumber'];
        row = this.data.find((row: any) => row['BlanketPO'] == blanketPOParam && row['ORNumber'] == orNumberParam && row['LineNumber'] == lineNumber);
      }
      else if (this.moduleName === 'FinishGoodHeader') {
        let newBatchNumberParam = this.route.snapshot.queryParams['id'];
        row = this.data.find((row: any) => row['NewBatchNumber'] == newBatchNumberParam);
      } else if (this.moduleName === 'FinishGoodDetail') {
        let seqNumber = this.route.snapshot.queryParams['detailId'];
        row = this.data.find((row: any) => row['SequenceNumber'] == seqNumber);
      } else if (this.moduleName === 'requisitionHeader') {
        let reqNumber = this.route.snapshot.queryParams['id'];
        console.log('reqNumber', reqNumber);
        row = this.data.find((row: any) => row['RequisitionNumber'] == +reqNumber);
        console.log('this.data', this.data);
      } else if (this.moduleName === 'requisitionDetail') {
        let reqNumber = this.route.snapshot.queryParams['id'];
        let sequenceNumber = this.route.snapshot.queryParams['SequenceNumber'];
        row = this.data.find((row: any) => row['RequisitionNumber'] == reqNumber && row['SequenceNumber'] == sequenceNumber);
      } else if (this.moduleName === 'bulkMaterialHeader') {
        let batchNumber = this.route.snapshot.queryParams['id'];
        row = this.data.find((row: any) => row['BatchNumber'] == batchNumber);
      } else if (this.moduleName === 'bulkMaterialDetail') {
        let batchNumber = this.route.snapshot.queryParams['id'];
        let sequenceNumber = this.route.snapshot.queryParams['SequenceNumber'];
        row = this.data.find((row: any) => row['BulkBatchNumber'] == batchNumber && row['SequenceNumber'] == sequenceNumber);
      }
      else if (this.moduleName === 'StockReceiptHeader') {
        const poNumber = this.route.snapshot.queryParams['id'];
        row = this.data.find((row: any) => row['PurchaseOrder'] === poNumber);
      }
      else if (this.moduleName === 'StockReceiptDetail') {
        const poorNumber = this.route.snapshot.queryParams['id'];
        const lineNumber = this.route.snapshot.queryParams['lineNumber'];
        row = this.data.find((row: any) => row['POORNumber'] === poorNumber && row['POLineNumber'] === +lineNumber);
      } else if (this.moduleName === 'StockReceiptWithoutPoDetail') {
        const id = this.route.snapshot.queryParams['id'];
        const lineNumber = this.route.snapshot.queryParams['lineNumber'];
        row = this.data.find((row: any) => row['PONumber'] === +id && row['POLineNumber'] === +lineNumber);
      } else if (this.moduleName === 'StockReceiptWithoutPoHeader') {
        const poNumber = this.route.snapshot.queryParams['id'];
        row = this.data.find((row: any) => row['Batch'] === poNumber);
      }
      else if (this.moduleName === 'InventoryAdjustmentHeader') {
        let adjustmentID = this.route.snapshot.queryParams['id'];
        row = this.data.find((row: any) => row['ICAdjustmentHeaderID'] == adjustmentID);
      } else if (this.moduleName === 'InventoryAdjustmentDetail') {
        let adjustmentID = this.route.snapshot.queryParams['id'];
        let sequenceNumber = this.route.snapshot.queryParams['SequenceNumber'];
        row = this.data.find((row: any) => row['ICAdjustmentHeaderID'] == adjustmentID && row['SequenceNumber'] == sequenceNumber);
      } else if (this.moduleName === 'AccountClassReference'){
        let assacc = this.route.snapshot.queryParams['assacc'];
        let offacc = this.route.snapshot.queryParams['offacc'];
        let asssub = this.route.snapshot.queryParams['asssub'];
        let offsub = this.route.snapshot.queryParams['offsub'];
        row = this.data.find((row: any) => row['AssetAccount'] == assacc && row['OffsetAccount'] == offacc
                                        && row['AssetSubAccount'] == asssub && row['OffsetSubAccount'] == offsub);
      }else if(this.moduleName==='LocationMaster'){
        let location = this.route.snapshot.queryParams['location'];
        row = this.data.find((row: any) => row['Location'] == location);
      }else if(this.moduleName==='UOMaster'){
        let uom = this.route.snapshot.queryParams['uom'];
        row = this.data.find((row: any) => row['UnitOfMeasure'] == uom);
      }else if(this.moduleName==='alternates'){
        let uom = this.route.snapshot.queryParams['uom'];
        let alternate = this.route.snapshot.queryParams['alternate'];
        row = this.data.find((row: any) => row['AlternateUnitOfMeasure'] == alternate && row['UnitOfMeasure'] == uom);
      }
      if (row) {
        this.selectedRows.clear();
        this.selectedRows.add(row);
        console.log("dynamic table selected row : ", row);
      }
    }
  }

  getSelectedOperator(columnDef: string): OperatorOption | undefined {
    const selectedOperatorValue = this.filterCriteria[columnDef]?.operator;
    return this.columns.find((col: any) => col.columnDef === columnDef)?.filterOptions?.find((op: any) => op.operator === selectedOperatorValue);
  }

  onOperatorSelected(operator: any, columnDef: string) {
    this.filterCriteria[columnDef] = { value: this.filterCriteria[columnDef].value, operator: operator }; // reset the value if any
    // this.onClearSearchInput(columnDef);
    this.applySearch(null, columnDef)
  }

  applyDateChange(event: MatDatepickerInputEvent<Date>, columnDef: string): void {
    // this.events.update(events => [...events, `${type}: ${event.value}`]);
    if (!this.filterCriteria[columnDef]) {
      this.filterCriteria[columnDef] = { value: '', operator: this.columns.find((itm) => itm.columnDef === columnDef)?.defaultFilter || 'Contains' };
    }

    this.filterCriteria[columnDef].value = moment(event.value).format('MM/DD/YYYY');

    this.searchSubject.next();
  }

  applySearch(event: Event | null, columnDef: string) {
    console.log('event', event);
    if (!this.filterCriteria[columnDef]) {
      this.filterCriteria[columnDef] = { value: '', operator: this.columns.find((itm) => itm.columnDef === columnDef)?.defaultFilter || 'Contains' };
    }

    if (event) {
      const target = event.target as HTMLInputElement;
      this.filterCriteria[columnDef].value = target.value;
    }

    this.searchSubject.next();
  }

  onClearSearchInput(columnDef: string): void {
    if (this.filterCriteria[columnDef]) {
      this.filterCriteria[columnDef] = { ...this.filterCriteria[columnDef], value: '' };
      // const inputElement = document.getElementById(columnDef) as HTMLInputElement;
      // Use querySelector to find the input by the custom attribute
      const inputElement = document.querySelector(`input[data-column='${columnDef}']`) as HTMLInputElement;
      if (inputElement) {
        inputElement.value = '';
      }
    }
    this.searchSubject.next();
  }


  getNonEmptyCriteria(criteria: any) {
    const nonEmptyCriteria: { [key: string]: FilterCriteria } = {};
    for (const key in criteria) {
      if (criteria[key].value !== '') {
        nonEmptyCriteria[key] = criteria[key];
      }
    }
    return nonEmptyCriteria;
  }

  processSearch() {
    const operatorMapping: { [key: string]: string } = {
      Contains: "Contains",
      DoesNotContains: "DoesNotContains",
      Equals: "Equals",
      DoesNotEqual: "DoesNotEqual",
      LessThan: "LessThan",
      LessThanOrEqual: "LessThanOrEqual",
      GreaterThan: "GreaterThan",
      GreaterThanOrEqual: "GreaterThanOrEqual",
      StartsWith: "StartsWith",
      EndsWith: "EndsWith",
      IsLike: "IsLike",
      IsNotLike: "IsNotLike"
    };

    const searchArray = Object.keys(this.filterCriteria).map(columnDef => {
      const criteria = this.filterCriteria[columnDef];
      let operator = 'Contains'; // Default to 'Contains'
      if (criteria.operator && operatorMapping[criteria.operator]) {
        operator = operatorMapping[criteria.operator];
      }
      return {
        PropertyName: columnDef,
        Operation: operator,
        Value: criteria.value
      };
    }).filter(item => item.Value); // Ensure only criteria with values are included

    console.log('searchArray', searchArray);

    this.searchParams = {
      filters: searchArray,
      Sortings: [], // Include sorting information if applicable
      Start: 0, // Replace with actual start value if needed
      Length: 10 // Replace with actual length value if needed
    };

    this.filterChanged.emit(this.searchParams);
  }

  processSort(sortState: Sort) {
    if (sortState.direction) {
      this.searchParams.Sortings = [{
        PropertyName: sortState.active,
        Order: sortState.direction.toUpperCase() // 'asc' or 'desc' to 'ASC' or 'DESC'
      }];
    }
    this.searchParams = {
      ...this.searchParams,
      Sortings: this.searchParams.Sortings
    };
    this.sortChanged.emit(this.searchParams);
  }

  onPageChange(event: PageEvent) {
    console.log('pagination event', event);
    this.searchParams = {
      ...this.searchParams,
     // Start: (event.previousPageIndex === event.pageIndex) ? event.pageIndex : event.pageIndex * event.pageSize + 1,
      Start: (event.previousPageIndex === event.pageIndex) ? event.pageIndex : event.pageIndex * event.pageSize,
      Length: event.pageSize
    };
    console.log('pagination start', this.searchParams.Start);
    this.filterChanged.emit(this.searchParams);
  }
  /* onFilterChanged(event: PageEvent) {
    console.log('onFilterChanged', event);
    this.searchParams = {
      ...this.searchParams,
      Start: event.pageIndex * event.pageSize,
      // Start: (event.pageIndex * event.pageSize) + 1,
      Length: event.pageSize
    };
    this.filterChanged.emit(this.searchParams);
  } */

  toggleRow(row: TableData, evt?: MatCheckboxChange): void {
    if (this.selectionMode === 'multiple') {
      this.selection.toggle(row);
      if (this.selectedRows.has(row)) {
        this.selectedRows.delete(row);
      } else {
        this.selectedRows.add(row);
      }
      this.emitSelectionChange();
    } else if (this.selectionMode === 'single') {
      this.selection.toggle(row);
      // this.emitSelectionChange();
      if (this.selectedRows.has(row)) {
        this.selectedRows.clear();
        this.selectionChange.emit([]);
      } else {
        this.selectedRows.clear();
        this.selectedRows.add(row);
        this.selectionChange.emit(this.selection.selected);
      }
      // this.selectedRows.clear();
      // this.selectedRows.add(row);
    } else {
      if (evt !== undefined && evt.checked) {
        this.selection.select(row);
        this.selectionChange.emit({ ...row, Selected: true } as any as TableData[]);
      } else if (evt !== undefined && !evt.checked) {
        this.selectionChange.emit({ ...row, Selected: false } as any as TableData[]);
      }
    }

  }

  emitSelectionChange() {
    this.selectionChange.emit(this.selection.selected);
  }

  ngOnDestroy() {
    if (this.clickListener) {
      this.clickListener();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  expandedElement: Element | null = null;
  // Method to check if details array is empty
  hasDetails(element: any): boolean {
    return element.details.length > 0;
  }
  toggleExpandRow(element: Element, event: Event) {
    event.stopPropagation();
    if (this.hasDetails(element)) {
      this.expandedElement = this.expandedElement === element ? null : element;
    }
  }

  // Calculate totals based on columns and data
  private initializeFooterTotals(): void {
    this.columns.forEach(column => {
      if (column.total != null) {
        console.log('column', column);
        this.footerTotals[column.columnDef] = column.total;
        this.isTotal = true;
      }
    });
  }

  resetAllFilters() {
    for (const columnDef in this.filterCriteria) {
      //&& this.filterCriteria[columnDef].value
      if (this.filterCriteria.hasOwnProperty(columnDef)) {
        // Reset the filter criteria for the column
        this.filterCriteria[columnDef] = { ...this.filterCriteria[columnDef], value: '' };

        // Clear the input element's value
        const inputElement = document.getElementById(columnDef) as HTMLInputElement;
        if (inputElement) {
          inputElement.value = '';
        }
      }
    }
  }

  resetMultiTableFilters(renderer: Renderer2) {
    for (const columnDef in this.filterCriteria) {
      if (this.filterCriteria.hasOwnProperty(columnDef)) {
        // Reset the filter criteria
        this.filterCriteria[columnDef].value = '';

        // Use Renderer2 to clear the input element's value
        const inputElement = document.getElementById(columnDef) as HTMLInputElement;
        if (inputElement) {
          renderer.setProperty(inputElement, 'value', '');
        }
      }
    }
  }

  exportTableToExcel(data: any[], columns: any[], fileName: string = 'items'): void {
    console.log({ columns })
    // Filter out columns with dataType 'checkbox'
    const columnsToExport = columns.filter(column => column.dataType !== 'checkbox');
    console.log({ columnsToExport })
    const dataToExport = data.map(row => {
      const formattedRow: { [key: string]: any } = {};
      columnsToExport.forEach(column => {
        // formattedRow[column.header] = row[column.columnDef];
        let cellValue = row[column.columnDef];
        // Check if the cell value is a boolean and convert it
        if (typeof cellValue === 'boolean') {
          cellValue = cellValue ? 'Yes' : 'No';
        }
        if (column.dataType === 'date') {
          cellValue = moment(cellValue).format('MM/DD/YYYY');
        }
        if (column?.decimalPlaces && column.dataType === 'number') {
          console.log("in if condition")
          let parsedValue = parseFloat(cellValue);
          if (!isNaN(parsedValue)) {
            cellValue = parsedValue.toFixed(column?.decimalPlaces);
          }
        }
        formattedRow[column.header] = cellValue;
      });
      console.log({ formattedRow })
      return formattedRow;
    });

    // Add footer totals row
    const footerRow: { [key: string]: any } = {};
    columnsToExport.forEach(column => {
      if (this.footerTotals[column.columnDef] !== null) {
        footerRow[column.header] = this.footerTotals[column.columnDef];
      } else {
        footerRow[column.header] = '';
      }
    });
    dataToExport.push(footerRow);

    // Create the worksheet from the data
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport);

    // Define styles
    const defaultStyle = {
      font: {
        name: 'arial',
        sz: 12,
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true, // Wrap text for all cells by default
      },
      border: {
        right: {
          style: 'thin',
          color: '000000',
        },
        left: {
          style: 'thin',
          color: '000000',
        },
      },
    };

    const headerStyle = {
      ...defaultStyle,
      font: {
        ...defaultStyle.font,
        bold: true,
      },
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'D1D5DB' },
      },
      alignment: {
        ...defaultStyle.alignment,
        wrapText: false, // No text wrap for headers
      },
    };

    const footerStyle = {
      ...headerStyle,
      fill: {
        patternType: 'solid',
        fgColor: { rgb: 'E5E7EB' },
      },
    };

    // Apply styles to cells
    const range = XLSX.utils.decode_range(worksheet['!ref'] as string);

    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = XLSX.utils.encode_cell({ r: R, c: C });
        const cell = worksheet[cell_address];

        if (cell) {
          // Apply default style
          cell.s = { ...defaultStyle };

          // Apply header style
          if (R === 0) {
            cell.s = { ...headerStyle };
          }

          // Apply footer style
          if (R === range.e.r) {
            cell.s = { ...footerStyle };
          }

          // Apply number formatting for specific columns (example column index 6)
          // if (C === 6) {
          //   cell.s.numFmt = 'DD-MM-YYYY'; // Date format
          // } else {
          //   cell.s.numFmt = '00'; // Number format
          // }

          // Apply background color for odd rows
          if (R % 2 === 1) {
            cell.s.fill = {
              patternType: 'solid',
              fgColor: { rgb: 'b2b2b2' },
            };
          }

          // Apply border bottom for header row
          if (R === 0) {
            cell.s.border = {
              ...cell.s.border,
              bottom: {
                style: 'thin',
                color: '000000',
              },
            };
          }
        }
      }
    }

    // Set header row height (Height in points)
    worksheet['!rows'] = [{ hpt: 30 }]; // Adjust the height as needed

    // Adjust column widths for padding effect
    worksheet['!cols'] = this.columns.map(column => ({
      wch: Math.max(column.header.length + 5, 15) // Add extra space for padding
    }));

    // Create a new workbook and append the styled worksheet
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

    // Write the file
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }


  exportTableToCsv(fileName: string = 'item-list'): void {
    const dataToExport = this.dataSource.filteredData.map(row => {
      const formattedRow: { [key: string]: any } = {};
      this.columns.forEach(column => {
        formattedRow[column.header] = row[column.columnDef]; // Use header instead of columnDef
      });
      return formattedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const csvData = XLSX.utils.sheet_to_csv(worksheet);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  }


}
