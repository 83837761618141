import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent {
  isArray: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isArray = Array.isArray(data.message);
  }

  closeAlert(): void {
    this.dialogRef.close();
  }
  /* @Input() type: 'success' | 'error' | 'warning' | 'info' = 'success';
  @Input() message: string | string[] = '';
  show: boolean = true;

  get alertClass(): string {
    return this.type;
  }

  closeAlert(): void {
    this.show = false;
  }

  isArray(value: any): value is string[] {
    return Array.isArray(value);
  } */
}
