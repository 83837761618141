import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DetailsService } from '../details.service';
import { MatDialog } from '@angular/material/dialog';
import { AddEquipmentDialogComponent } from '../add-equipment-dialog/add-equipment-dialog.component';
import { ToastrService } from 'ngx-toastr';
// import { CalendarComponent } from 'src/app/shared/calendar/calendar.component';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css']
})
export class EquipmentComponent implements OnInit {
  @Input() payHours: string = '';
  @Input() transactionNumber: string = '';
  @Input() readOnly!: boolean;
  @Input() hasDistrict!: boolean;
  @Input() hasDepartment!: boolean;
  @Input() employeeNumber: number = 0;

  @Output() eqpChangeEvent = new EventEmitter<string>();

  totalHours: number = 0;
  equipment: any = {
    equipmentList: [],
    districtList: [],
    departmentList: [],
    buttons: [],
    district: '',
    department: '',
    number: '',
    name: '',
    hours: '',
    showButtons: true,
    showHours: false,

  };
  searchText: string = ')'
  currentSelection: string = '';
  isEdit: boolean = false;


  // @ViewChild(CalendarComponent) calendarComponent!: CalendarComponent;

  constructor(private dialog: MatDialog, private toastr: ToastrService, private detailsService: DetailsService) {
    console.log('this.hasDepartment', this.hasDepartment);
  }

  ngOnInit(): void {
    this.loadEquipmentArray();
  }

  loadEquipmentArray() {
    // debugger;
    this.equipment.selectedEquipment = [];
    let totalHours = 0.00;
    // this.loading = true;
    if (this.transactionNumber != '') {
      this.detailsService.getTransactionEquipment(this.employeeNumber, this.transactionNumber).subscribe((d: any) => {
        // this.saving = false;
        for (let x = 0; x < d.length; x++) {
          let e: any = {};
          e.name = d[x].EquipmentDescription;
          e.number = d[x].EquipmentNumber;
          e.hours = d[x].EquipmentHours;
          e.identity = d[x].Identity;
          e.equipmentNumber = d[x].EquipmentNumber;
          this.equipment.selectedEquipment.push(e);

          totalHours += e.hours;
        }

        this.totalHours = totalHours;
      });
    }
  }

  formatHours(hours: any): string {
    if (typeof hours === 'number') {
      return hours.toFixed(2);
    } else if (typeof hours === 'string') {
      return parseFloat(hours).toFixed(2);
    } else {
      return '';
    }
  }

  openEquipmentModal(): void {
    this.searchText = '';
    this.equipment.hours = '';

    if (this.hasDepartment) {
      this.currentSelection = 'department';
      this.detailsService.getEquipmentDepartments(this.employeeNumber, this.equipment.district)
        .subscribe(d => {
          this.equipment.buttons = d;
          this.loadEquipmentModal();
        });
    } else if (this.hasDistrict) {
      this.currentSelection = 'district';
      this.detailsService.getEquipmentDistricts(this.employeeNumber, this.equipment.department)
        .subscribe(d => {
          this.equipment.buttons = d;
          this.loadEquipmentModal();
        });
    } else {
      this.currentSelection = 'equipment';
      this.detailsService.getEquipment(this.employeeNumber, this.equipment.district, this.equipment.department)
        .subscribe(d => {
          this.equipment.buttons = d;
          this.loadEquipmentModal();
          this.searchText = '';
        });
    }
  }

  getEquipmentButtons(): void {
    this.detailsService.getEquipment(this.employeeNumber, this.equipment.district, this.equipment.department)
      .subscribe(d => {
        // this.loadEquipmentModal();
        this.equipment.equipmentList = d;
        this.searchText = '';
      });
  }

  loadEquipmentModal(): void {
    this.isEdit = false;

    let dialogRef = this.dialog.open(AddEquipmentDialogComponent, {
      maxWidth: "600px",
      // hasBackdrop: false,
      data: {
        equipment: { ...this.equipment, showButtons: true, showHours: false },
        currentSelection: this.currentSelection,
        hasDistrict: this.hasDistrict,
        searchText: this.searchText,
        payHours: this.payHours,
        employeeNumber: this.employeeNumber,
        transactionNumber: this.transactionNumber,
      }
    });

    // Subscribe to the afterClosed event to handle actions after the dialog is closed
    dialogRef.afterClosed().subscribe((result) => {
      console.log('add equipment dialog result', result);
      if (result === 'done') {
        // this.getEquipmentButtons();
        this.loadEquipmentArray();
        this.eqpChangeEvent.emit();
        // this.calendarComponent.renderCalendar();
        // console.log('Done Add Equipment');
        this.toastr.clear();
        this.toastr.success('Detail entry equipment created successfully.', 'Success', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-success",
        });
      }
    });
  }

  loadEdit(i: any): void {
    this.isEdit = true;
    // this.editItem = i;
    // this.btnEquipmentHoursflag = false;
    this.equipment.number = i?.number;
    this.equipment.name = i?.name;
    // this.equipment.hours = i?.hours?.toFixed(2);
    this.equipment.hours = i?.hours;

    this.equipment.showButtons = false;
    this.equipment.showHours = true;
    // $('#equipmentModal_' + this.transactionNumber + this.id).modal('show');

    let dialogRef = this.dialog.open(AddEquipmentDialogComponent, {
      maxWidth: "600px",
      // hasBackdrop: false,
      data: {
        isEdit: true,
        editItem: i,
        btnEquipmentHoursflag: false,
        // equipment: this.equipment,
        equipment: {
          number: i.number,
          name: i.name,
          // hours: i?.hours?.toFixed(2),
          hours: i?.hours,
          showButtons: false,
          showHours: true
        },
        // currentSelection: this.currentSelection, 
        // hasDistrict: this.hasDistrict,
        // searchText: this.searchText,
        payHours: this.payHours,
        employeeNumber: this.employeeNumber,
        transactionNumber: this.transactionNumber,
      }
    });

    // Subscribe to the afterClosed event to handle actions after the dialog is closed
    dialogRef.afterClosed().subscribe((result) => {
      console.log('Edit equipment dialog result', result);
      if (result === 'done') {
        // this.getEquipmentButtons();
        this.loadEquipmentArray();
        this.eqpChangeEvent.emit();
        // this.calendarComponent.renderCalendar();
        console.log('Done Edit Equipment');
        this.toastr.clear();
        this.toastr.success('Detail entry equipment edited successfully.', 'Success', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-success",
        });
      }
    });

  }

  deleteEquipment(i: any): void {
    this.detailsService.deleteEquipment(i.identity).subscribe(
      (data) => {
        if (data) {
          i.deleted = true;
          // this.getEquipmentButtons();
          this.loadEquipmentArray();
          this.eqpChangeEvent.emit();
          // this.calendarComponent.renderCalendar();
          this.toastr.clear();
          this.toastr.success('Detail entry equipment deleted successfully.', 'Success', {
            positionClass: 'toast-bottom-left',
            toastClass: "toast-icon custom-toast-success",
          });
        }
      },
      (error) => {
        // Handle the error
        console.error('Error deleting inventory:', error);
      }
    );
  }


}
