import { Component, ElementRef, ViewChild, Input, Optional, Self, Output, EventEmitter, HostListener, forwardRef } from '@angular/core';
import { AbstractControl, FormControl, FormControlDirective, FormGroup, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KeyboardComponent),
      multi: true
    }
  ]
})
export class KeyboardComponent {
  @Input() control: AbstractControl<any> | null = null;
  // @Input() ngControl!: NgControl | null; // For ngModel
  @Input() ngControl: NgControl | string | null = null;
  @Input() keyboardType: string = 'qwerty';
  @Input() fieldType: string = '';
  @Input() fieldName: string = '';
  @Input() placeholder: string = '';
  @Input() class: string = '';
  @Input() keyButtonClass: string = '';
  @Input() passwordButtonClass: string = '';
  @Input() maxlength: any = '';
  inputValue: string = '';
  capsLockOn: boolean = false;
  isKeyboardOpen: boolean = false;
  activeInputFieldId: string | null = null;
  keyboardTop: string = '';
  keyboardLeft: number = 0;
  onChange: any = () => { };
  onTouched: any = () => { };

  // In the component class
  @Output() valueChange = new EventEmitter<string>();

  hidePassword: boolean = true;

  qwertyKeyboard: string[][] = [
    ['`', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '=', '←'],
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', '\\'],
    ['Caps', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', '\''],
    ['z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '/', '@', '_', '-', '+'],
    ['⟳', 'Space', '=', '&', '%', '$', '#', '!']
  ];

  numberKeyboard: string[][] = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['⟳', '0', '←']
  ];

  @ViewChild('inputField') inputField!: ElementRef;

  constructor(private elementRef: ElementRef) {
  }

  writeValue(value: any): void {
    this.inputValue = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void { }


  toggleKeyboard() {
    this.isKeyboardOpen = !this.isKeyboardOpen;
    if (this.isKeyboardOpen && this.activeInputFieldId) {
      // Focus on the active input field when the keyboard opens
      const inputElement = document.getElementById(this.activeInputFieldId);
      if (inputElement) {
        inputElement.focus();
      }
    }
    if (this.isKeyboardOpen) {
      // Update keyboard position when opening
      this.updateKeyboardPosition();
    }
  }

  handleInputFocus(inputFieldId: string) {
    // Close the keyboard if it's open and the active input field changes
    if (this.isKeyboardOpen && this.activeInputFieldId !== inputFieldId) {
      this.closeKeyboard();
    }
    this.activeInputFieldId = inputFieldId;
    // Update keyboard position when focus changes
    this.updateKeyboardPosition();
  }

  onInputChange(event: Event) {
    this.onChange(this.inputValue); // Notify Angular that the value has changed
    this.onTouched();
    // this.control.setValue(this.inputValue);
    this.updateValue();
  }

  updateKeyboardPosition() {
    if (this.isKeyboardOpen) {
      const keyboardTop = this.keyboardTopPosition();
      const keyboardElement = document.getElementById('keyboard-container');
      if (keyboardElement) {
        keyboardElement.style.top = keyboardTop;
      }
    }
  }

  pressKey(event: MouseEvent, key: any) {
    event.preventDefault();
    // debugger;
    if (key === 'back' || key === '←') {
      this.inputValue = this.inputValue.slice(0, -1);
    } else if (key === 'clear' || key === '⟳') {
      this.clearInput();
    } else if (key === 'Caps') {
      this.capsLockOn = !this.capsLockOn;
    } else if (key === 'Space') {
      this.inputValue += ' ';
    } else {
      this.inputValue += this.capsLockOn ? key.toUpperCase() : key.toLowerCase();
    }
    // this.control.setValue(this.inputValue);
    this.updateValue();
  }

  clearInput() {
    this.inputValue = '';
    this.updateValue();
  }

  updateValue() {
    // debugger;
    if (this.control) {
      this.control.setValue(this.inputValue);
    } else {
      // this.ngControl.control?.setValue(this.inputValue);
      console.log('ngModel')
      this.valueChange.emit(this.inputValue);
    }
  }

  private findFormControlDirective(controlName: string): FormControlDirective | undefined {
    // Find the corresponding FormControlName directive using the control name
    const formControlDirectives = this.inputField.nativeElement.querySelectorAll('[formControlName]');
    for (const directive of formControlDirectives) {
      const directiveInstance = directive as FormControlDirective;
      if (directiveInstance.name === controlName) {
        return directiveInstance;
      }
    }
    return undefined;
  }

  onInputKeydown(event: KeyboardEvent) {
    // Handle specific keydown events if needed
    if (event.key === 'Enter') {
      // Do something on Enter key press
    }
  }

  closeKeyboard() {
    // this.isKeyboardOpen = !this.isKeyboardOpen;
    this.isKeyboardOpen = false;
  }

  togglePasswordVisibility(event: MouseEvent): void {
    this.hidePassword = !this.hidePassword;
    event.stopPropagation();
  }

  keyboardTopPosition() {
    const inputElement = document.getElementById(this.fieldName);
    // debugger;
    if (inputElement) {
      const inputRect = inputElement.getBoundingClientRect();
      const inputTop = inputRect.top;
      const inputBottom = inputRect.bottom;
      const keyboardHeight = 300; // Set your desired keyboard height here
      const padding = 20; // Set padding between input and keyboard
      const screenHeight = window.innerHeight;
      const spaceAboveInput = inputTop - padding;
      const spaceBelowInput = screenHeight - inputBottom - padding;

      // Check if there's enough space above and below the input field for the keyboard
      if (spaceAboveInput >= keyboardHeight && spaceBelowInput >= keyboardHeight) {
        // Both spaces are sufficient, choose the one with more space
        if (spaceBelowInput > spaceAboveInput) {
          // Display keyboard below input
          return `${inputRect.bottom + padding}px`;
        } else {
          // Display keyboard above input
          return `${inputTop - keyboardHeight - padding}px`;
        }
      } else if (spaceBelowInput >= keyboardHeight) {
        // Enough space below input, display keyboard below input
        return `${inputRect.bottom + padding}px`;
      } else if (spaceAboveInput >= keyboardHeight) {
        // Enough space above input, display keyboard above input
        // return `${inputTop - keyboardHeight - padding}px`;
        if (this.keyboardType === 'number') {
          return `${inputTop - keyboardHeight - (padding + 130)}px`;
        } else {
          return `${inputTop - keyboardHeight - (padding + 91)}px`;
        }
      } else {
        // Not enough space above or below input, default to below input
        // return `${inputRect.bottom + padding}px`;
        return `${inputRect.bottom + padding - 70}px`;
      }
    }
    return 'auto'; // Default position if input element not found
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.closeKeyboard();
    }
  }

}
