import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/core/services/auth.service';
import { DetailsService } from '../details.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PaginationService } from 'src/app/core/services/pagination.service';
import { HomeService } from '../../home.service';

@Component({
  selector: 'app-add-inventory-dialog',
  templateUrl: './add-inventory-dialog.component.html',
  styleUrls: ['./add-inventory-dialog.component.css']
})
export class AddInventoryDialogComponent {
  userData: any;
  loginemployeeNumber: number = 0;

  public category: string = '';
  public unitOfMeasure: string = '';
  public inventory: any = {};
  public hasDistrict: boolean;
  public searchText: string = '';
  public employeeNumber: number = 0;
  public transactionNumber: string = '';
  public currentSelection: any = {};

  editItem: any = {};

  IsUpateNumberEntry: boolean = true;
  btnEquipmentHoursflag: boolean = false;
  popoverflag: boolean = false;
  payHours: string = '';
  popovermsg: string = "";
  isEdit: boolean = false;
  saving: boolean = false;

  searchQuery: string = '';
  isSearching: boolean = false;

  userFavoriting: string[] = [];
  showmeloader: boolean = false;
  Lock_In_Out_User_Preferences: number = 0;
  Lock_In_Out_Total_User_Preferences: number = 0;
  userInventoryFavoriting: any = [];

  dataSource: any[] = []

  constructor(
    private readonly authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<AddInventoryDialogComponent>,
    private detailsService: DetailsService,
    private sanitizer: DomSanitizer,
    public paginationService: PaginationService,
    private readonly homeService: HomeService
  ) {

    this.editItem = data?.editItem;
    this.btnEquipmentHoursflag = data?.btnEquipmentHoursflag;
    this.inventory = data.inventory;
    this.isEdit = data?.isEdit ? data?.isEdit : false;
    this.hasDistrict = data.hasDistrict;
    this.currentSelection = data.currentSelection;
    this.employeeNumber = data.employeeNumber;
    this.transactionNumber = data.transactionNumber;
    this.payHours = data.payHours;
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe(async (user) => {
      this.userData = user;
      this.loginemployeeNumber = user?.credentials?.EmployeeNumber;
      if (this.inventory?.inventoryList) {
        this.updateDisplayedData();
      }
      await this.getTimeCardEmployeeSettingsByEmployee();
      this.updateButtons(this.dataSource);
    });
  }

  updateButtons(buttons: any[]): any[] {
    buttons.forEach((item: any) => {
      var index = this.userInventoryFavoriting.indexOf(item.Tag === null ? 'null' : item.Tag.toString().split(' ').join('_').split('.').join('').split('/').join('').split('(').join('').split(')').join(''));
      if (index > -1)
        item.Favoriting = true;
      else item.Favoriting = false
    })
    buttons.sort((a, b) => (a.Favoriting === b.Favoriting ? 0 : a.Favoriting ? -1 : 1));
    console.log('buttons', buttons);
    return buttons;
  }

  updateDisplayedData() {
    const offset = this.paginationService.calculateOffset();
    // Filter data based on search query
    const filteredData = this.isSearching ? this.filterData(this.inventory?.inventoryList, this.searchQuery) : this.inventory?.inventoryList;
    // Use slice to get the desired subset of data based on offset and page size
    this.dataSource = filteredData.slice(offset, offset + this.paginationService.pageSize);
    // console.log('this.displayedButtons', this.displayedButtons);
  }

  generatePageLinks(): number[] {
    const totalRecords = this.isSearching ? this.filterData(this.inventory?.inventoryList, this.searchQuery).length : this.inventory?.inventoryList.length;
    const totalPages = Math.ceil(totalRecords / this.paginationService.pageSize);
    const currentPage = this.paginationService.currentPage;
    const displayLimit = 3;

    if (totalPages <= displayLimit) {
      // If total pages are less than or equal to the display limit, show all pages
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      // Show a range of pages centered around the current page with next and previous arrows
      const startPage = Math.max(1, currentPage - Math.floor(displayLimit / 2));
      const endPage = Math.min(totalPages, startPage + displayLimit - 1);

      return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    }
  }

  nextPage(event: Event) {
    event.preventDefault();
    if (!this.isLastPage()) {
      this.paginationService.nextPage();
      this.updateDisplayedData();
    }
  }

  prevPage(event: Event) {
    event.preventDefault();
    if (!this.isFirstPage()) {
      this.paginationService.prevPage();
      this.updateDisplayedData();
    }
  }

  protected isFirstPage(): boolean {
    return this.paginationService.currentPage === 1;
  }

  protected isLastPage(): boolean {
    const totalPages = Math.ceil(this.inventory?.inventoryList.length / this.paginationService.pageSize);
    return this.paginationService.currentPage === totalPages;
  }

  firstPage(event: Event) {
    event.preventDefault();
    if (!this.isFirstPage()) {
      this.paginationService.currentPage = 1;
      this.updateDisplayedData();
    }
  }

  lastPage(event: Event) {
    event.preventDefault();
    if (!this.isLastPage()) {
      const totalPages = Math.ceil(this.inventory?.inventoryList.length / this.paginationService.pageSize);
      this.paginationService.currentPage = totalPages;
      this.updateDisplayedData();
    }
  }

  onSearchQueryChange(value: any) {
    this.searchQuery = value;
    this.search();
  }

  search() {
    this.paginationService.currentPage = 1; // Reset page when performing a new search
    this.isSearching = true;
    this.updateDisplayedData();
  }

  clearSearch() {
    this.searchQuery = ''; // Clear the search query
    this.isSearching = false; // Reset the search flag
    this.updateDisplayedData();
  }

  private filterData(data: any[], query: string): any[] {
    console.log('data', data);
    // Implement your filtering logic here
    return data.filter(item => {
      // Check if ButtonDescription or Tag contains the query (case-insensitive)
      const buttonDescriptionMatch = item.ButtonDescription && item.ButtonDescription.toLowerCase().includes(query.toLowerCase());
      const tagMatch = item.Tag && item.Tag.toLowerCase().includes(query.toLowerCase());

      // Return true if either ButtonDescription or Tag matches the query
      return buttonDescriptionMatch || tagMatch;
    });
  }


  inventorySelect(item: any): void {
    if (this.inventory.department === '') {
      this.inventory.department = item.Tag;

      this.detailsService.getInventory(this.employeeNumber, this.category, this.inventory.department, this.transactionNumber)
        .subscribe(
          (data) => {
            this.inventory.inventoryList = data;
            this.updateDisplayedData();
            // Uncomment the line below if you want to show the scan button
            // this.showScanButton = true;
          },
          (error) => {
            // Handle the error
            console.error('Error getting inventory:', error);
          }
        );
    } else {
      this.inventory.name = item.ButtonDescription;
      this.inventory.number = item.Tag;
      this.inventory.showButtons = false;
      this.inventory.showQuantity = true;
      this.inventory.availableQuantity = item.AvailableQuantity;
      this.inventory.isValid = false;
      this.unitOfMeasure = item.UnitOfMeasure;
    }
  }

  async getTimeCardEmployeeSettingsByEmployee() {
    let empNumber = this.employeeNumber;
    let userSetting = await this.homeService.getTimeCardEmployeeSettings(empNumber);
    this.userInventoryFavoriting = userSetting?.ButtonPreferencesInv?.toString()?.split('$');
    console.log(this.userInventoryFavoriting)
    // this.homeService.getTimeCardEmployeeSettingsByEmployee(empNumber).subscribe({
    //   next: (response) => {
    //     this.userInventoryFavoriting = response.ButtonPreferencesEqu.toString().split('$');
    //     console.log('this.userInventoryFavoriting', this.userInventoryFavoriting);
    //   },
    //   error: (error) => {
    //     console.log('get TimeCard Employee Settings error : ', error);
    //   }
    // });
  }

  favouriteUnFavourite(tag: string, flag: boolean): void {
    // let buttonId = btnId === null ? 'null' : btnId.toString().split(' ').join('_').split('.').join('').split('/').join('').split('(').join('').split(')').join('');
    // let buttonId = btnId === null ? 'null' : this.sharedService.transformString(btnId);
    if (tag !== null) {
      if (flag) {
        this.removeUserFavoriting(tag);
      } else {
        this.addUserFavoriting(tag);
      }
    }
  }

  addUserFavoriting(buttonId: string) {
    this.showmeloader = true;
    // if (buttonId == '') {
    //   this.showmeloader = false;
    //   return false;
    // }
    let tag = buttonId === null ? 'null' : buttonId.toString().split(' ').join('_').split('.').join('').split('/').join('').split('(').join('').split(')').join('');
    this.userInventoryFavoriting.push(tag);
    let timeCardEmployeeSettings = {
      EmployeeId: this.employeeNumber,
      ButtonPreferencesInv: this.userInventoryFavoriting.join("$")
    }
    this.homeService.saveTimeCardEmployeeSettings(timeCardEmployeeSettings).subscribe((d) => {
      if (d) {
        this.showmeloader = false;
      } else {
        this.showmeloader = false;
      }
      // this.authService.setCurrentUser({ ...this.userData, ButtonPreferences: this.userFavoriting.join(',') });
      this.authService.setCurrentUser({ ...this.userData, ButtonPreferences: this.userFavoriting.join('$') });
    });

  }

  removeUserFavoriting(buttonId: string) {
    this.showmeloader = true;
    // if (buttonId == '') {
    //   this.showmeloader = false;
    //   return false;
    // }
    let tag = buttonId === null ? 'null' : buttonId.toString().split(' ').join('_').split('.').join('').split('/').join('').split('(').join('').split(')').join('')
    var index = this.userInventoryFavoriting.indexOf(tag.toString());
    this.userInventoryFavoriting.splice(index, 1);
    let timeCardEmployeeSettings = {
      EmployeeId: this.employeeNumber,
      ButtonPreferencesInv: this.userInventoryFavoriting.join("$")
    }
    this.homeService.saveTimeCardEmployeeSettings(timeCardEmployeeSettings).subscribe((d) => {
      if (d) {
        this.showmeloader = false;
      } else {
        this.showmeloader = false;
      }
      this.authService.setCurrentUser({ ...this.userData, ButtonPreferences: this.userFavoriting.join('$') });
    });

  }

  onCalculatorValueChanged(value: any) {
    this.inventory.isValid = !this.inventory.maxValue || value == '' || Number.isNaN(value) || parseFloat(this.inventory.maxValue) >= parseFloat(value)
    if (this.inventory.isValid) {
      this.IsUpateNumberEntry = true;
    }
    this.inventory.quantity = parseInt(value) || 0;

  }

  closeDialog() {
    this.dialog.close();
  }

  addItem() {
    this.saving = true;
    this.detailsService.addTransactionInventory(this.employeeNumber, this.transactionNumber, this.inventory.department, -1, -1, this.inventory.number, this.inventory.quantity).subscribe((d: any) => {
      this.inventory.selectedInventory.push({ name: this.inventory.name, number: this.inventory.number, quantity: this.inventory.quantity, department: this.inventory.department });
      // this.$root.$broadcast('inventory.modified', { });
      // $('#inventoryModal_' + this.transactionNumber + this.id).modal('hide');
      this.saving = false;
      this.closeInventoryDialog();
    });
  }

  closeInventoryDialog() {
    this.dialog.close('inventorydone');
  }

}
