import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutsModule } from './layouts/layouts.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './core/guards/auth.guard';
import { FormsModule } from '@angular/forms';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { MaterialModule } from './material.module';
import { SecurityInterceptor } from './core/interceptors/security.interceptor';
import { Router } from '@angular/router';
import { CustomToastComponent } from './shared/custom-toast/custom-toast.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './store/effects/user.effects';
// import { ProfileEffects } from './store/effects/profile.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { IsRmsUserGuard } from './core/guards/is-rms-user.guard';
// import { CustomToastComponent } from './shared/custom-toast/custom-toast.component';

// Example function to fetch the dynamic prefix
async function fetchDynamicPrefix(): Promise<string> {
  let currentTenant = localStorage.getItem('tenant');
  if (currentTenant !== null) {
    let tenant = JSON.parse(currentTenant);
    return tenant?.Name;
  } else {
    return 'kiosk';
  }
}

export function initializeApp(router: Router): () => Promise<void> {
  return async () => {
    // Logic to determine the dynamic prefix (e.g., based on language)
    const dynamicPrefix = await fetchDynamicPrefix();

    // Get the current URL
    const currentUrl = router.url;

    // Check if the current URL is the language selection page
    const isLanguageSelectionPage = currentUrl === '/auth/select-rc';

    // Get the base element
    const baseElement = document.querySelector('base');

    // Ensure the base element exists before manipulating it
    if (baseElement && !isLanguageSelectionPage) {
      baseElement.setAttribute('href', dynamicPrefix);
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    CustomToastComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers), // Register combined reducers
    EffectsModule.forRoot([UserEffects]), // Register effects
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      // logOnly: environment.production, // Restrict to log-only mode in production
      logOnly: true, // Restrict to log-only mode in production
    }),
    LayoutsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      toastComponent: CustomToastComponent,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
      maxOpened: 1,
      progressBar: true,
      closeButton: true,
      disableTimeOut: false,
      tapToDismiss: true,
    }),
    FormsModule,
    MaterialModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'This item is actually loading...' }),
  ],
   providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeApp,
    //   deps: [Router],
    //   multi: true
    // },
    AuthGuard,
    IsRmsUserGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule { }
