<div class="toaster">
  <div class="toast-icon" [class]="options.toastClass">
    <ng-container *ngIf="toastType === 'toast-success'">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      </span>
    </ng-container>
    <ng-container *ngIf="toastType === 'toast-info'">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-8"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
          />
        </svg>
      </span>
    </ng-container>
    <ng-container *ngIf="toastType === 'toast-error'">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z"
          />
        </svg>
      </span>
    </ng-container>
    <ng-container *ngIf="toastType === 'toast-warning'">
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
          />
        </svg>
      </span>
    </ng-container>
  </div>
  <div class="toast-details pr-10">
    @if(type === 'toast-upgrade') {
    <div class="">
      <!-- <div
        *ngIf="title"
        [class]="options.titleClass"
        [attr.aria-label]="title"
        class="custom-title"
      >
        {{ title }}
      </div> -->
      <div
        *ngIf="title && options.enableHtml"
        role="alert"
        aria-live="polite"
        class="custom-title mt-2 mb-3"
        [innerHTML]="title"
      ></div>
      <!-- <div
        *ngIf="message && options.enableHtml"
        role="alert"
        aria-live="polite"
        class="custom-message mb-4"
        [class]="options.messageClass"
        [innerHTML]="message"
      ></div> -->
      <div class="custom-message mb-4" [class]="options.messageClass">
        <p>
          <span
            class="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-3 py-2 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            (click)="yesUpgrade()"
            >Yes</span
          >
          <span
            class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-3 py-2 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            (click)="noUpgrade()"
            >No</span
          >
          <span
            class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-3 py-2 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            style="background-color: #35717c !important"
            (click)="askAgainLaterUpgrade()"
            >Ask again later</span
          >
        </p>
      </div>
    </div>
    } @else if(type === 'toast-upgrade-recently') {
    <div>
      <!-- <div
          *ngIf="title"
          [class]="options.titleClass"
          [attr.aria-label]="title"
          class="custom-title"
        >
          {{ title }}
        </div> -->
      <div
        *ngIf="title && options.enableHtml"
        role="alert"
        aria-live="polite"
        class="custom-title mt-2 mb-3"
        [innerHTML]="title"
      ></div>
      <!-- <div
          *ngIf="message && options.enableHtml"
          role="alert"
          aria-live="polite"
          class="custom-message mb-4"
          [class]="options.messageClass"
          [innerHTML]="message"
        ></div> -->
      <div class="custom-message mb-4" [class]="options.messageClass">
        <p>
          <span
            class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-3 py-2 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            style="background-color: #35717c !important"
            (click)="checkWhatsNew()"
            >Check out what's New!</span
          >
        </p>
      </div>
    </div>
    } @else if(type === 'toast-review-update') {
    <div>
      <div
        *ngIf="title && options.enableHtml"
        role="alert"
        aria-live="polite"
        class="custom-title mt-2 mb-3"
        [innerHTML]="title"
      ></div>

      <div class="custom-message mb-4" [class]="options.messageClass">
        <p>
          <span
            class="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-3 py-2 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            style="background-color: #35717c !important"
            (click)="ReviewPageReload()"
            >Reload</span
          >
        </p>
      </div>
    </div>
    } @else {
    <div>
      <div
        *ngIf="title"
        [class]="options.titleClass"
        [attr.aria-label]="title"
        class="custom-title"
      >
        {{ title }}
      </div>
      <div
        *ngIf="message && options.enableHtml"
        role="alert"
        aria-live="polite"
        class="custom-message"
        [class]="options.messageClass"
        [innerHTML]="message"
      ></div>
      <div
        *ngIf="message && !options.enableHtml"
        role="alert"
        aria-live="polite"
        class="custom-message"
        [class]="options.messageClass"
        [attr.aria-label]="message"
      >
        {{ message }}
      </div>
    </div>
    }
  </div>
  <div class="close-icon !absolute right-[14px] top-[50%] -mt-[16px]">
    <a *ngIf="options.closeButton" (click)="remove()">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
      </svg>      
    </a>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
