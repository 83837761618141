import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: '[toUppercase]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => UppercaseDirective),
        multi: true
    }]
})
export class UppercaseDirective implements ControlValueAccessor {
    private onChange: (val: string) => void = () => { };
    private onTouched: () => void = () => { };
    private value: string = '';

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) { }

    @HostListener('input', ['$event.target.value', '$event.target.selectionStart'])
    onInput(value: string, cursorPosition: number): void {
        const uppercaseValue = value.toUpperCase();

        // Update the value only if it has changed
        if (this.value !== uppercaseValue) {
            this.updateTextInput(uppercaseValue, cursorPosition);
        }
    }

    private updateTextInput(value: string, cursorPosition: number): void {
        const element = this.elementRef.nativeElement;

        // Update the input value
        this.renderer.setProperty(element, 'value', value);

        // Restore the cursor position after the update
        element.setSelectionRange(cursorPosition, cursorPosition);

        // Check if the field is readonly; if so, skip updating value
        if (!this.elementRef.nativeElement.readOnly) {
            // Propagate the change if necessary
            this.onChange(value);
        }
        // Propagate the change if necessary
        // this.onChange(value);

        // Update internal value
        this.value = value;
    }

    // ControlValueAccessor Interface
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
    }

    writeValue(value: any): void {
        value = value ? String(value) : '';

        this.updateTextInput(value, this.elementRef.nativeElement.selectionStart || 0);
    }
}
