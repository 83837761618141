import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.css']
})
export class NotificationPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef : MatDialogRef<NotificationPopupComponent>,
  ){}
  notificationList: any;

  ngOnInit():void{
    this.notificationList = this.data;
  }

  //Close Dialog box
  closeDialog() {
    this.dialogRef.close();
  }

}
