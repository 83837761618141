import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, interval } from 'rxjs'
import { throttle } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private idleSubject = new Subject<boolean>();
  // private timeout = 600;
  // private timeout = 1600;
  private timeout = 999999999;
  private lastActivity!: Date;
  private idleCheckInterval = 10; // 10 seconds
  private idleSubscription?: Subscription;

  constructor() {
    let userData: any = localStorage.getItem('user');
    userData = JSON.parse(userData);
    this.timeout = userData.timeoutValue;
    this.resetTimer();
    this.startWatching();
  }

  get idleState(): Observable<boolean> {
    return this.idleSubject.asObservable();
  }

  private startWatching() {
    console.log("timeout: " + this.timeout);
    this.idleSubscription = interval(this.idleCheckInterval * 1000).pipe(
      throttle(() => interval(1000))
    ).subscribe(() => {
      let now = new Date();
      if (now.getTime() - this.lastActivity?.getTime() > this.timeout * 1000) {
        this.idleSubject.next(true);
      }
    });
  }

  resetTimer() {
    this.lastActivity = new Date();
    this.idleSubject.next(false);
  }

  stopWatching() {
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }

}
