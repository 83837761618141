import { ActionReducerMap } from '@ngrx/store';
import * as fromProfile from './reducers/profile.reducer';
import * as fromUser from './reducers/user.reducer';
import { ProfileState } from './state/profile.state';
import { UserState } from './state/user.state';

export interface AppState {
  profile: ProfileState;
  user: UserState;
}

export const reducers: ActionReducerMap<AppState> = {
  profile: fromProfile.profileReducer,
  user: fromUser.userReducer,
};