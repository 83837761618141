<div
  class="font-avertareguler w-full bg-white h-screen p-10 dark:bg-gray-900 space-y-6 relative"
>
  <div class="flex">
    <h2 class="text-[#102030] text-[24px] font-avertaBold dark:text-white">
      Calendar
    </h2>
  </div>
  <div
    class="space-y-4 popBody scrollbar-thin scrollbar-thumb-[#000733]/20 scrollbar-track-transparent overflow-y-scroll"
  >
    <div class="font-avertareguler space-y-3 rounded-xl">
      <app-calendar
        [showSelectedDate]="true"
        [selectedDate]="selectedDate"
        (dateSelected)="onDateSelected($event)"
        (monthChanged)="onMonthChanged($event)"
        (yearChanged)="onYearChanged($event)"
      ></app-calendar>
    </div>
  </div>
  <div class="flex justify-between items-center w-full popFooter">
    <button
      (click)="closeDialog()"
      class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </span>
      <span>Close</span>
    </button>
    <button
      class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#010D58] text-[#ffffff] flex items-center"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M4.5 12.75l6 6 9-13.5"
          />
        </svg>
      </span>
      <span>SAVE</span>
    </button>
  </div>
  <button
    (click)="closeDialog()"
    class="absolute right-[5px] top-[-15px] h-8 w-8 rounded-full flex justify-center items-center bg-gray-100 text-red"
  >
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </span>
  </button>
</div>
