import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RcService } from '../services/rc.service';

@Injectable({
  providedIn: 'root',
})
export class IsRmsUserGuard implements CanActivate {
  constructor(
    private router: Router,
    private rcService: RcService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const storedUser = localStorage.getItem('user');
    const userData = storedUser ? JSON.parse(storedUser) : null;
    const isRMSUser = userData?.IsRmsUser;
    const rmsUrl = userData?.RMSUrl;

    if (isRMSUser && rmsUrl) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      // Redirect to external RMS URL if IsRmsUser is true
      window.location.href = rmsUrl;
      return of(false); // Prevent further route activation
    } else if (isRMSUser === false || isRMSUser === undefined) {
      // Optional tenant handling
      const tenant = this.rcService.getTenant();
      if (tenant) {
        this.router.navigate([`/${tenant}`]);
        return of(false); // Prevent further route activation
      } else {
        return of(true); // Allow route activation if no tenant is provided
      }
    }
    
    return of(true); // Allow route activation in other cases
  }
}
