<div class="font-avertareguler w-full bg-white h-screen p-14 pb-28 dark:bg-gray-900 space-y-6 relative">
  <div class="flex">
    <h2 class="text-[#102030] text-[24px] font-avertaBold dark:text-white">Entry Details - 601080</h2>
  </div>
  <div>
    <mat-tab-group>
      <mat-tab label="Details">
        <div class="py-8">
          <table class="pop-table w-full">
            <tbody>
              <tr class="items-center">
                <td><span class="font-avertaBold">Email Configuration:</span></td>
                <td><span class="text-[#707070]">Crack Sealing</span></td>
                <td>
                  <span class="flex justify-end">
                    <a href="" class="text-blue-700 text-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>                      
                    </a>
                  </span>
                </td>
              </tr>
              <tr class="items-center">
                <td><span class="font-avertaBold">Project:</span></td>
                <td><span class="text-[#707070]">11 Mile Road Bridge Over 1-94 (074)</span></td>
                <td>
                  <span class="flex justify-end">
                    <a href="" class="text-blue-700 text-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>                      
                    </a>
                  </span>
                </td>
              </tr>
              <tr class="items-center">
                <td><span class="font-avertaBold">Activity:</span></td>
                <td><span class="text-[#707070]">Employees Life Insurance (931)</span></td>
                <td>
                  <span class="flex justify-end">
                    <a href="" class="text-blue-700 text-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>                      
                    </a>
                  </span>
                </td>
              </tr>
              <tr class="items-center">
                <td><span class="font-avertaBold">Work Order:</span></td>
                <td><span class="text-[#707070]">25 MILE RD & MOUND Row Acquisition Costs (00100G)</span></td>
                <td>
                  <span class="flex justify-end">
                    <a href="" class="text-blue-700 text-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>                      
                    </a>
                  </span>
                </td>
              </tr>
              <tr class="items-center">
                <td><span class="font-avertaBold">Account:</span></td>
                <td><span class="text-[#707070]">Maintenance</span></td>
                <td>
                  <span class="flex justify-end">
                    <a href="" class="text-blue-700 text-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>                      
                    </a>
                  </span>
                </td>
              </tr>
              <tr class="items-center">
                <td><span class="font-avertaBold">Pay Type:</span></td>
                <td><span class="text-[#707070]">Overtime</span></td>
                <td>
                  <span class="flex justify-end">
                    <a href="" class="text-blue-700 text-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>                      
                    </a>
                  </span>
                </td>
              </tr>
              <tr class="items-center">
                <td><span class="font-avertaBold">Hours/Shift:</span></td>
                <td><span class="text-[#707070]">23/Day</span></td>
                <td>
                  <span class="flex justify-end">
                    <a href="" class="text-blue-700 text-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>                      
                    </a>
                  </span>
                </td>
              </tr>
              <tr class="items-center">
                <td><span class="font-avertaBold">Job Classification::</span></td>
                <td><span class="text-[#707070]">Administrator</span></td>
                <td>
                  <span class="flex justify-end">
                    <a href="" class="text-blue-700 text-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>                      
                    </a>
                  </span>
                </td>
              </tr>
              <tr class="items-center">
                <td><span class="font-avertaBold">Comments:</span></td>
                <td><span class="text-[#707070]">Test Admin Comment</span></td>
                <td>
                  <span class="flex justify-end">
                    <a href="" class="text-blue-700 text-right">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>                      
                    </a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-tab>
      <mat-tab label="Equipment">
        <div class="py-8">
          <table class="equipmentTable w-full">
            <thead>
              <tr class="text-[13px] text-left rounded-lg">
                <th class="rounded-l-xl bg-[#EEF4FE]">Equipment</th>
                <th class="bg-[#EEF4FE]">Hours</th>
                <th class="text-right bg-[#EEF4FE] rounded-r-xl">Action</th>
              </tr>
            </thead>
            <span class="block h-3"></span>
            <tbody class="font-avertaSemibold">
              <tr>
                <td><span class="text-sm">Equipment TesT (000307)</span></td>
                <td><span class="text-sm">1.00</span></td>
                <td>
                  <span class="flex justify-end space-x-2">
                    <a href="" class="text-yellow-500 text-right text-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>                      
                    </a>
                    <a href="" class="text-red-600 text-right text-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>                                          
                    </a>
                  </span>
                </td>
              </tr>
              <tr>
                <td><span class="text-sm">Ub Blades Underbody Blades (270905)</span></td>
                <td><span class="text-sm">2.00</span></td>
                <td>
                  <span class="flex justify-end space-x-2">
                    <a href="" class="text-yellow-500 text-right text-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>                      
                    </a>
                    <a href="" class="text-red-600 text-right text-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>                                          
                    </a>
                  </span>
                </td>
              </tr>
              <tr>
                <td><span class="text-sm">No Description Found (000001)</span></td>
                <td><span class="text-sm">5.00</span></td>
                <td>
                  <span class="flex justify-end space-x-2">
                    <a href="" class="text-yellow-500 text-right text-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>                      
                    </a>
                    <a href="" class="text-red-600 text-right text-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>                                          
                    </a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex justify-end my-3">
            <button class="py-2 px-4 space-x-1 rounded-full text-[16px] bg-blue-700 text-white flex items-center">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>                     
              </span>
              <span>Add</span>
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Inventory">
        <div class="py-8">
          <table class="equipmentTable w-full">
            <thead>
              <tr class="text-[13px] text-left rounded-lg">
                <th class="rounded-l-xl bg-[#EEF4FE]">Department</th>
                <th class="bg-[#EEF4FE]">Inventory</th>
                <th class="bg-[#EEF4FE]">Quantity</th>
                <th class="text-right bg-[#EEF4FE] rounded-r-xl">Action</th>
              </tr>
            </thead>
            <span class="block h-3"></span>
            <tbody class="font-avertaSemibold">
              <tr>
                <td><span class="text-sm">Clinton Twp. Service Center</span></td>
                <td><span class="text-sm">1/2" x 10' Re-Rod (0075000)</span></td>
                <td><span class="text-sm">2</span></td>
                <td>
                  <span class="flex justify-end">
                    <a href="" class="text-red-600 text-right text-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>                                          
                    </a>
                  </span>
                </td>
              </tr>
              <tr>
                <td><span class="text-sm">Stockroom</span></td>
                <td><span class="text-sm">"D" Battery (1758376)</span></td>
                <td><span class="text-sm">1</span></td>
                <td>
                  <span class="flex justify-end">
                    <a href="" class="text-red-600 text-right text-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>                                          
                    </a>
                  </span>
                </td>
              </tr>
              <tr>
                <td><span class="text-sm">Finance & M.I.S.</span></td>
                <td><span class="text-sm">UPM - CP #6 (0013201)</span></td>
                <td><span class="text-sm">5</span></td>
                <td>
                  <span class="flex justify-end">
                    <a href="" class="text-red-600 text-right text-sm">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                      </svg>                                          
                    </a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex justify-end my-3">
            <button class="py-2 px-4 space-x-1 rounded-full text-[16px] bg-blue-700 text-white flex items-center">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>                     
              </span>
              <span>Add</span>
            </button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="flex justify-between items-center w-full popFooter px-14 py-7 absolute inset-x-0 bottom-0 left-0 bg-white z-10">
    <button class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center dark:bg-white/20 dark:text-white">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>        
      </span>
      <span>Close</span>
    </button>
    <button class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#FB0505] text-[#ffffff] flex items-center">
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
        </svg>                   
      </span>
      <span>Delete</span>
    </button>
  </div>
</div>


