import { RcService } from 'src/app/core/services/rc.service';
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
    // selector: 'a[appRc]'
    selector: '[appRc]'
    // selector: 'a'
})
export class RcDirective {
    constructor(
        private router: Router,
        private rcService: RcService,
        private el: ElementRef,
        private renderer: Renderer2
    ) { }

    @HostListener('click', ['$event'])
    onClick(event: MouseEvent) {
        const href = this.el.nativeElement.getAttribute('href');
        const routerLink = this.el.nativeElement.getAttribute('routerLink');
        if (href && href.startsWith('/')) {
            // Modify the href to include the RC prefix
            event.preventDefault();
            this.navigateWithRcPrefix(href);
        } else if (routerLink && routerLink.startsWith('/')) {
            // Modify the routerLink to include the RC prefix
            event.preventDefault();
            this.navigateWithRcPrefix(routerLink);
        }
    }

    private navigateWithRcPrefix(link: string): void {
        const rcPrefix = this.rcService.getTenant() || ''; // Use provided RC prefix or empty string if not provided
        this.router.navigateByUrl(`/${rcPrefix}${link}`);
    }
}
