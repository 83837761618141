<app-header></app-header>
<app-sidebar></app-sidebar>
<!-- <div class="min-h-screen bg-[url('assets/images/summer-bg.jpg')] pr-24 pt-24 pb-4 pl-4 relative z-20 bg-fixed bg-center bg-cover"> -->
<div
  id="layout"
  class="min-h-screen pl-2 pr-2 pb-2 md:pr-24 pt-[72px] md:pt-24 lg:pb-4 lg:pl-4 lg:pr-24 relative z-20 bg-fixed bg-top bg-cover"
  [ngClass]="{
        'bg-summer': theme === 'summer',
        'bg-winter': !theme || theme === 'winter',
        'bg-fall': !theme || theme === 'fall',
        'bg-spring': !theme || theme === 'spring',
     }"
>
  <div
    class="before:block before:absolute before:left-0 before:top-0 before:h-full before:w-full before:bg-black/10 before:inset-1 before:-z-[10] dark:before:bg-black/60 duration-200"
  >
    <router-outlet class="z-10"></router-outlet>
  </div>
</div>
<!-- <app-footer></app-footer> -->

<div
  *ngIf="isModalOpen"
  class="relative z-50"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
  data-modal-backdrop="static"
>
  <!-- Background overlay -->
  <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity pointer-events-none"
  ></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto pointer-events-auto">
    <div
      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
    >
      <div
        class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
      >
        <div
          class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-gray-900 dark:text-white"
        >
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <svg
                class="h-6 w-6 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3
                class="text-base font-semibold leading-6 text-gray-900 dark:text-white"
                id="modal-title"
              >
                Session Expiring...
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  You have been idle for a while. Do you want to stay logged in?
                </p>
              </div>
            </div>
          </div>

          <!-- Progress bar -->
          <!-- <div class="mt-4" *ngIf="progressPercentage > 0">
            <div class="h-2 bg-gray-200 rounded-full">
              <div
                class="h-full bg-blue-600 rounded-full"
                [style.width.%]="progressPercentage"
              ></div>
            </div>
          </div> -->

          <div class="mt-4" *ngIf="progressPercentage > 0">
            <div class="h-2 bg-gray-200 rounded-full relative overflow-hidden">
              <!-- Progress bar -->
              <div
                class="absolute inset-0 bg-blue-600 transform origin-left"
                [style.transform]="'scaleX(' + progressPercentage / 100 + ')'"
              ></div>
            </div>
          </div>
        </div>
        <div
          class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-gray-900"
        >
          <button
            type="button"
            class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm dark:bg-gray-950 dark:hover:bg-gray-700 hover:bg-blue-500 sm:ml-3 sm:w-auto"
            (click)="logout()"
          >
            Logout
          </button>
          <button
            type="button"
            (click)="stay()"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-white/20 dark:text-white px-3 py-2 text-sm font-semibold text-gray-900 dark:hover:bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          >
            Stay
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="isModalOpenCloseConcurrentLogin"
  class="relative z-50"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
  data-modal-backdrop="static"
>
  <!-- Background overlay -->
  <div
    class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity pointer-events-none"
  ></div>
  <div class="fixed inset-0 z-10 w-screen overflow-y-auto pointer-events-auto">
    <div
      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
    >
      <div
        class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
      >
        <div
          class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 dark:bg-gray-900 dark:text-white"
        >
          <div class="sm:flex sm:items-start">
            <div
              class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
            >
              <svg
                class="h-6 w-6 text-red-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  We have detected another login attempt for your credentials.
                  As a precaution, both sessions have been logged out. Please
                  log in again with only one active login at a time.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-gray-900"
        >
          <button
            type="button"
            (click)="CloseConcurrentLogin()"
            class="mt-3 inline-flex w-full justify-center rounded-md bg-white dark:bg-white/20 dark:text-white px-3 py-2 text-sm font-semibold text-gray-900 dark:hover:bg-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
