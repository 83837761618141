<app-spinner></app-spinner>
<div
  class="font-avertareguler w-full h-screen px-2 py-10 md:py-10 md:p-4 bg-white/[67%] dark:bg-slate-900/60 space-y-4 relative"
>
  <div class="flex">
    <h2
      class="text-[#102030] text-[16px] md:text-[20px] font-avertaBold dark:text-white"
    >
      Update Employee Password
    </h2>
  </div>
  <form [formGroup]="itPasswordForm" (ngSubmit)="updatePassword()" class="">
    <div
      class="space-y-4 popBody py-1 scrollbar-thin scrollbar-thumb-[#000733]/20 scrollbar-track-transparent overflow-y-scroll"
    >
      <div class="grid grid-cols-12 items-center">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
          >
            Employee <span class="text-red-600">*</span>
          </span>
          <span class="text-xs text-gray-500 hidden md:block"
            >Select Employee
          </span>
        </div>
        <div class="col-span-12 md:col-span-8">
          <!-- <mat-form-field class="w-full">
            <mat-label>Select Employee</mat-label>
            <mat-select formControlName="employee">
              <mat-option *ngFor="let emp of employees" [value]="emp">
                {{ emp.LastName }}, {{ emp.FirstName }} ({{
                  emp.EmployeeNumber
                }})
              </mat-option>
            </mat-select>
          </mat-form-field> -->
          <div class="w-full">
            <select
              id="employee"
              formControlName="employee"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option selected>Select Employee</option>
              <option
                *ngFor="let emp of employees"
                [value]="emp.EmployeeNumber"
              >
                {{ emp.LastName }}, {{ emp.FirstName }} ({{
                  emp.EmployeeNumber
                }})
              </option>
            </select>
          </div>
          <div
            *ngIf="
              itPasswordForm.get('employee')?.hasError('required') &&
              (itPasswordForm.get('employee')?.touched || submitted)
            "
            class="text-red-400 text-xs"
          >
            Please select employee.
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 items-start">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
          >
            New Password <span class="text-red-600">*</span>
          </span>
          <span class="text-xs text-gray-500 hidden md:block"
            >Enter New Password</span
          >
        </div>
        <div class="col-span-12 md:col-span-8">
          <div class="w-full">
            <app-reactive-keyboard
              formControlName="newPassword"
              name="newPassword"
              placeholder="Enter your new password"
              inputType="password"
              [class]="
                'bg-white duration-200 w-full border border-[#E2E2E2] py-2 px-4 rounded-lg max-[640px]:rounded-r-lg sm:rounded-r-none rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white'
              "
              [keyButtonClass]="
                'hidden sm:block keyboard-btn relative py-2 px-3 space-x-1 rounded-r-lg border border-l-0 border-[#E2E2E2] text-[16px] bg-white flex items-center dark:bg-gray-700 duration-200 dark:border-gray-500'
              "
              [passwordButtonClass]="
                'keyboard-btn relative py-2 px-3 space-x-1 border border-l-0 border-[#E2E2E2] text-[16px] bg-white flex items-center dark:bg-gray-700 duration-200 dark:border-gray-500'
              "
            ></app-reactive-keyboard>
          </div>
          <!-- <mat-form-field class="w-full">
            <mat-label>Enter New Password</mat-label>
            <input
              matInput
              [type]="hideNewPassword ? 'password' : 'text'"
              formControlName="newPassword"
              required
            />
            <mat-icon matSuffix (click)="toggleVisibility('new')">{{
              hideNewPassword ? "visibility" : "visibility_off"
            }}</mat-icon>
          </mat-form-field> -->
          <div
            class="text-xs flex items-center space-x-1"
            [ngClass]="{
              'text-red-400' : itPasswordForm.get('newPassword')?.hasError('minLength'),
              'text-green-400' : !itPasswordForm.get('newPassword')?.hasError('minLength'),
            }"
          >
            <ng-container
              *ngIf="
                itPasswordForm.get('newPassword')?.hasError('minLength');
                else validIcon
              "
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-container>
            <ng-template #validIcon>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-template>

            <span>
              {{ passwordErrors.minLength }}
            </span>
          </div>

          <div
            class="text-xs flex items-center space-x-1"
            [ngClass]="{
              'text-red-400' : itPasswordForm.get('newPassword')?.hasError('uppercase'),
              'text-green-400' : !itPasswordForm.get('newPassword')?.hasError('uppercase'),
            }"
          >
            <ng-container
              *ngIf="
                itPasswordForm.get('newPassword')?.hasError('uppercase');
                else validIcon
              "
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-container>
            <ng-template #validIcon>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-template>

            <span>
              {{ passwordErrors.uppercase }}
            </span>
          </div>

          <div
            class="text-xs flex items-center space-x-1"
            [ngClass]="{
              'text-red-400' : itPasswordForm.get('newPassword')?.hasError('lowercase'),
              'text-green-400' : !itPasswordForm.get('newPassword')?.hasError('lowercase'),
            }"
          >
            <ng-container
              *ngIf="
                itPasswordForm.get('newPassword')?.hasError('lowercase');
                else validIcon
              "
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-container>
            <ng-template #validIcon>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-template>

            <span>
              {{ passwordErrors.lowercase }}
            </span>
          </div>

          <div
            class="text-xs flex items-center space-x-1"
            [ngClass]="{
              'text-red-400' : itPasswordForm.get('newPassword')?.hasError('digit'),
              'text-green-400' : !itPasswordForm.get('newPassword')?.hasError('digit'),
            }"
          >
            <ng-container
              *ngIf="
                itPasswordForm.get('newPassword')?.hasError('digit');
                else validIcon
              "
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-container>
            <ng-template #validIcon>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-template>

            <span>
              {{ passwordErrors.digit }}
            </span>
          </div>

          <div
            class="text-xs flex items-center space-x-1"
            [ngClass]="{
              'text-red-400' : itPasswordForm.get('newPassword')?.hasError('specialCharacter'),
              'text-green-400' : !itPasswordForm.get('newPassword')?.hasError('specialCharacter'),
            }"
          >
            <ng-container
              *ngIf="
                itPasswordForm.get('newPassword')?.hasError('specialCharacter');
                else validIcon
              "
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-container>
            <ng-template #validIcon>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-template>

            <span>
              {{ passwordErrors.specialCharacter }}
            </span>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 items-center">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
          >
            Re-Enter New Password <span class="text-red-600">*</span>
          </span>
          <span class="text-xs text-gray-500 hidden md:block"
            >Re-Enter New Password</span
          >
        </div>
        <div class="col-span-12 md:col-span-8">
          <!-- <mat-form-field class="w-full">
            <mat-label>Re-Enter New Password</mat-label>
            <input
              matInput
              [type]="hideConfirmPassword ? 'password' : 'text'"
              formControlName="confirmPassword"
              required
            />
            <mat-icon matSuffix (click)="toggleVisibility('confirm')">{{
              hideConfirmPassword ? "visibility" : "visibility_off"
            }}</mat-icon>
          </mat-form-field> -->
          <div class="w-full">
            <app-reactive-keyboard
              formControlName="confirmPassword"
              name="confirmPassword"
              placeholder="Re-Enter New Password"
              inputType="password"
              [class]="
                'bg-white duration-200 w-full border border-[#E2E2E2] py-2 px-4 rounded-lg max-[640px]:rounded-r-lg sm:rounded-r-none rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white'
              "
              [keyButtonClass]="
                'hidden sm:block keyboard-btn relative py-2 px-3 space-x-1 rounded-r-lg border border-l-0 border-[#E2E2E2] text-[16px] bg-white flex items-center dark:bg-gray-700 duration-200 dark:border-gray-500'
              "
              [passwordButtonClass]="
                'keyboard-btn relative py-2 px-3 space-x-1 border border-l-0 border-[#E2E2E2] text-[16px] bg-white flex items-center dark:bg-gray-700 duration-200 dark:border-gray-500'
              "
            ></app-reactive-keyboard>
          </div>
          <div
            *ngIf="
              itPasswordForm.get('confirmPassword')?.hasError('required') &&
              (itPasswordForm.get('confirmPassword')?.touched || submitted)
            "
            class="text-red-400 text-xs"
          >
            Confirm Password is required.
          </div>
          <div
            *ngIf="
              itPasswordForm
                .get('confirmPassword')
                ?.hasError('passwordMismatch')
            "
            class="text-red-400 text-xs"
          >
            New Password and Confirm Password must match.
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between items-center w-full popFooter">
      <button
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center dark:bg-white/20 dark:text-white"
        (click)="closePasswordDialog()"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
        <span>Close</span>
      </button>
      <button
        type="submit"
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#010D58] text-[#ffffff] flex items-center dark:bg-gray-950"
        [disabled]="itPasswordForm.invalid"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </span>
        <span>Submit</span>
      </button>
    </div>
  </form>

  <button
    (click)="closePasswordDialog()"
    type="button"
    class="absolute right-[5px] top-[-15px] h-8 w-8 rounded-full flex justify-center items-center bg-gray-100 text-red dark:bg-white/20 dark:text-white"
  >
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </span>
  </button>
</div>
