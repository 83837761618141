<app-spinner></app-spinner>
<div
  class="font-avertareguler w-full h-screen px-2 py-10 md:py-10 md:p-4 bg-white/[67%] dark:bg-slate-900/60 space-y-4 relative"
>
  <div class="flex">
    <h2
      class="text-[#102030] text-[16px] md:text-[20px] font-avertaBold dark:text-white"
    >
      {{ forcePassword ? "Update Password" : "Change Password" }}
    </h2>
  </div>
  <form [formGroup]="updatePasswordForm" (ngSubmit)="updatePassword()" class="">
    <div
      class="space-y-4 popBody py-1 scrollbar-thin scrollbar-thumb-[#000733]/20 scrollbar-track-transparent overflow-y-scroll"
    >
      <div class="grid grid-cols-12 items-center">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
          >
            Current Password <span class="text-red-600">*</span>
          </span>
          <span class="text-xs text-gray-500 hidden md:block"
            >Enter old Password</span
          >
        </div>
        <div class="col-span-12 md:col-span-8">
          <div class="flex">
            <div class="w-full">
              <app-reactive-keyboard
                formControlName="currentPassword"
                name="currentPassword"
                placeholder="Enter your current password"
                inputType="password"
                [class]="
                  'bg-white duration-200 w-full border border-[#E2E2E2] py-2 px-4 rounded-lg max-[640px]:rounded-r-lg sm:rounded-r-none rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white'
                "
                [keyButtonClass]="
                  'hidden sm:block keyboard-btn relative py-2 px-3 space-x-1 rounded-r-lg border border-l-0 border-[#E2E2E2] text-[16px] bg-white flex items-center dark:bg-gray-700 duration-200 dark:border-gray-500'
                "
                [passwordButtonClass]="
                  'keyboard-btn relative py-2 px-3 space-x-1 border border-l-0 border-[#E2E2E2] text-[16px] bg-white flex items-center dark:bg-gray-700 duration-200 dark:border-gray-500'
                "
              ></app-reactive-keyboard>
              <div
                *ngIf="
                  updatePasswordForm
                    .get('currentPassword')
                    ?.hasError('required') &&
                  (updatePasswordForm.get('currentPassword')?.touched ||
                    submitted)
                "
                class="text-red-400 text-xs"
              >
                Current Password is required.
              </div>
              <!-- &&
              (updatePasswordForm.get('currentPassword')?.touched ||
                submitted) -->
              <!-- <div
                class="text-red-400 text-xs"
                *ngIf="
                  updatePasswordForm
                    .get('currentPassword')
                    ?.hasError('invalidCurrentPassword') &&
                  updatePasswordForm.get('currentPassword')?.dirty &&
                  !updatePasswordForm
                    .get('currentPassword')
                    ?.hasError('required') &&
                  (updatePasswordForm.get('currentPassword')?.touched ||
                    submitted)
                "
              >
                Invalid current password.
              </div> -->
              <div
                class="text-red-400 text-xs"
                *ngIf="
                  updatePasswordForm
                    .get('currentPassword')
                    ?.hasError('invalidCurrentPassword') &&
                  !updatePasswordForm
                    .get('currentPassword')
                    ?.hasError('required')
                "
              >
                Invalid current password.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 items-start">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
          >
            New Password <span class="text-red-600">*</span>
          </span>
          <span class="text-xs text-gray-500 hidden md:block"
            >Enter New Password</span
          >
        </div>
        <div class="col-span-12 md:col-span-8">
          <div class="flex">
            <div class="w-full">
              <!-- <mat-form-field class="w-full">
                <mat-label>Enter New Password</mat-label>
                <input
                  matInput
                  [type]="hideNewPassword ? 'password' : 'text'"
                  formControlName="newPassword"
                  required
                />
                <mat-icon matSuffix (click)="toggleVisibility('new')">{{
                  hideNewPassword ? "visibility" : "visibility_off"
                }}</mat-icon>
              </mat-form-field> -->
              <app-reactive-keyboard
                formControlName="newPassword"
                name="newPassword"
                placeholder="Enter your new password"
                inputType="password"
                [class]="
                  'bg-white duration-200 w-full border border-[#E2E2E2] py-2 px-4 rounded-lg max-[640px]:rounded-r-lg sm:rounded-r-none rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white'
                "
                [keyButtonClass]="
                  'hidden sm:block keyboard-btn relative py-2 px-3 space-x-1 rounded-r-lg border border-l-0 border-[#E2E2E2] text-[16px] bg-white flex items-center dark:bg-gray-700 duration-200 dark:border-gray-500'
                "
                [passwordButtonClass]="
                  'keyboard-btn relative py-2 px-3 space-x-1 border border-l-0 border-[#E2E2E2] text-[16px] bg-white flex items-center dark:bg-gray-700 duration-200 dark:border-gray-500'
                "
              ></app-reactive-keyboard>
              <div
                class="text-xs flex items-center space-x-1"
                [ngClass]="{
              'text-red-400' : updatePasswordForm.get('newPassword')?.hasError('minLength'),
              'text-green-400' : !updatePasswordForm.get('newPassword')?.hasError('minLength'),
            }"
              >
                <ng-container
                  *ngIf="
                    updatePasswordForm
                      .get('newPassword')
                      ?.hasError('minLength');
                    else validIcon
                  "
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </span>
                </ng-container>
                <ng-template #validIcon>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  </span>
                </ng-template>

                <span>
                  {{ passwordErrors.minLength }}
                </span>
              </div>
            </div>
            <!-- <div>
              <button
                (click)="keyboardNewPassword?.toggleVisibility('newPassword')"
                class="keyboard-btn border-l relative bg-black-600 bg-white/30 py-2.5 px-2 rounded-r-lg inline-block"
                [ngClass]="{
                  'bg-yellow-600 after:absolute after:w-3 after:h-3 after:border-white after:left-1  after:z-10 after:border-l-transparent after:border-r-transparent after:border-b-8 after:border-l-8 after:border-r-8':
                    keyboardNewPassword?.activeFieldId === 'newPassword',
                  'after:-bottom-[1px]':
                    keyboardNewPassword?.keyboardPosition === 'below',
                  'after:-top-[12px]':
                    keyboardNewPassword?.keyboardPosition === 'above'
                }"
                data-field-id="newPassword"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
              </button>
              <app-keyboard
                #keyboardNewPassword
                (keyPressed)="
                  updatePasswordForm.get('newPassword')?.setValue($event)
                "
                keyboardType="computer"
                [fieldId]="'newPassword'"
                [inputValue]="updatePasswordForm.get('newPassword')?.value"
              ></app-keyboard>
            </div> -->
          </div>

          <div
            class="text-xs flex items-center space-x-1"
            [ngClass]="{
              'text-red-400' : updatePasswordForm.get('newPassword')?.hasError('uppercase'),
              'text-green-400' : !updatePasswordForm.get('newPassword')?.hasError('uppercase'),
            }"
          >
            <ng-container
              *ngIf="
                updatePasswordForm.get('newPassword')?.hasError('uppercase');
                else validIcon
              "
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-container>
            <ng-template #validIcon>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-template>

            <span>
              {{ passwordErrors.uppercase }}
            </span>
          </div>

          <div
            class="text-xs flex items-center space-x-1"
            [ngClass]="{
              'text-red-400' : updatePasswordForm.get('newPassword')?.hasError('lowercase'),
              'text-green-400' : !updatePasswordForm.get('newPassword')?.hasError('lowercase'),
            }"
          >
            <ng-container
              *ngIf="
                updatePasswordForm.get('newPassword')?.hasError('lowercase');
                else validIcon
              "
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-container>
            <ng-template #validIcon>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-template>

            <span>
              {{ passwordErrors.lowercase }}
            </span>
          </div>

          <div
            class="text-xs flex items-center space-x-1"
            [ngClass]="{
              'text-red-400' : updatePasswordForm.get('newPassword')?.hasError('digit'),
              'text-green-400' : !updatePasswordForm.get('newPassword')?.hasError('digit'),
            }"
          >
            <ng-container
              *ngIf="
                updatePasswordForm.get('newPassword')?.hasError('digit');
                else validIcon
              "
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-container>
            <ng-template #validIcon>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-template>

            <span>
              {{ passwordErrors.digit }}
            </span>
          </div>

          <div
            class="text-xs flex items-center space-x-1"
            [ngClass]="{
              'text-red-400' : updatePasswordForm.get('newPassword')?.hasError('specialCharacter'),
              'text-green-400' : !updatePasswordForm.get('newPassword')?.hasError('specialCharacter'),
            }"
          >
            <ng-container
              *ngIf="
                updatePasswordForm
                  .get('newPassword')
                  ?.hasError('specialCharacter');
                else validIcon
              "
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-container>
            <ng-template #validIcon>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </ng-template>

            <span>
              {{ passwordErrors.specialCharacter }}
            </span>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-12 items-center">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
          >
            Re-Enter New Password <span class="text-red-600">*</span>
          </span>
          <span class="text-xs text-gray-500 hidden md:block"
            >Re-Enter New Password</span
          >
        </div>
        <div class="col-span-12 md:col-span-8">
          <div class="flex">
            <div class="w-full">
              <!-- <mat-form-field class="w-full">
                <mat-label>Re-Enter New Password</mat-label>
                <input
                  matInput
                  [type]="hideConfirmPassword ? 'password' : 'text'"
                  formControlName="confirmPassword"
                  required
                />
                <mat-icon matSuffix (click)="toggleVisibility('confirm')">{{
                  hideConfirmPassword ? "visibility" : "visibility_off"
                }}</mat-icon>
              </mat-form-field> -->
              <app-reactive-keyboard
                formControlName="confirmPassword"
                name="confirmPassword"
                placeholder="Re-Enter New Password"
                inputType="password"
                [class]="
                  'bg-white duration-200 w-full border border-[#E2E2E2] py-2 px-4 rounded-lg max-[640px]:rounded-r-lg sm:rounded-r-none rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white'
                "
                [keyButtonClass]="
                  'hidden sm:block keyboard-btn relative py-2 px-3 space-x-1 rounded-r-lg border border-l-0 border-[#E2E2E2] text-[16px] bg-white flex items-center dark:bg-gray-700 duration-200 dark:border-gray-500'
                "
                [passwordButtonClass]="
                  'keyboard-btn relative py-2 px-3 space-x-1 border border-l-0 border-[#E2E2E2] text-[16px] bg-white flex items-center dark:bg-gray-700 duration-200 dark:border-gray-500'
                "
              ></app-reactive-keyboard>
              <div
                *ngIf="
                  updatePasswordForm
                    .get('confirmPassword')
                    ?.hasError('required') &&
                  (updatePasswordForm.get('confirmPassword')?.touched ||
                    submitted)
                "
                class="text-red-400 text-xs"
              >
                Confirm Password is required.
              </div>
              <div
                *ngIf="
                  updatePasswordForm
                    .get('confirmPassword')
                    ?.hasError('passwordMismatch')
                "
                class="text-red-400 text-xs"
              >
                New Password and Confirm Password must match.
              </div>
            </div>
            <!-- <div>
              <button
                (click)="
                  keyboardConfirmPassword?.toggleVisibility('confirmPassword')
                "
                class="keyboard-btn border-l relative bg-black-600 bg-white/30 py-2.5 px-2 rounded-r-lg inline-block"
                [ngClass]="{
                  'bg-yellow-600 after:absolute after:w-3 after:h-3 after:border-white after:left-1  after:z-10 after:border-l-transparent after:border-r-transparent after:border-b-8 after:border-l-8 after:border-r-8':
                    keyboardConfirmPassword?.activeFieldId ===
                    'confirmPassword',
                  'after:-bottom-[1px]':
                    keyboardConfirmPassword?.keyboardPosition === 'below',
                  'after:-top-[12px]':
                    keyboardConfirmPassword?.keyboardPosition === 'above'
                }"
                data-field-id="confirmPassword"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
              </button>
              <app-keyboard
                #keyboardConfirmPassword
                (keyPressed)="
                  updatePasswordForm.get('confirmPassword')?.setValue($event)
                "
                keyboardType="computer"
                [fieldId]="'confirmPassword'"
                [inputValue]="updatePasswordForm.get('confirmPassword')?.value"
              ></app-keyboard>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between items-center w-full popFooter">
      <button
        *ngIf="!forcePassword"
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center dark:bg-white/20 dark:text-white"
        (click)="closePasswordDialog()"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
        <span>Close</span>
      </button>
      <button
        *ngIf="forcePassword"
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#D4A500] text-white flex items-center dark:bg-white/20 dark:text-white"
        (click)="closePasswordLogout('logout')"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
            />
          </svg>
        </span>
        <span>Log Out</span>
      </button>
      <button
        type="submit"
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#010D58] text-[#ffffff] flex items-center dark:bg-gray-950"
        [disabled]="updatePasswordForm.invalid"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </span>
        <span>Submit</span>
      </button>
    </div>
  </form>

  <button
    *ngIf="!forcePassword"
    (click)="closePasswordDialog()"
    type="button"
    class="absolute right-[5px] top-[-15px] h-8 w-8 rounded-full flex justify-center items-center bg-gray-100 text-red dark:bg-white/20 dark:text-white"
  >
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </span>
  </button>
</div>
