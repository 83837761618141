import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConstantsService } from 'src/app/core/services/constants.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private settingsSubject = new BehaviorSubject<any>([]);
  public settings$: Observable<any> = this.settingsSubject.asObservable();

  private readonly clientToken: string = '';
  private readonly authToken: any = '';
  private readonly userDetails: any = '';
  private readonly saveProfileSettingsUrl = '/profile/saveprofilesettings';
  private readonly getProfileSettingsUrl = '/profile/getprofilesettings';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private constantService: ConstantsService) {
    this.clientToken = this.constantService.CLIENT_TOKEN;
    this.authToken = this.authService.getToken();
  }

  updateSettings(newSettings: any): void {
    this.settingsSubject.next(newSettings);
  }

  saveProfileSettings(empNumber: number, data: any): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      EmployeeNumber: empNumber,
      Settings: data,
    }
    return this.http.post(`${this.saveProfileSettingsUrl}`, payload);
  }

  getProfileSettings(empNumber: number): Observable<any> {
    let token = localStorage.getItem('token') || this.authToken;
    let payload = {
      ClientToken: this.clientToken,
      UserToken: token,
      EmployeeNumber: empNumber
    }
    return this.http.post(`${this.getProfileSettingsUrl}`, payload);
  }

  /* async getProfileSettings(empNumber: number): Promise<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      EmployeeNumber: empNumber
    }
    return firstValueFrom(this.http.post(`${this.getProfileSettingsUrl}`, payload));
  } */

  getGroups(empNumber: number): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      EmployeeNumber: empNumber
    }
    return this.http.post(`supervisor/getgroups`, payload);
  }

  getSupervisorEmployees(empNumber: number): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      EmployeeNumber: empNumber
    }
    return this.http.post(`employeehours/getsupervisoremployees`, payload);
  }

  getGroupEmployees(GroupID: number): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      GroupID: GroupID
    }
    return this.http.post(`supervisor/getgroupemployees`, payload);
  }

  getReviewGroups(empNumber: number): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      EmployeeNumber: empNumber
    }
    return this.http.post(`supervisor/getreviewgroups`, payload);
  }

  getReviewGroupEmployees(ReviewGroupID: number): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      ReviewGroupID: ReviewGroupID
    }
    return this.http.post(`supervisor/getreviewgroupemployees`, payload);
  }

  saveGroupEmployees(GroupID: number, GroupEmployees: string): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      GroupID: GroupID,
      GroupEmployees: GroupEmployees
    }
    return this.http.post(`supervisor/savegroupemployees`, payload);
  }

  saveReviewGroupEmployees(ReviewGroupID: number, ReviewGroupEmployees: string): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      ReviewGroupID: ReviewGroupID,
      ReviewGroupEmployees: ReviewGroupEmployees
    }
    return this.http.post(`supervisor/savereviewgroupemployees`, payload);
  }

  saveGroup(GroupID: number, GroupName: string, EmployeeNumber: number): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      GroupID: GroupID,
      GroupName: GroupName,
      CreatedBy: EmployeeNumber
    }
    return this.http.post(`supervisor/savegroup`, payload);
  }

  deleteGroup(GroupID: number): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      GroupID: GroupID,
      GroupName: ''
    }
    return this.http.post(`supervisor/deletegroup`, payload);
  }

  saveReviewGroup(EmployeeNumber: number, Reviewer: number): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      ReviewGroupID: -1,
      CreatedBy: EmployeeNumber,
      Reviewer: Reviewer,
    }
    return this.http.post(`supervisor/savereviewgroup`, payload);
  }


  deleteReviewGroup(ReviewGroupID: number): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      ReviewGroupID: ReviewGroupID,
      Reviewier: -1
    }
    return this.http.post(`supervisor/deletereviewgroup`, payload);
  }

}
