import { Directive, Input, OnDestroy, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[matTooltip][matTooltipPosition]'
})
export class TooltipPositionDirective implements AfterViewInit, OnDestroy {
  @Input('matTooltipPosition') position!: string;

  private tooltipShowSubscription: Subscription | null = null;

  constructor(
    private tooltip: MatTooltip,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {}

  ngAfterViewInit() {
    // Subscribe to tooltip show event
    if (this.tooltip._tooltipInstance) {
      this.tooltipShowSubscription = this.tooltip._tooltipInstance.afterHidden().subscribe(() => {
        // Ensure the tooltip is displayed
        const tooltipElement = this.tooltip._overlayRef?.overlayElement.querySelector('.mat-tooltip') as HTMLElement;
        if (tooltipElement) {
          this.applyPositionClass(tooltipElement);
        }
      });
    }
  }

  private applyPositionClass(tooltipElement: HTMLElement) {
    // Remove any previous position classes
    const positions = ['above', 'below', 'left', 'right'];
    positions.forEach(pos => {
      this.renderer.removeClass(tooltipElement, `custom-tooltip-${pos}`);
    });
    // Add the class for the current position
    if (this.position) {
      this.renderer.addClass(tooltipElement, `custom-tooltip-${this.position}`);
    }
  }

  ngOnDestroy() {
    if (this.tooltipShowSubscription) {
      this.tooltipShowSubscription.unsubscribe();
    }
  }
}
