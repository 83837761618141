<div
  class="font-avertareguler z-40 bg-white shadow-md py-3 px-2 md:px-4 pr-16 md:pr-24 flex justify-between items-center fixed top-0 left-0 right-0 w-full dark:bg-slate-900 duration-300"
>
  <div class="">
    <a routerLink="/" class="hidden lg:block md:block sm:hidden"
      ><img class="w-[200px]" src="assets/images/logo.png"
    /></a>
    <a routerLink="/" class="block lg:hidden md:hidden sm:block"
      ><img class="w-[40px] md:w-[60px]" src="assets/images/fav-icon.png"
    /></a>
  </div>
  <!-- <div class="bg-[#ABC7FF] py-1 px-4 rounded-full text-[10px] md:text-sm text-black dark:text-gray-300 dark:bg-gray-700 duration-200">Wed, Sep 09, 12:45:15</div> -->
  <div
    class="bg-gradient-to-t from-[#afff9f] to-[#ffffff] border md:border-2 border-gray-600 w-[147px] md:w-[210px] text-center py-1 px-2 rounded-full text-[10px] md:text-[16px] text-black dark:text-gray-300 dark:from-[#102030]  dark:to-[#444444] duration-200"
  >
    {{ currentDateTime }}
  </div>
  <div class="">
    <ul
      class="flex justify-between items-center space-x-2 md:space-x-2 text-gray-400"
    >
      <li>
        <a
          href="javascript:void(0)"
          class="flex transition-transform items-center hover:scale-125 align-middle"
          (click)="toggleTheme()"
        >
          <svg
            *ngIf="!isDarkTheme"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
            />
          </svg>
          <svg
            *ngIf="isDarkTheme"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 hover:text-yellow-600"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
            />
          </svg>
        </a>
      </li>
      <li *ngIf="notifications && notifications.length" class="hidden md:block">
        <a
          [routerLink]="'/' + rcService.getTenant() + '/notification'"
          class="flex space-x-3 py-3 px-3 items-center"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
              />
            </svg>
          </span>
          <span
            class="p-1 bg-red-600 text-white h-5 w-5 rounded-full text-[10px] flex justify-center items-center !-mt-6 !-m-1"
            >{{ notifications?.length }}</span
          >
        </a>
      </li>
      <li *ngIf="isSupervisor || isReviewer" class="hidden md:block">
        <button
          (click)="openProfileDailog()"
          class="flex transition-transform items-center align-middle"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </button>
      </li>
      <li class="pl-0 md:pl-4">
        <div class="flex items-center space-x-0 md:space-x-3">
          <div
            class="h-8 w-8 md:h-10 md:w-10 rounded-full bg-slate-50 border-2 border-gray-600 dark:border-white duration-200"
          >
            <img
              class="w-full h-full rounded-full"
              [src]="userImage"
              alt=""
            />
          </div>
          <p
            class="text-[#102030] text-[12px] lg:text-[14px] dark:text-white duration-200 hidden md:block"
          >
            Welcome,
            <strong class="font-avertaBold text-[14] lg:text-[18px]"
              >{{ currentUser?.FirstName }}!</strong
            >
          </p>
        </div>
      </li>
    </ul>
  </div>
</div>
