import { Component, ElementRef, forwardRef, HostListener, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-reactive-keyboard',
  templateUrl: './reactive-keyboard.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReactiveKeyboardComponent),
      multi: true
    }
  ]
})
export class ReactiveKeyboardComponent implements ControlValueAccessor {
  @ViewChild('inputElement', { static: false }) inputElement!: ElementRef<HTMLInputElement>;

  @Input() placeholder: string | undefined;
  @Input() inputType: string = 'text';
  @Input() name: string = '';
  @Input() class: string = '';
  @Input() keyButtonClass: string = '';
  @Input() keyboardType: string = 'qwerty';
  @Input() passwordButtonClass: string = '';
  @Input() maxlength: any = 500;

  hidePassword: boolean = true;
  capsLockOn: boolean = false;
  isKeyboardOpen: boolean = false;
  activeInputFieldId: string | null = null;

  qwertyKeyboard: string[][] = [
    ['`', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '-', '=', '←'],
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', '\\'],
    ['Caps', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', '\''],
    ['z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '/', '@', '_', '-', '+'],
    ['⟳', 'Space', '=', '&', '%', '$', '#', '!']
  ];

  numberKeyboard: string[][] = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['⟳', '0', '←']
  ];

  // reactive form control
  value: any;
  onChange: any = () => { };
  onTouch: any = () => { };

  constructor(private elementRef: ElementRef) {
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    // Implement if needed
  }

  onInputChange(event: any) {
    this.value = event.target.value;
    this.updateValue(this.value);
  }

  // 
  pressKey(event: MouseEvent, key: any) {
    event.preventDefault();
    // debugger;
    if (key === 'back' || key === '←') {
      this.value = this.value.slice(0, -1);
    } else if (key === 'clear' || key === '⟳') {
      this.clearInput();
    } else if (key === 'Caps') {
      this.capsLockOn = !this.capsLockOn;
    } else if (key === 'Space') {
      this.value += ' ';
    } else {
      this.value += this.capsLockOn ? key.toUpperCase() : key.toLowerCase();
    }
    // this.control.setValue(this.inputValue);
    this.updateValue(this.value);
  }

  clearInput() {
    this.value = '';
    this.updateValue(this.value);
  }

  updateValue(val: any) {
    this.onChange(val);
    this.onTouch();
  }

  togglePasswordVisibility(event: MouseEvent): void {
    this.hidePassword = !this.hidePassword;
    event.stopPropagation();
  }

  // Method to focus the input field
  focusInput() {
    if (this.inputElement && this.inputElement.nativeElement) {
      this.inputElement.nativeElement.focus();
    }
  }


  toggleKeyboard() {
    this.isKeyboardOpen = !this.isKeyboardOpen;
    if (this.isKeyboardOpen && this.activeInputFieldId) {
      // Focus on the active input field when the keyboard opens
      const inputElement = document.getElementById(this.activeInputFieldId);
      if (inputElement) {
        inputElement.focus();
      }
    }
    if (this.isKeyboardOpen) {
      // Update keyboard position when opening
      // this.updateKeyboardPosition();
    }
  }

  closeKeyboard() {
    // this.isKeyboardOpen = !this.isKeyboardOpen;
    this.isKeyboardOpen = false;
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.closeKeyboard();
    }
  }

}
