<div class="min-h-screen flex items-center justify-center bg-gray-100">
  <div class="text-center relative w-full">
    <!-- Loading Spinner at the top center -->
    <div
      class="loader ease-linear absolute -top-16 left-1/2 transform -translate-x-1/2 rounded-full border-4 border-t-4 border-gray-200 border-t-blue-500 h-12 w-12"
    ></div>

    <!-- Redirecting Text -->
    <h1 class="text-4xl font-bold text-gray-800 mb-4">Redirecting...</h1>
    <p class="text-lg text-gray-600">
      Please wait while we redirect you to the Inventory.
    </p>
  </div>
</div>
