import { Component, Input, OnInit } from '@angular/core';
import { DetailsService } from '../details.service';

@Component({
  selector: 'app-review-details',
  templateUrl: './review-details.component.html',
  styleUrls: ['./review-details.component.css']
})
export class ReviewDetailsComponent implements OnInit {
  @Input() payHours: string = '';
  @Input() employeeNumber: number = 0;
  @Input() transactionNumber: number = 0;
  @Input() sequenceNumber: number = 0;
  @Input() totalEquipmentHours: number = 0;
  @Input() hideEquipmentInventory: boolean = true;
  @Input() condensed: boolean = false;
  @Input() totalHours: number = 0;
  @Input() userData: any = {};

  details: any = {};
  showEquipment: boolean = false;
  showInventory: boolean = false;
  hideTimeClock: boolean = false;

  constructor(private detailsService: DetailsService) {

  }

  ngOnInit(): void {
    if (this.userData.HideTimeClock === true || this.userData.hideTimeClock === true) {
      this.hideTimeClock = true;
    }
    this.loadTransaction();
  }

  loadTransaction(): void {
    this.detailsService.getTransactionDetails(this.transactionNumber, this.sequenceNumber).subscribe(
      (d: any) => {
        this.details = d;
        console.log(this.details, this.details);
        this.details.programDirections = [];
        // Initialize programDirections if it's not already initialized
        // if (!this.details.programDirections) {
        // this.details.programDirections = [];
        // }

        this.addItem(d.areaNumber, "Fund");
        this.addItem(d.departmentName, "Department", d.departmentChange);
        this.addItem(d.activityName, "Activity");
        this.addItem(d.projectName, "Project");

        this.addItem(d.functionName, "Function");
        if (!d.functionName && d.functionNumber) {
          this.addItem(d.functionNumber, "Function #");
        }

        this.addItem(d.account, "Account");
        this.addItem(d.subAccount, "SubAccount");
        this.addItem(d.workType, "WorkType");
        this.addItem(d.jobClass, "Job Classification", !d.IsDefaultJobClass);
        //this.addItem(d.payType, "Pay Type", d.payType.toLowerCase() != "regular" ? "text-danger" : "");
        this.addItem(d.payType, "Pay Type", !d.payType.toLowerCase().includes("regular") ? true : false);
        this.addItem(d.ROnumber, "Repair Order");
        this.addItem(d.timeEntryTime, "Hours")
        if (d.personalHours) {
          this.addItem("Yes", "Personal Hours", true);
        }
        this.addItem(d.SupervisorName, "Supervisor");
        this.addItem(d.timeCardComments, "Comments");
        this.addItem(d.roComments, "RO Comments");

        if (d.timeEntryAdditionalHourlyRate && d.timeEntryAdditionalHourlyRate > 0.00) {
          this.addItem(d.timeEntryAdditionalHourlyRate.toFixed(2), "Addtl Rate");
        }

        if (d.AdditionalHourlyRate) {
          this.addItem("Yes", "Addtl Rate Requested", true);
        }

        if (d.completeRO) {
          this.addItem("Yes", "RO Completed", true);
        }

        this.addItem(d.DailyTimeCardHours, "Daily TimeCard Hours");

        if (d.timeEntryShift && d.timeEntryShift.toLowerCase() != 'day') {
          this.addItem(d.timeEntryShift, "Shift", true);
        }

        if (this.hideTimeClock == true) {
          this.addItem(d.DailyTimeClockHours, "Daily TimeClock Hours");
        }

        this.showEquipment = (d.hasEquipment == 1);
        this.showInventory = (d.hasInventory == 1);
      },
      (error) => {
        // Handle the error
        console.error('Error loading daily summary:', error);
      }
    );
  }

  addItem(v: any, h: any, b?: any) {
    if (v != null && v != undefined && v != "") {
      this.details.programDirections.push({ header: h, displayValue: v, highlight: b });
    }
  }

}
