import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, from, lastValueFrom, map, switchMap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConstantsService } from 'src/app/core/services/constants.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private timeClockAvailableHomeScreenUrl = '/authenticate/timeClockAvailableHomeScreen';
  private getTimeCardEmployeeSettingsByEmployeeUrl = '/authenticate/getTimeCardEmployeeSettingsByEmployee';
  private saveTimeCardEmployeeSettingsUrl = '/authenticate/saveTimeCardEmployeeSettings';

  private getTimeCardSettingsUrl = '/authenticate/getTimeCardSettings';
  private getCalendarEventsUrl = '/employeehours/getcalendar';
  private getEmployeeDetailsUrl = '/employeehours/getdetails';
  private getEmployeeStatusUrl = '/EmployeeStatus';
  private getEmployeeHoursUrl = '/EmployeeHours';
  // copy detail
  private validatepayhourscopytransactionsUrl = '/details/validatepayhourscopytransactions';
  private copytransactionsUrl = '/details/copytransactions';
  // delete transaction
  private deleteDetailUrl = '/employeehours/deletedetail';
  private submitTimeCardUrl = '/employeehours/submittimecard';
  private movetimeUrl = '/employeehours/movetime';
  // notification
  private getnotificationsUrl = '/employeestatus/getnotifications';


  private readonly clientToken: string = '';
  private readonly authToken: any = '';

  constructor(private http: HttpClient, private authService: AuthService,
    private constantService: ConstantsService) {
    this.clientToken = this.constantService.CLIENT_TOKEN;
    this.authToken = this.authService.getToken();
  }

  isTimeClockAvailableHomeScreen(userHideTime: any): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('UserHideTime', userHideTime);
    return this.http.get(`${this.timeClockAvailableHomeScreenUrl}`, { params: httpParams });
  }

  getTimeCardEmployeeSettingsByEmployee(empNumber: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeId', empNumber);
    return this.http.get(`${this.getTimeCardEmployeeSettingsByEmployeeUrl}`, { params: httpParams });
  }

  async getTimeCardEmployeeSettings(empNumber: number): Promise<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeId', empNumber);
    return firstValueFrom(this.http.get(`${this.getTimeCardEmployeeSettingsByEmployeeUrl}`, { params: httpParams }));
  }

  /* saveTimeCardEmployeeSettings(
    empNumber: number,
    // empSetingId: number,
    Lock_In_Out_User_Preferences: number,
    Lock_In_Out_Total_User_Preferences: number,
    ButtonPreferences: string
  ): Observable<any> {
    let payload = {
      EmployeeId: empNumber,
      UserToken: this.authToken,
      ClientToken: this.clientToken,
      // TimeCardEmployeeSettingsId: empSetingId,
      Lock_In_Out_User_Preferences: Lock_In_Out_User_Preferences,
      Lock_In_Out_Total_User_Preferences: Lock_In_Out_Total_User_Preferences,
      ButtonPreferences: ButtonPreferences,
    }
    return this.http.post(`${this.saveTimeCardEmployeeSettingsUrl}`, payload);
  } */

  saveTimeCardEmployeeSettings(timeCardEmployeeSettings: any) {
    var payload: any = {};
    payload.ClientToken = this.clientToken;
    payload.UserToken = this.authToken;
    payload.EmployeeId = timeCardEmployeeSettings.EmployeeId;
    if (timeCardEmployeeSettings.Lock_In_Out_User_Preferences) {
      payload.Lock_In_Out_User_Preferences = timeCardEmployeeSettings.Lock_In_Out_User_Preferences;
    }
    else {
      payload.Lock_In_Out_User_Preferences = null;
    }
    if (timeCardEmployeeSettings.Lock_In_Out_Total_User_Preferences) {
      payload.Lock_In_Out_Total_User_Preferences = timeCardEmployeeSettings.Lock_In_Out_Total_User_Preferences;
    } else {
      payload.Lock_In_Out_Total_User_Preferences = null;
    }
    if (timeCardEmployeeSettings.ButtonPreferences) {
      payload.ButtonPreferences = timeCardEmployeeSettings.ButtonPreferences;
    } else {
      payload.ButtonPreferences = null;
    }

    if (timeCardEmployeeSettings.PageSize) {
      payload.PageSize = timeCardEmployeeSettings.PageSize;
    } else {
      payload.PageSize = null;
    }

    if (timeCardEmployeeSettings.ButtonPreferencesInv) {
      payload.ButtonPreferencesInv = timeCardEmployeeSettings.ButtonPreferencesInv;
    } else {
      payload.ButtonPreferencesInv = null;
    }

    if (timeCardEmployeeSettings.ButtonPreferencesEqu) {
      payload.ButtonPreferencesEqu = timeCardEmployeeSettings.ButtonPreferencesEqu;
    } else {
      payload.ButtonPreferencesEqu = null;
    }

    return this.http.post(`${this.saveTimeCardEmployeeSettingsUrl}`, payload);
  }

  getTimeCardSettings(): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    return this.http.get(`${this.getTimeCardSettingsUrl}`, { params: httpParams });
  }

  async getTimeCardSettingsAsync(): Promise<any> {
    //providing request headers
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    return firstValueFrom(this.http.get(`${this.getTimeCardSettingsUrl}`, { params: httpParams }));
  }

  async getTailoringAsync(): Promise<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return firstValueFrom(this.http.post('/authenticate/gettailoring', JSON.stringify(payload), { headers: headers }));
  }

  getEmployeeStatus(employeeNumber: number): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      employeeNumber: employeeNumber
    }
    return this.http.put(`${this.getEmployeeStatusUrl}`, payload);
  }

  getEmployeeHours(employeeNumber: number, hoursDate: string): Observable<any> {
    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      employeeNumber: employeeNumber,
      HoursDate: hoursDate
    }
    return this.http.put(`${this.getEmployeeHoursUrl}`, payload);
  }

  getCalendarEvents(employeeNumber: number, calendarDate: string): Observable<any> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeNumber', employeeNumber);
    httpParams = httpParams.append('calendarDate', calendarDate);
    // return this.http.post(`${this.loginUrl}`, credentials);
    return this.http.get(`${this.getCalendarEventsUrl}`, { params: httpParams });
  }

  getEmployeeDetails(employeeNumber: number, calendarDate: string): Observable<any> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeNumber', employeeNumber);
    httpParams = httpParams.append('calendarDate', calendarDate);
    return this.http.get(`${this.getEmployeeDetailsUrl}`, { params: httpParams });
  }


  deleteTransaction(transactionNumber: number): Observable<any> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('TransactionNumber', transactionNumber);
    return this.http.get(`${this.deleteDetailUrl}`, { params: httpParams });
  }

  submitTimecard(empNumber: number, timeCardDate: any): Observable<any> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeNumber', empNumber);
    httpParams = httpParams.append('WorkDate', timeCardDate);
    return this.http.get(`${this.submitTimeCardUrl}`, { params: httpParams });
  }

  recallDetail(empNumber: number, transactionNumber: number): Observable<any> {

    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      Details: [
        {
          EmployeeNumber: empNumber,
          TransactionNumber: transactionNumber,
          Action: 'User',
          SupervisorComment: '',
          SupervisorNumber: undefined,
        }
      ]
    }
    return this.http.post(`${this.movetimeUrl}`, payload);
  }

  checkValidatePayHoursCopyTransactions(transactionNumbers: any, copyStartDate: string, copyEndDate: string, employeeNumber: number): Observable<any> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('TransactionNumbers', transactionNumbers);
    httpParams = httpParams.append('CopyStartDate', copyStartDate);
    httpParams = httpParams.append('CopyEndDate', copyEndDate);
    httpParams = httpParams.append('EmployeeNumber', employeeNumber);
    return this.http.get(`${this.validatepayhourscopytransactionsUrl}`, { params: httpParams });
  }

  copyDetails(empNumber: number, transactions: any, fromDate: Date, toDate: Date, copyEquipment: boolean, copyInventory: boolean): Observable<any> {

    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      EmployeeNumber: empNumber,
      TransactionNumbers: transactions,
      CopyStartDate: fromDate,
      CopyEndDate: toDate,
      CopyEquipment: copyEquipment,
      CopyInventory: copyInventory,
    }
    return this.http.post(`${this.copytransactionsUrl}`, payload);
  }

  getTimeCardDetailReport(empNumber: number, timecardDate: string): void {
    const employeeNumber = empNumber;
    const url = `${this.constantService.API_URL}/report/timecarddetail?c=${this.clientToken}&u=${localStorage.getItem('token')}&e=${employeeNumber}&sd=${timecardDate}&ed=${timecardDate}`;
    window.open(url, '_blank');
  }

  getNotifications(empNumber: number): Observable<any> {

    let payload = {
      ClientToken: this.clientToken,
      UserToken: this.authToken,
      EmployeeNumber: empNumber
    }
    return this.http.post(`${this.getnotificationsUrl}`, payload);
  }

  getTCKModuleControlMasterForMaintenance(): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    return this.http.get(`authenticate/getTCKModuleControlMasterForMaintenance`, { params: httpParams });
  };

  getTCKTimeCardSettingsForMaintenance(): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    return this.http.get(`authenticate/getTCKTimeCardSettingsForMaintenance`, { params: httpParams });
  };

  getHolidaysObservedList(): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    return this.http.get(`authenticate/getHolidaysObservedList`, { params: httpParams });
  };

  saveTCKMaintenanceData(payload: any): Observable<any> {
    return this.http.post(`authenticate/saveTCKMaintenanceData`, payload);
  }

  saveHolidaysObserved(payload: any): Observable<any> {
    return this.http.post(`authenticate/saveHolidaysObserved`, payload);
  }
  
  deleteHolidaysObserved(id: any): Observable<any> {
    return this.http.post(`authenticate/deleteHolidaysObserved?HolidaysObservedById=${id}`, {});
  }


}
