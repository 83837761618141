import { createAction, props } from '@ngrx/store';
import { UserState } from '../state/user.state';

export const login = createAction(
  '[Auth] Login',
  props<{ user: any }>()
);

export const hydrate = createAction('[Auth] Hydrate');
export const hydrateSuccess = createAction(
  '[Auth] Hydrate Success',
  props<{ state: UserState }>()
);

export const logout = createAction('[Auth] Logout');