import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {

  readonly Signalr_URL: string = environment.Signalr_URL; // rms katdev
  readonly API_URL: string = environment.API_URL; // new
  readonly INVENTORY_API_URL: string = environment.INVENTORY_API_URL; // inventory  
  readonly IMAGE_URL: string = environment.IMAGE_URL; // new

  // readonly Signalr_URL: string = 'http://apipcskiosk.katdev.com/signalr'; // rms katdev
  // readonly API_URL: string = 'http://apipcskiosk.katdev.com/api'; // new
  // readonly INVENTORY_API_URL: string = 'http://apiinventory.katdev.com/api'; // inventory
  // readonly IMAGE_URL: string = 'http://pcskiosk.katdev.com'; // new

  //readonly Signalr_URL: string = 'https://beta.precisioncsrms.com/KioskAPI/signalr'; // rms signalr
  // readonly INVENTORY_API_URL: string = 'https://beta.precisioncsrms.com/RMSAPI/api'; // inventory
  // readonly API_URL: string = 'https://beta.precisioncsrms.com/KioskAPI/api'; // new RMS
  //readonly IMAGE_URL: string = 'https://beta.precisioncsrms.com'; // new

  readonly CLIENT_TOKEN: string = '245C224B-35E1-44EA-9EC7-7D533BC12EEF';
  readonly DEFAULT_LOGO: string = 'assets/images/dummy-logo.png';

  constructor(private http: HttpClient) { }

  pingUrl(url: string): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      this.http.get(url, { observe: 'response' }).subscribe(
        response => {
          resolve(response.status);
        },
        error => {
          if (error.status) {
            resolve(error.status); // In case of an error, you may still get a status code
          } else {
            reject(error);
          }
        }
      );
    });
  }

  // get current season for USA
  getCurrentSeason(): string {
    const today = new Date();
    const month = today.getMonth();
    let season;
    if (month >= 3 && month < 6) {
      season = "spring";
    } else if (month >= 6 && month < 9) {
      season = "summer";
    } else if (month >= 9 && month < 12) {
      season = "fall";
    } else {
      season = "winter";
    }
    // console.log(`The current season in the USA is ${season}`);
    return season;
  }

}
