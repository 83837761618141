import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'titleCase'
})
export class TitleCasePipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;

        return value.replace(/([A-Z])/g, ' $1')
            // Remove leading space and capitalize the first character
            .replace(/^./, function (match) {
                return match.toUpperCase();
            });
    }
}
