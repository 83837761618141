import { Component, ElementRef, EventEmitter, HostListener, Input, Output, OnChanges, ViewChild, SimpleChanges, forwardRef, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ColumnDefinition } from '../dynamic-table/table.model';

interface Option {
  [key: string]: any;
}

@Component({
  selector: 'app-advance-dropdown',
  templateUrl: './advance-dropdown.component.html',
  styleUrls: ['./advance-dropdown.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AdvanceDropdownComponent),
      multi: true
    }
  ]
})
export class AdvanceDropdownComponent implements OnChanges, AfterViewInit, ControlValueAccessor {
  @Output() itemOnChange = new EventEmitter<any>();
  @Output() onOpen = new EventEmitter<void>();
  @Output() pageChange = new EventEmitter<{ page: number, search: string, pageSize: number, column?: string }>();
  @Output() search = new EventEmitter<string>();
  @Output() onClear = new EventEmitter<string>();
  @Output() onSort = new EventEmitter<string>();

  @Input() label: string = 'Select';
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() data: Option[] = [];
  @Input() selectedOption: any | null = null;
  @Input() selectedColumn: string | null = null;
  @Input() totalItems: number = 0;
  @Input() pageSize: number = 20;
  // @Input() columnDefinitions: ColumnDefinition[] = [];
  @Input() columns!: ColumnDefinition[];

  @ViewChild('filterInput', { static: true }) filterInput!: ElementRef;
  @ViewChild('dropdownContainer', { static: true }) dropdownContainer!: ElementRef;
  @ViewChild('paginator') paginator!: ElementRef;

  filteredData: Option[] = [];
  dataSource = new MatTableDataSource<Option>([]);
  isOpen = false;
  // columns: string[] = [];
  displayedColumns: string[] = [];
  filterControl = new FormControl('');
  optionSelected = false;

  page = 1;
  searchQuery = '';

  columnFilters: { [key: string]: FormControl } = {}; // FormControl for each column search
  sortDirection: { [key: string]: 'asc' | 'desc' | '' } = {}; // Track sort direction for each column
  currentFilterColumn: string = '';
  currentFilterValue: string = '';

  private onChange: (value: any) => void = () => { };
  private onTouched: () => void = () => { };

  private searchSubject = new Subject<string>();
  private destroy$ = new Subject<void>();

  constructor(private elementRef: ElementRef, private fb: FormBuilder) { }

  filterCriteria: { [key: string]: { value: string; operator?: string } } = {};
  searchParams: any = {
    filters: [],
    Sortings: [],
    Start: 0,
    Length: 10
  };

  ngOnInit() {
    this.searchSubject.pipe(
      debounceTime(500), // Debounce input events by 500ms
      takeUntil(this.destroy$) // Unsubscribe when component is destroyed
    ).subscribe((value) => {
      this.currentFilterValue = value; // Assign debounced value to thavalue
      this.applyFilters(); // Perform filtering operation
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.updateColumnsAndDataSource();
      this.initializeColumnFilters();
    }
    if (changes['selectedOption']?.currentValue === null) {
      this.filterControl.setValue('');
      this.searchQuery = '';
      this.page = 1;
      this.search.emit('');
      this.selectedOption = null;
      this.optionSelected = false;
      this.itemOnChange.emit(null);
      this.filterControl.setValue('', { emitEvent: false });
      this.onChange(null);
      this.onTouched();
      this.onClear.emit('');
    }
    if (this.selectedOption) {
      this.optionSelected = true;
      this.filterControl.setValue(this.getFirstColumnValue(), { emitEvent: false });
    }
    if (changes['readonly'] && !this.selectedOption) {
      this.filterControl.setValue('');
    }
  }

  ngAfterViewInit() {
    fromEvent<Event>(this.filterInput.nativeElement, 'input').pipe(
      debounceTime(700),
      map(event => (event.target as HTMLInputElement).value),
      distinctUntilChanged()
    ).subscribe((filterValue: string) => {
      this.searchQuery = filterValue;
      this.page = 1;
      this.search.emit(filterValue);
      if (filterValue === '') {
        this.selectedOption = null;
        // this.isOpen = false;
        this.search.emit('');
      }
    });
  }

  onFocusOut(event: FocusEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    console.log('Input value on focusout:', inputValue);
    // You can perform additional actions with the input value here
    if (inputValue && this.filteredData.length) {
      const firstColumnKey = Object.keys(this.filteredData[0])[0];
      console.log('firstColumnKey:', firstColumnKey);
      let selectedItem = this.filteredData.find(value => value[firstColumnKey] === inputValue);
      if (selectedItem) {
        this.optionSelected = true;
        this.selectOption(selectedItem);
      }
    }
    // else {
    //   this.optionSelected = false;
    //   this.searchQuery = '';
    //   this.search.emit('');
    //   this.filterControl.setValue('');
    // }
  }

  toggleDropdown() {
    if (!this.readonly) {
      this.isOpen = true;
      if (this.isOpen) {
        this.filteredData = this.data;
        this.onOpen.emit();
        if (this.page === 1) {
          this.pageChange.emit({ page: this.page, search: this.searchQuery, pageSize: this.pageSize });
        }
      }
    }
  }

  selectOption(option: Option) {
    this.optionSelected = true;
    this.selectedOption = option;
    this.itemOnChange.emit(option);
    this.isOpen = false;
    this.filterControl.setValue(this.getFirstColumnValue(), { emitEvent: false });
    this.onChange(this.selectedOption);
    this.onTouched();
  }

  getFirstColumnValue(): string {
    if (this.selectedOption || this.columns.length > 0) {
      let firstColumn = this.selectedColumn || this.columns[0].columnDef;
      return this.selectedOption[firstColumn];
    }
    return '';
  }

  /* @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpen = false;
      this.optionSelected = false;
      if (this.searchQuery !== '' && !this.selectedOption) {
        this.searchQuery = '';
        this.search.emit('');
        this.filterControl.setValue('');
      }
    }
  } */
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const targetElement = event.target as HTMLElement;
    // Check if the clicked element or any of its parents have the specific class
    if (targetElement.classList.contains('custom-dropdown') ||
      targetElement.closest('.custom-dropdown') || this.hasClass(targetElement, 'mat-mdc-option')) {
      // console.log('Clicked inside the specific element class');
    } else {
      // console.log('Clicked outside the specific element class');
      this.isOpen = false;
      this.optionSelected = false;
      if (this.searchQuery !== '' && !this.selectedOption) {
        this.searchQuery = '';
        this.search.emit('');
        this.filterControl.setValue('');
      }
    }
  }
  // Helper method to check if an element or any of its parents have the specified class
  hasClass(element: HTMLElement, className: string): boolean {
    while (element) {
      if (element.classList.contains(className)) {
        return true;
      }
      element = element.parentElement as HTMLElement;
    }
    return false;
  }

  writeValue(value: any): void {
    this.selectedOption = value;
    if (this.selectedOption) {
      this.filterControl.setValue(this.getFirstColumnValue(), { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.filterControl.disable();
    } else {
      this.filterControl.enable();
    }
  }

  private updateColumnsAndDataSource() {
    if (this.data.length > 0) {
      let columns = this.columns.length > 0 ? this.columns.map(def => def.columnDef) : Object.keys(this.data[0]);
      this.displayedColumns = ['select', ...columns];
      this.dataSource.data = this.data;
    }
    this.filteredData = this.data;
  }

  onPageChange(event: any) {
    this.pageSize = event.pageSize;
    this.page = event.pageIndex + 1;
    this.pageChange.emit({ page: this.page, search: this.searchQuery, pageSize: this.pageSize });
  }

  clearInput(event: Event) {
    event.stopPropagation();
    this.filterControl.setValue('');
    this.searchQuery = '';
    this.page = 1;
    this.search.emit('');
    this.selectedOption = null;
    this.optionSelected = false;
    this.itemOnChange.emit(null);
    this.filterControl.setValue('', { emitEvent: false });
    this.onChange(null);
    this.onTouched();
    this.onClear.emit('');
  }

  initializeColumnFilters() {
    // Initialize FormControl for each column
    this.columns.forEach(column => {
      this.columnFilters[column.columnDef] = new FormControl('');
      this.columnFilters[column.columnDef].valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged()
      ).subscribe(filterValue => {
        this.searchQuery = filterValue;
        this.page = 1;
        this.applyFilters();
      });
    });
  }

  onColumnFilter(event: Event, column: string) {
    const target = event.target as HTMLInputElement;
    this.currentFilterColumn = column;
    if (target.value !== '') {
      console.log('with value', target.value);
      const value = target.value.trim(); // Trim input value
      this.searchSubject.next(value); // Emit value to searchSubject
    } else {
      console.log('with empty');
      this.currentFilterValue = '';
      this.searchSubject.next(''); // Emit value to searchSubject
    }
  }

  onClearSearchInput(columnId: string) {
    this.currentFilterValue = ''; // Clear thavalue
    this.searchSubject.next('');
    const inputElement = document.getElementById(columnId) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = '';
    }
  }

  clearOtherFields(currentFieldId: string) {
    const inputElements = document.querySelectorAll('.filter-input input');
    inputElements.forEach((input: any) => {
      if (input.id !== currentFieldId) {
        input.value = '';
        const column = input.id;
      }
    });
  }

  ngOnDestroy() {
    this.currentFilterValue = '';
    this.destroy$.next();
    this.destroy$.complete();
  }

  applyFilters() {
    // Emit pageChange event with current page, search query, pageSize, and current column
    this.pageChange.emit({
      page: this.page,
      pageSize: this.pageSize,
      column: this.currentFilterColumn,
      search: this.currentFilterValue
    });
  }

  applySort(column: string = '', defaultDirection: 'asc' | 'desc' = 'asc') {
    // Apply sorting logic based on sortDirection
    if (column) {
      const direction = this.sortDirection[column] === 'asc' ? 1 : -1;
      this.filteredData.sort((a, b) => {
        const valueA = a[column];
        const valueB = b[column];
        return valueA.localeCompare(valueB) * direction;
      });
    }
  }

  toggleSort(column: string) {
    // Toggle sort direction
    if (this.sortDirection[column] === 'asc') {
      this.sortDirection[column] = 'desc';
    } else {
      this.sortDirection[column] = 'asc';
    }
    // Apply sorting
    // this.applySort(column, this.sortDirection[column]);
  }

  onSortChange($event: any) {
    this.onSort.emit($event)
  }

  onOperatorSelected(operator: any, columnDef: string) {
    // this.filterCriteria[columnDef] = { value: this.filterCriteria[columnDef]?.value || '', operator: operator }; // keep the value
    this.filterCriteria[columnDef] = { value: '', operator: operator }; // reset the value if any
    this.onClearSearchInput(columnDef);
  }

  applySearch(event: Event | null, columnDef: string) {
    if (!this.filterCriteria[columnDef]) {
      this.filterCriteria[columnDef] = { value: '', operator: 'Contains' };
    }

    if (event) {
      const target = event.target as HTMLInputElement;
      this.filterCriteria[columnDef].value = target.value;
    }

    this.searchSubject.next('');
  }

}
