// security.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request and add security headers
        const secureRequest = request.clone({
            setHeaders: {
                'X-Frame-Options': 'DENY',
                'X-XSS-Protection': '1; mode=block',
                'X-Content-Type-Options': 'nosniff',
                // 'Content-Security-Policy': "default-src 'self'; connect-src 'self' http://apipcskiosk1.katdev.com"
            }
        });

        // Pass the cloned request instead of the original request to the next handle
        return next.handle(secureRequest);
    }
}