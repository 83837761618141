import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { isEqual } from "lodash";
import { MaterialModule } from 'src/app/material.module';
import { DatepickerDialogComponent } from '../../datepicker-dialog/datepicker-dialog.component';
import { HomeService } from 'src/app/pages/home/home.service';
import { AddHolidaysComponent } from './add-holidays/add-holidays.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'maintenance',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, DatepickerDialogComponent],
  templateUrl: './maintenance.component.html',
  styleUrl: './maintenance.component.css'
})
export class MaintenanceComponent implements OnInit {
  maintenanceForm!: FormGroup;

  // @Output() formStatus = new EventEmitter<boolean>();
  formStatus = output<string>()
  // firstnameSignal: Signal<any> | undefined;
  moduleControlMaster: any = {};
  timeCardSetting: any = {};
  holidaysList: any[] = [];

  initialFormValues: any; // keep track of any changes

  private homeService: HomeService = inject(HomeService);

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private toastr: ToastrService,
  ) {
    this.maintenanceForm = this.fb.group({
      // RMS Module Control  
      GLDepartment: new FormControl(''),
      FirstPayrollStartDate: new FormControl(''),
      ShowAttachments: new FormControl(''),
      AfterDate: new FormControl(''),
      InventoryLockedAfterDate: new FormControl(''),
      Message: new FormControl(''),
      ShowAdditionalHourlyRateApplies: new FormControl(''),
      EmployeeLogOff: new FormControl(''),
      SupervisorLogOff: new FormControl(''),
      InventoryLockedMessage: new FormControl(''),
      TimeClock: new FormControl(''),
      TimeClockTotal: new FormControl(''),
      TimeClockDecimal: new FormControl(''),
      Inventory: new FormControl(''),
      InventoryReleaseApproval: new FormControl(''),
      TimeClockDecimalTenths: new FormControl(''),
      CalendarYears: new FormControl(''),
      AllowChangePassword: new FormControl(''),
      APIKey: new FormControl(''),
      SecondApproval: new FormControl(''),
      ComplexPassword: new FormControl(''),
      ResetPasswordDays: new FormControl(''),
      AdditionalRate: new FormControl(''),
      AdditionalRateRequested: new FormControl(''),
      useLocationServices: new FormControl(''),
      useCombinedPayTypeEntry: new FormControl(''),
      IsTraining: new FormControl(''),
      TrainingAdminEmpId: new FormControl(''),
      IsDailyNotification: new FormControl(''),
      IsWeeklyNotification: new FormControl(''),
      DailyNotificationRunDateTime: new FormControl(''),
      WeeklyNotificationRunDateTime: new FormControl(''),
      RegularPayTypeAlias: new FormControl(''),

      // Time Card Settings
      LaborWarningHours: new FormControl(''),
      EquipmentMaxHours: new FormControl(''),
      IsLaborHoursWarning: new FormControl(''),
      IsLaborUpdateEquipmentHoursWarning: new FormControl(''),
      IsEquipmentMaxHoursWarning: new FormControl(''),
      IsEquipmentandLaborHoursWarning: new FormControl(''),
      IsAllowReviewersSubmit: new FormControl(''),
      IsEquipmentNotLaborHoursWarning: new FormControl(''),
      LoginScreenShowAll: new FormControl(''),
      IsLogDuplicateTokens: new FormControl(''),
      ConcurrentLogin: new FormControl(''),
      IsSequenceIncrements: new FormControl(''),
      IsEmpSummaryJoClassificationToggle: new FormControl(''),
      IsEmpSummaryExpandAllToggle: new FormControl(''),
      IsEmpSummaryTrimGLToggle: new FormControl(''),
      IsConsecutiveLoginFailedLock: new FormControl(''),
      LockEMailAddress: new FormControl(''),
      IsReviewShowTimecard: new FormControl(''),
      IsMinimumThresholdAlerts: new FormControl(''),

    });

    // firstNameSignal is created using the toSignal function.
    // represents the value of the 'firstName' form control.
    // so now instead of manually subscribing to the valueChanges of the form control, we can directly use the signal in our template
    // this.firstnameSignal = toSignal(
    //   this.maintenanceForm.get('firstname')?.valueChanges.pipe(debounceTime(300)) ??
    //     of(null),
    //   {}
    // );

    // Listen to form changes to check if it's touched or dirty
    this.maintenanceForm.valueChanges.subscribe(() => {
      this.checkFormStatus();
    });
  }

  ngOnInit(): void {
    this.getTCKModuleControlMaster();
    this.getTCKTimeCardSettings();
    this.getHolidaysObservedList();


    // Save the initial form values when the form is initialized
    this.initialFormValues = this.maintenanceForm.value;

    // Subscribe to form value changes to monitor if the form reverts back to its original state
    this.maintenanceForm.valueChanges.subscribe(() => {
      this.checkFormState();
    });

  }

  checkFormState() {
    // Compare current form values with the initial values
    const currentValues = this.maintenanceForm.value;

    if (isEqual(currentValues, this.initialFormValues)) {
      // If the values match, mark the form as pristine and untouched
      this.maintenanceForm.markAsPristine();
      this.maintenanceForm.markAsUntouched();
    }
  }

  getTCKModuleControlMaster() {
    this.homeService.getTCKModuleControlMasterForMaintenance().subscribe({
      next: (response) => {
        console.log("getGroups Response : ", response);
        this.moduleControlMaster = response;
        this.maintenanceForm.patchValue({ ...this.moduleControlMaster });
      },
      error: (error) => {
        console.log("get tck master error : ", error);
      }
    });
  }
  getTCKTimeCardSettings() {
    this.homeService.getTCKTimeCardSettingsForMaintenance().subscribe({
      next: (response) => {
        this.timeCardSetting = response;
        this.maintenanceForm.patchValue({ ...this.timeCardSetting });
      },
      error: (error) => {
        console.log("get timecard setting error : ", error);
      }
    });
  }

  getHolidaysObservedList() {
    this.homeService.getHolidaysObservedList().subscribe({
      next: (response) => {
        this.holidaysList = response;
        // this.maintenanceForm.patchValue({ ...this.timeCardSetting });
      },
      error: (error) => {
        console.log("get timecard setting error : ", error);
      }
    });
  }

  openPayrollStartCalendar(): void {
    const dialogRef = this.dialog.open(DatepickerDialogComponent, {
      width: '450px', // Set the desired width
      panelClass: 'custom-sml-calendar',
      data: {
        showEvents: false
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle any actions after the dialog is closed
      this.maintenanceForm.patchValue({
        FirstPayrollStartDate: result.date
      });
    });
  }

  openAfterDateCalendar(): void {
    const dialogRef = this.dialog.open(DatepickerDialogComponent, {
      width: '450px', // Set the desired width
      panelClass: 'custom-sml-calendar',
      data: {
        showEvents: false
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle any actions after the dialog is closed
      this.maintenanceForm.patchValue({
        AfterDate: result.date
      });
    });
  }

  openInventoryLockedAfterDateCalendar(): void {
    const dialogRef = this.dialog.open(DatepickerDialogComponent, {
      width: '450px', // Set the desired width
      panelClass: 'custom-sml-calendar',
      data: {
        showEvents: false
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle any actions after the dialog is closed
      this.maintenanceForm.patchValue({
        InventoryLockedAfterDate: result.date
      });
    });
  }

  openDailyNotificationRunDateTimeCalendar(): void {
    const dialogRef = this.dialog.open(DatepickerDialogComponent, {
      width: '450px', // Set the desired width
      panelClass: 'custom-sml-calendar',
      data: {
        showEvents: false
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle any actions after the dialog is closed
      this.maintenanceForm.patchValue({
        FirstPayrollStartDate: result.date
      });
    });
  }

  addHolidays(): void {
    const dialogRef = this.dialog.open(AddHolidaysComponent, {
      width: '450px', // Set the desired width
      panelClass: 'custom-sml-calendar',
      data: {
        showEvents: false,
        isEdit: false
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle any actions after the dialog is closed
      if (result === 'saved') {
        this.getHolidaysObservedList();
        this.toastr.success('Holiday added successfully!', 'Success', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-success",
        });
      }
    });
  }

  onUpdateHoliday(item: any): void {
    const dialogRef = this.dialog.open(AddHolidaysComponent, {
      width: '450px', // Set the desired width
      panelClass: 'custom-sml-calendar',
      data: {
        showEvents: false,
        isEdit: true,
        item: item
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle any actions after the dialog is closed
      if (result === 'saved') {
        this.getHolidaysObservedList();
        this.toastr.success('Holiday updated successfully!', 'Success', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-success",
        });
      }
    });
  }

  deleteHoliday(id: number): void {
    const message = `Are you sure you want to delete this record?`;

    const dialogData = new ConfirmDialogModel("Confirm Delete", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      // hasBackdrop: false,
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult && id) {
        this.homeService.deleteHolidaysObserved(id).subscribe({
          next: (response) => {
            this.holidaysList = this.holidaysList.filter((item: any) => item.HolidayId !== id);
            this.toastr.success('Holiday deleted successfully!', 'Success', {
              positionClass: 'toast-bottom-left',
              toastClass: "toast-icon custom-toast-success",
            });
          },
          error: (error) => {
            console.log('Error to delete holiday : ', error);
          }
        });
      }
    });
  }

  checkFormStatus() {
    const isFormDirty: any = this.maintenanceForm.dirty || this.maintenanceForm.touched;
    this.formStatus.emit(isFormDirty);
  }

  saveMaintenance() {
    console.log('submited values: ', this.maintenanceForm.value);
    if (this.maintenanceForm.value) {
      // console.log('submited values valid: ', this.maintenanceForm.value);
      this.homeService.saveTCKMaintenanceData(this.maintenanceForm.value).subscribe({
        next: (response) => {
          this.toastr.success('Maintenance data updated successfully!', 'Success', {
            positionClass: 'toast-bottom-left',
            toastClass: "toast-icon custom-toast-success",
          });
        },
        error: (error) => {
          console.log("get tck master error : ", error);
        }
      });
    }
  }

}
