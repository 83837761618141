import { Component, HostListener, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { SharedService } from '../shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { ReviewEntriesService } from 'src/app/pages/review-entries/review-entries.service';

@Component({
  selector: 'app-it-password-reset',
  templateUrl: './it-password-reset.component.html',
  styleUrls: ['./it-password-reset.component.css']
})
export class ItPasswordResetComponent implements OnInit {
  userData: any;

  employees: any[] = [];
  selectedEmployee: any = null;
  
  itPasswordForm!: FormGroup;

  hideCurrentPassword = true;
  hideNewPassword = true;
  hideConfirmPassword = true;
  // hide = true;

  submitted = false;

  passwordErrors: any = {
    lowercase: 'at least one lowercase character',
    uppercase: 'at least one uppercase character',
    digit: 'at least one number',
    specialCharacter: ' at least one special character',
    minLength: 'at least 8 characters',
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private readonly authService: AuthService,
    private readonly sharedService: SharedService,
    public dialogRef: MatDialogRef<ItPasswordResetComponent>,
    private readonly reviewService: ReviewEntriesService
  ) {
    this.authService.getCurrentUser().subscribe((user) => {
      this.userData = user;
    });
  }

  ngOnInit(): void {
    this.itPasswordForm = this.fb.group({
      employee: ['', {
        validators: [Validators.required]
      }],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        this.passwordStrengthValidator
      ]],
      confirmPassword: ['', Validators.required],
    }, { validators: this.passwordMatchValidator });

    this.getEmployees();
  }

  passwordStrengthValidator(control: any) {
    const password = control.value;
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    const errors: any = {};
    
    if (!passwordRegex.test(password)) {
      if (!/(?=.*[a-z])/.test(password)) {
        errors.lowercase = true;
      }
      if (!/(?=.*[A-Z])/.test(password)) {
        errors.uppercase = true;
      }
      if (!/(?=.*\d)/.test(password)) {
        errors.digit = true;
      }
      if (!/(?=.*[!@#$%^&*(),.?":{}|<>])/.test(password)) {
        errors.specialCharacter = true;
      }
      const passwordWithoutSpace = password.trim();
      console.log("passwordWithoutSpace : ",passwordWithoutSpace)
      if (passwordWithoutSpace.length < 8) {
        errors.minLength = true;
      }
    }
    
    return Object.keys(errors).length !== 0 ? errors : null;
  }

  getEmployees() {
    this.reviewService.getAllEmployees().subscribe((d) => {
      this.employees = d;
      this.selectedEmployee = this.employees[0];
      // this.employeeList = [];
      // this.employeeListCopy = [];

      // for (var e = 0; e < d.data.length; e++) {
      //   var ee = d.data[e];
      //   var i = {};
      //   var c = {};
      //   i.displayValue = ee.LastName + ', ' + ee.FirstName + ' (' + ee.EmployeeNumber + ')';
      //   i.EmployeeNumber = ee.EmployeeNumber;
      //   c.displayValue = ee.LastName + ', ' + ee.FirstName + ' (' + ee.EmployeeNumber + ')';
      //   c.EmployeeNumber = ee.EmployeeNumber;
      //   this.employeeList.push(i);
      //   this.employeeListCopy.push(c);
      // }

    });
  }

  passwordMatchValidator(formGroup: FormGroup) {
    const newPasswordControl = formGroup.get('newPassword');
    const confirmPasswordControl = formGroup.get('confirmPassword');

    if (newPasswordControl && confirmPasswordControl) {
      const newPassword = newPasswordControl.value;
      const confirmPassword = confirmPasswordControl.value;

      if (newPassword !== confirmPassword) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }
  }

  updatePassword() {
    this.submitted = true;
    if (this.itPasswordForm.valid) {
      let fieldData = this.itPasswordForm.value;
      // Perform password update logic here
      this.sharedService.updateEmployeePassword(fieldData?.employee, fieldData.newPassword, true).subscribe({
        next: (response) => {
          this.toastr.clear();
          this.toastr.success('Employee password updated successfully!', 'Success', {
            positionClass: 'toast-bottom-left',
            toastClass: "toast-icon custom-toast-success"
          });
          this.dialogRef.close();
        },
        error: (error) => {
          console.log('Error with password update: ', error);
          this.toastr.clear();
          this.toastr.error('Error with password update!', 'Error', {
            positionClass: 'toast-bottom-left',
            toastClass: "toast-icon custom-toast-error",
          });
        }
      });
    }
  }

  toggleVisibility(field: string): void {
    if (field === 'current') {
      this.hideCurrentPassword = !this.hideCurrentPassword;
    } else if (field === 'new') {
      this.hideNewPassword = !this.hideNewPassword;
    } else if (field === 'confirm') {
      this.hideConfirmPassword = !this.hideConfirmPassword;
    }
  }

  closePasswordDialog(): void {
    this.dialogRef.close();
  }

  // @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent) {
  //   event.preventDefault();
  // }

  @HostListener('copy', ['$event']) blockCopy(event: ClipboardEvent) {
    event.preventDefault();
  }

}
