import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatNativeDateModule} from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// components
import { CustomCalendarComponent } from './custom-calendar.component';
import { DateFormatPipe } from 'src/app/pipes/date-format.pipe';
import { DialogComponent } from './dialog/dialog.component';


@NgModule({
  declarations: [
    CustomCalendarComponent,
    DialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatDatepickerModule
  ],
  exports: [CustomCalendarComponent, DialogComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class CustomCalendarModule { }
