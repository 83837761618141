import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileService } from './profile.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
// ngrx setup
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state';
import * as ProfileActions from 'src/app/store/actions/profile.actions';
import { selectProfileData } from 'src/app/store/selectors/profile.selectors';
import { Observable } from 'rxjs';
import { MaintenanceComponent } from './maintenance/maintenance.component';


export interface GroupSubTask {
  id: number;
  name: string;
  completed: boolean;
}
export interface ReviewGroupSubTask {
  id: number;
  name: string;
  completed: boolean;
}
export interface GroupTask {
  name: string;
  completed: boolean;
  subtasks?: GroupSubTask[];
}
export interface ReviewGroupTask {
  name: string;
  completed: boolean;
  reviewSubtasks?: ReviewGroupSubTask[];
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  value = 'Clear me';

  userData: any;
  employeeNumber!: number;
  groupsList: any;
  reviewGroupsList: any;
  supervisorEmpList: any;

  selectedTab: number = 0;
  displayType: number = 1;

  isSupervisor: boolean = false;
  isReviewer: boolean = false;
  isMaintenanceUser: boolean = false;

  profileType1: boolean = false;
  profileType2: boolean = false;
  profileType3: string = 'summer';
  profileType4: boolean = false;

  themes: string[] = ['summer', 'winter', 'fall', 'spring', 'seasonal'];
  // themes: string[] = ['summer', 'winter'];
  savingSettings: boolean = false;
  settingsSaved: boolean = false;

  profileForm!: FormGroup;
  groupsForm!: FormGroup;
  reviewGroupsForm!: FormGroup;

  selectedGroupId!: number;
  selectedReviewGroupId!: number;

  showAddGroupPopup: boolean = false;
  showEditGroupPopup: boolean = false;
  groupName!: string;
  editGroupName!: string;

  showAddReviewGroupPopup: boolean = false;
  reviewer!: number;
  reviewersList: any;

  groupTask: GroupTask = {
    name: 'Select all',
    completed: false,
    subtasks: [],
  };

  reviewGroupTask: ReviewGroupTask = {
    name: 'Select all',
    completed: false,
    reviewSubtasks: [],
  };

  allGroupComplete: boolean = false;
  allReviewGroupComplete: boolean = false;

  profileData$: Observable<any>;

  isMaintenanceFormDirty: boolean = false;
  // Use ViewChild to get a reference to the MaintenanceComponent
  @ViewChild(MaintenanceComponent) maintenanceComponent!: MaintenanceComponent;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private readonly authService: AuthService,
    private readonly service: ProfileService,
    public dialogRef: MatDialogRef<ProfileComponent>,
    private store: Store<AppState>
  ) {
    this.authService.getCurrentUser().subscribe((user) => {
      this.userData = user;
      this.employeeNumber = user?.credentials?.EmployeeNumber;
      this.isSupervisor = this.userData?.isSupervisor;
      this.isMaintenanceUser = this.userData?.IsMaintenanceUser;
      this.isReviewer = (this.userData?.canReviewFirstLevel == "1") && (this.userData?.SecondApproval !== undefined && this.userData?.SecondApproval == "1");
    });

    this.profileData$ = this.store.select(selectProfileData);

  }

  ngOnInit(): void {
    // console.log("selected group id : ", this.selectedGroupId);
    this.profileForm = this.fb.group({
      profileType1: false,
      profileType2: false,
      profileType4: false,
      profileType3: 'seasonal'
    });

    this.loadSavedSettings();
    this.getGroups();
    // this.getSupervisorEmployees();
    this.getReviewGroups();
  }

  changeTab(tabChangeEvent: MatTabChangeEvent) {
    let index = tabChangeEvent.index;
    if (this.selectedTab === index) {
      // Do something when the selected tab is clicked again
    } else {
      this.selectedTab = index;
    }

    if (this.selectedTab === 1) {
      this.getSupervisorEmployees();
    }
    if (this.selectedTab === 2) {
      this.getSupervisorReviewEmployees();
    }
    // this.allGroupComplete = false;
    // this.allReviewGroupComplete = false;
    // this?.groupTask?.subtasks?.forEach(item => {
    //   item.completed = false;
    // });
    // this?.reviewGroupTask?.subtasks?.forEach(item => {
    //   item.completed = false;
    // });
  }

  loadSavedSettings(): void {
    // Assuming there is a method in your service to retrieve saved settings
    this.service.getProfileSettings(this.employeeNumber).subscribe((savedSettings) => {
      if (savedSettings) {
        // console.log('savedSettings', savedSettings);
        let profileSetting = {
          profileType1: savedSettings.some((item: any) => item.ProfileTypeID === 1 && item.SettingValue === 'true'),
          profileType2: savedSettings.some((item: any) => item.ProfileTypeID === 2 && item.SettingValue === 'true'),
          profileType4: savedSettings.some((item: any) => item.ProfileTypeID === 4 && item.SettingValue === 'true'),
          profileType3: savedSettings.some((item: any) => item.ProfileTypeID === 3),
        }
        // console.log('profileSetting', profileSetting);
        // Update the form with saved settings
        this.profileForm.patchValue(profileSetting);
        const theme = savedSettings.find((setting: { ProfileTypeID: number; }) => setting.ProfileTypeID === 3).SettingValue;
        // You may need to handle the theme separately as it is stored in sessionStorage
        // const theme = this.userData.kiosk_theme;
        // console.log("theme : ", theme)
        this.profileForm.get('profileType3')?.setValue(theme);
      }
    });
  }

  saveSettings(): void {
    this.savingSettings = true;
    this.settingsSaved = false;

    const settings: any = [];

    // if (this.isSupervisor) {
    settings.push({ ProfileTypeID: 1, SettingValue: this.profileForm.get('profileType1')?.value.toString() });
    settings.push({ ProfileTypeID: 2, SettingValue: this.profileForm.get('profileType2')?.value.toString() });
    settings.push({ ProfileTypeID: 4, SettingValue: this.profileForm.get('profileType4')?.value.toString() });
    // }

    settings.push({ ProfileTypeID: 3, SettingValue: this.profileForm.get('profileType3')?.value });

    if (this.profileForm.get('profileType3')?.value === null) {
      this.authService.setCurrentUser({ ...this.userData, kiosk_theme: 'seasonal' });
    } else {
      this.authService.setCurrentUser({ ...this.userData, kiosk_theme: this.profileForm.get('profileType3')?.value });
    }

    this.service.saveProfileSettings(this.employeeNumber, settings).subscribe((d) => {
      // Assuming saveProfileSettings returns an observable, adjust accordingly
      // Also, make sure to inject and configure HttpClient in your ReviewService

      // kioskSession.addData('profile', JSON.stringify(settings)); // Commented out as kioskSession is not defined in the provided code
      this.authService.setCurrentUser({ ...this.userData, profile: JSON.stringify(settings) });

      this.savingSettings = false;
      this.settingsSaved = true;
      this.toastr.clear();
      this.toastr.success('Profile updated successfully!', 'Success', {
        positionClass: 'toast-bottom-left',
        toastClass: "toast-icon custom-toast-success",
      });
      this.service.updateSettings(settings);
      // store data to ngrx store
      this.store.dispatch(ProfileActions.setProfileData({ data: JSON.stringify(settings) }));
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          // all: this.profileForm.get('profileType1')?.value ? 'yes' : 'no',
          ...(this.profileForm.get('profileType1')?.value ? { e: undefined } : {}),
        },
        queryParamsHandling: 'merge', // keep other query params unchanged
      });
    }, (err) => {
      this.toastr.clear();
      this.toastr.error('Profile updation failed!', 'Error', {
        positionClass: 'toast-bottom-left',
        toastClass: "toast-icon custom-toast-error",
      });
    });
  }

  saveGroupEmployees() {
    // console.log("selected GroupId : ", this.selectedGroupId);
    let selectedEmployees = '';
    this?.groupTask?.subtasks?.forEach((item, index) => {
      if (item.completed == true) {
        // Add comma if this is not the last item
        if (index > 0) {
          selectedEmployees += ',';
        }
        selectedEmployees += item.id;
      }
    });
    // console.log("selected employees : ", selectedEmployees)
    this.service.saveGroupEmployees(this.selectedGroupId, selectedEmployees).subscribe({
      next: (response) => {
        // console.log("saveGroupEmployees response : ", response);
        this.toastr.clear();
        this.toastr.success('Profile updated successfully!', 'Success', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-success",
        });
      },
      error: (error) => {
        // console.log("saveGroupEmployees error : " , error);
        this.toastr.clear();
        this.toastr.error('Profile updation failed!', 'Error', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-error",
        });
      }
    })
  }

  saveReviewGroupEmployees() {
    // console.log("selected GroupId : ", this.selectedGroupId);
    let selectedEmployees = '';
    this?.reviewGroupTask?.reviewSubtasks?.forEach((item, index) => {
      if (item.completed == true) {
        // Add comma if this is not the last item
        if (index > 0) {
          selectedEmployees += ',';
        }
        selectedEmployees += item.id;
      }
    });
    // console.log("selected employees : ", selectedEmployees)
    this.service.saveReviewGroupEmployees(this.selectedReviewGroupId, selectedEmployees).subscribe({
      next: (response) => {
        // console.log("saveGroupEmployees response : ", response);
        this.toastr.clear();
        this.toastr.success('Profile updated successfully!', 'Success', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-success"
        });
      },
      error: (error) => {
        // console.log("saveGroupEmployees error : " , error);
        this.toastr.clear();
        this.toastr.error('Profile updation failed!', 'Error', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-error",
        });
      }
    })
  }

  saveGroup() {
    // console.log("groupName : ",this.groupName);
    this.editGroupName = this.groupName;
    // console.log("editGroupName : ",this.editGroupName);
    this.service.saveGroup(-1, this.groupName, this.employeeNumber).subscribe({
      next: (response) => {
        // console.log("saveGroup response : ",response);
        this.toastr.clear();
        this.toastr.success('Group added successfully!', 'Success', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-success",
        });
        this.getGroups();

        this.selectedGroupId = response;
      },
      error: (error) => {
        // console.log(" saveGroup Error : ", error);
        this.toastr.clear();
        this.toastr.error('Something went wrong!', 'Error', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-error",
        });
      }
    });
    this.toggleAddGroupPopup();
  }

  saveReviewGroup() {
    this.service.saveReviewGroup(this.employeeNumber, this.reviewer).subscribe({
      next: (response) => {
        // console.log("saveReviewGroup response : ",response);
        this.toastr.clear();
        this.toastr.success('Review Group added successfully!', 'Success', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-success",
        });
        this.getReviewGroups();

        this.selectedReviewGroupId = response;
      },
      error: (error) => {
        // console.log(" saveReviewGroup Error : ", error);
        this.toastr.clear();
        this.toastr.error('Something went wrong!', 'Error', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-error",
        });
      }
    });
    this.toggleAddReviewGroupPopup();
  }

  updateGroup() {
    // console.log("groupName : ", this.groupName)
    this.service.saveGroup(this.selectedGroupId, this.editGroupName, this.employeeNumber).subscribe({
      next: (response) => {
        // console.log("saveGroup response : ",response);
        this.toastr.clear();
        this.toastr.success('Group updated successfully!', 'Success', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-success",
        });
        this.getGroups();
        // this.selectedGroupId = response;
      },
      error: (error) => {
        // console.log(" saveGroup Error : ", error);
        this.toastr.clear();
        this.toastr.error('Something went wrong!', 'Error', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-error",
        });
      }
    });
    this.toggleEditGroupPopup();
  }

  deleteGroup() {
    // console.log("groupName : ", this.groupName)
    this.service.deleteGroup(this.selectedGroupId).subscribe({
      next: (response) => {
        // console.log("saveGroup response : ",response);
        this.toastr.clear();
        this.toastr.success('Group deleted successfully!', 'Success', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-success",
        });
        this.getGroups();
        this.groupTask.completed = false;
        this.allGroupComplete = false;
        this.selectedGroupId = 0;
        this?.groupTask?.subtasks?.forEach(item => {
          item.completed = false;
        });
      },
      error: (error) => {
        // console.log(" saveGroup Error : ", error);
        this.toastr.clear();
        this.toastr.error('Something went wrong!', 'Error', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-error",
        });
      }
    });
  }

  deleteReviewGroup() {
    // console.log("groupName : ", this.groupName)
    this.service.deleteReviewGroup(this.selectedReviewGroupId).subscribe({
      next: (response) => {
        // console.log("deleteReviewGroup response : ",response);
        this.toastr.clear();
        this.toastr.success('Review Group deleted successfully!', 'Success', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-success",
        });
        this.getReviewGroups();
        this.reviewGroupTask.completed = false;
        this.allReviewGroupComplete = false;
        this.selectedReviewGroupId = 0;
        this?.reviewGroupTask?.reviewSubtasks?.forEach(item => {
          item.completed = false;
        });
      },
      error: (error) => {
        // console.log(" deleteReviewGroup Error : ", error);
        this.toastr.clear();
        this.toastr.error('Something went wrong!', 'Error', {
          positionClass: 'toast-bottom-left',
          toastClass: "toast-icon custom-toast-error",
        });
      }
    });
  }

  getGroups() {
    this.service.getGroups(this.employeeNumber).subscribe({
      next: (response) => {
        // console.log("getGroups Response : ", response);
        this.groupsList = response;
      },
      error: (error) => {
        console.log("getGroups error : ", error);
      }
    });
  }

  getSupervisorEmployees() {
    this.service.getSupervisorEmployees(this.employeeNumber).subscribe({
      next: (response) => {
        // console.log('getSupervisorEmployees response : ', response);
        this.supervisorEmpList = response;
        response.forEach((employee: { EmployeeNumber: number; LastName: string; FirstName: string; }) => {
          let empObj = {
            id: employee.EmployeeNumber,
            name: `${employee.LastName}, ${employee.FirstName} (${employee.EmployeeNumber})`,
            completed: false
          };
          this.groupTask.subtasks?.push(empObj);
          // this.reviewGroupTask.reviewSubtasks?.push(empObj);
        });
        // console.log('supervisor employees : ',this.reviewGroupTask.subtasks)
      },
      error: (error) => {
        console.log('getSupervisorEmployees error :', error);
      }
    })
  }

  getSupervisorReviewEmployees() {
    this.service.getSupervisorEmployees(this.employeeNumber).subscribe({
      next: (response) => {
        // console.log('getSupervisorEmployees response : ', response);
        this.supervisorEmpList = response;
        response.forEach((employee: { EmployeeNumber: number; LastName: string; FirstName: string; }) => {
          let empObj = {
            id: employee.EmployeeNumber,
            name: `${employee.LastName}, ${employee.FirstName} (${employee.EmployeeNumber})`,
            completed: false
          };
          // this.groupTask.subtasks?.push(empObj);
          this.reviewGroupTask.reviewSubtasks?.push(empObj);
        });
        // console.log('supervisor employees : ',this.reviewGroupTask.subtasks)
      },
      error: (error) => {
        console.log('getSupervisorEmployees error :', error);
      }
    })
  }

  getGroupEmployees(Group: any) {
    this.selectedGroupId = Group.GroupID;
    this.editGroupName = Group.GroupName;
    // console.log("selected group id : ", GroupID)
    this.service.getGroupEmployees(this.selectedGroupId).subscribe({
      next: (response) => {
        // console.log("getGroupEmployees Response : ", response);
        // response.forEach((id: any) => {
        //   let index = this?.task?.subtasks?.findIndex(item => item.id === id);
        //   if (index !== -1) {
        //     this?.task?.subtasks?[index].completed = true;
        //   }
        // });
        this.allGroupComplete = true;
        this?.groupTask?.subtasks?.forEach(item => {
          if (response.includes(item.id)) {
            item.completed = true;
          } else {
            item.completed = false;
            this.allGroupComplete = false;
          }
        });
        // console.log("review group tasks : ", this.reviewGroupTask)
      },
      error: (error) => {
        console.log("getGroupEmployees error : ", error);
      }
    });
  }

  getReviewGroups() {
    this.service.getReviewGroups(this.employeeNumber).subscribe({
      next: (response) => {
        console.log("getReviewGroups Response : ", response);
        this.reviewGroupsList = response;
      },
      error: (error) => {
        console.log("getReviewGroups error : ", error);
      }
    });
  }

  getReviewGroupEmployees(GroupID: number) {
    this.selectedReviewGroupId = GroupID;
    this.service.getReviewGroupEmployees(GroupID).subscribe({
      next: (response) => {
        // console.log("getReviewGroupEmployees Response : ", response);
        this?.reviewGroupTask?.reviewSubtasks?.forEach(item => {
          if (response.includes(item.id)) {
            item.completed = true;
          } else {
            item.completed = false;
            this.allReviewGroupComplete = false;
          }
        });
      },
      error: (error) => {
        console.log("getReviewGroupEmployees error : ", error);
      }
    });
  }

  closeProfileDialog(): void {
    this.dialogRef.close();
  }

  updateAllCompleteGroupTask() {
    this.allGroupComplete = this.groupTask.subtasks != null && this.groupTask.subtasks.every(t => t.completed);
  }

  onGroupItemClick(checked: boolean, item: any): void {
    item.subtasks = checked;
    this.updateAllCompleteGroupTask();
  }

  onReviewGroupItemClick(checked: boolean, item: any): void {
    item.reviewSubtasks = checked;
    this.updateAllCompleteReviewGroupTask();
  }

  someCompleteGroupTask(): boolean {
    if (this.groupTask.subtasks == null) {
      return false;
    }
    return this.groupTask.subtasks.filter(t => t.completed).length > 0 && !this.allGroupComplete;
  }

  setAllGroupTask(completed: boolean) {
    this.allGroupComplete = completed;
    if (this.groupTask.subtasks == null) {
      return;
    }
    this.groupTask.subtasks.forEach(t => (t.completed = completed));
  }

  updateAllCompleteReviewGroupTask() {
    this.allReviewGroupComplete = this.reviewGroupTask.reviewSubtasks != null && this.reviewGroupTask.reviewSubtasks.every(t => t.completed);
  }

  someCompleteReviewGroupTask(): boolean {
    if (this.reviewGroupTask.reviewSubtasks == null) {
      return false;
    }
    return this.reviewGroupTask.reviewSubtasks.filter(t => t.completed).length > 0 && !this.allReviewGroupComplete;
  }

  setAllReviewGroupTask(completed: boolean) {
    this.allReviewGroupComplete = completed;
    if (this.reviewGroupTask.reviewSubtasks == null) {
      return;
    }
    this.reviewGroupTask.reviewSubtasks.forEach(t => (t.completed = completed));
  }

  toggleAddGroupPopup() {
    this.showAddGroupPopup = !this.showAddGroupPopup;
    this.groupName = '';
  }

  toggleEditGroupPopup() {
    this.showEditGroupPopup = !this.showEditGroupPopup;
    // this.editGroupName = '';
  }

  toggleAddReviewGroupPopup() {
    this.showAddReviewGroupPopup = !this.showAddReviewGroupPopup;
    this.reviewer = 0;
    // Extract reviewer IDs from reviewGroups
    const reviewerIds = this.reviewGroupsList.map((group: { Reviewer: any; }) => group.Reviewer);
    // console.log('Reviewer IDs:', reviewerIds);
    // Filter supervisor employees who are not in review groups
    this.reviewersList = this.supervisorEmpList.filter((employee: { EmployeeNumber: { toString: () => any; }; }) => {
      const employeeIdString = employee.EmployeeNumber;
      // console.log('Checking employee ID:', employeeIdString);
      return !reviewerIds.includes(employeeIdString);
    });

    console.log("reviewers list : ", this.reviewersList);
  }

  checkMaintenanceFormState(isDirty: boolean | any) {
    this.isMaintenanceFormDirty = isDirty;
  }

  saveMaintenanceData() {
    // Check if the form is valid and call the child component's save method
    if (this.isMaintenanceFormDirty) {
      this.maintenanceComponent.saveMaintenance(); // Calls the saveData method in the child component
    }
  }

}
