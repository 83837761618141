import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SettingsService } from './settings.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConstantsService } from 'src/app/core/services/constants.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, AfterViewInit  {
  constructor(
    private fb: FormBuilder,
    public service: SettingsService,
    public authService : AuthService,
    public constantService : ConstantsService,
    private toastr: ToastrService,
    // private dialogRef: MatDialog
    public dialogRef: MatDialogRef<SettingsComponent>,
  ) {}

  form!: FormGroup;
  formSubmitted = false;
  mailSettings : any;
  preDepartmentList : any;
  departmentList : any;
  clientToken: any = this.constantService.CLIENT_TOKEN;
  userToken: any = this.authService.getToken();

  ngOnInit(): void {
    // console.log("userToken : ",this.userToken)
    // console.log("clientToken : ",this.clientToken)
    this.getDepartmentList();
    this.getMailSettings();

    //form validations
    this.form = this.fb.group({
      from: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      host: ['', Validators.required],
      port: ['', Validators.required],
      user_name: ['', Validators.required],
      password: ['', Validators.required],
      ssl: ['', Validators.required],
      pre_department: [''],
      department: [''],

    });
    // console.log(this.mailSettings.Department.split(','))

   
  }

  ngAfterViewInit(): void {
     
  }


  //get training mail settings
  getMailSettings(){
    this.service.getMailSettings(this.clientToken, this.userToken).subscribe({
      next: (response) => {
        // console.log('getMailSettings resonse : ',response);
        this.mailSettings = response;
        this.patchValues();
      },
      error: (error) => {
        console.log('getMailSettings error : ', error);
      }
    });

    // this.mailSettings = {
    //   "MailSettingsNumber": 1,
    //   "SMTPFrom": "admin@celeroerp.com",
    //   "SMTPSubject": "Training mail from Kiosk",
    //   "SMTPHost": "email-smtp.us-east-1.amazonaws.com\t",
    //   "SMTPPort": "587",
    //   "SMTPEnableSsl": true,
    //   "SMTPUserName": "AKIAIMEFQKOW3GBZR44A",
    //   "SMTPPassword": "AvtioqbW+aG10mx3hA15oN+2Tmf7dQ+BR5GoOjrp32WM",
    //   "PreDepartment": "Board Administration,Contractor,Electrical",
    //   "Department": "Board Administration,Contractor",
    // }
  }

  patchValues(){
    //bind data
    this.form.patchValue({
      from: this.mailSettings.SMTPFrom,
      subject: this.mailSettings.SMTPSubject,
      host: this.mailSettings.SMTPHost,
      port: this.mailSettings.SMTPPort,
      user_name: this.mailSettings.SMTPUserName,
      password: this.mailSettings.SMTPPassword,
      ssl: this.mailSettings.SMTPEnableSsl,
      pre_department: this.mailSettings.PreDepartment.split(','),
      department: this.mailSettings.Department.split(',')
    });

    this.departmentList = this.mailSettings.PreDepartment.split(',');
  }

  // get list of departments
  getDepartmentList(){
    this.service.getDepartmentList(this.clientToken, this.userToken).subscribe({
      next: (response) => {
        // console.log('getDepartmentList resonse : ',response);
        this.preDepartmentList = response;
      },
      error: (error) => {
        console.log('getDepartmentList error : ', error);
      }
    });
  }

  onSubmit() {
    console.log('Form valid:', this.form.valid);
    this.formSubmitted = true;
    if (this.form.valid) {
      // console.log('Form values:', this.form.value);
      const formData = this.form.value;
      console.log('formData.preDepartment',formData.pre_department[0])
      if (!formData.pre_department[0]) {
        console.log("PreDepartment is null.")
        formData.department = [];
      }
      const payload = {
        ClientToken: this.clientToken,
        Department: formData?.department.join(','),
        PreDepartment: formData?.pre_department.join(','),
        SMTPEnableSsl: formData?.ssl,
        SMTPFrom: formData?.from,
        SMTPHost: formData?.host,
        SMTPPassword: formData?.password,
        SMTPPort: formData?.port,
        SMTPSubject: formData?.subject,
        SMTPUserName: formData?.user_name,
        TrainingSettingsNumber: this.mailSettings.MailSettingsNumber,
        UserToken: this.userToken,
      }
      // console.log("saveMailSettings Payload : ", payload);

      //save mail settings
      this.service.saveMailSetting(payload).subscribe({
        next: (response) => {
          // console.log('saveMailSetting response : ', response);
          if (response == true) {
            this.toastr.clear();
            this.toastr.success('Training email settings updated successfully.', 'Success', {
              positionClass: 'toast-bottom-left',
              toastClass: "toast-icon custom-toast-success",
            });
            this.closeDialog();
          } else {
            this.toastr.clear();
            this.toastr.error('Something went wrong', 'Error', {
              positionClass: 'toast-bottom-left',
              toastClass: "toast-icon custom-toast-error",
            });
          }
        },
        error : (error) => {
          console.log("saveMailSetting error : ", error);
        }
      });
    }
  }

  handlePreDepartmentChange(){
    this.departmentList = this.form.get('pre_department')?.value;
  }

  togglePreDepartmentCheck() {
    const preDepartmentControl = this.form.get('pre_department');
    const allPreDepartments = this.preDepartmentList?.map((dep: { Department: any; }) => dep.Department);
    // console.log('allPreDepartments : ',allPreDepartments);
    // console.log('isAllPreDepartmentsSelected : ',this.isAllPreDepartmentsSelected());
    if (this.isAllPreDepartmentsSelected()) {
        // Uncheck all
        preDepartmentControl?.patchValue([]);
    } else {
        // Check all
        preDepartmentControl?.patchValue(allPreDepartments);
    }
    this.handlePreDepartmentChange();
  }

  isAllPreDepartmentsSelected() {
    const preDepartmentControl = this.form.get('pre_department');
    const allPreDepartments = this.preDepartmentList?.map((dep: { Department: any; }) => dep.Department);
    // console.log('preDepartmentControl length',preDepartmentControl?.value.length);   
    // console.log('allPreDepartments length',allPreDepartments.length);   
    return preDepartmentControl?.value?.length === allPreDepartments?.length ;
  }

  toggleDepartmentCheck() {
    const departmentControl = this.form.get('department');
    const allDepartments = this.departmentList;
    console.log('allDepartments : ',allDepartments);
    console.log('isAllDepartmentsSelected : ',this.isAllDepartmentsSelected());
    if (this.isAllDepartmentsSelected()) {
        // Uncheck all
        departmentControl?.patchValue([]);
    } else {
        // Check all
        departmentControl?.patchValue(allDepartments);
    }
  }

  isAllDepartmentsSelected() {
    const departmentControl = this.form.get('department');
    const allDepartments = this.departmentList?.map((dep: { Department: any; }) => dep.Department);
    // console.log('departmentControl length',departmentControl?.value.length);   
    // console.log('allDepartments length',allDepartments.length);   
    return departmentControl?.value?.length === allDepartments?.length ;
  }

  //Open training settings dialog
  closeDialog() {
    this.dialogRef.close();
  }

}
