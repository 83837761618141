<app-spinner></app-spinner>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div
    class="font-avertareguler w-full h-screen px-2 py-10 md:py-10 md:p-4 space-y-4 bg-white/[67%] dark:bg-slate-900/60 relative"
  >
  
    <div class="flex">
      <h2 class="text-[#102030] text-[16px] md:text-[20px] font-avertaBold dark:text-white">
        Training Configuration
      </h2>
    </div>
    <div class="space-y-4 popBody py-3 scrollbar-thin scrollbar-thumb-[#000733]/20 scrollbar-track-transparent overflow-y-scroll" >
      <div class="grid grid-cols-12 items-start">
        <div class="col-span-12 md:col-span-4">
          <span class="font-avertaBold block dark:text-white duration-200"
            >Email Configuration:</span
          >
        </div>
        <div class="col-span-12 md:col-span-8">
          <p class="text-sm text-[#B29600] bg-[#ECD60E]/30 p-4 rounded-2xl">
            <b class="font-avertaBold">Note!</b> Training mail configuration are
            used for setting notifications emails to employee based on manual
            trigger Employee training details page.
          </p>
        </div>
      </div>
      <div class="grid grid-cols-12 items-center">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
            >FROM <span class="text-red-600">*</span></span
          >
          <span class="text-xs text-gray-500 hidden md:block"
            >Email Id From</span
          >
        </div>
        <div class="col-span-12 md:col-span-8">
          <mat-form-field class="w-full">
            <!-- <mat-label class="text-black">Email</mat-label> -->
            
            <input matInput formControlName="from" />
          </mat-form-field>
          <div
              *ngIf="
                form.get('from')?.hasError('required') &&
                (form.get('from')?.touched || formSubmitted)
              "
            >
              <span class="text-red-600 dark:text-red-300 text-xs">From is required.</span>
            </div>
            <div
              *ngIf="
                form.get('from')?.hasError('email') &&
                (form.get('from')?.touched || formSubmitted)
              "
            >
              <span class="text-red-600 dark:text-red-300 text-xs">Invalid From email.</span>
            </div>
        </div>
      </div>
      <div class="grid grid-cols-12 items-center">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
            >SUBJECT <span class="text-red-600">*</span></span
          >
          <span class="text-xs text-gray-500 hidden md:block">Subject</span>
        </div>
        <div class="col-span-12 md:col-span-8">
          <mat-form-field class="w-full">
            <!-- <mat-label class="text-black">Training mail from Kiosk</mat-label> -->
            <input matInput formControlName="subject" />
          </mat-form-field>
          <div
            *ngIf="
              form.get('subject')?.hasError('required') &&
              (form.get('subject')?.touched || formSubmitted)
            "
          >
            <span class="text-red-600 dark:text-red-300 text-xs">Subject is required.</span>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-12 items-center">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
            >HOST <span class="text-red-600">*</span></span
          >
          <span class="text-xs text-gray-500 hidden md:block">Host Info</span>
        </div>
        <div class="col-span-12 md:col-span-8">
          <mat-form-field class="w-full">
            <!-- <mat-label class="text-black">email-smtp.us-east-1.amazonaws.com</mat-label> -->
            <input matInput formControlName="host" />
          </mat-form-field>
          <div
            *ngIf="
              form.get('host')?.hasError('required') &&
              (form.get('host')?.touched || formSubmitted)
            "
          >
            <span class="text-red-600 dark:text-red-300 text-xs">Host is required.</span>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-12 items-center">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
            >SMTP PORT <span class="text-red-600">*</span></span
          >
          <span class="text-xs text-gray-500 hidden md:block">SMTP</span>
        </div>
        <div class="col-span-12 md:col-span-8">
          <mat-form-field class="w-full">
            <!-- <mat-label class="text-black">587</mat-label> -->
            <input matInput formControlName="port" />
          </mat-form-field>
          <div
            *ngIf="
              form.get('port')?.hasError('required') &&
              (form.get('port')?.touched || formSubmitted)
            "
          >
            <span class="text-red-600 dark:text-red-300 text-xs">SMTP Port is required.</span>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-12 items-center">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
            >USER NAME <span class="text-red-600">*</span></span
          >
          <span class="text-xs text-gray-500 hidden md:block">User Name</span>
        </div>
        <div class="col-span-12 md:col-span-8">
          <mat-form-field class="w-full">
            <!-- <mat-label class="text-black">AKIAIMEFQKOW3GBZR44A</mat-label> -->
            <input matInput formControlName="user_name" />
          </mat-form-field>
          <div
            *ngIf="
              form.get('user_name')?.hasError('required') &&
              (form.get('user_name')?.touched || formSubmitted)
            "
          >
            <span class="text-red-600 dark:text-red-300 text-xs">User name is required.</span>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-12 items-center">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
            >PASSWORD <span class="text-red-600">*</span></span
          >
          <span class="text-xs text-gray-500 hidden md:block">Password</span>
        </div>
        <div class="col-span-12 md:col-span-8">
          <mat-form-field class="w-full">
            <!-- <mat-label class="text-black">AvtioqbW+aG10mx3hA15oN+2Tmf7dQ+BR5GoOjrp32WM</mat-label> -->
            <input matInput formControlName="password" />
          </mat-form-field>
          <div
            *ngIf="
              form.get('password')?.hasError('required') &&
              (form.get('password')?.touched || formSubmitted)
            "
          >
            <span class="text-red-600 dark:text-red-300 text-xs">Password is required.</span>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-12 items-center">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
            >Enable SSL <span class="text-red-600">*</span></span
          >
          <span class="text-xs text-gray-500 hidden md:block"
            >SSL check/uncheck</span
          >
        </div>
        <div class="col-span-12 md:col-span-8">
          <!-- <mat-checkbox
            class="example-margin text-xs text-gray-200"
            formControlName="ssl"
            >By clicking on check box you can Enable SSL Or Disable SSL
          </mat-checkbox> -->
          <label class="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              class="sr-only peer"
              formControlName="ssl"
            />
            <div
              class="relative w-[60px] h-8 bg-gray-300 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
            ></div>
          </label>
        </div>
      </div>
      <div class="font-avertaBold block dark:text-white duration-200 py-6">
        Employee Training Details Configuration:
      </div>
      <div class="grid grid-cols-12 items-center">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
            >Department Restriction Pre-Filter
          </span>
          <span class="text-xs text-gray-500 hidden md:block"
            >Filter Option</span
          >
        </div>
        <div class="col-span-12 md:col-span-8">
          <mat-form-field class="w-full">
            <!-- <mat-label>Department Restriction Pre-Filter</mat-label> -->
            <mat-select
              formControlName="pre_department"
              multiple
              (selectionChange)="handlePreDepartmentChange()"
            >
              <button
                (click)="togglePreDepartmentCheck()"
                style="margin-left: 5px"
              >
                <mat-checkbox [checked]="isAllPreDepartmentsSelected()"
                  >Check/Uncheck All</mat-checkbox
                >
              </button>
              <mat-option
                *ngFor="let preDepartment of preDepartmentList"
                [value]="preDepartment.Department"
                >{{ preDepartment.Department }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="grid grid-cols-12 items-center">
        <div class="col-span-12 md:col-span-4">
          <span
            class="font-avertaBold block dark:text-white duration-200 text-sm"
            >Default Department Filter
          </span>
          <span class="text-xs text-gray-500 hidden md:block"
            >Filter Option</span
          >
        </div>
        <div class="col-span-12 md:col-span-8">
          <mat-form-field class="w-full">
            <!-- <mat-label>Department Restriction Pre-Filter</mat-label> -->
            <mat-select formControlName="department" multiple>
              <button
                (click)="toggleDepartmentCheck()"
                style="margin-left: 5px"
              >
                <mat-checkbox [checked]="isAllDepartmentsSelected()"
                  >Check/Uncheck All</mat-checkbox
                >
              </button>
              <mat-option
                *ngFor="let department of departmentList"
                [value]="department"
                >{{ department }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center w-full popFooter">
      <button
        (click)="closeDialog()"
        type="button"
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center dark:bg-white/20 dark:text-white"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
        <span>Close</span>
      </button>
      <button
        mat-raised-button
        type="submit"
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#010D58] text-[#ffffff] flex items-center dark:bg-gray-950"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        </span>
        <span>SAVE</span>
      </button>
    </div>
    <button
      (click)="closeDialog()"
      type="button"
      class="absolute right-[5px] top-[-15px] h-8 w-8 rounded-full flex justify-center items-center bg-gray-100 text-red dark:bg-white/20 dark:text-white"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </span>
    </button>
  </div>
</form>
