import { PaginatorService } from './paginator.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent {
  @Input() records: any[] = [];
  @Input() numberOfLinks: number = 10; // Display number of links
  @Input() length: number = 0; // Default page size
  @Input() pageSize: number = 10; // Default page size
  @Output() pageChange = new EventEmitter<number>();
  @Output() perPageChange = new EventEmitter<number>();

  // currentPage: number = 1;

  constructor(public paginatorService: PaginatorService) { }

  // prevPage(event: Event) {
  //   event.preventDefault();
  //   if (!this.isFirstPage()) {
  //     this.currentPage--;
  //     this.pageChange.emit(this.currentPage);
  //   }
  // }

  // nextPage(event: Event) {
  //   event.preventDefault();
  //   if (!this.isLastPage()) {
  //     this.currentPage++;
  //     this.pageChange.emit(this.currentPage);
  //   }
  // }

  // isFirstPage(): boolean {
  //   return this.currentPage === 1;
  // }

  // isLastPage(): boolean {
  //   return this.currentPage === Math.ceil(this.records.length / this.pageSize);
  // }

  onPageSizeChange(event: Event) {
    const newPageSize = (event.target as HTMLSelectElement).value;
    if (newPageSize) {
      this.pageSize = parseInt(newPageSize, 10);
      // Here you can implement logic to update the pagination based on the new page size
      console.log('New page size:', this.pageSize);
      this.perPageChange.emit(this.pageSize);
    }
  }

  nextPage(event: Event) {
    event.preventDefault();
    if (!this.isLastPage()) {
      this.paginatorService.nextPage();
      this.pageChange.emit(this.paginatorService.currentPage);
    }
  }

  prevPage(event: Event) {
    event.preventDefault();
    if (!this.isFirstPage()) {
      this.paginatorService.prevPage();
      this.pageChange.emit(this.paginatorService.currentPage);
    }
  }

  protected isFirstPage(): boolean {
    return this.paginatorService.currentPage === 1;
  }

  protected isLastPage(): boolean {
    const totalPages = Math.ceil(this.length / this.pageSize);
    return this.paginatorService.currentPage === totalPages;
  }

  generatePageLinks(): (string | number)[] {
    const totalRecords = this.length;
    const totalPages = Math.ceil(totalRecords / this.pageSize);
    const currentPage = this.paginatorService.currentPage;

    const displayLimit = this.numberOfLinks;
    const firstPage = 1;
    const lastPage = totalPages;
    const pagesToShow: (string | number)[] = [];
    if (totalPages <= displayLimit) {
      // If total pages are less than or equal to the display limit, show all pages
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      if (currentPage <= displayLimit - 5) {
        // Show first 'displayLimit - 4' pages, ellipsis, and last page
        for (let i = 1; i <= displayLimit - 4; i++) {
          pagesToShow.push(i);
        }
        pagesToShow.push('...');
        pagesToShow.push(lastPage);
      } else if (currentPage >= totalPages - 4) {
        // Show first page, ellipsis, and last 'displayLimit - 4' pages
        pagesToShow.push(firstPage);
        pagesToShow.push('...');
        for (let i = totalPages - (displayLimit - 4); i <= totalPages; i++) {
          pagesToShow.push(i);
        }
      } else {
        // Show first page, ellipsis, current page, surrounding pages, ellipsis, and last page
        pagesToShow.push(firstPage);
        pagesToShow.push('...');
        for (let i = currentPage - 2; i <= currentPage + 2; i++) {
          pagesToShow.push(i);
        }
        pagesToShow.push('...');
        pagesToShow.push(lastPage);
      }
      return pagesToShow;
    }
  }

  handlePageClick(page: any) {
    // this.currentPage = page;
    this.paginatorService.currentPage = page;
    this.pageChange.emit(page);
  }

  get dataSource(): any[] {
    const startIndex = (this.paginatorService.currentPage - 1) * this.pageSize;
    return this.records.slice(startIndex, startIndex + this.pageSize);
  }

}
