import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutsComponent } from './layouts/layouts.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './core/guards/auth.guard';
import { RcComponent } from './select-rc/rc.component';
import { InventoryAccessGuard } from './core/guards/inventory-access.guard';
import { BlankComponent } from './layouts/blank/blank.component';
import { RedirectPageComponent } from './redirect-page/redirect-page.component';
import { IsRmsUserGuard } from './core/guards/is-rms-user.guard';

/* const routes: Routes = [
  // { path: '', redirectTo: '/select-rc', pathMatch: 'full' },
  {
    path: 'select-rc',
    // canActivate: [AuthGuard],
    component: RcComponent,
    loadChildren: () => import('./select-rc/rc.module').then(m => m.RcModule)
  },
  {
    path: ':rc/auth',
    component: AuthComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: ':rc/inventory',
    canActivate: [AuthGuard, InventoryAccessGuard], // Separate guard for authorization within RC-specific routes
    component: LayoutsComponent,
    loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
    data: { feature: 'inventory' },
  },
  {
    path: ':rc',
    canActivate: [AuthGuard],
    component: LayoutsComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  
  // Other routes...
  // { path: '**', redirectTo: '/select-rc' } // Redirect any invalid URL to RC selection page
]; */

const routes: Routes = [
  // Redirect to /select-rc as a default route
  // { path: '', redirectTo: '/select-rc', pathMatch: 'full' },

  {
    path: 'select-rc',
    component: RcComponent,
    loadChildren: () => import('./select-rc/rc.module').then(m => m.RcModule)
  },
  { path: 'inventoryloginbridges', component: RedirectPageComponent },
  {
    path: 'auth',
    component: AuthComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'inventory',
    canActivate: [InventoryAccessGuard],
    component: LayoutsComponent,
    loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
    data: { feature: 'inventory' }
  },
  {
    path: 'barcode',
    component: BlankComponent,
    loadChildren: () => import('./barcode/barcode.module').then(m => m.BarCodeModule),
  },
  {
    path: '',
    canActivate: [AuthGuard, IsRmsUserGuard],
    component: LayoutsComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: ':rc/auth',
    component: AuthComponent,
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: ':rc/inventory',
    canActivate: [InventoryAccessGuard],
    component: LayoutsComponent,
    loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
    data: { feature: 'inventory' }
  },
  {
    path: ':rc',
    canActivate: [],
    component: LayoutsComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },

  // Other routes...
  // { path: '**', redirectTo: '/select-rc' } // Redirect any invalid URL to RC selection page
  { path: '**', redirectTo: '/' } // Redirect any invalid URL to RC selection page
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
  // providers: [AuthGuard]
})
export class AppRoutingModule { }
