import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private isDarkThemeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isDarkTheme$: Observable<boolean> = this.isDarkThemeSubject.asObservable();

  constructor() {
    // Load theme preference from local storage if available
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      this.isDarkThemeSubject.next(savedTheme === 'dark');
    }
  }

  toggleTheme() {
    const isDarkTheme = !this.isDarkThemeSubject.value;
    this.isDarkThemeSubject.next(isDarkTheme);
    // Save theme preference to local storage
    localStorage.setItem('theme', isDarkTheme ? 'dark' : 'light');
  }

  getCurrentThemeState(): boolean {
    return this.isDarkThemeSubject.value;
  }
}
