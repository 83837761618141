<app-spinner></app-spinner>
<div
  class="font-avertareguler w-full h-screen px-2 py-10 md:py-10 md:p-4 bg-white/[67%] dark:bg-slate-900/60 space-y-4 relative"
>
  <div class="flex">
    <h2
      class="text-[#102030] text-[16px] md:text-[20px] font-avertaBold dark:text-white"
    >
      Profile Options
    </h2>
  </div>
  <div
    class="space-y-4 popBody scrollbar-thin scrollbar-thumb-[#000733]/20 scrollbar-track-transparent overflow-y-scroll"
  >
    <mat-tab-group
      [selectedIndex]="selectedTab"
      (selectedTabChange)="changeTab($event)"
      disablePagination="true"
    >
      <!-- Defaults tab -->
      <mat-tab label="Defaults">
        <form [formGroup]="profileForm">
          <div class="py-8">
            <div class="space-y-3">
              <div>
                <mat-checkbox
                  class="example-margin"
                  formControlName="profileType1"
                >
                  Default Review Screen to
                  <span class="font-avertaBold"> All By Date</span>
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox
                  class="example-margin"
                  formControlName="profileType2"
                >
                  Default Review Screen to
                  <span class="font-avertaBold"> View Details</span>
                </mat-checkbox>
              </div>
              <div>
                <mat-checkbox
                  class="example-margin"
                  formControlName="profileType4"
                >
                  Default Review Screen to
                  <span class="font-avertaBold"> Direct only ⟫ All</span>
                </mat-checkbox>
              </div>
              <div>
                <div class="flex justify-start items-center space-x-3">
                  <label class="text-sm font-avertaSemibold">Theme</label>
                  <mat-form-field class="w-[200px]">
                    <mat-label>Select Theme</mat-label>
                    <mat-select formControlName="profileType3">
                      <mat-option
                        *ngFor="let theme of themes"
                        [value]="theme"
                        >{{ theme | titlecase }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex justify-end">
                <button
                  class="py-4 px-6 space-x-1 rounded-lg text-[16px] bg-gradient-to-t from-[#184900] to-[#39B300] text-[#ffffff] flex items-center"
                  (click)="saveSettings()"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16L21 8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21Z"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 21V13H7V21"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7 3V8H15"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <span>SAVE</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </mat-tab>

      <!-- Groups tab -->
      <mat-tab *ngIf="isSupervisor" label="Groups">
        <div class="py-8">
          <div class="space-y-6">
            <div class="flex items-stretch justify-start space-x-1">
              <mat-form-field class="w-full sm:w-72">
                <mat-label>Select Group</mat-label>
                <mat-select [(ngModel)]="selectedGroupId">
                  <mat-option
                    *ngFor="let group of groupsList"
                    [value]="group.GroupID"
                    (click)="getGroupEmployees(group)"
                  >
                    {{ group.GroupName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button
                class="border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-500 p-3 rounded-2xl text-green-700"
                (click)="toggleAddGroupPopup()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </button>
              <button
                class="border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-500 p-3 rounded-2xl text-blue-600"
                [disabled]="!selectedGroupId || selectedGroupId == 0"
                (click)="toggleEditGroupPopup()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                  />
                </svg>
              </button>
              <button
                class="border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-500 p-3 rounded-2xl text-red-600"
                [disabled]="!selectedGroupId || selectedGroupId == 0"
                (click)="deleteGroup()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </button>
            </div>
            <div
              class="px-3 py-4 rounded-lg bg-[#F0F0F0] seter-h-vw-cheker dark:bg-gray-700"
            >
              <p class="font-avertaBold mb-3 dark:text-white">Employees</p>
              <section class="example-section">
                <span class="example-list-section">
                  <mat-checkbox
                    class="example-margin font-avertaBold"
                    [checked]="allGroupComplete"
                    [indeterminate]="someCompleteGroupTask()"
                    (change)="setAllGroupTask($event.checked)"
                  >
                    {{ groupTask.name }}
                  </mat-checkbox>
                </span>
                <span class="example-list-section">
                  <ul class="">
                    <li *ngFor="let subtask of groupTask.subtasks">
                      <!-- [(ngModel)]="subtask.completed" -->
                      <!-- (ngModelChange)="updateAllCompleteGroupTask()" -->
                      <mat-checkbox
                        (change)="onGroupItemClick($event.checked, subtask)"
                        [(ngModel)]="subtask.completed"
                      >
                        {{ subtask.name }}
                      </mat-checkbox>
                    </li>
                  </ul>
                </span>
              </section>
            </div>
            <div class="flex justify-end">
              <button
                class="py-4 px-6 space-x-1 rounded-lg text-[16px] bg-gradient-to-t from-[#184900] to-[#39B300] text-[#ffffff] flex items-center"
                (click)="saveGroupEmployees()"
                [disabled]="!selectedGroupId || selectedGroupId == 0"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16L21 8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 21V13H7V21"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 3V8H15"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span>SAVE</span>
              </button>
            </div>
          </div>
        </div>
      </mat-tab>

      <!-- Review Groups tab -->
      <mat-tab label="Review Groups">
        <div class="py-8">
          <div class="space-y-6">
            <div class="flex items-stretch justify-start space-x-1">
              <mat-form-field class="w-full sm:w-72">
                <mat-label>Select Reviewer...</mat-label>
                <mat-select [(ngModel)]="selectedReviewGroupId">
                  <mat-option
                    *ngFor="let group of reviewGroupsList"
                    [value]="group.ReviewGroupID"
                    (click)="getReviewGroupEmployees(group.ReviewGroupID)"
                  >
                    {{ group.FirstName }} {{ group.LastName }}</mat-option
                  >
                  <mat-option
                    *ngIf="reviewGroupsList?.length == 0"
                    disabled="true"
                  >
                    No reviewer
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <button
                class="border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-500 p-3 rounded-2xl text-green-700"
                (click)="toggleAddReviewGroupPopup()"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              </button>
              <button
                class="border border-gray-300 bg-white dark:bg-gray-700 dark:border-gray-500 p-3 rounded-2xl text-red-600"
                (click)="deleteReviewGroup()"
                [disabled]="
                  !selectedReviewGroupId || selectedReviewGroupId == 0
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </button>
            </div>
            <div
              class="px-3 py-4 rounded-lg bg-[#F0F0F0] seter-h-vw-cheker dark:bg-gray-700"
            >
              <p class="font-avertaBold mb-3 dark:text-white">Employees</p>
              <section class="example-section">
                <span class="example-list-section">
                  <mat-checkbox
                    class="example-margin font-avertaBold"
                    [checked]="allReviewGroupComplete"
                    [indeterminate]="someCompleteReviewGroupTask()"
                    (change)="setAllReviewGroupTask($event.checked)"
                  >
                    {{ reviewGroupTask.name }}
                  </mat-checkbox>
                </span>
                <span class="example-list-section">
                  <ul class="">
                    <li
                      *ngFor="
                        let reviewSubtasks of reviewGroupTask.reviewSubtasks
                      "
                    >
                      <!-- [(ngModel)]="reviewSubtasks.completed" -->
                      <!-- (ngModelChange)="updateAllCompleteReviewGroupTask()" -->
                      <mat-checkbox
                        (change)="
                          onReviewGroupItemClick($event.checked, reviewSubtasks)
                        "
                        [(ngModel)]="reviewSubtasks.completed"
                      >
                        {{ reviewSubtasks.name }}
                      </mat-checkbox>
                    </li>
                  </ul>
                </span>
              </section>
            </div>
            <div class="flex justify-end">
              <button
                class="py-4 px-6 space-x-1 rounded-lg text-[16px] bg-gradient-to-t from-[#184900] to-[#39B300] text-[#ffffff] flex items-center"
                (click)="saveReviewGroupEmployees()"
                [disabled]="
                  !selectedReviewGroupId || selectedReviewGroupId == 0
                "
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16L21 8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 21V13H7V21"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 3V8H15"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <span>SAVE</span>
              </button>
            </div>
          </div>
        </div>
      </mat-tab>

      @if (isMaintenanceUser) {
      <!-- Maintenance tab -->
      <mat-tab label="Maintenance">
        <div class="py-4">
          <div class="space-y-6">
            <maintenance (formStatus)="checkMaintenanceFormState($event)" />
          </div>
        </div>
      </mat-tab>
      }
    </mat-tab-group>
  </div>
  <div class="flex justify-end items-center w-full popFooter gap-2">
    <button
      class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center dark:bg-white/20 dark:text-white"
      (click)="closeProfileDialog()"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </span>
      <span>Close</span>
    </button>
    @if (isMaintenanceUser && selectedTab === 3) {
    <button
      class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-gradient-to-t from-[#184900] to-[#39B300] text-[#ffffff] flex items-center "
      [ngClass]="{
        'bg-gradient-to-t from-[#184900] to-[#39B300]': isMaintenanceFormDirty,
        'bg-gray-600': !isMaintenanceFormDirty
      }"
      (click)="saveMaintenanceData()"
      [disabled]="!isMaintenanceFormDirty"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H16L21 8V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17 21V13H7V21"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7 3V8H15"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <span>SAVE</span>
    </button>
    }
  </div>
  <button
    (click)="closeProfileDialog()"
    type="button"
    class="absolute right-[5px] top-[-12px] h-8 w-8 rounded-full flex justify-center items-center bg-gray-100 text-red dark:bg-white/20 dark:text-white"
  >
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </span>
  </button>
</div>

<!-- Add Group Popup -->
<div
  *ngIf="showAddGroupPopup"
  class="createGrp-User fixed left-0 top-0 w-full h-screen z-10 flex items-center justify-center font-avertareguler"
>
  <div
    class="p-6 rounded-xl w-11/12 md:w-4/12 space-y-4 relative z-20 shadow-2xl bg-white/[67%] dark:bg-slate-900/60"
  >
    <div class="font-avertaBold text-[20px] dark:text-white">Add Group</div>
    <div>
      <mat-form-field class="example-form-field w-full">
        <mat-label>Group Name</mat-label>
        <input matInput type="text" placeholder="" [(ngModel)]="groupName" />
        <button
          *ngIf="groupName"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="groupName = ''"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="flex justify-between items-center w-full popFooter">
      <button
        type="button"
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center dark:bg-white/20 dark:text-white"
        (click)="toggleAddGroupPopup()"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
        <span>Close</span>
      </button>
      <button
        type="submit"
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#010D58] text-[#ffffff] flex items-center dark:bg-gray-950"
        (click)="saveGroup()"
        [disabled]="!groupName"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        </span>
        <span>SAVE</span>
      </button>
    </div>
  </div>
</div>

<!-- Edit Group Popup -->
<div
  *ngIf="showEditGroupPopup"
  class="createGrp-User fixed left-0 top-0 w-full h-screen z-10 flex items-center justify-center font-avertareguler"
>
  <div
    class="p-6 rounded-xl w-11/12 md:w-4/12 space-y-4 relative z-20 shadow-2xl bg-white/[67%] dark:bg-slate-900/60"
  >
    <div class="font-avertaBold text-[20px] dark:text-white">Edit Group</div>
    <div>
      <mat-form-field class="example-form-field w-full">
        <mat-label>Group Name</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="editGroupName"
          [value]="editGroupName"
        />
        <button
          *ngIf="editGroupName"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="editGroupName = ''"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="flex justify-between items-center w-full popFooter">
      <button
        type="button"
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center dark:bg-white/20 dark:text-white"
        (click)="toggleEditGroupPopup()"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
        <span>Close</span>
      </button>
      <button
        type="submit"
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#010D58] text-[#ffffff] flex items-center dark:bg-gray-950"
        (click)="updateGroup()"
        [disabled]="!editGroupName"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        </span>
        <span>SAVE</span>
      </button>
    </div>
  </div>
</div>

<!-- Add Review Group Popup -->
<div
  *ngIf="showAddReviewGroupPopup"
  class="createGrp-User fixed left-0 top-0 w-full h-screen z-10 flex items-center justify-center font-avertareguler"
>
  <div
    class="p-6 rounded-xl w-11/12 md:w-4/12 space-y-4 relative z-20 shadow-2xl bg-white/[67%] dark:bg-slate-900/60"
  >
    <div class="font-avertaBold text-[20px] dark:text-white">Add Reviewer</div>
    <div>
      <!-- <mat-form-field class="example-form-field w-full">
        <mat-label>Group Name</mat-label>
        <input matInput type="text" placeholder="Please Enter Group Name..." [(ngModel)]="reviewGroupName">
        <button *ngIf="reviewGroupName" matSuffix mat-icon-button aria-label="Clear" (click)="reviewGroupName=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field> -->
      <mat-form-field class="w-full">
        <mat-label>Select Reviewer...</mat-label>
        <mat-select [(ngModel)]="reviewer">
          <mat-option
            *ngFor="let reviewer of reviewersList"
            [value]="reviewer.EmployeeNumber"
          >
            {{ reviewer.FirstName }}, {{ reviewer.LastName }} ({{
              reviewer.EmployeeNumber
            }})</mat-option
          >
          <mat-option *ngIf="reviewersList.length == 0" disabled="true">
            No reviewer
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex justify-between items-center w-full popFooter">
      <button
        type="button"
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center dark:bg-white/20 dark:text-white"
        (click)="toggleAddReviewGroupPopup()"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
        <span>Close</span>
      </button>
      <button
        type="submit"
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#010D58] text-[#ffffff] flex items-center dark:bg-gray-950"
        (click)="saveReviewGroup()"
        [disabled]="!reviewer"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        </span>
        <span>SAVE</span>
      </button>
    </div>
  </div>
</div>
