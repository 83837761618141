import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'isDate'
})
export class IsDatePipe implements PipeTransform {
  transform(value: any): any {
    const datePipe = new DatePipe('en-US');
    if (value instanceof Date || this.isValidDate(value)) {
      return datePipe.transform(new Date(value), 'MM/dd/yyyy');
    }
    return value;
  }

  isValidDate(dateString: string): boolean {
    // Regular expression to match valid date strings like "2021/05/25T00:00:00" or "2021-05-25T00:00:00"
    const isoDatePattern = /^\d{4}[\/-]\d{2}[\/-]\d{2}T\d{2}:\d{2}:\d{2}$/;
    return isoDatePattern.test(dateString);
  }
}
