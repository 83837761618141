import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  currentPage: number = 1;
  public pageSize: number = 30; // set your desired page size here

  calculateOffset(): number {
    return (this.currentPage - 1) * this.pageSize;
  }

  firstPage() {
    this.currentPage = 1;
  }

  nextPage() {
    this.currentPage++;
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  
}