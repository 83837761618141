import { Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { RcService } from 'src/app/core/services/rc.service';

@Component({
  selector: 'ConcurrentLogin', 
  templateUrl: './ConcurrentLogin.component.html',
  styleUrl: './ConcurrentLogin.component.css'
})
export class ConcurrentLoginComponent  {

  constructor(private authService: AuthService, private router: Router,public rcService: RcService){
    
  }
  ngOnInit() {
    // This hook is called after the component is initialized
    console.log('CustomToastComponent initialized');
  }
  CloseConcurrentLogin() {
    // Define your method logic here
    console.log('Button clicked in toast');

    this.authService.clearUserData();
    this.router.navigate([`/${this.rcService.getTenant()}/auth/login`]);
  }
}
