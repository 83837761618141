import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy'
})

export class SortByPipe implements PipeTransform {
  transform(array: any[], field: string, reverse: boolean = false): any[] {
    const newArray = array.sort((a: any, b: any) => {
      const x = a[field];
      const y = b[field];
      return reverse ? y - x : x - y;
    });
    return newArray;
  }
}
