<div
  class="font-avertareguler w-full h-screen px-2 py-10 md:py-10 md:p-4 bg-white/[67%] dark:bg-slate-900/60 space-y-4 relative"
>
  <div class="flex">
    <h2
      class="text-[#102030] text-[16px] md:text-[20px] font-avertaBold dark:text-white"
    >
    {{ data.title }}
    </h2>
  </div>
  <div
    class="popBody scrollbar-thin scrollbar-thumb-[#000733]/20 scrollbar-track-transparent overflow-y-scroll"
  >
    <div class="relative overflow-x-auto">
      <div class="w-full m-auto text-center">
        <div class="bg-white rounded-lg p-8 shadow-md">
          <ng-container *ngIf="isArray; else singleMessage">
            <div>
              <ng-container *ngFor="let itm of data.message">
                <div
                  class="flex items-center p-4 mb-4 text-sm rounded-lg bg-blue-50 dark:bg-gray-800"
                  [ngClass]="{
                      'text-green-800 dark:text-green-400': itm.type == 'success',
                      'text-blue-800 dark:text-blue-400': itm.type == 'info',
                      'text-red-800 dark:text-red-400': itm.type == 'error',
                  }"
                  role="alert"
                >
                  <svg
                    class="flex-shrink-0 inline w-4 h-4 me-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
                    />
                  </svg>
                  <span class="sr-only">Info</span>
                  <div>
                    {{ itm.msg }}
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-template #singleMessage>
            <div
              *ngIf="data.type === 'success'"
              class="flex p-4 mb-4 text-sm text-green-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-green-400"
              role="alert"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <span class="sr-only">Info</span>
              <div>
                <span class="font-medium">Success:</span> {{ data.message }}
              </div>
            </div>
            <div
              *ngIf="data.type === 'error'"
              class="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
              role="alert"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <span class="sr-only">Error</span>
              <div>
                <span class="font-medium">Error:</span> {{ data.message }}
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-end items-center w-full popFooter">
    <button
      class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center dark:bg-white/20 dark:text-white"
      (click)="closeAlert()"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </span>
      <span>Close</span>
    </button>
  </div>

  <button
    (click)="closeAlert()"
    type="button"
    class="absolute right-[5px] top-[-15px] h-8 w-8 rounded-full flex justify-center items-center bg-gray-100 text-red dark:bg-white/20 dark:text-white"
  >
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </span>
  </button>
</div>
