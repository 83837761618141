import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, withLatestFrom } from 'rxjs/operators';
import * as UserActions from './../actions/user.actions';
import { selectUserState } from './../selectors/user.selectors';

@Injectable()
export class UserEffects {
  persistUserState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.login, UserActions.logout),
      withLatestFrom(this.store.select(selectUserState)),
      tap(([action, userState]) => {
        localStorage.setItem('user', JSON.stringify(userState.user));
      })
    ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store) {}
}