import { Injectable, RendererFactory2 } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService, 
    private router: Router, 
    private toastr: ToastrService,
    private rendererFactory: RendererFactory2
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.authService.isAuthenticated() && state.url === '/') {
      // No RC is selected, redirect to select-rc
      // this.router.navigate(['/select-rc']);
      this.router.navigate(['/auth/login']);
    }
    // If user is already authenticated, redirect to home page
    /* if (this.authService.isAuthenticated()) {
      this.applyDirectiveToRouterLinks();
      return true; // Prevent further navigation
    }

    // Check if an RC is selected
    const rc = next.paramMap.get('rc');
    if (rc) {
      // An RC is selected but the user is not logged in, redirect to login
      this.router.navigate([`/${rc}/auth/login`]);
    } else {
      // No RC is selected, redirect to select-rc
      // this.router.navigate(['/select-rc']);
      this.router.navigate(['/auth/login']);
    }
    return false; */
    return true;
  }

  // applyDirectiveToAnchors() {
  //   // debugger;
  //   const renderer = this.rendererFactory.createRenderer(null, null);
  //   const anchors = document.querySelectorAll('a');
  //   anchors.forEach(anchor => {
  //     renderer.setAttribute(anchor, 'appRc', '');
  //   });
  // }

  applyDirectiveToRouterLinks() {
    const renderer = this.rendererFactory.createRenderer(null, null);
    const routerLinks = document.querySelectorAll('[routerLink]');
    routerLinks.forEach(link => {
      renderer.setAttribute(link, 'appRc', '');
    });
  }

}


// export class AuthGuard implements CanActivate {
//   constructor(private authService: AuthService, private router: Router, private toastr: ToastrService) {}

//   canActivate(
//     next: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot
//   ): boolean {
//     if (this.authService.isAuthenticated()) {
//       // let currentUrl = state.url;
//       // debugger;
//       // User is logged in, allow access to the route
//       return true;
//     } else {
//       debugger;
//       // Check if an RC is selected
//       const rc = next.paramMap.get('rc');
//       if (rc) {
//         // An RC is selected but the user is not logged in, redirect to login
//         this.router.navigate([rc, '/auth/login']);
//       } else {
//         // No RC is selected, redirect to select-rc
//         this.router.navigate(['/select-rc']);
//       }
//       return false;
//     }
//   }
// }
