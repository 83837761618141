import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, from, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ConstantsService } from './constants.service';
import { Store } from '@ngrx/store';
import * as UserActions from 'src/app/store/actions/user.actions';
import { UserState } from 'src/app/store/state/user.state';

declare var $: any;
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenKey = 'token';
  private userKey = 'user';

  // get tenants
  private readonly getTenantUrl = '/authenticate/gettenant';

  private loginUrl = 'Authenticate';
  private isValidLoginUrl = '/authenticate/IsValidLogin';
  private validateRedirectUrl = '/authenticate/getAuthenticateUserInfo';
  private getEmployeeImageUrl = '/employeestatus/getEmployeeImages';
  private isGetInventoryAccessUrl = '/Authenticate/GetInventoryAccess';
  private userLogOutTokensUrl = '/authenticate/UserLogOutTokens';
  private isTimeClockAvailableLoginScreenUrl = '/authenticate/TimeClockAvailableLoginScreen';
  private timeClockAvailableHomeScreenUrl = '/authenticate/timeClockAvailableHomeScreen';
  private SaveEmployeeUserNotificationUpgradeURL = '/authenticate/SaveEmployeeUserNotificationUpgrade';
  private checkIsValidLogin = true;

  private _notesHubProxy: any = undefined;
  private connectionId: any = undefined;
  private connection: any;
  private proxy: any;

  private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private apiUrl ='/ItemMaster/GetItemMasterInitialData'
  userData: any;

  constructor(
    private http: HttpClient,
    private constantService: ConstantsService,
    private store: Store
  ) {
    const storedUser = localStorage.getItem(this.userKey);
    if (storedUser) {
      this.currentUserSubject.next(JSON.parse(storedUser));
    }
  }

  public SaveEmployeeUserNotificationUpgrade(employeeNumber: string, employeeResponse: boolean): Observable<any> {
    return this.http.post<boolean>(`${this.SaveEmployeeUserNotificationUpgradeURL}?EmployeeNumber=${employeeNumber}&EmployeeResponse=${employeeResponse}`, {});

  }

  public initializeSignalRConnection(): void {
    this.connection = $.hubConnection(`${this.constantService.Signalr_URL}`, { useDefaultPath: false });
    // Please note that by default '/signalr' URL will be used to connect to your SignalR service. Providing 'useDefaultPath' allows you to customize it
    this.proxy = this.connection.createHubProxy('notesHub');
    this.connection.start().done((data: any) => {
      console.log('Connected to Connection Hub');
      this.connectionId = this.connection.id
      let user: any = localStorage.getItem(this.userKey);
      let userData = JSON.parse(user);
      if (this.connectionId == undefined) {
        this.proxy.invoke('AddNewUser', userData.credentials.EmployeeNumber, sessionStorage.getItem('EmpTenant'), this.connectionId)

          .catch((error: any) => {
            console.log('sending message error -> ' + error);
          });
      }
    }).catch((error: any) => {
      console.log('Connection Hub error:' + error);
    });
  }

  login(credentials: { EmployeeNumber: string; Password: string }): Observable<any> {
    return this.http.put(`${this.loginUrl}`, credentials).pipe(
      tap((response: any) => {
        if (response && response.LoginToken) {
          this.setToken(response.LoginToken);
          const loginData = {
            ...response,
            token: response.LoginToken,
            credentials: {
              EmployeeNumber: credentials.EmployeeNumber,
              Password: credentials.Password
            }
          };
          this.store.dispatch(UserActions.login({ user: loginData }));
          this.setCurrentUser(loginData);
        }
      }));
  }

  hydrateUserState() {
    const storedState = localStorage.getItem('user');
    // console.log('Hydrating storedState:', storedState);
    if (storedState) {
      const state = JSON.parse(storedState) as UserState;
      this.store.dispatch(UserActions.login({ user: state }));
      // console.log('Hydrating user state:', state);
      this.store.dispatch(UserActions.hydrateSuccess({ state }));
    }
  }

  authenticateUser(credentials: any): Observable<any> {
    return this.http.put(`${this.loginUrl}`, credentials);
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  setCurrentUser(user: any): void {
    this.checkIsValidLogin = false;
    localStorage.setItem(this.userKey, JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  getCurrentUser(): Observable<any> {
    if (this.checkIsValidLogin) {
      this.checkActiveSessions().subscribe((isValid) => {
        if (isValid) {
          // If the login is still valid, fetch the current user
          this.fetchCurrentUser();
        }
        return isValid;
      });
    }

    return this.currentUserSubject.asObservable();
  }

  private fetchCurrentUser(): void {
    // Fetch the current user from local storage and update the BehaviorSubject
    const storedUser = localStorage.getItem(this.userKey);
    if (storedUser) {
      this.currentUserSubject.next(JSON.parse(storedUser));
    }
  }

  clearUserData(): void {
    // debugger;
    this.logout().subscribe(() => {
      localStorage.removeItem(this.tokenKey);
      localStorage.removeItem(this.userKey);
      this.currentUserSubject.next(null);
      location.reload();
    }, (err) => {
      console.log('err', err);
      // location.href = '/';
    });
  }

  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  checkActiveSessions(employeeNumber?: string): Observable<boolean> {
    if (!employeeNumber) {
      // If employee number is not provided, try to get it from the current user data
      const storedUser = localStorage.getItem(this.userKey);
      const userData = storedUser ? JSON.parse(storedUser) : null;
      employeeNumber = userData?.credentials?.EmployeeNumber;
    }

    if (!employeeNumber) {
      // If employee number is still not available, return an observable with false
      return of(false);
    }

    // Make an API call to check active sessions
    return this.http.post<boolean>(`${this.isValidLoginUrl}?EmployeeNumber=${employeeNumber}`, {});
  }

  checkInventoryAccess(employeeNumber: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.isGetInventoryAccessUrl}?EmployeeNumber=${employeeNumber}`);
  }
  
  getEmployeeImage(employeeNumber: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.getEmployeeImageUrl}?EmployeeNumber=${employeeNumber}`);
  }

  logout(): Observable<any> {
    // localStorage.removeItem(this.tokenKey);
    sessionStorage.removeItem('selectedEmployee');
    let user: any = localStorage.getItem(this.userKey);
    let userData = JSON.parse(user);
    let employeeNumber = userData?.credentials?.EmployeeNumber;
    let UserToken = this.getToken();
    if (employeeNumber !== undefined) {
      return this.http.post<boolean>(`${this.userLogOutTokensUrl}?EmployeeNumber=${employeeNumber}&UserToken=${UserToken}`, {});
    } else {
      return of(true);
    }
    // this.clearUserData();
  }

  //fetch employee training notifications list
  fetchTrainingNotifications(
    itemsPerPage: any,
    page: number,
    sortBy: string,
    sortColumn: string,
  ): Observable<any> {
    let user: any = localStorage.getItem(this.userKey);
    let userData = JSON.parse(user);
    let employeeNumber = userData?.credentials?.EmployeeNumber;
    return this.http.get(`/Training/getTrainingNotificationsMessageByEmployeeNumber?ClientToken=${this.constantService.CLIENT_TOKEN}&EmployeeNumber=${employeeNumber}&ItemsPerPage=${itemsPerPage}&Page=${page}&Search=&Searchcolumn=&SortBy=${sortBy}&SortColumn=${sortColumn}&UserToken=${this.getToken()}&reverse=false`);
  }

  // get notifications list
  getNotifications(): Observable<any> {
    let user: any = localStorage.getItem(this.userKey);
    let userData = JSON.parse(user);
    let employeeNumber = userData?.credentials?.EmployeeNumber;
    const payload = {
      ClientToken: this.constantService.CLIENT_TOKEN,
      UserToken: this.getToken(),
      EmployeeNumber: employeeNumber,
    };
    return this.http.post(`/employeestatus/getnotifications`, payload);
  }

  // get notifications list
  useLocationServices(): Observable<any> {
    const payload = {};
    return this.http.post(`/location/uselocationservices`, payload);
  }

  getSingleTenant(): Observable<any> {
    return this.http.get(`${this.getTenantUrl}`);
  }

  isTimeClockAvailableLoginScreen(): Observable<any> {
    return this.http.get(`${this.isTimeClockAvailableLoginScreenUrl}`);
  }

  isTimeClockAvailableHomeScreen(userHideTime: any): Observable<any> {
    let token: any = this.getToken();
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.constantService.CLIENT_TOKEN);
    httpParams = httpParams.append('UserToken', token);
    httpParams = httpParams.append('UserHideTime', userHideTime);
    return this.http.get(`${this.timeClockAvailableHomeScreenUrl}`, { params: httpParams });
  }

  updateEmployeeStatus(employeeNumber: number, bypassLocation: boolean): Observable<any> {
    let _clientToken = this.constantService.CLIENT_TOKEN;
    return this.getLatLong(bypassLocation).pipe(
      map((d) => {
        let token: any = this.getToken();
        let params: any = {};
        params.EmployeeNumber = employeeNumber;
        params.UserToken = token;
        params.ClientToken = _clientToken;

        if (d.coords) {
          params.Latitude = d.coords.latitude;
          params.Longitude = d.coords.longitude;
        } else {
          params.Latitude = "";
          params.Longitude = "";
        }

        return params;
      }),
      switchMap(params => this.http.post('EmployeeStatus', { ...params }))
    );
  }

  getLatLong(bypassLocation: boolean): Observable<any> {
    let user: any = localStorage.getItem('user');
    // this.authService.getToken()
    let userData = JSON.parse(user);
    let LocationServices = userData?.tailoring?.LocationServices;
    if (LocationServices && !bypassLocation) {
      return from(
        new Promise((res, rej) => {
          navigator.geolocation.getCurrentPosition(res, rej);
        })
      );
    } else {
      return from(Promise.resolve(''));
    }
  }

  validateRedirectUser(employeeNumber: string, clientToken: string, userToken: string): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('EmployeeNumber', employeeNumber);
    httpParams = httpParams.append('UserToken', userToken);
    httpParams = httpParams.append('ClientToken', clientToken);
    return this.http.get<string>(`${this.validateRedirectUrl}`, { params: httpParams });
  }

  GetItemMasterInitialDataFlags(): Observable<any> {
    this.getCurrentUser().subscribe((user) => {
      this.userData = user;
    });
      let params = new HttpParams()
      .set('UserName', this.userData?.ApplicationUserName)
      return this.http.get<any>(this.apiUrl,{params})
  }

}
