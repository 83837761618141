import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConstantsService } from 'src/app/core/services/constants.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {
  private getTrainingNotificationsMessageByEmployeeNumberUrl = '/Training/getTrainingNotificationsMessageByEmployeeNumber';

  private readonly clientToken: string = '';
  private readonly authToken: any = '';

  constructor(private http: HttpClient, private authService: AuthService,
    private constantService: ConstantsService) {
    this.clientToken = this.constantService.CLIENT_TOKEN;
    this.authToken = this.authService.getToken();
  }

  getTrainingNotificationsMessageByEmployeeNumber(employeeNumber: number): Observable<any> {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('ClientToken', this.clientToken);
    httpParams = httpParams.append('UserToken', this.authToken);
    httpParams = httpParams.append('EmployeeNumber', employeeNumber);
    return this.http.get(`${this.getTrainingNotificationsMessageByEmployeeNumberUrl}`, { params: httpParams });
  }


}
