<div
  class="flex justify-between items-center mt-5"
  [ngClass]="{ 'justify-end': length === 0 }"
>
  <!-- [ngClass]="{ 'justify-between': length }" -->
  <!-- [ngClass]="{
  'justify-between': length > pageSize,
  'justify-end': !(length > pageSize)
}" -->
  <!-- *ngIf="length > pageSize" -->
  <div class="flex items-center space-x-0 md:space-x-1" *ngIf="length">
    <div class="max-w-sm mx-auto">
      <select
        class="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        (change)="onPageSizeChange($event)"
      >
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
      </select>
    </div>
    <a
      href="javascript:void(0);"
      class="h-6 w-6 md:h-10 md:w-10 justify-center items-center flex text-xs bg-white border border-[#DADADA] rounded-md"
      (click)="prevPage($event)"
      [class.cursor-not-allowed]="isFirstPage()"
      [attr.disabled]="isFirstPage() ? true : null"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="h-3 w-3 md:w-4 md:h-4"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
        />
      </svg>
    </a>

    <!-- Dynamically generate page links -->
    <a
      *ngFor="let page of generatePageLinks()"
      href="javascript:void(0);"
      class="h-6 w-6 md:h-10 md:w-10 justify-center items-center flex text-black bg-[#EEF4FE] rounded-md"
      [class.hover:bg-blue-600]="page !== paginatorService.currentPage"
      [class.hover:text-white]="page !== paginatorService.currentPage"
      [class.transition-duration-200]="page !== paginatorService.currentPage"
      [class.active]="page === paginatorService.currentPage"
      (click)="handlePageClick(page)"
    >
      {{ page }}
    </a>

    <a
      href="javascript:void(0);"
      class="h-6 w-6 md:h-10 md:w-10 justify-center items-center flex text-sm bg-white border border-[#DADADA] rounded-md"
      (click)="nextPage($event)"
      [class.cursor-not-allowed]="isLastPage()"
      [attr.disabled]="isLastPage() ? true : null"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="h-3 w-3 md:w-4 md:h-4"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
        />
      </svg>
    </a>
  </div>

  <div>
    <span class="text-sm text-[#5D5D5D]"
      >Showing {{ dataSource.length }} of {{ length }}</span
    >
  </div>
</div>
