import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { initFlowbite } from 'flowbite';
import { DailogComponent } from './shared/dialog/dailog.component';
import { RcService } from './core/services/rc.service';
import { FlowbiteService } from './services/flowbite.service';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public title = 'RMS - ';

  constructor(
    private dialogRef: MatDialog,
    private router: Router,
    private authService: AuthService,
    private titleService: Title,
    private rcService: RcService,
    private flowbiteService: FlowbiteService
  ) {
    // this.router.events
    //   .pipe(filter(event => event instanceof NavigationEnd))
    //   .subscribe(() => {
    //     const tree = this.router.parseUrl(this.router.url);
    //     const root = tree.root.children['primary'];
    //     const isTenantSelectionPage = this.router.url === '/auth/select-rc'; // Assuming the URL for language selection page is '/language-selection'
    //     if (root && root.segments.length > 0 && root.segments[0].path !== this.rcService.getTenant() && !isTenantSelectionPage) {
    //       this.router.navigateByUrl(`/${this.rcService.getTenant()}${this.router.url}`);
    //     }
    //   });
  }

  ngOnInit() {
    initFlowbite();
    this.authService.hydrateUserState();
    let rc = this.rcService.getTenant();
    /* this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (rc && event.url === '/') {
          this.router.navigate([`/${rc}`]);
        }
        if (!rc && event.url !== '/select-rc') {
          window.location.href = `/select-rc`;
        }
      }
    }); */
  }

  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

  openDailog() {
    this.dialogRef.open(DailogComponent, {
      data: {
        DailogHeading: 'Pop Up'
      }
    });
  }
}
