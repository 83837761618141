import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: '[custom-toast-component]',
  styleUrls: [`./custom-toast.component.css`],
  templateUrl: `./custom-toast.component.html`,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 1,
      })),
      transition('inactive <=> active', animate('500ms ease-out', keyframes([
        style({
          transform: 'translateX(-340px)',
          offset: 0,
          opacity: 0,
        }),
        style({
          offset: .7,
          opacity: 1,
          transform: 'translateX(20px)'
        }),
        style({
          offset: 1,
          transform: 'translateX(0)',
        })
      ]))),
      transition('active => removed', animate('500ms ease-in', keyframes([
        style({
          transform: 'translateX(20px)',
          opacity: 1,
          offset: .2
        }),
        style({
          opacity: 0,
          transform: 'translateX(-340px)',
          offset: 1
        })
      ])))
    ]),
  ],
  preserveWhitespaces: false,
})
export class CustomToastComponent extends Toast {
  private userKey = 'user';
  // used for demo purposes
  undoString = 'undo';
  toastType: any = '';
  type: string = '';

  // Add the 'override' modifier to the property
  override toastrService: ToastrService;

  // constructor is only necessary when not using AoT
  constructor(
    private authService: AuthService,
    toastrService: ToastrService,
    toastPackage: ToastPackage,
    private router: Router,
  ) {
    super(toastrService, toastPackage);
    this.toastrService = toastrService; // Assigning toastrService to the property
    if (toastPackage.toastType) {
      this.toastType = toastPackage.toastType;
      // @ts-ignore
      this.type = toastPackage.config.toastType;
    }
  }
  ReviewPageReload()
  {
    this.toastrService.clear();
    console.log('ReviewPageReload');
    window.location.reload();
    // this.router.navigate([], {
    //   skipLocationChange: true,
    //   queryParamsHandling: 'merge' //== if you need to keep queryParams
    // })
  }
  action(event: Event) {
    event.stopPropagation();
    this.undoString = 'undid';
    this.toastPackage.triggerAction();
    return false;
  }

  yesUpgrade() {
    this.toastrService.clear();
    console.log('yesUpgrade');
    let user: any = localStorage.getItem(this.userKey);
    let userData = JSON.parse(user);
    this.authService.SaveEmployeeUserNotificationUpgrade(userData.credentials.EmployeeNumber,true).subscribe((d) => {
      console.log(d);
    });
  }

  noUpgrade() {
    this.toastrService.clear();
    console.log('noUpgrade');
    let user: any = localStorage.getItem(this.userKey);
    let userData = JSON.parse(user);
    this.authService.SaveEmployeeUserNotificationUpgrade(userData.credentials.EmployeeNumber,false).subscribe((d) => {
      console.log(d);
    });
  }

  askAgainLaterUpgrade() {
    this.toastrService.clear();
    console.log('upgrade ask again');
 

  }

  checkWhatsNew() {
    this.toastrService.clear();
    window.open("https://community.precisioncs.com/knowledgebase.aspx#https://publish.obsidian.md/pcsi/PCSi+Knowledgebase/RMS/Release+Notes", "_blank");
  }

}