<form [formGroup]="maintenanceForm">
  <div class="grid grid-cols-12 gap-4">
    <!-- First column, spans 5 columns -->
    <div class="col-span-12 md:col-span-5">
      <div class="bg-white shadow-md rounded-lg">
        <div class="p-4">
          <div class="grid grid-cols-12">
            <div class="col-span-12">
              <h6 class="text-lg font-semibold">RMS Module Control</h6>
            </div>
          </div>

          <div class="grid grid-cols-12 items-center gap-4">
            <div class="col-span-12 md:col-span-6">
              <!-- <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="GLDepartment"
                  ng-model="ModuleControlMaster.GLDepartment"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label for="form-check-input" class="whitespace-pre-wrap"
                  >GL Department</label
                >
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="GL Department"
                  data-trigger="hover"
                ></i>
              </div> -->
              <div class="flex items-center">
                <input
                  checked
                  id="GLDepartment"
                  type="checkbox"
                  formControlName="GLDepartment"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="GLDepartment"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >GL Department</label
                >
                <span
                  class="text-yellow-500"
                  matTooltip="GL Department"
                  matTooltipPosition="after"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="w-5 h-5"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div class="col-span-12 md:col-span-6">
              <div class="space-y-1">
                <div class="flex align-middle">
                  <label for="input">First Payroll Start Date</label>
                  <span
                    class="text-yellow-500"
                    matTooltip="First Payroll Start Date"
                    matTooltipPosition="after"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </span>
                </div>
                <!-- <kiosk-calendar display-type="'dropdown'" id="'FirstPayrollStartDate'" selected-date="ModuleControlMaster.FirstPayrollStartDate" show-details="false"></kiosk-calendar> -->
                <div class="flex" (click)="openPayrollStartCalendar()">
                  <input
                    type="text"
                    id="FirstPayrollStartDate"
                    placeholder="Select date..."
                    class="bg-white w-full duration-200 border border-[#E2E2E2] py-2 px-4 rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white"
                    formControlName="FirstPayrollStartDate"
                    readonly
                  />
                  <button
                    class="py-2 px-3 space-x-1 rounded-r-lg border border-[#E2E2E2] text-[16px] bg-white text-gray-600 flex items-center dark:text-white dark:bg-gray-700 duration-200 dark:border-gray-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6">
              <!-- <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="ShowAttachments"
                  ng-model="ModuleControlMaster.ShowAttachments"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label for="form-check-input" class="whitespace-pre-wrap"
                  >Show Attachments</label
                >
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Show Attachments"
                  data-trigger="hover"
                ></i>
              </div> -->
              <div class="flex items-center">
                <input
                  checked
                  id="ShowAttachments"
                  type="checkbox"
                  formControlName="ShowAttachments"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="ShowAttachments"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >Show Attachments</label
                >
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <!-- <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="ShowAdditionalHourlyRateApplies"
                  ng-model="ModuleControlMaster.ShowAdditionalHourlyRateApplies"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label for="form-check-input" class="whitespace-pre-wrap"
                  >Show Additional Hourly Rate Applies</label
                >
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Show Additional Hourly Rate Applies"
                  data-trigger="hover"
                ></i>
              </div> -->
              <div class="flex items-center">
                <input
                  checked
                  id="ShowAdditionalHourlyRateApplies"
                  type="checkbox"
                  formControlName="ShowAdditionalHourlyRateApplies"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="ShowAdditionalHourlyRateApplies"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >Show Additional Hourly Rate Applies</label
                >
              </div>
            </div>
          </div>

          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6">
              <div class="space-y-1">
                <label for="input">After Date</label>
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="After Date"
                  data-trigger="hover"
                ></i>
                <!-- <kiosk-calendar
                  display-type="'dropdown'"
                  id="'AfterDate'"
                  selected-date="ModuleControlMaster.AfterDate"
                  show-details="false"
                ></kiosk-calendar> -->
                <div class="flex" (click)="openAfterDateCalendar()">
                  <input
                    type="text"
                    id="AfterDate"
                    placeholder="Select date..."
                    class="bg-white w-full duration-200 border border-[#E2E2E2] py-2 px-4 rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white"
                    formControlName="AfterDate"
                    readonly
                  />
                  <button
                    class="py-2 px-3 space-x-1 rounded-r-lg border border-[#E2E2E2] text-[16px] bg-white text-gray-600 flex items-center dark:text-white dark:bg-gray-700 duration-200 dark:border-gray-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <div class="space-y-1">
                <!-- <label for="input">Message</label>
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Message"
                  data-trigger="hover"
                ></i>
                <input
                  type="text"
                  class="form-input"
                  name="Message"
                  ng-model="ModuleControlMaster.Message"
                  autocomplete="off"
                /> -->
                <div>
                  <label
                    for="Message"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Message</label
                  >
                  <input
                    type="text"
                    id="Message"
                    formControlName="Message"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Message"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6">
              <div class="space-y-1">
                <label for="input">Inventory Locked After Date</label>
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Inventory Locked AfterDate"
                  data-trigger="hover"
                ></i>
                <!-- <kiosk-calendar
                  display-type="'dropdown'"
                  id="'InventoryLockedAfterDate'"
                  selected-date="ModuleControlMaster.InventoryLockedAfterDate"
                  show-details="false"
                ></kiosk-calendar> -->
                <div
                  class="flex"
                  (click)="openInventoryLockedAfterDateCalendar()"
                >
                  <input
                    type="text"
                    id="InventoryLockedAfterDate"
                    placeholder="Select date..."
                    class="bg-white w-full duration-200 border border-[#E2E2E2] py-2 px-4 rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white"
                    formControlName="InventoryLockedAfterDate"
                    readonly
                  />
                  <button
                    class="py-2 px-3 space-x-1 rounded-r-lg border border-[#E2E2E2] text-[16px] bg-white text-gray-600 flex items-center dark:text-white dark:bg-gray-700 duration-200 dark:border-gray-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <!-- <div class="space-y-1">
                <label for="input">Inventory Locked Message</label>
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Inventory Locked Message"
                  data-trigger="hover"
                ></i>
                <input
                  type="text"
                  class="form-input"
                  name="InventoryLockedMessage"
                  ng-model="ModuleControlMaster.InventoryLockedMessage"
                  autocomplete="off"
                />
              </div> -->
              <div>
                <label
                  for="InventoryLockedMessage"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Inventory Locked Message</label
                >
                <input
                  type="text"
                  id="InventoryLockedMessage"
                  formControlName="InventoryLockedMessage"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Inventory Locked Message"
                  required
                />
              </div>
            </div>
          </div>

          <!-- <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6">
              <label for="datetime">Benefits Locked After Date</label>
              <i
                class="fa fa-info-circle text-info ml-1"
                data-boundary="window"
                data-toggle="popover"
                data-content="Benefits Locked After Date"
                data-trigger="hover"
              ></i>
              <kiosk-calendar
                display-type="'dropdown'"
                id="'BenefitsLockedAfterDate'"
                selected-date="ModuleControlMaster.BenefitsLockedAfterDate"
                show-details="false"
              ></kiosk-calendar>
            </div>

            <div class="col-span-12 md:col-span-6">
              <div class="space-y-1">
                <label for="input">Benefits Locked Message</label>
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Benefits Locked Message"
                  data-trigger="hover"
                ></i>
                <input
                  type="text"
                  class="form-input"
                  name="BenefitsLockedMessage"
                  ng-model="ModuleControlMaster.BenefitsLockedMessage"
                  autocomplete="off"
                />
              </div>
            </div>
          </div> -->

          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6">
              <!-- <div class="flex items-center space-x-2">
                <label for="input" class="flex-shrink-0 mb-0"
                  >Employee LogOff<span class="text-red-600">*</span></label
                >
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Employee LogOff"
                  data-trigger="hover"
                ></i>
                <input
                  type="text"
                  class="form-input ml-2"
                  name="EmployeeLogOff"
                  ng-model="ModuleControlMaster.EmployeeLogOff"
                  autocomplete="off"
                />
              </div> -->
              <div>
                <label
                  for="EmployeeLogOff"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Employee LogOff</label
                >
                <input
                  type="text"
                  id="EmployeeLogOff"
                  formControlName="EmployeeLogOff"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Employee LogOff"
                  required
                />
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <!-- <div class="flex items-center space-x-2">
                <label for="input" class="flex-shrink-0 mb-0"
                  >Supervisor LogOff<span class="text-red-600">*</span></label
                >
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Supervisor LogOff"
                  data-trigger="hover"
                ></i>
                <input
                  type="text"
                  class="form-input ml-2"
                  name="SupervisorLogOff"
                  ng-model="ModuleControlMaster.SupervisorLogOff"
                  autocomplete="off"
                />
              </div> -->
              <div>
                <label
                  for="SupervisorLogOff"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Supervisor LogOff</label
                >
                <input
                  type="text"
                  id="SupervisorLogOff"
                  formControlName="SupervisorLogOff"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Supervisor LogOff"
                  required
                />
              </div>
            </div>
          </div>

          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6">
              <!-- <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="TimeClock"
                  ng-model="ModuleControlMaster.TimeClock"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label for="form-check-input" class="whitespace-pre-wrap"
                  >Time Clock</label
                >
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Time Clock"
                  data-trigger="hover"
                ></i>
              </div> -->
              <div class="flex items-center">
                <input
                  checked
                  id="TimeClock"
                  type="checkbox"
                  formControlName="TimeClock"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="TimeClock"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >Time Clock</label
                >
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <!-- <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="TimeClockTotal"
                  ng-model="ModuleControlMaster.TimeClockTotal"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label for="form-check-input" class="whitespace-pre-wrap"
                  >Time Clock Total</label
                >
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Time Clock Total"
                  data-trigger="hover"
                ></i>
              </div> -->
              <div class="flex items-center">
                <input
                  checked
                  id="TimeClockTotal"
                  type="checkbox"
                  formControlName="TimeClockTotal"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="TimeClockTotal"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >Time Clock Total</label
                >
              </div>
            </div>
          </div>

          <div class="grid grid-cols-2 gap-4">
            <div>
              <!-- <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="TimeClockDecimal"
                  ng-model="ModuleControlMaster.TimeClockDecimal"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Time Clock Decimal</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Time Clock Decimal"
                  data-trigger="hover"
                ></i>
              </div> -->
              <div class="flex items-center">
                <input
                  checked
                  id="TimeClockDecimal"
                  type="checkbox"
                  formControlName="TimeClockDecimal"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="TimeClockDecimal"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >Time Clock Decimal</label
                >
              </div>
            </div>
            <div>
              <!-- <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="Inventory"
                  ng-model="ModuleControlMaster.Inventory"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Inventory</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Inventory"
                  data-trigger="hover"
                ></i>
              </div> -->
              <div class="flex items-center">
                <input
                  checked
                  id="Inventory"
                  type="checkbox"
                  formControlName="Inventory"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="Inventory"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >Inventory</label
                >
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4 items-center">
            <div>
              <!-- <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="InventoryReleaseApproval"
                  ng-model="ModuleControlMaster.InventoryReleaseApproval"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Inventory Release Approval</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Inventory Release Approval"
                  data-trigger="hover"
                ></i>
              </div> -->
              <div class="flex items-center">
                <input
                  checked
                  id="InventoryReleaseApproval"
                  type="checkbox"
                  formControlName="InventoryReleaseApproval"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="InventoryReleaseApproval"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >Inventory Release Approval</label
                >
              </div>
            </div>
            <div>
              <!-- <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="TimeClockDecimalTenths"
                  ng-model="ModuleControlMaster.TimeClockDecimalTenths"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Time Clock Decimal Tenths</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Time Clock Decimal Tenths"
                  data-trigger="hover"
                ></i>
              </div> -->
              <div class="flex items-center">
                <input
                  checked
                  id="TimeClockDecimalTenths"
                  type="checkbox"
                  formControlName="TimeClockDecimalTenths"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="TimeClockDecimalTenths"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >Time Clock Decimal Tenths</label
                >
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4 items-center">
            <!-- <div class="flex items-center space-x-2">
                <label class="flex-shrink-0 mb-0">Calendar Years</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Calendar Years"
                  data-trigger="hover"
                ></i>
                <input
                  type="text"
                  class="form-input ml-2"
                  name="CalendarYears"
                  ng-model="ModuleControlMaster.CalendarYears"
                  autocomplete="off"
                />
              </div> -->
            <div>
              <label
                for="CalendarYears"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Calendar Years</label
              >
              <input
                type="text"
                id="CalendarYears"
                formControlName="CalendarYears"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Calendar Years"
                required
              />
            </div>
            <div>
              <!-- <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="AllowChangePassword"
                  ng-model="ModuleControlMaster.AllowChangePassword"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Allow Change Password</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Allow Change Password"
                  data-trigger="hover"
                ></i>
              </div> -->
              <div class="flex items-center">
                <input
                  checked
                  id="AllowChangePassword"
                  type="checkbox"
                  formControlName="AllowChangePassword"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="AllowChangePassword"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >Allow Change Password</label
                >
              </div>
            </div>
          </div>
          <div class="grid grid-cols-1">
            <input
              type="hidden"
              class="form-input"
              name="APIKey"
              ng-model="ModuleControlMaster.APIKey"
              autocomplete="off"
            />
            <div>
              <!-- <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="SecondApproval"
                  ng-model="ModuleControlMaster.SecondApproval"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Second Approval</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Second Approval"
                  data-trigger="hover"
                ></i>
              </div> -->
              <div class="flex items-center">
                <input
                  checked
                  id="SecondApproval"
                  type="checkbox"
                  formControlName="SecondApproval"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="SecondApproval"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >Second Approval</label
                >
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4">
            <div>
              <!-- <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="ComplexPassword"
                  ng-model="ModuleControlMaster.ComplexPassword"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Complex Password</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Complex Password"
                  data-trigger="hover"
                ></i>
              </div> -->
              <div class="flex items-center">
                <input
                  checked
                  id="ComplexPassword"
                  type="checkbox"
                  formControlName="ComplexPassword"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  for="ComplexPassword"
                  class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >Complex Password</label
                >
              </div>
            </div>
            <!-- <div>
              <div class="flex items-center space-x-2">
                <label class="flex-shrink-0 mb-0">Reset Password Days</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Reset Password Days"
                  data-trigger="hover"
                ></i>
                <input
                  type="text"
                  class="form-input ml-2"
                  name="ResetPasswordDays"
                  ng-model="ModuleControlMaster.ResetPasswordDays"
                  autocomplete="off"
                />
              </div>
            </div> -->
            <div>
              <label
                for="ResetPasswordDays"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Reset Password Days</label
              >
              <input
                type="text"
                id="ResetPasswordDays"
                formControlName="ResetPasswordDays"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Reset Password Days"
                required
              />
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4">
            <!-- <div>
              <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="AdditionalRate"
                  ng-model="ModuleControlMaster.AdditionalRate"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Additional Rate</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Additional Rate"
                  data-trigger="hover"
                ></i>
              </div>
            </div> -->
            <div class="flex items-center">
              <input
                id="AdditionalRate"
                type="checkbox"
                formControlName="AdditionalRate"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="AdditionalRate"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Additional Rate</label
              >
            </div>
            <!-- <div>
              <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="AdditionalRateRequested"
                  ng-model="ModuleControlMaster.AdditionalRateRequested"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Additional Rate Requested</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Additional Rate Requested"
                  data-trigger="hover"
                ></i>
              </div>
            </div> -->
            <div class="flex items-center">
              <input
                id="AdditionalRateRequested"
                type="checkbox"
                formControlName="AdditionalRateRequested"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="AdditionalRateRequested"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Additional Rate Requested</label
              >
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4">
            <!-- <div>
              <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="useLocationServices"
                  ng-model="ModuleControlMaster.useLocationServices"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Use Location Services</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Use Location Services"
                  data-trigger="hover"
                ></i>
              </div>
            </div> -->
            <div class="flex items-center">
              <input
                id="useLocationServices"
                type="checkbox"
                formControlName="useLocationServices"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="useLocationServices"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Use Location Services</label
              >
            </div>
            <!-- <div>
              <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="useCombinedPayTypeEntry"
                  ng-model="ModuleControlMaster.useCombinedPayTypeEntry"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Use Combined PayType Entry</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Use Combined PayType Entry"
                  data-trigger="hover"
                ></i>
              </div>
            </div> -->
            <div class="flex items-center">
              <input
                id="useCombinedPayTypeEntry"
                type="checkbox"
                formControlName="useCombinedPayTypeEntry"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="useCombinedPayTypeEntry"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Use Combined PayType Entry</label
              >
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4">
            <!-- <div>
              <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="IsTraining"
                  ng-model="ModuleControlMaster.IsTraining"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Training Enable</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Training Enable"
                  data-trigger="hover"
                ></i>
              </div>
            </div> -->
            <div class="flex items-center">
              <input
                id="IsTraining"
                type="checkbox"
                formControlName="IsTraining"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsTraining"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Training Enable</label
              >
            </div>
            <!-- <div>
              <div class="flex items-center space-x-2">
                <label class="flex-shrink-0 mb-0">Training Admin EmpId</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Training Admin Emp Id"
                  data-trigger="hover"
                ></i>
                <input
                  type="text"
                  class="form-input ml-2"
                  name="TrainingAdminEmpId"
                  ng-model="ModuleControlMaster.TrainingAdminEmpId"
                  autocomplete="off"
                />
              </div>
            </div> -->
            <div>
              <label
                for="TrainingAdminEmpId"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Training Admin EmpId</label
              >
              <input
                type="text"
                id="TrainingAdminEmpId"
                formControlName="TrainingAdminEmpId"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Training Admin EmpId"
                required
              />
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4">
            <!-- <div>
              <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="IsDailyNotification"
                  ng-model="ModuleControlMaster.IsDailyNotification"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Is Daily Notification</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Is Daily Notification"
                  data-trigger="hover"
                ></i>
              </div>
            </div> -->
            <div class="flex items-center">
              <input
                id="IsDailyNotification"
                type="checkbox"
                formControlName="IsDailyNotification"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsDailyNotification"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Daily Notification</label
              >
            </div>
            <!-- <div>
              <div class="flex items-center space-x-2">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  name="IsOverrideLeave"
                  ng-model="ModuleControlMaster.IsOverrideLeave"
                  ng-true-value="true"
                  ng-false-value="false"
                />
                <label class="break-words">Is Override Leave</label>
                <i
                  class="fa fa-info-circle text-info"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Is Override Leave"
                  data-trigger="hover"
                ></i>
              </div>
            </div> -->
            <div class="flex items-center">
              <input
                id="IsWeeklyNotification"
                type="checkbox"
                formControlName="IsWeeklyNotification"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsWeeklyNotification"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Weekly Notification</label
              >
            </div>
          </div>

          <div class="grid grid-cols-12 items-center gap-4">
            <div class="col-span-12 md:col-span-6">
              <div class="space-y-1">
                <label for="input">Daily Notification Run Date and Time</label>
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Daily Notification Run Date and Time"
                  data-trigger="hover"
                ></i>
                <!-- <kiosk-calendar display-type="'dropdown'" id="'FirstPayrollStartDate'" selected-date="ModuleControlMaster.FirstPayrollStartDate" show-details="false"></kiosk-calendar> -->
                <div
                  class="flex"
                  (click)="openDailyNotificationRunDateTimeCalendar()"
                >
                  <input
                    type="text"
                    id="DailyNotificationRunDateTime"
                    placeholder="Select date..."
                    class="bg-white w-full duration-200 border border-[#E2E2E2] py-2 px-4 rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white"
                    formControlName="DailyNotificationRunDateTime"
                    readonly
                  />
                  <button
                    class="py-2 px-3 space-x-1 rounded-r-lg border border-[#E2E2E2] text-[16px] bg-white text-gray-600 flex items-center dark:text-white dark:bg-gray-700 duration-200 dark:border-gray-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-span-12 md:col-span-6">
              <div class="space-y-1">
                <label for="input">Weekly Notification Run Date and Time</label>
                <i
                  class="fa fa-info-circle text-info ml-1"
                  data-boundary="window"
                  data-toggle="popover"
                  data-content="Weekly Notification Run Date and Time"
                  data-trigger="hover"
                ></i>
                <!-- <kiosk-calendar display-type="'dropdown'" id="'FirstPayrollStartDate'" selected-date="ModuleControlMaster.FirstPayrollStartDate" show-details="false"></kiosk-calendar> -->
                <div class="flex" (click)="openPayrollStartCalendar()">
                  <input
                    type="text"
                    id="WeeklyNotificationRunDateTime"
                    placeholder="Select date..."
                    class="bg-white w-full duration-200 border border-[#E2E2E2] py-2 px-4 rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white"
                    formControlName="WeeklyNotificationRunDateTime"
                    readonly
                  />
                  <button
                    class="py-2 px-3 space-x-1 rounded-r-lg border border-[#E2E2E2] text-[16px] bg-white text-gray-600 flex items-center dark:text-white dark:bg-gray-700 duration-200 dark:border-gray-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <label
              for="RegularPayTypeAlias"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >Regular PayType Alias</label
            >
            <input
              type="text"
              id="RegularPayTypeAlias"
              formControlName="RegularPayTypeAlias"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Regular PayType Alias"
              required
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Second column, spans 5 columns -->
    <div class="col-span-12 md:col-span-4">
      <div class="bg-white shadow-md rounded-lg">
        <div class="p-4">
          <div class="grid grid-cols-12">
            <div class="col-span-12">
              <h6 class="text-lg font-semibold">Time Card Settings</h6>
            </div>
          </div>
          <div class="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <label
                for="LaborWarningHours"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Labor Warning Hours</label
              >
              <input
                type="text"
                id="LaborWarningHours"
                formControlName="LaborWarningHours"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Labor Warning Hours"
              />
            </div>
            <div>
              <label
                for="EquipmentMaxHours"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Equipment Max Hours</label
              >
              <input
                type="text"
                id="EquipmentMaxHours"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Equipment Max Hours"
              />
            </div>

            <div class="flex items-center">
              <input
                id="IsLaborHoursWarning"
                type="checkbox"
                formControlName="IsLaborHoursWarning"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsLaborHoursWarning"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Labor Hours Warning</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="IsLaborUpdateEquipmentHoursWarning"
                type="checkbox"
                formControlName="IsLaborUpdateEquipmentHoursWarning"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsLaborUpdateEquipmentHoursWarning"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Labor Update Equipment Hours Warning</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="IsEquipmentMaxHoursWarning"
                type="checkbox"
                formControlName="IsEquipmentMaxHoursWarning"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsEquipmentMaxHoursWarning"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Equipment MaxHours Warning</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="IsEquipmentandLaborHoursWarning"
                type="checkbox"
                formControlName="IsEquipmentandLaborHoursWarning"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsEquipmentandLaborHoursWarning"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Equipment and Labor Hours Warning</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="IsAllowReviewersSubmit"
                type="checkbox"
                formControlName="IsAllowReviewersSubmit"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsAllowReviewersSubmit"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Allow Reviewers Submit</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="IsEquipmentNotLaborHoursWarning"
                type="checkbox"
                formControlName="IsEquipmentNotLaborHoursWarning"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsEquipmentNotLaborHoursWarning"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Equipment Not Labor Hours Warning</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="LoginScreenShowAll"
                type="checkbox"
                formControlName="LoginScreenShowAll"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="LoginScreenShowAll"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Login Screen ShowAll</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="IsLogDuplicateTokens"
                type="checkbox"
                formControlName="IsLogDuplicateTokens"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsLogDuplicateTokens"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Log Duplicate Tokens</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="ConcurrentLogin"
                type="checkbox"
                formControlName="ConcurrentLogin"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="ConcurrentLogin"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Concurrent Login</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="IsSequenceIncrements"
                type="checkbox"
                formControlName="IsSequenceIncrements"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsSequenceIncrements"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Sequence Increments</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="IsEmpSummaryJoClassificationToggle"
                type="checkbox"
                formControlName="IsEmpSummaryJoClassificationToggle"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsEmpSummaryJoClassificationToggle"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Emp Summary JoClassification Toggle</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="IsEmpSummaryExpandAllToggle"
                type="checkbox"
                formControlName="IsEmpSummaryExpandAllToggle"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsEmpSummaryExpandAllToggle"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Emp Summary Expand All Toggle</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="IsEmpSummaryTrimGLToggle"
                type="checkbox"
                formControlName="IsEmpSummaryTrimGLToggle"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsEmpSummaryTrimGLToggle"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Emp Summary Trim GL Toggle</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="IsConsecutiveLoginFailedLock"
                type="checkbox"
                formControlName="IsConsecutiveLoginFailedLock"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsConsecutiveLoginFailedLock"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Is Consecutive Login Failed Lock</label
              >
            </div>
            <div>
              <label
                for="Lock EMail Address"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Lock EMail Address</label
              >
              <input
                type="text"
                id="LockEMailAddress"
                formControlName="LockEMailAddress"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="EMail Address"
                required
              />
            </div>
            <div class="flex items-center">
              <input
                id="IsReviewShowTimecard"
                type="checkbox"
                formControlName="IsReviewShowTimecard"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsReviewShowTimecard"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Review Entry - Display Detailed Time Clock Punches</label
              >
            </div>
            <div class="flex items-center">
              <input
                id="IsMinimumThresholdAlerts"
                type="checkbox"
                formControlName="IsMinimumThresholdAlerts"
                value=""
                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                for="IsMinimumThresholdAlerts"
                class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >Engage Workday Time Card Entry Notifications</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Third column, spans 2 columns -->
    <div class="col-span-12 md:col-span-3">
      <div class="bg-white shadow-md rounded-lg">
        <div class="p-4">
          <div class="grid grid-cols-12">
            <div class="col-span-12">
              <div class="flex justify-between">
                <h6 class="text-lg font-semibold">Holidays Observed</h6>
                <a
                  href="javascript:void(0);"
                  (click)="addHolidays()"
                  class="text-[#001f66]"
                  [matTooltip]="'Add Holidays'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                </a>
              </div>

              <div class="table-container mt-2">
                <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <table
                    class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
                  >
                    <thead
                      class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                    >
                      <tr>
                        <th scope="col" class="px-6 py-3">Name</th>
                        <th scope="col" class="px-6 py-3">Date</th>
                        <th scope="col" class="px-6 py-3">
                          <span class="sr-only">Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      @for (item of holidaysList; track $index) {
                      <tr
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td class="px-6 py-4">{{ item.HolidayName }}</td>
                        <td class="px-6 py-4">
                          {{ item.HolidayDate | date : "MM/dd/yyyy" }}
                        </td>
                        <td class="px-6 py-4 text-right">
                          <div class="flex gap-2">
                          <button
                            (click)="onUpdateHoliday(item)"
                            class="font-medium text-[#d96e02] dark:text-[#d96e02] hover:underline"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </button>
                          <button
                            (click)="deleteHoliday(item.HolidayId)"
                            class="font-medium text-[#dc3545] dark:text-[#dc3545] hover:underline"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        </div>
                        </td>
                      </tr>
                      }
                      <!-- <tr
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td class="px-6 py-4">Silver</td>
                        <td class="px-6 py-4">$2999</td>
                        <td class="px-6 py-4 text-right">
                          <a
                            href="#"
                            class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                            >Edit</a
                          >
                        </td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
