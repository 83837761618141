export const operatorMetadata: { [key: string]: { label: string, icon: string } } = {
  Contains: {
    label: 'Contains', icon: `
<svg class="w-4 h-4" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M163.356 2H37.6309C28.3262 2 26 11.2309 26 15.8464L37.6309 173.141C40.2895 191.308 57.939 196.957 66.4314 197.511C80.2778 197.88 113.177 198.397 134.002 197.511C154.827 196.625 162.248 178.31 163.356 169.264C166.494 121.633 172.993 24.265 173.879 15.8464C174.765 7.42778 167.233 3.10771 163.356 2Z" fill="#183153"/>
<path d="M147.848 26.3696H52.0311L57.0158 87.2936H143.417L147.848 26.3696Z" fill="#748398"/>
</svg>
  ` },
  DoesNotContains: {
    label: 'Does not contain', icon: `
<svg class="w-4 h-4" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M163.356 2H37.6309C28.3262 2 26 11.2309 26 15.8464L37.6309 173.141C40.2895 191.308 57.939 196.957 66.4314 197.511C80.2778 197.88 113.177 198.397 134.002 197.511C154.827 196.625 162.248 178.31 163.356 169.264C166.494 121.633 172.993 24.265 173.879 15.8464C174.765 7.42778 167.233 3.10771 163.356 2Z" fill="#183153"/>
<path d="M149 25H50C53.3795 70.2381 60.1386 162.143 60.1386 167.857C60.1386 173.571 64.1145 175 66.1024 175H131.108C136.357 175 137.669 170.238 137.669 167.857L149 25Z" fill="#748398"/>
</svg>
  ` },
  Equals: {
    label: 'Equals', icon: `
  <svg class="w-4 h-4" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.374 63.6031L179.626 63.6031" stroke="#183153" stroke-width="23" stroke-linecap="round"/>
    <path d="M20.374 135.397L179.626 135.397" stroke="#183153" stroke-width="23" stroke-linecap="round"/>
  </svg>
  ` },
  DoesNotEqual: {
    label: 'Does not equal', icon: `
<svg class="w-4 h-4" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.374 63.6031L179.626 63.6031" stroke="#183153" stroke-width="23" stroke-linecap="round"/>
<path d="M20.374 135.397L179.626 135.397" stroke="#183153" stroke-width="23" stroke-linecap="round"/>
<path d="M157.435 14L42.5648 185" stroke="#183153" stroke-width="24" stroke-linecap="round"/>
</svg>
  ` },
  LessThan: {
    label: 'Is less than', icon: `
<svg class="w-3 h-3" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M177 22L22 100.684L177 178" stroke="#183153" stroke-width="30" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  ` },
  LessThanOrEqual: {
    label: 'Is less than or equal', icon: `
<svg class="w-4 h-4" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M162 26L45 74.4211L162 122" stroke="#183153" stroke-width="24" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M162 173.811L37 173.811" stroke="#183153" stroke-width="20" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  ` },
  GreaterThan: {
    label: 'Is greater than', icon: `
<svg class="w-3 h-3" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23 22L178 100.684L23 178" stroke="#183153" stroke-width="30" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  ` },
  GreaterThanOrEqual: {
    label: 'Is greater than or equal', icon: `
<svg class="w-4 h-4" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M38 26L155 74.4211L38 122" stroke="#183153" stroke-width="24" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M38 173.811L163 173.811" stroke="#183153" stroke-width="20" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  ` },
  StartsWith: {
    label: 'Begins with', icon: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
   class="w-4 h-4" viewBox="0 0 288.000000 149.000000"
   preserveAspectRatio="xMidYMid meet">
  
  <g transform="translate(0.000000,149.000000) scale(0.100000,-0.100000)"
  fill="#000000" stroke="none">
  <path d="M581 1428 c-5 -13 -125 -320 -266 -683 -142 -363 -261 -670 -266
  -683 l-9 -23 152 3 151 3 57 155 57 155 283 0 283 0 60 -157 60 -158 155 0
  c119 0 153 3 149 12 -3 7 -129 325 -282 706 l-277 692 -149 0 c-146 0 -150 -1
  -158 -22z m253 -575 l93 -248 -185 -3 c-102 -1 -187 -1 -189 1 -2 3 33 105 78
  228 45 123 86 237 91 252 6 15 13 26 15 23 3 -3 47 -116 97 -253z"/>
  <path d="M1560 515 l0 -465 55 0 55 0 0 36 0 37 27 -28 c61 -60 143 -78 238
  -51 77 23 152 96 185 181 33 87 33 245 0 329 -29 77 -98 147 -165 169 -92 32
  -200 14 -249 -42 -11 -11 -23 -21 -28 -21 -4 0 -8 72 -8 160 l0 160 -55 0 -55
  0 0 -465z m373 107 c60 -36 96 -122 97 -229 1 -161 -70 -263 -182 -263 -121 0
  -180 82 -181 250 -1 82 3 103 25 151 48 105 151 144 241 91z"/>
  <path d="M2452 721 c-111 -38 -172 -122 -193 -265 -20 -136 13 -267 86 -341
  60 -59 115 -80 215 -80 73 0 92 4 136 27 29 15 66 44 83 65 32 38 70 137 58
  149 -4 4 -29 9 -56 12 -47 4 -49 3 -55 -25 -19 -84 -80 -133 -167 -133 -126 0
  -201 116 -186 290 7 85 31 142 73 179 91 80 230 50 265 -56 l11 -35 52 7 c28
  4 53 9 54 10 9 8 -11 66 -34 102 -65 100 -210 140 -342 94z"/>
  </g>
  </svg>` },
  EndsWith: {
    label: 'Ends with', icon: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
   class="w-4 h-4" viewBox="0 0 273.000000 152.000000"
   preserveAspectRatio="xMidYMid meet">
  <g transform="translate(0.000000,152.000000) scale(0.100000,-0.100000)"
  fill="#000000" stroke="none">
  <path d="M1970 1464 c-218 -46 -389 -210 -459 -436 -62 -205 -45 -477 41 -658
  33 -69 56 -101 122 -165 137 -137 259 -181 476 -172 183 7 281 46 388 153 48
  48 73 84 107 156 67 143 71 132 -70 178 -66 22 -127 40 -136 40 -10 0 -20 -15
  -28 -42 -50 -169 -172 -258 -332 -245 -163 14 -265 123 -300 322 -15 90 -6
  333 15 399 39 121 110 194 219 228 162 49 323 -21 383 -165 23 -56 20 -56 174
  -18 112 28 115 30 113 56 -3 37 -54 142 -92 189 -42 53 -129 118 -191 145
  -116 50 -295 65 -430 35z"/>
  <path d="M830 540 l0 -470 50 0 50 0 0 41 0 41 41 -36 c51 -47 79 -57 155 -57
  78 0 139 27 194 88 72 77 85 118 85 268 0 122 -2 133 -28 187 -33 68 -100 130
  -157 147 -93 28 -195 9 -252 -46 l-28 -27 0 167 0 167 -55 0 -55 0 0 -470z
  m365 107 c30 -16 48 -37 70 -82 26 -53 30 -69 30 -150 0 -111 -20 -170 -75
  -219 -87 -79 -204 -53 -263 58 -18 35 -21 58 -22 151 0 94 3 116 22 150 22 41
  62 84 98 103 33 18 97 13 140 -11z"/>
  <path d="M320 759 c-118 -12 -200 -69 -230 -160 -7 -21 -11 -41 -9 -43 2 -2
  27 -6 55 -9 l51 -6 21 44 c27 57 57 76 129 82 118 11 182 -29 191 -116 l4 -50
  -38 -10 c-22 -6 -97 -19 -167 -30 -153 -23 -206 -49 -244 -120 -43 -82 -22
  -192 45 -240 47 -33 83 -42 164 -43 80 0 149 23 217 71 l28 20 12 -39 12 -40
  59 0 c33 0 60 3 60 6 0 3 -6 15 -12 27 -9 15 -14 101 -18 272 -6 267 -9 283
  -62 332 -47 44 -149 64 -268 52z m208 -419 c-9 -128 -79 -190 -212 -190 -64 0
  -70 2 -102 34 -38 38 -43 70 -19 116 21 40 38 48 164 70 60 11 119 24 132 29
  40 16 42 13 37 -59z"/>
  </g>
  </svg>` },
  IsLike: {
    label: 'Is like', icon: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    class="w-4 h-4" viewBox="0 0 163.000000 107.000000"
   preserveAspectRatio="xMidYMid meet">
  
  <g transform="translate(0.000000,107.000000) scale(0.100000,-0.100000)"
  fill="#000000" stroke="none">
  <path d="M237 890 c-120 -31 -203 -122 -223 -248 l-7 -42 45 0 45 0 7 42 c5
  32 18 53 54 89 93 92 178 92 497 -2 77 -23 145 -45 150 -49 6 -4 21 -10 35
  -14 333 -89 386 -98 490 -85 164 20 262 109 286 258 l7 41 -45 0 c-43 0 -44
  -1 -55 -42 -17 -63 -52 -110 -103 -136 -40 -21 -55 -23 -130 -19 -84 5 -111
  12 -435 111 -245 74 -354 98 -470 102 -60 2 -127 -1 -148 -6z"/>
  <path d="M225 467 c-120 -40 -191 -123 -211 -245 l-7 -42 47 0 c41 0 46 3 46
  22 0 43 40 106 87 137 91 61 197 52 493 -39 413 -128 485 -144 619 -138 71 3
  109 10 146 27 92 41 154 124 171 230 l7 41 -47 0 c-37 0 -46 -3 -46 -17 0 -41
  -49 -122 -89 -148 -87 -58 -213 -41 -571 75 -231 75 -355 102 -490 106 -77 3
  -128 0 -155 -9z"/>
  </g>
  </svg>` },
  IsNotLike: {
    label: 'Is not like', icon: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
   class="w-4 h-4" viewBox="0 0 163.000000 107.000000"
   preserveAspectRatio="xMidYMid meet">
  
  <g transform="translate(0.000000,107.000000) scale(0.100000,-0.100000)"
  fill="#000000" stroke="none">
  <path d="M926 914 c-53 -110 -62 -123 -80 -118 -84 27 -230 66 -316 85 -132
  28 -269 24 -345 -10 -92 -41 -154 -124 -171 -230 l-7 -41 45 0 45 0 7 42 c5
  32 18 53 54 89 93 92 178 92 497 -2 77 -23 144 -45 148 -49 4 -3 -18 -62 -50
  -129 -53 -115 -59 -122 -82 -118 -15 2 -73 14 -131 26 -137 30 -280 26 -355
  -8 -92 -41 -154 -124 -171 -230 l-7 -41 47 0 c41 0 46 3 46 22 0 43 40 106 87
  137 52 35 112 46 186 37 51 -7 244 -53 259 -62 4 -3 -24 -68 -62 -145 -39 -78
  -67 -145 -64 -150 3 -5 34 -9 70 -9 l65 0 61 131 c54 113 65 130 82 126 12 -3
  93 -26 181 -53 155 -46 164 -47 295 -48 121 -1 140 1 185 22 93 43 154 125
  171 231 l7 41 -47 0 c-37 0 -46 -3 -46 -17 0 -10 -11 -41 -24 -70 -33 -70 -84
  -104 -168 -110 -73 -6 -135 7 -359 73 l-157 47 59 126 c33 69 65 125 72 125 6
  -1 48 -11 93 -23 174 -45 299 -45 403 -1 86 38 150 125 167 228 l7 42 -45 0
  c-43 0 -44 -1 -55 -42 -28 -104 -100 -158 -209 -158 -61 0 -290 52 -307 69 -5
  5 21 71 57 146 36 76 66 140 66 142 0 1 -33 3 -72 2 l-73 0 -59 -125z"/>
  </g>
  </svg>` }
};
