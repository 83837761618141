import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RcService {
  private rc: string | null = null;

  setTenant(tenant: string) {
    this.rc = tenant;
  }

  getTenant() {
    let tenant = localStorage.getItem('tenant');
    // let selectedTenant = 'ACRC';
    let selectedTenant = '';
    if (tenant) {
      let tenantData = JSON.parse(tenant);
      selectedTenant = tenantData.Name;
      // if (selectedTenant === '') {
      //   selectedTenant = tenantData.DisplayName;
      // }
    }
    // this.rc = selectedTenant
    return selectedTenant;
  }

  clearTenant() {
    this.rc = null;
  }
}
