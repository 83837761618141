import { CommonModule } from '@angular/common';
import { Component, inject, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from 'src/app/material.module';
import { HomeService } from 'src/app/pages/home/home.service';
import { DatepickerDialogComponent } from 'src/app/shared/datepicker-dialog/datepicker-dialog.component';

@Component({
  selector: 'app-add-holidays',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, DatepickerDialogComponent],
  templateUrl: './add-holidays.component.html',
  styleUrl: './add-holidays.component.css'
})
export class AddHolidaysComponent {
  holidayForm!: FormGroup;
  isSubmitted: boolean = false;
  isEditPage: boolean = false;
  currentItem: any = null;

  private homeService: HomeService = inject(HomeService);

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddHolidaysComponent>,
  ) {
    this.isEditPage = this.data.isEdit;
    this.currentItem = this.data?.item;
    // this.employeeNumber = data.employeeNumber;
    this.holidayForm = this.fb.group({
      HolidayName: new FormControl('', [Validators.required]),
      HolidayDate: new FormControl('', [Validators.required]),

    });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (this.currentItem) {
      this.holidayForm.patchValue({ ...this.currentItem });
    }
  }

  openDateCalendar(): void {
    const dialogRef = this.dialog.open(DatepickerDialogComponent, {
      width: '450px', // Set the desired width
      panelClass: 'custom-sml-calendar',
      data: {
        showEvents: false
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle any actions after the dialog is closed
      this.holidayForm.patchValue({
        HolidayDate: result.date
      });
    });
  }

  onSave(): void {
    this.isSubmitted = true;
    if (this.holidayForm.valid) {
      let payload = {
        ...this.holidayForm.value
      }
      if (this.isEditPage) {
        payload = {
          ...this.holidayForm.value,
          HolidayId: this.currentItem.HolidayId
        }
      }
      this.homeService.saveHolidaysObserved(payload).subscribe({
        next: (response) => {
          this.closeDialog();
        },
        error: (error) => {
          console.log("get tck master error : ", error);
        }
      });
    }
  }

  closeDialog() {
    this.dialogRef.close('saved');
  }

  closeHolidayDialog(): void {
    this.dialogRef.close();
  }

}
