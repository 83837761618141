<div class="wrapper">
  <div
    class="space-y-1 sm:space-y-0 sm:flex sm:items-center sm:justify-between text-[15px] mb-3"
  >
    <div
      *ngIf="showSelectedDate; else calendarLavel"
      class="bg-white border-2 border-gray-600 dark:bg-gray-700 dark:text-white py-1 px-3 rounded-2xl text-[14px] inline-block"
    >
      Selected date:
      <span class="font-avertaBold text-[#FF8000]">{{
        selectedDate | date : "MM/dd/yyyy"
      }}</span>
    </div>
    <ng-template #calendarLavel>
      <div class="dark:text-white">Select Date</div>
    </ng-template>
    <div class="flex justify-start items-stretch space-x-0">
      <div
        class="flex justify-between items-center icons w-auto py-1 px-1 bg-white dark:bg-gray-500 dark:text-white rounded-l-full text-sm border-2 border-gray-600 dark:border-gray-700"
      >
        <span class="prev" (click)="changeMonth(-1)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 18L9 12L15 6"
              stroke="#666"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span>
          <div>
            <select
              class="bg-white dark:bg-gray-500 month-select"
              [(ngModel)]="currMonth"
              (change)="onMonthChange(currMonth)"
            >
              <option *ngFor="let month of months; let i = index" [value]="i">
                {{ month }}
              </option>
            </select>
          </div>
        </span>
        <span class="next mr-0.5 dark:text-white" (click)="changeMonth(1)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="#666"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <!-- <span class="font-avertaSemibold month">{{ months[currMonth]}}</span> -->
      </div>
      <!-- {{ months[currMonth]}} -->
      <div>
        <select
          class="w-auto py-2 px-2 bg-white dark:bg-gray-500 dark:text-white rounded-r-full border-l-0 text-sm border-2 border-gray-600 dark:border-gray-700"
          [(ngModel)]="currYear"
          (change)="onYearChange(currYear)"
        >
          <option *ngFor="let year of years" [value]="year">
            {{ year }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="calendar !p-0 border-2 border-gray-600 rounded-[22px]">
    <ul class="weeks font-avertabold rounded-t-[20px] overflow-hidden">
      <li
        class="border text-xs !text-black bg-gray-200 border-t-0 dark:bg-gray-800 dark:!text-white border-gray-300 dark:border-gray-950 border-l-0 py-0.5"
      >
        Sun
      </li>
      <li
        class="border text-xs !text-black bg-gray-200 border-t-0 dark:bg-gray-800 dark:!text-white border-gray-300 dark:border-gray-950 border-l-0 py-0.5"
      >
        Mon
      </li>
      <li
        class="border text-xs !text-black bg-gray-200 border-t-0 dark:bg-gray-800 dark:!text-white border-gray-300 dark:border-gray-950 border-l-0 py-0.5"
      >
        Tue
      </li>
      <li
        class="border text-xs !text-black bg-gray-200 border-t-0 dark:bg-gray-800 dark:!text-white border-gray-300 dark:border-gray-950 border-l-0 py-0.5"
      >
        Wed
      </li>
      <li
        class="border text-xs !text-black bg-gray-200 border-t-0 dark:bg-gray-800 dark:!text-white border-gray-300 dark:border-gray-950 border-l-0 py-0.5"
      >
        Thu
      </li>
      <li
        class="border text-xs !text-black bg-gray-200 border-t-0 dark:bg-gray-800 dark:!text-white border-gray-300 dark:border-gray-950 border-l-0 py-0.5"
      >
        Fri
      </li>
      <li
        class="border text-xs !text-black bg-gray-200 border-t-0 border-l-0 border-r-0 dark:bg-gray-800 dark:!text-white border-gray-300 dark:border-gray-950 py-0.5"
      >
        Sat
      </li>
    </ul>
    <ul
      class="days text-xs bg-white dark:bg-gray-400 rounded-b-[20px] overflow-hidden"
    >
      <li
        *ngFor="let day of days; let i = index"
        [class.selected]="day.selected"
        [class.active]="day.active"
        [class.inactive]="day.inactive"
        (click)="toggleDate(i)"
        [ngStyle]="{
          'background-color': showEvents ? day.bgColor : '',
          color: 'black'
        }"
      >
        <div
          *ngIf="day.TimeCardDate; else elseBlock"
          class="relative col-span-1 p-0.5 border border-b-0 border-l-0 border-gray-200 min-h-[72px] dark:border-gray-500 transition-all duration-200 cursor-pointer flex flex-col"
        >
          <div
            class="flex font-avertaSemibold items-center"
            [ngClass]="{
              'justify-between': day.timeClockStatus,
              'justify-end':
                !day.timeClockStatus || day.timeClockStatus === false
            }"
          >
            <span
              *ngIf="day.timeClockStatus && showEvents"
              class="font-medium text-white rounded-full inline-block"
              [ngClass]="{
                  ' bg-green-700': day.timeClockStatus == 1,
                  ' bg-red-700': day.timeClockStatus == 2,
                }"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1"
                stroke="currentColor"
                class="w-3 h-3 md:w-4 md:h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
            <span class="">{{ day.value > 0 ? day.value : "" }}</span>
          </div>
          <div
            *ngIf="showEvents"
            class="flex justify-center text-[8px] sm:text-[10px] truncate italic absolute left-0 top-[50%] w-full translate-y-[-50%]"
          >
            <span class="text-black"> {{ day.Type }} </span>
          </div>
          <div
            *ngIf="showEvents"
            class="flex justify-end text-[9px] md:text-[12px] truncate mt-[34px]"
          >
            <span>{{ day.TCHours | number : "1.2" }}</span>
          </div>
        </div>
        <ng-template #elseBlock>
          <!-- bg-gray-300 dark:bg-gray-600 -->
          <div
            class="col-span-1 p-0.5 border border-b-0 border-l-0 border-gray-200  min-h-[72px] dark:border-gray-500 text-gray-400 dark:text-gray-700 hover:bg-purple-400 transition-all duration-200 cursor-pointer"
          >
            <div class="flex justify-end" *ngIf="!day.inactive">
              {{ day.value > 0 ? day.value : "" }}
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
  </div>
  <div class="mt-2 flex justify-center">
    <button
      class="flex justify-between items-center bg-gradient-to-t from-blue-800 to-blue-950 text-white py-0.5 px-4 border-2 border-white rounded-full space-x-2"
      (click)="setToday()"
    >
      <span>Today</span>
    </button>
  </div>
</div>
