import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


@Component({
    selector: 'app-blank',
    providers: [],
    imports:
        [
            CommonModule,
            RouterModule
        ],
    standalone: true,    
  templateUrl: './blank.component.html',
    styleUrl: './blank.component.css',
})
export class BlankComponent {

}
