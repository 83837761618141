<app-spinner></app-spinner>
<div
  class="font-avertareguler w-full h-screen px-2 py-10 md:py-10 md:p-4 bg-white/[67%] dark:bg-slate-900/60 space-y-4 relative"
>
  <div class="flex">
    <h2
      class="text-[#102030] text-[16px] md:text-[20px] font-avertaBold dark:text-white"
    >
      Copy Details
    </h2>
  </div>
  <div
    class="space-y-4 popBody scrollbar-thin scrollbar-thumb-[#000733]/20 scrollbar-track-transparent overflow-y-scroll"
    [ngClass]="{'copy': !copyDetailsPayHoursValidate}"
  >
    <div class="">
      <div class="flex mb-3">
        <span class="dark:text-white"
          >Date Selected:
          <span
            [ngClass]="{
              'text-red-600 font-normal ': copyDetailsModalcopydate
            }"
            >{{ selectedDate }}</span
          ></span
        >
        <!-- <span>{{ copyDate }}</span> -->
      </div>
      <div>
        <ol
          class="space-y-1 text-gray-500 text-sm list-decimal list-inside dark:text-gray-400"
        >
          <li>Select the date range you would like to apply the entries to</li>
          <li>Select which entries you'd like to apply to the date range</li>
          <li>Click 'Copy' when you're ready to apply the update's</li>
        </ol>
      </div>
      <div class="pt-5">
        <form>
          <div class="grid gap-6 mb-2 md:grid-cols-2">
            <div>
              <label
                for="copyStartDate"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Begin Date:</label
              >
              <div class="flex" (click)="openBeginCalendarDialog()">
                <input
                  type="text"
                  id="copyStartDate"
                  placeholder="Select date..."
                  class="bg-white w-full duration-200 border border-[#E2E2E2] py-2 px-4 rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white"
                  [(ngModel)]="copyStartDate"
                  [ngModelOptions]="{ standalone: true }"
                  readonly
                />
                <button
                  class="py-2 px-3 space-x-1 rounded-r-lg border border-[#E2E2E2] text-[16px] bg-white text-gray-600 flex items-center dark:text-white dark:bg-gray-700 duration-200 dark:border-gray-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                    />
                  </svg>
                </button>
              </div>
              <span
                *ngIf="messagecopyStartDateError"
                class="text-sm text-red-800 rounded-lg dark:bg-gray-800 dark:text-red-400"
                >Begin Date should be less than Through Date</span
              >
            </div>
            <div>
              <label
                for="copyEndDate"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Through Date:</label
              >
              <div class="flex" (click)="openEndCalendarDialog()">
                <input
                  type="text"
                  id="copyEndDate"
                  placeholder="Select date..."
                  class="bg-white w-full duration-200 border border-[#E2E2E2] py-2 px-4 rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white"
                  [(ngModel)]="copyEndDate"
                  [ngModelOptions]="{ standalone: true }"
                  readonly
                />
                <button
                  class="py-2 px-3 space-x-1 rounded-r-lg border border-[#E2E2E2] text-[16px] bg-white text-gray-600 flex items-center dark:text-white dark:bg-gray-700 duration-200 dark:border-gray-500"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                    />
                  </svg>
                </button>
              </div>
              <span
                *ngIf="messagecopyEndDateError"
                class="text-sm text-red-800 rounded-lg dark:bg-gray-800 dark:text-red-400"
                >Through Date should be greater than Begin Date</span
              >
            </div>
          </div>
          <div
            *ngIf="copyDetailsInlinewarning"
            class="flex items-center p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
            role="alert"
          >
            <svg
              class="flex-shrink-0 inline w-4 h-4 me-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
              />
            </svg>
            <span class="sr-only">Info</span>
            <div>
              <span class="font-medium">Warning!</span> The date range you have
              selected overlaps the day you are copying from. This could result
              in unintentional duplication of records.
            </div>
          </div>
        </form>
      </div>

      <div class="grid gap-6 mt-4 mb-6 md:grid-cols-2">
        <div
          class="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700"
        >
          <input
            checked
            id="chkCopyEquipment"
            type="checkbox"
            name="bordered-checkbox"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            [(ngModel)]="copyEquipment"
          />
          <label
            for="chkCopyEquipment"
            class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Copy Equipment</label
          >
        </div>
        <div
          class="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700"
        >
          <input
            checked
            id="chkCopyInventory"
            type="checkbox"
            name="bordered-checkbox"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            [(ngModel)]="copyInventory"
          />
          <label
            for="chkCopyInventory"
            class="w-full py-4 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Copy Inventory</label
          >
        </div>
      </div>

      <div
        class="flex items-center p-4 text-sm text-gray-800 rounded-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-300 mb-3"
        role="alert"
      >
        <svg
          class="flex-shrink-0 inline w-4 h-4 me-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
          />
        </svg>
        <span class="sr-only">Info</span>
        <div>Select which details should be copied</div>
      </div>
      <div class="w-full custTableCss">
        <div class="relative shadow-md sm:rounded-lg">
          <table
            class="text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <tbody>
              <tr
                *ngFor="let s of dailySummary"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td class="w-4 p-4">
                  <div class="flex items-center">
                    <input
                      id="checkbox-table-search-1"
                      type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      [ngClass]="{ checkCopy: allowCheck }"
                      (click)="selectTransaction(s)"
                    />
                    <label for="checkbox-table-search-1" class="sr-only"
                      >checkbox</label
                    >
                  </div>
                </td>
                <td
                  scope="row"
                  class="font-medium text-gray-900 dark:text-white"
                >
                  <button
                    *ngIf="!condensed"
                    class="btn btn-outline-danger ab-btn-cust"
                    ng-click="confirmDeletedailySummaryTransaction(s.TransactionNumber);"
                    style="padding: 5px"
                  >
                    <i class="fa fa-trash-o"></i>
                    Delete
                  </button>

                  <app-review-details
                    [payHours]="s.PayHours"
                    [employeeNumber]="s.EmployeeNumber"
                    [transactionNumber]="s.TransactionNumber"
                    [sequenceNumber]="s.SequenceNumber"
                    [totalEquipmentHours]="s.EquipmentHours"
                    [hideEquipmentInventory]="hideEquipmentInventory"
                    [condensed]="condensed"
                    [totalHours]="totalHours"
                    [userData]="userData"
                  ></app-review-details>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- <div
        *ngIf="!copyDetailsPayHoursValidate"
        class="flex items-center p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
        role="alert"
      >
        <svg
          class="flex-shrink-0 inline w-4 h-4 me-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
          />
        </svg>
        <span class="sr-only">Info</span>
        <div>
          <span class="font-medium">Warning!</span> Your selected timeframe and
          associated details would exceed the hours currently available for this
          pay type
        </div>
      </div> -->
    </div>
  </div>
  <div
    *ngIf="!copyDetailsPayHoursValidate"
    class="flex items-center p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300"
    role="alert"
  >
    <svg
      class="flex-shrink-0 inline w-4 h-4 me-3"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path
        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
      />
    </svg>
    <span class="sr-only">Info</span>
    <div>
      <span class="font-medium">Warning!</span> Your selected timeframe and
      associated details would exceed the hours currently available for this pay
      type
    </div>
  </div>
  <div class="flex justify-between items-center w-full popFooter">
    <button
      type="button"
      mat-dialog-close
      class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center dark:bg-white/20 dark:text-white"
    >
      Cancel
    </button>
    <button
      [disabled]="selections.length === 0 || !copyDetailsPayHoursValidate"
      type="button"
      [mat-dialog-close]="true"
      cdkFocusInitial
      class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-gradient-to-b from-[#04BC00] to-[#026000] text-[#ffffff] flex items-center disabled:opacity-75"
      (click)="saveCopySelections()"
      [ngClass]="{
        'disabled:opacity-50':
          selections.length === 0 || !copyDetailsPayHoursValidate
      }"
    >
      Copy
    </button>
  </div>
  <button
    mat-dialog-close
    type="button"
    class="absolute right-[5px] top-[-15px] h-8 w-8 rounded-full flex justify-center items-center bg-gray-100 text-red dark:bg-white/20 dark:text-white"
  >
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </span>
  </button>
</div>
