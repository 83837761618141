import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[floatingPointComma]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FloatingPointCommaDirective),
    multi: true
  }]
})
export class FloatingPointCommaDirective implements ControlValueAccessor {
  @Input() decimalPlaces: number = 1; // Decimal places
  @Input() maxLengthWithComma: number = 12; // Max length including commas and decimal

  private onChange!: (val: string) => void;
  private onTouched!: () => void;
  private value: string = '';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['decimalPlaces'] && !changes['decimalPlaces'].firstChange) {
      const currentValue = this.elementRef.nativeElement.value;
      const formattedValue = this.formatToFloatingPoint(currentValue, false);
      this.updateTextInput(formattedValue, false);
    }
  }

  @HostListener('focus')
  onFocus(): void {
    const currentValue = this.elementRef.nativeElement.value;
    if (currentValue === this.formatEmptyValue()) {
      this.selectEntireValue();
    }
  }

  @HostListener('blur')
  onBlur(): void {
    const value = this.formatToFloatingPoint(this.elementRef.nativeElement.value);
    this.updateTextInput(value, this.value !== value);
    this.onTouched();
  }

  /* @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const rawValue = inputElement.value;
    const formattedValue = this.handleMaxLength(rawValue);

    this.updateTextInput(formattedValue, true);
  } */
  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    let cursorPosition = inputElement.selectionStart || 0;

    const rawValue = inputElement.value;
    const oldLength = rawValue.length;

    // Don't format during input; only clean non-numeric and non-decimal values
    const cleanedValue = rawValue.replace(/[^0-9.-]/g, '');

    // Only update the input value if there are invalid characters removed
    if (cleanedValue !== rawValue) {
      this.updateTextInput(cleanedValue, true);
    }

    // Keep the cursor at its natural position
    this.renderer.setProperty(inputElement, 'selectionStart', cursorPosition);
    this.renderer.setProperty(inputElement, 'selectionEnd', cursorPosition);
  }

  private formatToFloatingPoint(value: string, allowManualDecimal: boolean = false): string {
    // Strip all non-numeric characters except for periods and commas
    let cleanedValue = value.replace(/[^0-9.]/g, '');

    // Add decimal places on blur if needed
    if (!allowManualDecimal) {
      const parsedValue = parseFloat(cleanedValue);
      if (!isNaN(parsedValue)) {
        cleanedValue = parsedValue.toFixed(this.decimalPlaces);
      } else {
        cleanedValue = this.formatEmptyValue();
      }
    }

    return this.addCommas(cleanedValue);
  }

  private addCommas(value: string): string {
    const parts = value.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }

  private handleMaxLength(value: string): string {
    // Remove commas and handle maxLength for numeric part
    let cleanedValue = value.replace(/,/g, '');

    // Split into integer and decimal parts
    let [integerPart, decimalPart = ''] = cleanedValue.split('.');

    // Check the maximum length allowed for the integer part
    let formattedIntegerPart = this.addCommas(integerPart);
    const integerMaxLength = this.maxLengthWithComma - (this.decimalPlaces + 1); // Exclude decimal and places

    if (formattedIntegerPart.length > integerMaxLength) {
      integerPart = integerPart.slice(0, integerMaxLength - Math.floor((integerMaxLength - 1) / 3)); // Remove characters beyond limit
      formattedIntegerPart = this.addCommas(integerPart);
    }

    // Ensure decimal part stays within limits
    decimalPart = decimalPart.slice(0, this.decimalPlaces);

    // Combine integer and decimal parts
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
  }

  private formatEmptyValue(): string {
    return `0.${'0'.repeat(this.decimalPlaces)}`;
  }

  private updateTextInput(value: string, propagateChange: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
    if (propagateChange) {
      this.onChange(value);
    }
    this.value = value;
  }

  private selectEntireValue(): void {
    setTimeout(() => {
      this.elementRef.nativeElement.select();
    });
  }

  // ControlValueAccessor Interface
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  writeValue(value: any): void {
    if (value === null || value === undefined || value === '') {
      value = this.formatEmptyValue();
    } else {
      value = this.formatToFloatingPoint(String(value));
    }
    this.updateTextInput(value, false);
  }
}
