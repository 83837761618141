import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'src/app/core/services/auth.service';
import { RcService } from 'src/app/core/services/rc.service';
import { MaterialModule } from 'src/app/material.module';
import { SharedModule } from 'src/app/shared/shared.module';
import * as UserActions from 'src/app/store/actions/user.actions';
import { ProfileService } from '../shared/profile/profile.service';

@Component({
  selector: 'app-redirect-page',
  standalone: true,
  imports: [CommonModule, RouterModule, SharedModule, MaterialModule],
  templateUrl: './redirect-page.component.html',
  styleUrl: './redirect-page.component.css'
})
export class RedirectPageComponent {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private store: Store,
    private readonly service: ProfileService,
    private rcService: RcService
  ) { }

  ngOnInit(): void {
    // Get EmployeeNumber and UserToken from the URL query parameters
    this.route.queryParams.subscribe((params) => {
      const employeeNumber = params['EmployeeNumber'];
      const clientToken = params['ClientToken'];
      const userToken = params['UserToken'];

      // Call the API to validate the user
      this.authService.validateRedirectUser(employeeNumber, clientToken, userToken).subscribe(
        (response) => {
          if (response.IsInventory) {
            // Redirect to the target page if valid
            // Redirect after 3 seconds (you can adjust the timeout)
            // localStorage.setItem('user', JSON.stringify(response));
            // this.authService.setToken(response.LoginToken);
            const loginData = {
              ...response,
              token: response.LoginToken,
              credentials: {
                EmployeeNumber: employeeNumber
              }
            };
            // localStorage.setItem('token', userToken);
            this.store.dispatch(UserActions.login({ user: loginData }));
            this.authService.setToken(userToken);
            this.authService.setCurrentUser(loginData);
            // localStorage.setItem('user', JSON.stringify(userToken));
            setTimeout(() => {
              // Assuming there is a method in your service to retrieve saved settings
              /* this.service.getProfileSettings(employeeNumber, userToken).subscribe((savedSettings) => {
                if (savedSettings) {
                  // console.log('savedSettings', savedSettings);
                  let profileSetting = {
                    profileType1: savedSettings.some((item: any) => item.ProfileTypeID === 1 && item.SettingValue === 'true'),
                    profileType2: savedSettings.some((item: any) => item.ProfileTypeID === 2 && item.SettingValue === 'true'),
                    profileType4: savedSettings.some((item: any) => item.ProfileTypeID === 4 && item.SettingValue === 'true'),
                    profileType3: savedSettings.some((item: any) => item.ProfileTypeID === 3),
                  }
                  const theme = savedSettings.find((setting: { ProfileTypeID: number; }) => setting.ProfileTypeID === 3).SettingValue;
                  // You may need to handle the theme separately as it is stored in sessionStorage
                  // const theme = this.userData.kiosk_theme;
                }
              }); */
              this.router.navigate([`/${this.rcService.getTenant()}/inventory/master`]);
            }, 3000);
          } else {
            // Redirect to the login page if invalid
            this.router.navigate([`/${this.rcService.getTenant()}/auth/login`]);
          }
        },
        (error) => {
          // Handle error, maybe redirect to login
          this.router.navigate([`/${this.rcService.getTenant()}/auth/login`]);
        }
      );
    });
  }

}
