import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css'] // Add any styles if needed
})
export class CalculatorComponent {
  @Output() valueChanged = new EventEmitter<string>();

  @Input() isAvailable: boolean = false;
  @Input() maxValue: number = 0;
  @Input() kioskBenefitMax: number = 0;
  @Input() input: any = '';
  maxLength: number = 7;

  addToInput(value: string) {
    if (value === 'backspace') {
      this.input = this.input.toString();
      this.input = this.input.slice(0, -1);
      this.valueChanged.emit(this.input);
    } else if (value === 'clear') {
      this.clearInput();
    } else {
      // Check if input length is already 5
      if (this.input.toString().length === this.maxLength) {
        return; // Do nothing if input length is already 5
      }
      // Check if the current value already contains a dot
      const containsDot = this.input.toString().includes('.');

      // If the value is a dot and the current input already contains a dot, do nothing
      if (value === '.' && containsDot) {
        return;
      }

      this.input = isNaN(this.input) ? "" : this.input;
      console.log('this.input', this.input);
      this.input += value;
    }

    this.valueChanged.emit(this.input);
  }

  clearInput() {
    this.input = '';
    // this.valueChanged.emit(this.input);
    this.input = isNaN(this.input) ? "" : this.input;
    // console.log('this.input', this.input);
    this.valueChanged.emit(this.input);
  }

  increaseCount() {
    if (Number.isNaN(parseInt(this.input))) {
      this.input = '1';
    } else {
      this.input = (parseInt(this.input) + 1).toString();
    }

    this.valueChanged.emit(this.input);
  }

  decreaseCount() {
    if (parseInt(this.input) > 0) {
      let number = parseInt(this.input) - 1;
      this.input = number.toString();
      this.valueChanged.emit(this.input);
    }
  }
}
