import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  //fetch training mail settings
  getMailSettings(clientToken: string, userToken: string): Observable<any> {
    return this.http.get(`/Training/getMailSettings?ClientToken=${clientToken}&UserToken=${userToken}`);
  }

  //fetch employee department list
  getDepartmentList(clientToken: string, userToken: string): Observable<any> {
    return this.http.get(`Training/getEmployeeDepartments?ClientToken=${clientToken}&UserToken=${userToken}`);
  }

  //save mail settings
  saveMailSetting(payload: any): Observable<any> {
    return this.http.post(`Training/saveMailSetting`, payload);
  }
}
