<ng-container *ngIf="loader.isLoading$ | async">
  <div
    class="h-full w-full fixed flex justify-center bg-white/70 items-center z-[999999] left-0 top-0"
  >
    <div class="">
      <!-- <mat-progress-spinner
        class="example-margin"
        [color]="color"
        [mode]="mode"
        [value]="value"
      >
      </mat-progress-spinner> -->
      <mat-spinner></mat-spinner>
    </div>
  </div>
</ng-container>
<!-- <div class=" h-full w-full fixed flex justify-center bg-white/70  items-center z-10 left-0 top-0">
    <div class="h-10 w-10">
      <span class="inline-block animate-spin">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 text-blue-600">
              <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>   
      </span>    
    </div>
  </div> -->
