
<div
  class="font-avertareguler w-full h-screen px-2 py-10 md:py-10 md:p-4 bg-white/[67%] dark:bg-slate-900/60 space-y-4 relative"
>
  <div class="flex justify-between items-center">
    <h2 class="text-[#102030] text-[16px] md:text-[20px] font-avertaBold dark:text-white">
      {{ title }}
    </h2>
  </div>
  <div class="space-y-4 popBody scrollbar-thin scrollbar-thumb-[#000733]/20 scrollbar-track-transparent overflow-y-scroll flex items-center w-full">
    <div class="w-3/4 m-auto text-center">
      <img class="w-[120px] m-auto mb-6" src="assets/images/confCheck.png" />
      <div class="font-avertaSemibold text-[18px] dark:text-white">{{ message }}</div>
    </div>
  </div>
  <div class="flex justify-between items-center w-full popFooter"
  >
    <button
      class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center dark:bg-white/20 dark:text-white"
      (click)="onDismiss()"
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </span>
      <span>Close</span>
    </button>
    <button
      class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-gradient-to-b from-[#04BC00] to-[#026000] text-[#ffffff] flex items-center disabled:opacity-75 dark:bg-gray-950"
      (click)="onConfirm()"
    >
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
        </svg>                               
      </span>
      <span>YES</span>
    </button>
  </div>
  <button (click)="onDismiss()" type="button" class="absolute right-[5px] top-[-15px] h-8 w-8 rounded-full flex justify-center items-center bg-gray-100 text-red dark:bg-white/20 dark:text-white">
      <span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>        
        </span>
  </button>
</div>
