import { Component } from '@angular/core';
import { ConstantsService } from '../core/services/constants.service';

@Component({
  selector: 'app-rc',
  templateUrl: './rc.component.html',
  styleUrls: ['./rc.component.css']
})
export class RcComponent {
  theme!: string;

  constructor(
    private readonly constantsService: ConstantsService
  ) {
  }


  ngOnInit(): void {
    this.theme = this.constantsService.getCurrentSeason();
  }
}
