import { SelectionModel } from '@angular/cdk/collections';
import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { AuthService } from 'src/app/core/services/auth.service';
import { MaterialModule } from 'src/app/material.module';
import { SharedService } from 'src/app/shared/shared.service';
import { CommonModule } from '@angular/common';
import { DatepickerDialogComponent } from './datepicker-dialog/datepicker-dialog.component';
import { Subscription } from 'rxjs';
import { HomeService } from '../home/home.service';
import { DetailsService } from '../home/details/details.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { RcService } from 'src/app/core/services/rc.service';
import { CalendarService } from 'src/app/core/services/calendar.service';

export interface DetailSummery {
  TransactionNumber: number;
  DetailStatus: string;
  PayTypeCode: string;
  PayHours: number;
  EquipmentHours: number;
  categoryValue: string;
  displayValue?: string;
  programDirections?: any;
}

@Component({
  selector: 'app-copy-details',
  templateUrl: './copy-details.component.html',
  styleUrls: ['./copy-details.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, MaterialModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CopyDetailsComponent implements OnInit {
  userData: any;
  employeeNumber: number = 0;

  selectedDate: any = moment().format("MM/DD/YYYY");
  copyStartDate: any = moment().format("MM/DD/YYYY");
  copyEndDate: any = moment().add(1, 'days').format("MM/DD/YYYY");
  copyEquipment: boolean = true;
  copyInventory: boolean = true;
  hideEquipmentInventory: boolean = true;

  condensed: boolean = true;
  allowCheck: boolean = true;

  dailySummary: any[] = [];
  totalHours: number = 0.0;
  totalEquipmentHours: number = 0.0;
  canSubmit: boolean = false;
  summaryDate: Date = new Date;

  details: any[] = [];
  selections: any[] = [];
  copyDate: Date = new Date;
  private selectedDateSubscription!: Subscription;
  private detailsSubscription!: Subscription;

  copyDetailsModalcopydate: boolean = false;
  copyDetailsPayHoursValidate: boolean = true;
  // copyDetailsInlinewarning: boolean = false;
  _copyDetailsInlinewarning: boolean = false;
  messagecopyStartDateError: boolean = false;
  messagecopyEndDateError: boolean = false;
  copyDetailsSelecteddate: boolean = false;


  constructor(
    private router: Router,
    private toastr: ToastrService,
    public rcService: RcService,
    private cdr: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly sharedService: SharedService,
    private readonly homeService: HomeService,
    private readonly detailsService: DetailsService,
    private calendarService: CalendarService,
    public dialogRef: MatDialogRef<CopyDetailsComponent>,
    private dialog: MatDialog
  ) {
    this.authService.getCurrentUser().subscribe((user) => {
      this.userData = user;
      this.employeeNumber = this.userData?.credentials?.EmployeeNumber;
    });
  }

  ngOnInit(): void {
    this.selectedDateSubscription = this.sharedService.selectedDate$.subscribe(date => {
      this.copyDate = date;
      this.summaryDate = this.copyDate;
    });
    this.detailsSubscription = this.sharedService.events$.subscribe(events => {
      // this.hasEvents = events && events.length > 0;
      this.details = events;
    });
    const dateObject = this.copyDate.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    this.selectedDate = dateObject;

    let copyDate = new Date(this.copyDate);
    let startDate = new Date(this.copyStartDate);
    let endDate = new Date(this.copyEndDate);

    if (!(endDate >= startDate)) {
      this.messagecopyEndDateError = true;
      this.messagecopyStartDateError = false;
    }
    if ((copyDate <= endDate && copyDate >= startDate)) {
      this.copyDetailsModalcopydate = true;
    }

    if ((copyDate <= endDate && copyDate >= startDate)) {
      this.copyDetailsInlinewarning = true;
    }

    this.loadDailySummary();
  }

  get copyDetailsInlinewarning(): boolean {
    return this._copyDetailsInlinewarning;
  }

  set copyDetailsInlinewarning(value: any) {
    if (this._copyDetailsInlinewarning !== value) {
      // Perform additional operations here
      this._copyDetailsInlinewarning = value;
      this.copyDetailsPayHoursValidate = true;
      if (this.selections.length > 0) {
        this.checkcopyDetailsPayHoursValidate();
      }
    }
  }

  // ngAfterViewInit() {
  //   this.loadDailySummary();
  // }

  ngOnDestroy(): void {
    if (this.detailsSubscription) {
      this.detailsSubscription.unsubscribe();
    }
  }

  openBeginCalendarDialog(): void {
    const dialogRef = this.dialog.open(DatepickerDialogComponent, {
      width: '450px', // Set the desired width
      panelClass: 'custom-sml-calendar',
      data: {
        selectedDate: this.copyStartDate,
        showEvents: true
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle any actions after the dialog is closed
      this.copyStartDate = result.date;

      this.copyDetailsModalcopydate = false;
      this.messagecopyStartDateError = false;
      this.messagecopyEndDateError = false;
      this.copyDetailsInlinewarning = false;
      // this.copyDetailsPayHoursValidate = true;
      // console.log('result', result);
      let copyDate = new Date(this.copyDate);
      let startDate = new Date(result.date);
      let endDate = new Date(this.copyEndDate);

      if (!(endDate >= startDate)) {
        this.messagecopyEndDateError = false;
        this.messagecopyStartDateError = true;
      }

      if ((copyDate <= endDate && copyDate >= startDate)) {
        this.copyDetailsModalcopydate = true;
      }
      if ((copyDate <= endDate && copyDate >= startDate)) {
        this.copyDetailsInlinewarning = true;
      }

      this.copyDetailsPayHoursValidate = true;
      if (this.selections.length > 0) {
        this.checkcopyDetailsPayHoursValidate();
      }

    });
  }

  openEndCalendarDialog(): void {
    const dialogRef = this.dialog.open(DatepickerDialogComponent, {
      width: '450px', // Set the desired width
      panelClass: 'custom-sml-calendar',
      data: {
        selectedDate: this.copyEndDate,
        showEvents: true
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Handle any actions after the dialog is closed
      // console.log('result', result);
      this.copyEndDate = result.date;

      this.copyDetailsModalcopydate = false;
      this.messagecopyStartDateError = false;
      this.messagecopyEndDateError = false;
      this.copyDetailsInlinewarning = false;

      let copyDate = new Date(this.copyDate);
      let startDate = new Date(this.copyStartDate);
      let endDate = new Date(this.copyEndDate);

      if (!(endDate >= startDate)) {
        this.messagecopyEndDateError = true;
        this.messagecopyStartDateError = false;
      }
      if ((copyDate <= endDate && copyDate >= startDate)) {
        this.copyDetailsModalcopydate = true;
      }
      if ((copyDate <= endDate && copyDate >= startDate)) {
        this.copyDetailsInlinewarning = true;
      }
      this.copyDetailsPayHoursValidate = true;
      if (this.selections.length > 0) {
        this.checkcopyDetailsPayHoursValidate();
      }
    });
  }

  loadDailySummary(): void {
    const empNum = this.employeeNumber;

    this.totalHours = 0.0;
    this.totalEquipmentHours = 0.0;
    this.canSubmit = false;

    this.detailsService.getDailySummary(empNum, this.selectedDate).subscribe(
      (d) => {
        this.dailySummary = d;
        // this.dataSource = new MatTableDataSource(d);
        console.log('this.dailySummary', this.dailySummary);
        for (let i = 0; i < this.dailySummary.length; i++) {
          this.dailySummary[i].programDirections = [];
          this.dailySummary[i].categoryValue = '';
          this.dailySummary[i].checked = false;
          this.totalHours += this.dailySummary[i].PayHours;
          this.totalEquipmentHours += this.dailySummary[i].EquipmentHours;

          if (this.dailySummary[i]?.DetailStatus.toLowerCase() === "new" || this.dailySummary[i]?.DetailStatus.toLowerCase() === "rejected") {
            this.canSubmit = true;
          }
        }
      },
      (error) => {
        // Handle the error
        console.error('Error loading daily summary:', error);
      }
    );
    this.cdr.detectChanges();
  }

  checkcopyDetailsPayHoursValidate() {
    this.copyDetailsPayHoursValidate = true;

    this.homeService.checkValidatePayHoursCopyTransactions(this.selections.join(','), this.copyStartDate, this.copyEndDate, this.employeeNumber).subscribe((d) => {
      if (!d) {
        this.copyDetailsPayHoursValidate = false
      }
    });
  }

  // Save selected rows logic
  // saveCopySelections(): void {
  //   const selectedIds = this.rowSelection.selected.map(row => row.TransactionNumber);
  //   // console.log('Selected Rows:', selectedIds);

  //   this.homeService.copyDetails(this.employeeNumber, selectedIds, this.copyStartDate, this.copyEndDate, this.copyEquipment, this.copyInventory).subscribe((d: any) => {
  //     this.toastr.success('Detail entry copied successfully.', 'Success');
  //     this.router.navigate(['/'], { queryParams: { dt: this.copyStartDate } });
  //   }, (error) => {
  //     this.toastr.error('Something went wrong, please try again.', 'Success');
  //   });

  // }
  saveCopySelections(): void {
    // const selectedIds = this.rowSelection.selected.map(row => row.TransactionNumber);
    // console.log('Selected Rows:', this.selections);

    this.homeService.copyDetails(this.employeeNumber, this.selections, this.copyStartDate, this.copyEndDate, this.copyEquipment, this.copyInventory).subscribe((d: any) => {
      this.toastr.success('Detail entry copied successfully.', 'Success', {
        positionClass: 'toast-bottom-left',
        toastClass: "toast-icon custom-toast-success",
      });
      const dateObject = this.selectedDate ? new Date(this.selectedDate) : new Date();
      const calendarDate = dateObject.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
  
      this.homeService.getCalendarEvents(this.employeeNumber, calendarDate).subscribe({
        next: (response) => {
          // this.calendarEvents = response;
          // Set the data in the service
          this.calendarService.setData(response);
          // this.patchValues();
        },
        error: (error) => {
          console.log('getCalendarInfo error : ', error);
        }
      });
      this.router.navigate([`/${this.rcService.getTenant()}/`], { queryParams: { dt: this.copyStartDate } });
      // window.location.href = `/${this.rcService.getTenant()}/?dt=` + this.copyStartDate;
    }, (error) => {
      this.toastr.error('Something went wrong, please try again.', 'Success', {
        positionClass: 'toast-bottom-left',
        toastClass: "toast-icon custom-toast-success",
      });
    });

  }


  selectTransaction(s: any): void {
    s.checked = !s.checked;
    this.setSelections();
    this.copyDetailsPayHoursValidate = true
    if (this.selections.length > 0) {
      this.checkcopyDetailsPayHoursValidate();
    }
    // this.checkcopyDetailsPayHoursValidate();
  }

  setSelections(): void {
    this.selections = [];
    for (let x = 0; x < this.dailySummary.length; x++) {
      if (this.dailySummary[x].checked) {
        this.selections.push(this.dailySummary[x].TransactionNumber);
      }
    }
    // You might not need $timeout here. If needed, use Angular's ChangeDetectorRef to trigger change detection.
  }

  closeCopyDetail(): void {
    this.dialogRef.close();
  }

}
