<div
  class="font-avertareguler w-full h-screen px-2 py-10 md:py-10 md:p-4 bg-white/[67%] dark:bg-slate-900/60 space-y-4 relative"
>
  <div class="flex justify-between items-center">
    <h2
      class="text-[#102030] text-[16px] md:text-[20px] font-avertaBold dark:text-white"
    >
      Add Inventory
    </h2>
    <div *ngIf="inventory.showButtons">
      <label for="hs-trailing-button-add-on-with-icon" class="sr-only"
        >Label</label
      >
      <div class="flex rounded-lg shadow-sm">
        <!-- <input
          type="text"
          id="hs-trailing-button-add-on-with-icon"
          name="hs-trailing-button-add-on-with-icon"
          class="py-3 px-4 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
          [(ngModel)]="searchQuery"
          (input)="search()"
          placeholder="Search....."
        /> -->
        <app-keyboard
          (valueChange)="onSearchQueryChange($event)"
          [fieldName]="'searchQuery'"
          [fieldType]="'text'"
          keyboardType="qwerty"
          [placeholder]="'Search...'"
          [class]="
            'bg-white duration-200 w-full border border-[#E2E2E2] py-2 px-4 rounded-lg max-[640px]:rounded-r-lg sm:rounded-r-none rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white'
          "
          [keyButtonClass]="
            'hidden sm:block keyboard-btn relative py-2 px-3 space-x-1 rounded-r-lg border border-l-0 border-[#E2E2E2] text-[16px] bg-white flex items-center dark:bg-gray-700 duration-200 dark:border-gray-500'
          "
          [maxlength]="80"
        ></app-keyboard>
      </div>
    </div>
  </div>
  <div
    class="space-y-4 popBody scrollbar-thin scrollbar-thumb-[#000733]/20 scrollbar-track-transparent overflow-y-scroll"
  >
    <div
      class="py-2"
      *ngIf="inventory.showButtons && inventory.inventoryList?.length > 0"
    >
      <div
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2"
      >
        <ng-container
          *ngFor="let item of dataSource"
        >
          <!-- [disabled]="buttonsDisabled" -->
          <div class="col-span-1 relative flex">
            <!-- [disabled]="item.selected ? buttonsDisabled : false" -->

            <!-- [ngClass]="{
                  'bg-[#39B300] to-[#39B300] dark:from-[#184900] dark:to-[#39B300]':
                  item.selected
                }" -->
            <button
              (click)="inventorySelect(item)"
              class="flex justify-center border-2 items-center py-2 min-h-32 w-full relative text-sm px-12 text-white bg-gradient-to-t from-[#011d68] to-[#0b44cd] rounded-2xl text-center duration-200 hover:from-[#184900] hover:to-[#39B300] dark:hover:from-[#184900] dark:hover:to-[#39B300]"
            >
              <!-- [attr.id]="transformTag(item.Tag)" -->

              {{ item.ButtonDescription }}
            </button>
            <ng-container *ngIf="loginemployeeNumber === employeeNumber">
              <a
                href="javascript:void(0)"
                class="z-20 h-9 w-9 rounded-full flex justify-center items-center absolute right-2 top-2"
                (click)="favouriteUnFavourite(item.Tag, item.Favoriting)"
                [ngClass]="{
                  'bg-yellow-600 text-white':
                    item.Favoriting,
                    'bg-white text-yellow-600':
                    !item.Favoriting,
                }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
              </a>
            </ng-container>
            <ng-container *ngIf="loginemployeeNumber !== employeeNumber">
              <a
                href="javascript:void(0)"
                class="z-20 h-9 w-9 rounded-full flex justify-center items-center absolute right-2 top-2"
                [ngClass]="{
                  'bg-yellow-600 text-white':
                    item.Favoriting,
                    'bg-white text-yellow-600':
                    !item.Favoriting,
                }"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                  />
                </svg>
              </a>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="py-6" *ngIf="inventory.showQuantity">
      <!-- <div class="flex mb-1">
        <span>Enter Inventory Quantity</span> <span>({{ unitOfMeasure }})</span>
      </div>
      <div class="flex mb-1">
        <span>{{ inventory.quantity }}</span>
      </div> -->
      <div
        class="text-[18px] text-[#102030] dark:text-white flex justify-center font-avertaBold mb-2"
      >
        <span>Enter Inventory Quantity</span> <span>({{ unitOfMeasure }})</span>
      </div>
      <app-calculator
        [maxValue]="inventory.availableQuantity"
        (valueChanged)="onCalculatorValueChanged($event)"
        [input]="inventory.quantity"
        [isAvailable]="false"
      ></app-calculator>
    </div>
  </div>
  <div class="flex justify-between items-center w-full popFooter">
    <div
      *ngIf="
        inventory.showButtons &&
        inventory.inventoryList.length > paginationService.pageSize
      "
      class="sm:flex sm:justify-start sm:items-center sm:space-x-2 w-full"
    >
      <div class="flex items-center space-x-0 md:space-x-1">
        <a
          href="javascript:void(0);"
          class="h-6 w-6 md:h-10 md:w-10 justify-center items-center flex text-sm bg-white border border-[#DADADA] rounded-md"
          (click)="firstPage($event)"
          [class.cursor-not-allowed]="isFirstPage()"
          [attr.disabled]="isFirstPage() ? true : null"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="h-3 w-3 md:w-4 md:h-4"
          >
            <path
              fill-rule="evenodd"
              d="M10.72 11.47a.75.75 0 0 0 0 1.06l7.5 7.5a.75.75 0 1 0 1.06-1.06L12.31 12l6.97-6.97a.75.75 0 0 0-1.06-1.06l-7.5 7.5Z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M4.72 11.47a.75.75 0 0 0 0 1.06l7.5 7.5a.75.75 0 1 0 1.06-1.06L6.31 12l6.97-6.97a.75.75 0 0 0-1.06-1.06l-7.5 7.5Z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
        <a
          href="javascript:void(0);"
          class="h-6 w-6 md:h-10 md:w-10 justify-center items-center flex text-sm bg-white border border-[#DADADA] rounded-md"
          (click)="prevPage($event)"
          [class.cursor-not-allowed]="isFirstPage()"
          [attr.disabled]="isFirstPage() ? true : null"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="h-3 w-3 md:w-4 md:h-4"
          >
            <path
              fill-rule="evenodd"
              d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
              clip-rule="evenodd"
            />
          </svg>
        </a>

        <!-- Dynamically generate page links -->
        <a
          *ngFor="let page of generatePageLinks()"
          href="javascript:void(0);"
          class="h-6 w-6 md:h-10 md:w-10 justify-center items-center flex text-black bg-[#EEF4FE] rounded-md"
          [class.hover:bg-blue-600]="page !== paginationService.currentPage"
          [class.hover:text-white]="page !== paginationService.currentPage"
          [class.transition-duration-200]="
            page !== paginationService.currentPage
          "
          [class.active]="page === paginationService.currentPage"
          (click)="paginationService.currentPage = page; updateDisplayedData()"
        >
          {{ page }}
        </a>

        <a
          href="javascript:void(0);"
          class="h-6 w-6 md:h-10 md:w-10 justify-center items-center flex text-sm bg-white border border-[#DADADA] rounded-md"
          (click)="nextPage($event)"
          [class.cursor-not-allowed]="isLastPage()"
          [attr.disabled]="isLastPage() ? true : null"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="h-3 w-3 md:w-4 md:h-4"
          >
            <path
              fill-rule="evenodd"
              d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
        <a
          href="javascript:void(0);"
          class="h-6 w-6 md:h-10 md:w-10 justify-center items-center flex text-sm bg-white border border-[#DADADA] rounded-md"
          (click)="lastPage($event)"
          [class.cursor-not-allowed]="isLastPage()"
          [attr.disabled]="isLastPage() ? true : null"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="h-3 w-3 md:w-4 md:h-4"
          >
            <path
              fill-rule="evenodd"
              d="M13.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L11.69 12 4.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M19.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06L17.69 12l-6.97-6.97a.75.75 0 0 1 1.06-1.06l7.5 7.5Z"
              clip-rule="evenodd"
            />
          </svg>
        </a>
      </div>

      <div>
        <span class="text-xs text-[#5D5D5D]"
          >Showing {{ dataSource.length }} of
          {{ inventory.inventoryList.length }}</span
        >
      </div>
    </div>
    <div
      class="flex items-center w-full text-end space-x-2"
      [ngClass]="{
        'justify-end': !inventory.showQuantity,
        'justify-between': inventory.showQuantity
      }"
    >
      <button
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#EEF4FE] text-[#1F74EC] flex items-center dark:bg-white/20 dark:text-white"
        (click)="closeDialog()"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
        <span>Close</span>
      </button>
      <button
        *ngIf="inventory.showQuantity"
        class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-gradient-to-b from-[#04BC00] to-[#026000] text-[#ffffff] flex items-center disabled:opacity-75"
        [disabled]="saving || !inventory.isValid"
        (click)="addItem()"
      >
        <!-- [disabled]="saving || (!equipment.hours || equipment.hours == '.' || equipment.hours==0)" -->
        <!-- <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </span> -->
        <span>{{ isEdit ? "Update" : "Add" }} Inventory</span>
      </button>
    </div>
  </div>

  <button
    (click)="closeDialog()"
    type="button"
    class="absolute right-[5px] top-[-15px] h-8 w-8 rounded-full flex justify-center items-center bg-gray-100 text-red dark:bg-white/20 dark:text-white"
  >
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </span>
  </button>
</div>
