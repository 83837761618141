import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  selectedDate: Date = new Date();
  selection: any = {};
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<DialogComponent>) {
    this.selection = data.selection;
    // this.employeeNumber = data.employeeNumber;
  }

  ngOnInit(): void {
      // console.log("selection.timeCardDate", this.selection.timeCardDate);
  }

  onDateSelected(date: any) {
    const dateObject = new Date(date);
    this.selectedDate = dateObject;
    const formattedDate = dateObject.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    // close the calendar dialog
    this.dialog.close();
  }

  onMonthChanged(month: any) {
    console.log('month', month)
  }
  onYearChanged(year: any) {
    console.log('year', year)
  }

  closeDialog() {
    this.dialog.close();
  }

}
