<div
  class="font-avertareguler w-full h-screen px-2 py-10 md:py-10 md:p-4 bg-white/[67%] dark:bg-slate-900/60 space-y-4 relative"
>
  <div class="flex">
    <h2
      class="text-[#102030] text-[16px] md:text-[20px] font-avertaBold dark:text-white"
    >
      {{ isEditPage ? 'Update ' : 'Add ' }} Holiday
    </h2>
  </div>
  <div
    class="space-y-4 popBody scrollbar-thin scrollbar-thumb-[#000733]/20 scrollbar-track-transparent overflow-y-scroll"
  >
    <form class="max-w-sm mx-auto" [formGroup]="holidayForm">
      <div class="mb-5">
        <label
          for="HolidayName"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Name</label
        >
        <input
          type="text"
          id="HolidayName"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500"
          placeholder="Holiday Name"
          formControlName="HolidayName"
          [ngClass]="{
            'border-green-700 dark:border-green-500':
              holidayForm.get('HolidayName')?.valid &&
              holidayForm.get('HolidayName')?.touched,
            'border-red-700 dark:border-red-500':
              holidayForm.get('HolidayName')?.invalid &&
              holidayForm.get('HolidayName')?.touched
          }"
        />
      </div>
      <div class="mb-5">
        <label
          for="HolidayDate"
          class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >Date</label
        >
        <div class="flex" (click)="openDateCalendar()">
          <input
            type="text"
            id="HolidayDate"
            placeholder="Select date..."
            class="bg-white w-full duration-200 border border-[#E2E2E2] py-2 px-4 rounded-l-lg rounded-r-none dark:bg-gray-700 dark:border-gray-500 dark:text-white"
            formControlName="HolidayDate"
            [ngClass]="{
                'border-green-700 dark:border-green-500':
                  holidayForm.get('HolidayDate')?.valid &&
                  holidayForm.get('HolidayDate')?.touched,
                'border-red-700 dark:border-red-500':
                  holidayForm.get('HolidayDate')?.invalid &&
                  holidayForm.get('HolidayDate')?.touched
              }"
          />
          <button
            class="py-2 px-3 space-x-1 rounded-r-lg border border-[#E2E2E2] text-[16px] bg-white text-gray-600 flex items-center dark:text-white dark:bg-gray-700 duration-200 dark:border-gray-500"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
              />
            </svg>
          </button>
        </div>
        <!-- <input
          type="password"
          id="password"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500"
          required
        /> -->
      </div>
      <div class="flex justify-end">
        <button
          type="button"
          (click)="onSave()"
          class="py-3 px-6 space-x-1 rounded-full text-[16px] bg-[#010D58] text-[#ffffff] flex items-center dark:bg-gray-950"
        >
          Save
        </button>
      </div>
    </form>
  </div>

  <button
    (click)="closeHolidayDialog()"
    type="button"
    class="absolute right-[5px] top-[-12px] h-8 w-8 rounded-full flex justify-center items-center bg-gray-100 text-red dark:bg-white/20 dark:text-white"
  >
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </span>
  </button>

</div>
